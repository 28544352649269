import React, { useContext, useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import OKRList from "./ui/OKRList";
import MyOKRList from "./ui/MyOKRList";
import OKRChallengeList from "./ui/OKRChallengeList";
import MyOKRsRequestModal from "./ui/MyOKRsRequestModal";
import ModalListPendingOKRs from "./ui/ModalListPendingOKRs";
import { OKRContext } from "../../context/OKRContext";
import { Button, Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const OKRDashboardPage = () => {
  const {
    auth,
    LabObjectiveList,
    ChildObjectivesList,
    TeamObjectiveList,
    MyObjectiveList,
    ChallengeObjectiveList,

    fetchListOKRsRequestToLabManagerInLabOKRs,
    fetchListOKRsRequestToMentorInLabOKRs,
    fetchListOKRsRequestToMentorInTeamOKRs,

    TeamChallengePendingList,
    InternChallengePendingList,
  } = useContext(OKRContext);

  const { searchQuery, status, sortBy } = useOutletContext();


  const [myObjectives, setMyObjectives] = useState([]);
  const [labObjectives, setLabObjectives] = useState([]);
  const [teamObjectives, setTeamObjectives] = useState([]);
  const [challengeObjectives, setChallengeObjectives] = useState([]);

  const [loadingLab, setLoadingLab] = useState(false);
  const [loadingMy, setLoadingMy] = useState(false);
  const [loadingTeam, setLoadingTeam] = useState(false);
  const [loadingChallenge, setLoadingChallenge] = useState(false);
  useEffect(() => {
    
    const fetchLabObjectives = async () => {
      setLoadingLab(true);
      try {
        const labData = await LabObjectiveList(searchQuery, status, sortBy);
        setLabObjectives(labData || []);
      } catch (error) {
        console.error("Error fetching Lab objectives:", error);
        setLabObjectives([]);
      } finally {
        setLoadingLab(false);
      }
    };
  
    fetchLabObjectives();
  }, [searchQuery, status, sortBy]);
  

  // useEffect cho My OKRs
  useEffect(() => {
    const fetchMyObjectives = async () => {
      setLoadingMy(true);
      try {
        const myData = await MyObjectiveList(searchQuery, status, sortBy);
        setMyObjectives(myData || []);
      } catch (error) {
        console.error("Error fetching My objectives:", error);
        setMyObjectives([]);
      } finally {
        setLoadingMy(false);
      }
    };

    fetchMyObjectives();
  }, [searchQuery, status, sortBy]);

  // useEffect cho Team OKRs
  useEffect(() => {
    const fetchTeamObjectives = async () => {
      setLoadingTeam(true);
      try {
        const teamData = await TeamObjectiveList(searchQuery, status, sortBy);
        setTeamObjectives(teamData || []);
      } catch (error) {
        console.error("Error fetching Team objectives:", error);
        setTeamObjectives([]);
      } finally {
        setLoadingTeam(false);
      }
    };

    fetchTeamObjectives();
  }, [searchQuery, status, sortBy]);

  // useEffect cho Challenge OKRs
  useEffect(() => {
    const fetchChallengeObjectives = async () => {
      setLoadingChallenge(true);
      try {
        const challengeData = await ChallengeObjectiveList(
          searchQuery,
          status,
          sortBy
        );
        setChallengeObjectives(challengeData || []);
      } catch (error) {
        console.error("Error fetching Challenge objectives:", error);
        setChallengeObjectives([]);
      } finally {
        setLoadingChallenge(false);
      }
    };

    fetchChallengeObjectives();
  }, [searchQuery, status, sortBy]);

  const [isMyOKRsRequestModalOpen, setIsMyOKRsRequestModalOpen] =
    useState(false);
  const [isPendingLabOKRsModalOpen, setIsPendingLabOKRsModalOpen] =
    useState(false);
  const [isPendingTeamOKRsModalOpen, setIsPendingTeamOKRsModalOpen] =
    useState(false);
  const [isPendingChallengeModalOpen, setIsPendingChallengeModalOpen] =
    useState(false);

  const openMyOKRsRequestModal = () => {
    setIsMyOKRsRequestModalOpen(true);
  };

  const closeMyOKRsRequestModal = () => {
    setIsMyOKRsRequestModalOpen(false);
  };

  const handleOpenPendingLabOKRsModal = () =>
    setIsPendingLabOKRsModalOpen(true);
  const handleClosePendingLabOKRsModal = () =>
    setIsPendingLabOKRsModalOpen(false);

  const handleOpenPendingTeamOKRsModal = () =>
    setIsPendingTeamOKRsModalOpen(true);
  const handleClosePendingTeamOKRsModal = () =>
    setIsPendingTeamOKRsModalOpen(false);

  const handleOpenPendingChallengeModal = () =>
    setIsPendingChallengeModalOpen(true);
  const handleClosePendingChallengeModal = () =>
    setIsPendingChallengeModalOpen(false);

  const isMentor = auth?.user?.roles?.includes("Mentor");
  const isLabManager = auth?.user?.roles?.includes("Lab Manager");
  const isIntern = auth?.user?.roles?.includes("Intern");
  const isJuryPanel = auth?.user?.roles?.includes("Jury Panel");

  return (
    <>
      {(isMentor || isIntern) && (
        <Button
          onClick={openMyOKRsRequestModal}
          sx={{
            backgroundColor: "#1976d2",
            color: "white",
            textTransform: "none",
            padding: "4px 8px",
            mt: 3,
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          My OKRs Requests
        </Button>
      )}

      <Box mb={2}>
        {/* Sử dụng OKRList cho Lab OKRs */}
        {!isJuryPanel && (
          <OKRList
            title="Lab OKRs"
            objectives={labObjectives}
            loading={loadingLab}
            fetchChildObjectives={ChildObjectivesList}
          />
        )}
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {(isMentor || isLabManager) && (
            <Button
              onClick={handleOpenPendingLabOKRsModal}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                textTransform: "none",
                padding: "4px 8px",
                mt: 1,
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Requests from Lab OKRs
            </Button>
          )}
        </Box>
      </Box>

      <Box mb={2}>
        <OKRChallengeList
          title="Challenge OKRs"
          objectives={challengeObjectives}
          loading={loadingChallenge}
          fetchChildObjectives={ChildObjectivesList}
        />
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {(isLabManager || isMentor) && (
            <Button
              onClick={handleOpenPendingChallengeModal}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                textTransform: "none",
                padding: "4px 8px",
                mt: 1,
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Requests from Challenge OKRs
            </Button>
          )}
        </Box>
      </Box>

      <Box mb={2}>
        {/* Chỉ hiển thị Team OKRs nếu là Mentor hoặc Intern */}
        {(isMentor || isIntern) && (
          <OKRList
            title="Team OKRs"
            objectives={teamObjectives}
            loading={loadingTeam}
            fetchChildObjectives={ChildObjectivesList}
          />
        )}
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {isMentor && (
            <Button
              onClick={handleOpenPendingTeamOKRsModal}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                textTransform: "none",
                padding: "4px 8px",
                mt: 1,
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            >
              Requests from Team OKRs
            </Button>
          )}
        </Box>
      </Box>

      {/* MyOKRsRequestModal */}
      <MyOKRsRequestModal
        open={isMyOKRsRequestModalOpen}
        onClose={closeMyOKRsRequestModal}
      />

      {/* Modal for Lab OKRs */}
      <ModalListPendingOKRs
        open={isPendingLabOKRsModalOpen}
        onClose={handleClosePendingLabOKRsModal}
        fetchPendingOKRsFunction={
          isLabManager
            ? fetchListOKRsRequestToLabManagerInLabOKRs
            : fetchListOKRsRequestToMentorInLabOKRs
        }
        title="Pending OKRs Requests in Lab OKRs"
      />

      {/* Modal for Challenge OKRs */}
      <ModalListPendingOKRs
        open={isPendingChallengeModalOpen}
        onClose={handleClosePendingChallengeModal}
        fetchPendingOKRsFunction={
          isLabManager ? TeamChallengePendingList : InternChallengePendingList
        }
        title="Pending OKRs Requests in Challenge OKRs"
      />

      {/* Modal for Team OKRs */}
      <ModalListPendingOKRs
        open={isPendingTeamOKRsModalOpen}
        onClose={handleClosePendingTeamOKRsModal}
        fetchPendingOKRsFunction={fetchListOKRsRequestToMentorInTeamOKRs}
        title="Pending OKRs Requests in Team OKRs"
      />

      {/* MyOKRList*/}
      {!isJuryPanel && (
        <MyOKRList
          title="My OKRs"
          objectives={myObjectives}
          loading={loadingMy}
        />
      )}
    </>
  );
};

export default OKRDashboardPage;
