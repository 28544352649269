import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Button,
  Dialog,
  MenuItem,
  Select,
} from "@mui/material";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import RequestDetailsPage from "./RequestDetailsPage";
import CreateRequest from "./CreateRequest";
import "./RequestPage.css";
import { toast } from "react-toastify";
import RequestList from "./RequestList";
const TicketPage = () => {
  const { t, auth } = useAuth();
  const isLabManager = auth?.user?.roles.includes("Lab Manager");
  const [tickets, setTickets] = useState([]);
  const [status, setStatus] = useState(isLabManager ? "1" : "");
  const [keyword, setKeyword] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [open, setOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const apiEndpoint = isLabManager
    ? "/Application/getLabManagerApplicationsOther"
    : "/Application/getApplications";

  useEffect(() => {
    fetchTickets();
  }, [apiEndpoint, auth.token, status]);

  const fetchTickets = async () => {
    try {
      const response = await apiAuth.get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        params: {
          status: status,
          keyword: keyword,
        },
      });
      setTickets(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching tickets:", error);
      toast.error(t(error.response?.data?.message));
      setTickets([]);
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    fetchTickets();
  };

  const handleTabChange = (event, newValue) => {
    setStatus(newValue);
  };

  const handleOpenModal = (ticketId) => {
    setSelectedTicketId(ticketId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedTicketId(null);
  };

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCreateSuccess = () => {
    fetchTickets();
    handleCloseCreateModal();
  };

  return (
    <div className="ticket-page">
      <h1>
        {auth?.user?.roles.includes("Lab Manager")
          ? "List of requests to LAB awaiting approval"
          : "List of requests to LAB"}
      </h1>

      {isLabManager ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginY: 2 }}
          >
            <TextField
              label={t("attendance.search")}
              variant="outlined"
              value={keyword}
              onChange={handleKeywordChange}
              sx={{ flexGrow: 1, marginRight: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleSearch}>
              {t("attendance.search")}
            </Button>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
            <Tabs
              value={status}
              onChange={handleTabChange}
              aria-label="Status Tabs"
            >
              <Tab label="Pending" value="1" />
              <Tab label="Approved" value="2" />
              <Tab label="Reject" value="5" />
              <Tab label="Request for leave" value="4" />
            </Tabs>
          </Box>

          <RequestList tickets={tickets} onOpenModal={handleOpenModal} />

          {selectedTicketId && (
            <Dialog open={open} onClose={handleCloseModal}>
              <RequestDetailsPage
                id={selectedTicketId}
                onClose={handleCloseModal}
                onUpdateSuccess={fetchTickets}
              />
            </Dialog>
          )}
        </>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              aria-label="Ticket Tabs"
            >
              <Tab label="View Request List" />
              <Tab label="Create Request" />
            </Tabs>
          </Box>

          {currentTab === 0 && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ marginY: 2 }}
              >
                <TextField
                  label={t("attendance.search")}
                  variant="outlined"
                  value={keyword}
                  onChange={handleKeywordChange}
                  sx={{ flexGrow: 1, marginRight: 2 }}
                />
                {/* Bộ lọc trạng thái cho các vai trò khác ngoài Lab Manager */}
                {!auth?.user?.roles.includes("Lab Manager") && (
                  <Select
                    value={status}
                    onChange={handleStatusChange}
                    displayEmpty
                    sx={{ width: 200, marginRight: 2 }}
                  >
                    <MenuItem value="">{t(`ticket.statusText.all`)}</MenuItem>
                    <MenuItem value="1">{t(`ticket.statusText.1`)}</MenuItem>
                    <MenuItem value="2">{t(`ticket.statusText.2`)}</MenuItem>
                    <MenuItem value="3">{t(`ticket.statusText.3`)}</MenuItem>
                    <MenuItem value="4">{t(`ticket.statusText.4`)}</MenuItem>
                    <MenuItem value="5">{t(`ticket.statusText.5`)}</MenuItem>
                  </Select>
                )}
                <Button variant="contained" color="primary" onClick={handleSearch}>
                  {t("attendance.search")}
                </Button>
              </Box>

              <RequestList tickets={tickets} onOpenModal={handleOpenModal} />
            </>
          )}

          {currentTab === 1 && (
            <Box sx={{ padding: 2 }}>
              <CreateRequest onCreateSuccess={handleCreateSuccess} setCurrentTab={setCurrentTab} />
            </Box>
          )}

          {selectedTicketId && (
            <Dialog open={open} onClose={handleCloseModal}>
              <RequestDetailsPage
                id={selectedTicketId}
                onClose={handleCloseModal}
                onUpdateSuccess={fetchTickets}
              />
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

const getColorStatus = (status) => {
  switch (status) {
    case 2:
      return "green";
    case 5:
      return "red";
    case 1:
      return "orange";
    default:
      return "skyblue";
  }
};

export default TicketPage;




