import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import { useAuth } from "../../customs/authService";
import { JuryPanelContext } from "../../context/JuryPanelContext";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

import GroupModal from "../Import/ui/GroupModal";

const JuryPanelList = () => {
  const navigate = useNavigate();
  const { t } = useAuth();
  const [juryPanels, setJuryPanels] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [juryIdForModal, setJuryIdForModal] = useState(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [juryIdToUpdate, setJuryIdToUpdate] = useState(null);

  const {
    fetchJuryPanels,
    fetchSearchJuryPanel,
    fetchGroupByjuryId,
    fetchJuryPanelGroup,
    fetchUpdatejuryPaneltoGroup,
  } = useContext(JuryPanelContext);
  const { fetchUpdateStatusJuryPanel } = useContext(UserContext);

  useEffect(() => {
    fetchData();
  }, [fetchJuryPanels]);

  const fetchData = async () => {
    try {
      const data = await fetchJuryPanels();
      setJuryPanels(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataSearch = async (searchKeyword) => {
    try {
      const data = await fetchSearchJuryPanel(searchKeyword);
      setJuryPanels(data);
    } catch (error) {
      console.error("Error fetching data");
    }
  };

  const changeStatus = async (juryId) => {
    try {
      showLoading();
      await fetchUpdateStatusJuryPanel(juryId);
      toast.success("Update Status Jury Panel Success");
      navigate("/members");
    } catch (error) {
      toast.error("Update Status Jury Panel Error:");
    } finally {
      hideLoading();
    }
  };

  const handleSearch = () => {
    if (searchKeyword) {
      fetchDataSearch(searchKeyword);
    } else {
      fetchData();
    }
  };

  const handleGroupClick = (jury) => {
    setJuryIdForModal(jury.id);
    setOpenGroupModal(true);
  };

  const handleOpenConfirmationDialog = (juryId) => {
    setJuryIdToUpdate(juryId);
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setJuryIdToUpdate(null);
  };

  const handleConfirmChangeStatus = () => {
    if (juryIdToUpdate) {
      changeStatus(juryIdToUpdate);
    }
    setOpenConfirmationDialog(false);
    setJuryIdToUpdate(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <TextField
          label={t("members.searchKey")}
          variant="outlined"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          sx={{
            mr: 2,
            minWidth: 400,
            "& .MuiInputBase-root": {
              height: "40px",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiInputLabel-root": {
              lineHeight: "15px",
            },
          }}
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ height: "40px" }}
        >
          {t("members.search")}
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ maxHeight: 500, overflowY: "auto" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", width: "20%" }}>
                {t("members.jury.avatar")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "30%" }}>
                {t("members.jury.fullName")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "40%" }}>
                {t("members.jury.email")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }} align="center">
                {t("members.jury.group")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }} align="center">
                {t("members.jury.status")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {juryPanels.length > 0 ? (
              juryPanels.map((jury) => (
                <TableRow key={`jury-${jury.id}`}>
                  <TableCell align="center">
                    <Avatar
                      src={jury.avatar || ""}
                      alt={jury.fullName}
                      sx={{ width: 50, height: 50 }}
                    />
                  </TableCell>
                  <TableCell>{jury.fullName}</TableCell>
                  <TableCell>{jury.email}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: 100 }}
                      onClick={() => handleGroupClick(jury)}
                    >
                      {t("members.jury.group")}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color={jury.status ? "success" : "error"}
                      onClick={() => handleOpenConfirmationDialog(jury.id)} // Open confirmation dialog
                      sx={{ width: 120 }}
                    >
                      {jury.status ? t("members.active") : t("members.inactive")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  {t("members.noJuryFound")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Group Modal */}
      <GroupModal
        open={openGroupModal}
        onClose={() => setOpenGroupModal(false)}
        juryId={juryIdForModal}
        fetchGroupByjuryId={fetchGroupByjuryId}
        fetchJuryPanelGroup={fetchJuryPanelGroup}
        fetchUpdatejuryPaneltoGroup={fetchUpdatejuryPaneltoGroup}
        t={t}
      />

      {/* Confirmation Dialog for changing status */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>{"Confirm"}</DialogTitle>
        <DialogContent>
          <p>{"Do you confirm the change in status of the Jury Panel?"}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="error">
            {"No"}
          </Button>
          <Button
            onClick={handleConfirmChangeStatus}
            color="primary"
            variant="contained"
          >
            {"Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JuryPanelList;
