import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Button,
  Stack,
  IconButton,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import JuryPanelContext from "../../context/JuryPanelContext";
import AddGroupModal from "./AddGroupModal";
import JuryPanelAddModal from "./ui/JuryPanelAddModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const JuryPanelGroupList = () => {
  const navigate = useNavigate();
  const [juryGroups, setJuryGroups] = useState([]);
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openJuryPanelAddModal, setOpenJuryPanelAddModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [juryIdToUpdate, setJuryIdToUpdate] = useState(null);
  const [groupIdToUpdate, setGroupIdToUpdate] = useState(null);
  const {
    fetchJuryPanelGroupHaveMember,
    fetchDeleteJuryOfGroup,
    fetchSearchGroup,
  } = useContext(JuryPanelContext);

  const fetchData = async () => {
    try {
      const data = await fetchJuryPanelGroupHaveMember();
      setJuryGroups(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchJuryPanelGroupHaveMember]);

  const handleOpenAddGroupModal = () => setOpenAddGroupModal(true);
  const handleCloseAddGroupModal = () => setOpenAddGroupModal(false);

  const handleCloseJuryPanelAddModal = () => setOpenJuryPanelAddModal(false);

  const handleDelete = async (juryId, groupId) => {
    try {
      const result = await fetchDeleteJuryOfGroup(juryId, groupId);

      toast.success("Jury panel deleted successfully.", result.success);
      navigate("/members");
    } catch (error) {
      console.error("Error deleting jury panel:", error);
    }
  };
  const handleSearch = () => {
    if (searchKeyword) {
      fetchDataSearch(searchKeyword);
    } else {
      fetchData();
    }
  };
  const fetchDataSearch = async (searchKeyword) => {
    try {
      const data = await fetchSearchGroup(searchKeyword);
      setJuryGroups(data);
    } catch (error) {
      console.error("Error fetching data");
    }
  };
  const handleOpenConfirmationDialog = (juryId, groupId) => {
    setJuryIdToUpdate(juryId, groupId);
    setGroupIdToUpdate(groupId);
    setOpenConfirmationDialog(true);
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setJuryIdToUpdate(null);
    setGroupIdToUpdate(null);
  };
  const handleConfirmChangeStatus = () => {
    if (juryIdToUpdate) {
      handleDelete(juryIdToUpdate, groupIdToUpdate);
    }
    setOpenConfirmationDialog(false);
    setJuryIdToUpdate(null);
    setGroupIdToUpdate(null);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Để căn đều hai phần tử ở hai đầu
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        {/* Phần tìm kiếm nằm bên trái */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Search Group Name"
            variant="outlined"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            sx={{
              mr: 2,
              minWidth: 400,
              "& .MuiInputBase-root": {
                height: "40px",
                display: "flex",
                alignItems: "center",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{ height: "40px" }}
          >
            Search
          </Button>
        </Box>

        {/* Nút Add Group với icon */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
          onClick={handleOpenAddGroupModal}
          startIcon={<GroupAddIcon />} // Thêm icon vào nút
        >
          Add Group
        </Button>
      </Box>

      {juryGroups?.map((group) => (
        <Box key={group.id} sx={{ marginBottom: 4 }}>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ m: 2 }}
            >
              <Typography variant="h6" gutterBottom>
                <strong>Name Group: {group.name}</strong>{" "}
                <Typography variant="body2" component="span">
                  (From Day{" "}
                  {new Date(group.startDate).toLocaleDateString("en-GB")} To Day{" "}
                  {new Date(group.endDate).toLocaleDateString("en-GB")})
                </Typography>
              </Typography>
            </Stack>

            {/* Kiểm tra xem nhóm có chứa juryPanels hay không */}
            {group.juryPanels.length === 0 ? (
              <Typography
                variant="body1"
                sx={{ color: "red", textAlign: "center", padding: 2 }}
              >
                Not Jury Panel in group
              </Typography>
            ) : (
              <Box sx={{ maxHeight: 300, overflowY: "auto", padding: 0 }}>
                <Table stickyHeader sx={{ borderTop: "1px solid #e0e0e0" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.juryPanels.map((panel) => (
                      <TableRow key={panel.id}>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Avatar
                              src={panel.avatar}
                              alt={panel.fullName}
                              sx={{ mr: 1 }}
                            />
                            {panel.fullName.trim() || "N/A"}
                          </Box>
                        </TableCell>
                        <TableCell>{panel.email || "N/A"}</TableCell>
                        <TableCell>
                          {panel.phoneNumber || "0123456789"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{
                              color: panel.status ? "green" : "red", // Xanh nếu Active, đỏ nếu Inactive
                              fontWeight: "bold",
                            }}
                          >
                            {panel.status ? "Active" : "Inactive"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleOpenConfirmationDialog(panel.id, group.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </TableContainer>
        </Box>
      ))}

      <AddGroupModal
        open={openAddGroupModal}
        handleClose={handleCloseAddGroupModal}
        onAddSuccess={fetchData} // Callback to reload data
      />

      {/* Pass selectedGroupId to JuryPanelAddModal */}
      <JuryPanelAddModal
        open={openJuryPanelAddModal}
        onClose={handleCloseJuryPanelAddModal}
        juryGroups={juryGroups}
      />
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>{"Confirm"}</DialogTitle>
        <DialogContent>
          <p>{"Do you confirm removing Jury Panel from this Group?"}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="error">
            {"No"}
          </Button>
          <Button
            onClick={handleConfirmChangeStatus}
            color="primary"
            variant="contained"
          >
            {"Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JuryPanelGroupList;
