import React, { useState } from "react";
import { Box, Button, TextField, Select, MenuItem, Typography } from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import { useAuth } from "../../customs/authService";
import { toast } from "react-toastify";

const CreateTicketPage = ({ onCreateSuccess, setCurrentTab }) => {
  const { auth } = useAuth();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("leave"); // Mặc định là đơn xin nghỉ
  const [date, setDate] = useState(""); // Ngày cho khiếu nại điểm danh nếu cần
  const [dateError, setDateError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const handleSave = async () => {
    if (!title) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (!description) {
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }

    if (!title || !description) {
      return;
    }
    try {
      if (type === "attendance") {
        const response = await apiAuth.post(
          "/Application/createApplicationsAttendance",
          {
            title,
            description,
            date,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        toast.success("Attendance complaint request created successfully!");
      } else {
        const response = await apiAuth.post(
          "/Application/create-application",
          {
            title,
            description,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        toast.success("Request created successfully!");
      }
      if (!auth?.user?.roles.includes("Lab Manager")) {
        setCurrentTab(0);
      }

      if (onCreateSuccess) onCreateSuccess();
    } catch (error) {

      if (error.response) {
        const errorMessage = error.response.data.message || "Error creating request.";
        toast.info(errorMessage);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  const today = new Date().toISOString().split("T")[0];
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    if (selectedDate < today) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: 1500,
        margin: "auto",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Create Request
      </Typography>

      <Select
        label="Type"
        value={type}
        onChange={(e) => setType(e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value="leave">Request for other</MenuItem>
        {auth?.user?.roles.includes("Intern") && (
          <MenuItem value="attendance">Request for leave</MenuItem>
        )}
      </Select>

      {type === "attendance" && (
        <TextField
          label="Date"
          type="date"
          fullWidth
          value={date}
          onChange={handleDateChange}
          sx={{ marginBottom: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: today,
          }}
          error={dateError}
          helperText={dateError ? "Please select a date from today onward." : ""}
        />
      )}

      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ marginBottom: 2 }}
        error={titleError}
        helperText={titleError ? "Title is required." : ""}
      />
      
      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{ marginBottom: 2 }}
        error={descriptionError}
        helperText={descriptionError ? "Description is required." : ""}
      />

      <Button variant="contained" color="primary" fullWidth onClick={handleSave} disabled={dateError}>
        CREATE REQUEST
      </Button>
    </Box>
  );
};

export default CreateTicketPage;
