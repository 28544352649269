import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { OKRContext } from "../../../context/OKRContext";
import { formatDate, truncateTarget } from "./format";
import KeyResultsModal from "./KeyResultsModal";

export default function OKRsLinkRequest({ objectiveId }) {
  const {
    fetchOKRsLinkRequestListByObjectiveId,
    updateStatusApproveObjective,
  } = useContext(OKRContext);
  const [okrRequests, setOkrRequests] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [requestToReject, setRequestToReject] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchOKRsLinkRequestListByObjectiveId(objectiveId);
        setOkrRequests(data.ChildObjectives || []);
      } catch (error) {
        console.error("Error fetching OKRs link requests:", error);
      }
    };
    fetchData();
  }, [fetchOKRsLinkRequestListByObjectiveId, objectiveId]);

  const handleOpenModal = (objectiveId) => {
    setSelectedObjectiveId(objectiveId);
    setOpenModal(true);
  };

  const handleOpenConfirmDialog = (okrId) => {
    setRequestToReject(okrId);
    setOpenConfirmDialog(true);
  };

  const handleApproveRequest = async (okrId) => {
    try {
      await updateStatusApproveObjective(okrId, "Approved");
      const updatedPendingObjectives =
        await fetchOKRsLinkRequestListByObjectiveId();
      setOkrRequests(updatedPendingObjectives);
    } catch (error) {
      console.error("Error approving request:", error);
    }
  };

  const handleRejectRequest = async () => {
    try {
      await updateStatusApproveObjective(requestToReject, "Rejected");
      const updatedPendingObjectives =
        await fetchOKRsLinkRequestListByObjectiveId(objectiveId);
      setOkrRequests(updatedPendingObjectives.ChildObjectives || []);
      setOpenConfirmDialog(false);
      setRequestToReject(null);
    } catch (error) {
      console.error("Error rejecting request:", error);
    }
  };

  return (
    <Box>
      <Typography
        variant="h6"
        component="h3"
        sx={{ fontWeight: "bold", mb: 2, marginTop: 2 }}
      >
        OKRs Link Request
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "white",
          border: "1px solid #ddd",
          borderRadius: 1,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Target
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Creator
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Key Results
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Start Date
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                End Date
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                StatusApprove
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {okrRequests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="left">
                  No objectives need to be approved.
                </TableCell>
              </TableRow>
            ) : (
              okrRequests.map((okr, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={
                          okr.User.Avatar
                            ? `data:image/png;base64,${okr.User.Avatar}`
                            : "/default-avatar.png"
                        }
                        alt={`${okr.User.FirstName} ${okr.User.LastName}`}
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <strong>{truncateTarget(okr.Target)}</strong>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{`${okr.User.FirstName} ${okr.User.LastName}`}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleOpenModal(okr.Id)}
                    >
                      {okr.KeyResults.length} Key Results
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(okr.StartDate)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(okr.EndDate)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Approved">
                      <IconButton
                        color="success"
                        onClick={() => {
                          console.log(`Approved objectiveId: ${okr.Id}`);
                          handleApproveRequest(okr.Id);
                        }}
                      >
                        <CheckCircleIcon />
                      </IconButton>{" "}
                    </Tooltip>
                    <Tooltip title="Rejected">
                      <IconButton
                        color="error"
                        onClick={() => {
                          console.log(`Rejected objectiveId: ${okr.Id}`);
                          handleOpenConfirmDialog(okr.Id);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Key Results Modal */}
      <KeyResultsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        objectiveId={selectedObjectiveId}
      />

      {/* Confirm Dialog for Rejecting Request */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={1}>
            <WarningAmberIcon color="error" />
            Confirm Rejection
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reject this request? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmDialog(false)}
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
              "&:hover": { backgroundColor: "#115293" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRejectRequest}
            sx={{
              backgroundColor: "#d32f2f",
              color: "white",
              "&:hover": { backgroundColor: "#9a0007" },
            }}
            autoFocus
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
