import React, { useEffect, useState } from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Button,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {statusStyles} from "../Okr/ui/format"
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Home = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalObjectives, setObjectivesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartType, setChartType] = useState("line");
  const { t } = useAuth();
  const formatLabel = (label) => {
    return label
      .replace(/_/g, " ") // Thay dấu gạch dưới bằng khoảng trắng
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()); // Viết hoa từng từ
  };

  const normalizeObjectivesData = (data) => {
    const defaultStatuses = [
      "Not_Started",
      "In_Progress",
      "Accomplished",
      "Not_Achieved",
      "Unknown",
    ];

    // Trả về các trạng thái mặc định với giá trị 0 nếu không tìm thấy dữ liệu
    return defaultStatuses.map((status) => {
      const found = data?.find((item) => item.status === status);
      return {
        status: formatLabel(status), // Chuyển đổi nhãn để hiển thị đẹp
        count: found ? found.count : 0, // Nếu không tìm thấy thì mặc định là 0
      };
    });
  };

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const response = await apiAuth.get("/DashBoard/intern-summary");
        const data = response.data || {};

        if (
          Array.isArray(data.attendanceSummary) &&
          (Array.isArray(data.objectivesSummary) ||
            data.keyResultsSummary === null)
        ) {
          setAttendanceData(data.attendanceSummary);
          setObjectivesData(
            normalizeObjectivesData(data.objectivesSummary || [])
          );
        } else {
          setError("Invalid data format received from API");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSummaryData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  const attendanceLabels = ["Attended", "Absent", "Late In", "Soon Out"];
  const attendanceDatasets = [
    {
      label: t("report.attended"),
      data: [
        attendanceData.reduce(
          (sum, item) => sum + (item.totalAttendanced || 0),
          0
        ),
        attendanceData.reduce((sum, item) => sum + (item.totalAbsent || 0), 0),
        attendanceData.reduce((sum, item) => sum + (item.totalLateIn || 0), 0),
        attendanceData.reduce((sum, item) => sum + (item.totalSoonOut || 0), 0),
      ],
      backgroundColor: ["#33CC00", "#CC3300", "#facc15", "#9ca3af"],
      borderColor: ["#33CC00", "#CC3300", "#facc15", "#9ca3af"],
      borderWidth: 1,
      tension: 0.4,
    },
  ];

  const attendanceChartData = {
    labels: attendanceLabels,
    datasets: attendanceDatasets,
  };

  // Prepare data for key results chart
  const objectivesLabels = (totalObjectives || []).map((item) => item.status);

  const objectivesDatasets = [
    {
      data: (totalObjectives || []).map((item) => item.count),
      backgroundColor: (totalObjectives || []).map(
        (item) => statusStyles[item.status.replace(/ /g, "_")]?.color || "#000"
      ),
      borderColor: (totalObjectives || []).map(
        (item) => statusStyles[item.status.replace(/ /g, "_")]?.color || "#000"
      ),
      borderWidth: 1,
      tension: 0.4,
    },
  ];

  const objectivesChartData = {
    labels: objectivesLabels,
    datasets: objectivesDatasets,
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: chartType === "pie",
        position: "bottom",
        labels: {
          boxWidth: 25,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ": " + tooltipItem.raw;
          },
        },
      },
      datalabels: {
        display: chartType === "pie",
        color: "#fff",
        formatter: (value) => value,
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
  };

  const handleChartTypeChange = () => {
    setChartType((prevType) => {
      if (prevType === "pie") return "bar";
      if (prevType === "bar") return "line";
      return "pie";
    });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: chartType === "pie", // Chỉ hiển thị legend khi là biểu đồ tròn
        position: "bottom",
        labels: {
          boxWidth: 25,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
      // Cấu hình cho ChartDataLabels
      datalabels: {
        display: chartType === "pie",
        color: "#fff",
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce(
            (acc, val) => acc + val,
            0
          );
          const percentage = ((value / total) * 100).toFixed(1);
          return `${percentage}%`; // Hiển thị phần trăm
        },
        font: {
          weight: "bold",
          size: 10,
        },
      },
    },
    layout: {
      padding: 20,
    },
    maintainAspectRatio: false,
    aspectRatio: 2.5,
  };

  return (
    <Card style={{ width: "100%", margin: "auto" }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {t("reportV.dashBoard")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ddd",
                padding: 2,
                paddingBottom: 5,
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "400px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                {t("report.attendanceOverview")}
              </Typography>
              <div style={{ width: "100%", height: "100%" }}>
                {chartType === "pie" ? (
                  <Pie data={attendanceChartData} options={chartOptions} />
                ) : chartType === "line" ? (
                  <Line data={attendanceChartData} options={chartOptions} />
                ) : (
                  <Bar data={attendanceChartData} options={chartOptions} />
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ddd",
                padding: 2,
                paddingBottom: 5,
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "400px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                Objectives Overview
              </Typography>
              <div style={{ width: "100%", height: "100%" }}>
              {chartType === "pie" ? (
                <Pie data={objectivesChartData} options={options} />
              ) : chartType === "bar" ? (
                <Bar data={objectivesChartData} options={options} />
              ) : (
                <Line data={objectivesChartData} options={options} />
              )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                marginTop: "20px",
                border: "1px solid #ddd",
                padding: 2,
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
                width: "50%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                {t("reportV.totalObjectives")}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h5"
                  sx={{ color: "#2196f3", fontWeight: "bold" }}
                >
                  {totalObjectives.reduce((sum, item) => sum + item.count, 0)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <Button
          onClick={handleChartTypeChange}
          variant="contained"
          sx={{ marginTop: 5 }}
        >
          {t("report.toggleChartType")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Home;
