import React, { useState, useContext } from "react";
import { Box, Button, Modal, Typography, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import JuryPanelContext from "../../context/JuryPanelContext";
import { toast } from "react-toastify";

const AddGroupModal = ({ open, handleClose, onAddSuccess }) => {
  const [formData, setFormData] = useState({
    groupName: "",
    startDate: "",
    endDate: "",
    maxPeople: "",
  });
  const [errors, setErrors] = useState({});
  const { fetchAddGroupJuryPanel } = useContext(JuryPanelContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    const now = new Date().getTime();
    const startDate = new Date(formData.startDate).getTime();
    const endDate = new Date(formData.endDate).getTime();
  
    if (!formData.groupName) newErrors.groupName = "Group name is required.";
    if (!formData.startDate) newErrors.startDate = "Start date is required.";
    if (!formData.endDate) newErrors.endDate = "End date is required.";
    if (!formData.maxPeople) newErrors.maxPeople = "Maximum number of people is required.";
    if (formData.maxPeople && formData.maxPeople <= 0) newErrors.maxPeople = "Maximum number of people must be greater than 0.";
    if (formData.startDate && startDate < now) {
      newErrors.startDate = "Start date must be in the future.";
    }
    if (formData.startDate && formData.endDate && startDate >= endDate) {
      newErrors.endDate = "End date must be after the start date.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validate()) {
      try {
        const response = await fetchAddGroupJuryPanel(formData);

        if (response.status===200) {
          if (onAddSuccess) onAddSuccess();
          handleCloseAndReset(); // Đóng và reset form data
          toast.success("Add Group Success")
        } else {
          toast.error("Add Group failed")
        }
      } catch (error) {
        toast.error("Error adding group:", error);
      }
    }
};


  // Reset form data and errors
  const resetForm = () => {
    setFormData({
      groupName: "",
      startDate: "",
      endDate: "",
      maxPeople: "",
    });
    setErrors({});
  };

  // Close modal and reset form data
  const handleCloseAndReset = () => {
    resetForm();
    handleClose();
  };

  const currentDateTime = new Date(new Date().getTime() + 7 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 16);

  return (
    <Modal open={open} onClose={handleCloseAndReset}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <IconButton
          onClick={handleCloseAndReset}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "grey.500",
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" gutterBottom>
          Add New Group
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Group Name *"
            variant="outlined"
            margin="normal"
            name="groupName"
            value={formData.groupName}
            onChange={handleChange}
            error={!!errors.groupName}
            helperText={errors.groupName}
          />
          <TextField
            fullWidth
            label="Start Date *"
            type="datetime-local"
            variant="outlined"
            margin="normal"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: currentDateTime }}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
          <TextField
            fullWidth
            label="End Date *"
            type="datetime-local"
            variant="outlined"
            margin="normal"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: currentDateTime }}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
          <TextField
            fullWidth
            label="Limited Number Of People *"
            variant="outlined"
            margin="normal"
            name="maxPeople"
            type="number"
            value={formData.maxPeople}
            onChange={handleChange}
            error={!!errors.maxPeople}
            helperText={errors.maxPeople}
          />
          <Box sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddGroupModal;
