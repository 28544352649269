import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Close";
import FeedbackIcon from "@mui/icons-material/Feedback";

const HistoryModal = ({
  isOpen,
  onClose,
  historyData,
  handleNavigateToFeedback,
}) => (
  <Modal open={isOpen} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        pt: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{ display: "flex", justifyContent: "space-between" }}
        startIcon={<FeedbackIcon />}
      >
        History Report

        <IconButton aria-label="close" color="error" onClick={onClose}>
          <DeleteIcon />
        </IconButton>
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ mt: 2, maxHeight: "300px", overflowY: "auto" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Report Date</TableCell>
              <TableCell>Next Report Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.length > 0 ? (
              historyData.map((history) => (
                <TableRow key={history.id}>
                  <TableCell>{history.title}</TableCell>
                  <TableCell>
                    {new Date(history.reportDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(history.nextReportDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell sx={{ wordBreak: "break-word" }}>
                    {history.status.replace(/_/g, " ")}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color={
                        history.status === "Passed"
                          ? "success"
                          : history.status === "Not_Approved_Yet"
                          ? "warning"
                          : "error"
                      }
                      onClick={() => handleNavigateToFeedback(history.id)}
                    >
                      Feedback
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Modal>
);

export default HistoryModal;
