import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Modal,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OKRContext } from "../../../context/OKRContext";
import { truncateTarget, formatDate, getStatusApproveStyle } from "./format";
import { useNavigate } from "react-router-dom";
// import KeyResultsModal from "./KeyResultsModal";

export default function MyOKRsRequestModal({ open, onClose }) {
  const {
    auth,
    fetchMyOKRsRequestwithRoleIntern,
    fetchMyOKRsRequestwithRoleMentor,
  } = useContext(OKRContext);
  const [objectives, setObjectives] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  // const [openKeyResultsModal, setOpenKeyResultsModal] = useState(false);
  // const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchObjectives = async () => {
      if (open && !hasFetched) {
        setLoading(true);
        let fetchedObjectives = [];
        if (auth?.user?.roles?.includes("Intern")) {
          fetchedObjectives = await fetchMyOKRsRequestwithRoleIntern();
        } else if (auth?.user?.roles?.includes("Mentor")) {
          fetchedObjectives = await fetchMyOKRsRequestwithRoleMentor();
        }
        setObjectives(fetchedObjectives || []);
        setLoading(false);
        setHasFetched(true);
      }
    };

    fetchObjectives();
  }, [
    open,
    hasFetched,
    auth,
    fetchMyOKRsRequestwithRoleIntern,
    fetchMyOKRsRequestwithRoleMentor,
  ]);

  function handleClose() {
    onClose();
    setObjectives([]);
    setHasFetched(false);
  }

  // function handleOpenKeyResultsModal(objectiveId) {
  //   setSelectedObjectiveId(objectiveId);
  //   setOpenKeyResultsModal(true);
  // }

  // function handleCloseKeyResultsModal() {
  //   setOpenKeyResultsModal(false);
  //   setSelectedObjectiveId(null);
  // }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 1,
          width: "70vw",
          maxHeight: "70vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            mb: 2, // thêm margin-bottom để tách tiêu đề khỏi nội dung
          }}
        >
          My OKRs Request
        </Typography>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : objectives.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography variant="body1">No OKRs Request</Typography>
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "40%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Target
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Creator
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Key Results
                </TableCell> */}
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Start Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  End Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Status Approve
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objectives.map((objective) => (
                <TableRow key={objective.id}>
                  <TableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/okr-detail/${objective.id}`)}
                  >
                    <Avatar
                      alt={`${objective.user.firstName} ${objective.user.lastName}`}
                      src={
                        objective.user.avatar
                          ? `data:image/png;base64,${objective.user.avatar}`
                          : "/default-avatar.png"
                      }
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <strong>{truncateTarget(objective.target)}</strong>
                  </TableCell>
                  <TableCell
                    sx={{ textAlign: "center" }}
                  >{`${objective.user.firstName} ${objective.user.lastName}`}</TableCell>
                  {/* <TableCell sx={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      sx={{ padding: "2px 4px" }}
                      onClick={() => handleOpenKeyResultsModal(objective.id)}
                    >
                      {objective.keyResults.length} Key Results
                    </Button>
                  </TableCell> */}
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatDate(objective.startDate)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatDate(objective.endDate)}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      ...getStatusApproveStyle(objective.statusApprove),
                    }}
                  >
                    {objective.statusApprove}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
      {/* <KeyResultsModal
        open={openKeyResultsModal}
        onClose={handleCloseKeyResultsModal}
        objectiveId={selectedObjectiveId}
      /> */}
    </Modal>
  );
}
