import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

const CompletionEvaluation = ({ value, onChange,disabled }) => (
  <RadioGroup value={value || "Not_Good"} onChange={(e) => onChange(e.target.value)}>
    <FormControlLabel
      value="Very_Good"
      control={<Radio sx={{ color: "#94d87a", "&.Mui-checked": { color: "#4caf50" } }} />}
      label="Very Good"
      sx={{ "& .MuiFormControlLabel-label": { color: "black" } }} // Keep label text black
      disabled={disabled}
    />
    <FormControlLabel
      value="Good"
      control={<Radio sx={{ color: "#ffa726", "&.Mui-checked": { color: "#fb8c00" } }} />}
      label="Good"
      sx={{ "& .MuiFormControlLabel-label": { color: "black" } }} // Keep label text black
      disabled={disabled}
   />
    <FormControlLabel
      value="Not_Good"
      control={<Radio sx={{ color: "#e57373", "&.Mui-checked": { color: "#f44336" } }} />}
      label="Not Good"
      sx={{ "& .MuiFormControlLabel-label": { color: "black" } }} // Keep label text black
      disabled={disabled}
    />
  </RadioGroup>
);

export default CompletionEvaluation;
