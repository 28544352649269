import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  CssBaseline,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Divider,
  Slide,
  Switch,
  FormControlLabel,
  Stack,
  Avatar,
  IconButton,
  Modal,
} from "@mui/material";
import DropPDF from "./PDFDroper";
import { PDFModal } from "./ModalPDF";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import cameraAuth from "../../customs/axiosBackendAI";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import { PhotoCamera } from "@mui/icons-material";
import i18n from "../../assets/configs/language";

const Profile = () => {
  const { t, auth, isIntern } = useAuth();
  const languages = ["en", "vi"];

  const [formValues, setFormValues] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [openFaceUpload, setOpenFaceUpload] = useState(false); // Quản lý mở modal upload ảnh nhận diện
  const [faceImages, setFaceImages] = useState([null]);
  useEffect(() => {
    showLoading();
    apiAuth
      .get("/user")
      .then((res) => {
        setFormValues(res?.data);
        setInitialValues(res?.data);
      })
      .catch((error) => {
        if (error.response?.message) {
          toast.error(t(error.response?.message));
        } else {
          toast.error(t("systemError"));
        }
      })
      .finally(() => hideLoading());
    // eslint-disable-next-line
  }, [auth]);

  const handleOpenFaceUpload = async () => {
    setOpenFaceUpload(true);
    try {
      const response = await apiAuth.get("/UserImage/user-images");
      const images = response.data; // Lấy danh sách URL ảnh
      setFaceImages([...images, ...Array(3 - images.length).fill(null)]);
    } catch (error) {
      if (error.response?.status === 404) {
        setFaceImages([null, null, null]);
      } else {
        toast.error("An error occurred while loading the image.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleDrop = (acceptedFiles) => {
    const droppedFile = acceptedFiles[0];
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (acceptedFiles[0].size > maxSize) {
      toast.error("File size exceeds 5MB limit. Please choose a smaller file.");
      return;
    }
    if (droppedFile.type === "application/pdf") {
      setFormValues({
        ...formValues,
        cv: droppedFile,
      });
    } else {
      toast.error("Please drop a PDF file.");
    }
  };

  const handleAvatarDrop = () => (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB
      const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];

      if (!allowedTypes.includes(file.type)) {
        toast.error("Invalid file type. Please upload an image (JPEG, PNG, GIF).");
        return;
      }
      if (file.size > maxSize) {
        toast.error(
          "File size exceeds 5MB limit. Please choose a smaller file."
        );
        return;
      }

      setFormValues({ ...formValues, avatar: file });
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateProfile = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setFormValues(initialValues);
    setIsEditMode(false);
    setErrors(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    showLoading();
    apiAuth
      .post(`/user/update`, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        i18n.changeLanguage(formValues.language);
        toast.success(t(res?.data?.message));
        setInitialValues(formValues);
        setIsEditMode(false);
        setErrors(null);
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          setErrors(error.response?.data);
          toast.error(t("validate"));
        } else {
          toast.error(t(error?.response?.data?.message));
        }
      })
      .finally(() => hideLoading());
  };

  // Mới thêm: Xử lý upload ảnh nhận diện
  const handleFaceImageDrop = (event, index) => {
    const file = event.target.files[0];
    const maxSize = 5 * 1024 * 1024; // Giới hạn kích thước 5MB cho ảnh

    if (file.size > maxSize) {
      toast.error(`File ${file.name} vượt quá kích thước 5MB.`);
      return;
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Invalid file type. Please upload an image (JPEG, PNG, GIF).");
      return;
    }

    // Cập nhật ảnh tại vị trí index
    setFaceImages([file]); // Chỉ lưu 1 ảnh
  };


  const handleSaveFaceImages = () => {
    if (faceImages[0] === null) {
      toast.error("You must upload 1 image.");
      return;
    }

    const formData = new FormData();
    formData.append("file", faceImages[0]); // Chỉ upload 1 ảnh

    formData.append("userId", formValues.userId);

    showLoading();
    apiAuth
      .post("/UserImage/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("The identification image has been uploaded successfully.");
          setOpenFaceUpload(false); // Đóng modal sau khi upload thành công
        } else {
          throw new Error("Upload image failed");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while uploading the image.");
      })
      .finally(() => hideLoading());
  };


  const avatarSrc =
    typeof formValues?.avatar === "string"
      ? formValues.avatar
      : formValues?.avatar instanceof Blob
        ? URL.createObjectURL(formValues.avatar)
        : null;

  const cvSrc =
    typeof formValues?.cv === "string"
      ? formValues?.cv
      : formValues?.cv instanceof Blob
        ? URL.createObjectURL(formValues?.cv)
        : null;

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h3">
          {t("profile")}
        </Typography>
        <Paper elevation={3} sx={{ padding: 3, width: "100%", marginTop: 3 }}>
          <Box component="form" sx={{ mt: 3, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item lg={6} sm={12}>
                <Stack spacing={2} alignItems="center" sx={{ p: 3 }}>
                  <Avatar
                    alt="Avatar"
                    src={avatarSrc}
                    sx={{ width: 150, height: 150, border: "solid 1px" }}
                  />
                  {isEditMode && (
                    <>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="avatar-upload"
                        type="file"
                        onChange={handleAvatarDrop()}
                      />
                      <label htmlFor="avatar-upload">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Stack>
                {/* Hiển thị nút mở modal nếu có file PDF */}
                {formValues?.cv && isIntern && (
                  <Grid container spacing={2}>
                    <Grid item xs={isEditMode ? 6 : 12}>
                      <Button
                        onClick={handleOpenModal}
                        fullWidth
                        variant="outlined"
                        color="primary"
                      >
                        View PDF
                      </Button>
                    </Grid>
                    {isEditMode ? (
                      <Grid item xs={6}>
                        <Button
                          onClick={() =>
                            setFormValues({ ...formValues, cv: null })
                          }
                          fullWidth
                          variant="outlined"
                          color="error"
                        >
                          Clear PDF
                        </Button>
                      </Grid>
                    ) : null}
                    <PDFModal
                      openModal={openModal}
                      formValues={cvSrc}
                      handleCloseModal={handleCloseModal}
                    />
                  </Grid>
                )}
                {!formValues?.cv && isIntern && isEditMode && (
                  <DropPDF onDrop={handleDrop} />
                )}

                <FormControl fullWidth margin="normal">
                  <InputLabel id="language-label">{t("system")}</InputLabel>
                  <Select
                    disabled={!isEditMode}
                    variant="outlined"
                    labelId="language-label"
                    id="language"
                    name="language"
                    fullWidth
                    value={formValues?.language ?? "en"}
                    onChange={handleInputChange}
                    label={t("system")}
                  >
                    {languages.map((lang) => (
                      <MenuItem key={lang} value={lang}>
                        {t(lang)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  disabled={!isEditMode}
                  control={
                    <Switch
                      disabled={!isEditMode}
                      checked={formValues?.notifyToMail ?? true}
                      name="notifyToMail"
                      onChange={handleSwitchChange}
                    />
                  }
                  label={t("userProfile.receiveEmailNotify")}
                />
              </Grid>
              <Grid item lg={6} sm={12}>
                {/* Các trường input */}
                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("Id")}
                  name="userId"
                  disabled={!isEditMode}
                  autoFocus
                  error={errors?.UserId}
                  helperText={errors?.UserId &&t(`errors.${errors?.UserId}`)}
                  InputProps={{
                    readOnly: !auth.user.roles.includes("System Admin"),
                  }}
                  onChange={handleInputChange}
                  value={formValues?.userId ?? ""}
                /> */}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("email")}
                  name="email"
                  disabled={!isEditMode}
                  autoFocus
                  error={errors?.Email}
                  helperText={errors?.Email && t(`errors.${errors?.Email}`)}
                  InputProps={{
                    readOnly: !auth.user.roles.includes("System Admin"),
                  }}
                  onChange={handleInputChange}
                  value={formValues?.email ?? ""}
                />
                <TextField
                  error={errors?.FirstName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("fname")}
                  name="firstName"
                  helperText={errors?.FirstName && t(`errors.${errors?.FirstName}`)}
                  autoFocus
                  disabled={!isEditMode}
                  value={formValues?.firstName ?? ""}
                  onChange={handleInputChange}
                />

                <TextField
                  error={errors?.LastName}
                  helperText={errors?.LastName && t(`errors.${errors?.LastName}`)}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={t("lname")}
                  name="lastName"
                  autoFocus
                  disabled={!isEditMode}
                  value={formValues?.lastName ?? ""}
                  onChange={handleInputChange}
                />
              </Grid>
              <Slide in={isEditMode} direction="up" mountOnEnter unmountOnExit>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        disabled={!isEditMode}
                        name="newPassword"
                        label={t("userProfile.newPassword")}
                        type="password"
                        error={errors?.NewPassword}
                        helperText={errors?.NewPassword && t(`errors.${errors?.NewPassword}`)}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        error={errors?.ConfirmPassword}
                        helperText={errors?.ConfirmPassword && t(`errors.${errors?.ConfirmPassword}`)}
                        disabled={!isEditMode}
                        name="confirmPassword"
                        label={t("userProfile.confirmPassword")}
                        type="password"
                        id="confirmPassword"
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Slide>
            </Grid>
          </Box>

          {/* Nút upload ảnh nhận diện */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleOpenFaceUpload}
          >
            Upload identification images
          </Button>

          {/* Modal upload ảnh nhận diện */}
          <Modal
            open={openFaceUpload}
            onClose={() => setOpenFaceUpload(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                boxShadow: 24,
                p: 4,
                maxWidth: 600,
                width: "100%",
              }}
            >
              <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                Upload identification image
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Please ensure that:
                <ul>
                  <li>The image clearly shows your face.</li>
                  <li>The image is well-lit and not blurry.</li>
                  <li>Accepted formats: JPEG, PNG. Maximum size: 5MB.</li>
                  <li>Image will be moderated by the mentor after successful upload.</li>
                </ul>
              </Typography>

              {/* Chỉ 1 ô upload ảnh */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: "1px dashed gray",
                      height: 300, // Tăng chiều cao của box để ảnh có thể hiển thị theo chiều dọc
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      backgroundImage: faceImages[0]
                        ? `url(${typeof faceImages[0] === "string" ? faceImages[0] : URL.createObjectURL(faceImages[0])})`
                        : "none",
                      backgroundSize: "contain", // Giữ ảnh tỷ lệ gốc nhưng vẫn vừa vặn trong box
                      backgroundPosition: "center center", // Đảm bảo ảnh nằm ở giữa cả chiều dọc và chiều ngang
                      backgroundRepeat: "no-repeat", // Không lặp lại ảnh
                    }}
                    onClick={() => document.getElementById("upload-image-0").click()}
                  >
                    {!faceImages[0] && (
                      <Typography variant="body2">Select image</Typography>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      id="upload-image-0"
                      style={{ display: "none" }}
                      onChange={(event) => handleFaceImageDrop(event, 0)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleSaveFaceImages} // Lưu ảnh khi nhấn nút Save
              >
                Save
              </Button>
            </Box>
          </Modal>


          {/* Buttons */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            {!isEditMode ? (
              <Button
                onClick={() => handleUpdateProfile()}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mr: 1 }}
              >
                {t("userProfile.btnUpdate")}
              </Button>
            ) : (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mr: 1 }}
                  onClick={(e) => handleSubmit(e)}
                >
                  {t("userProfile.btnSave")}
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleCancel()}
                  sx={{ ml: 1 }}
                >
                  {t("userProfile.btnCancel")}
                </Button>
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Profile;
