import React from "react";
import {
  Avatar,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { truncateTarget, formatDateTime, roundToDecimals } from "./format";

export default function ChallengeObjectiveRanking({ objective }) {
  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Avatar
            alt={objective.user.fullName}
            src={
              objective.user.avatar
                ? `data:image/png;base64,${objective.user.avatar}`
                : "/default-avatar.png"
            }
            sx={{ width: 40, height: 40, mr: 2 }}
          />
          <Typography
            variant="h5"
            component="h1"
            sx={{ wordWrap: "break-word" }}
          >
            {objective.target}
          </Typography>
        </Box>
      </Box>

      {/* Ranking Table */}
      {objective.objectiveTeamChallenges.length === 0 ? (
        <Typography>No Team Objective</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "5%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Rank
                </TableCell>
                <TableCell style={{ width: "25%", fontWeight: "bold" }}>
                  Target
                </TableCell>
                <TableCell
                  style={{
                    width: "15%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Deadline Compliance Score (10%)
                </TableCell>
                <TableCell
                  style={{
                    width: "15%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Objective Quality Score (20%)
                </TableCell>
                <TableCell
                  style={{
                    width: "15%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Jury Panel Assessment Score (70%)
                </TableCell>
                <TableCell
                  style={{
                    width: "15%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Completion Time
                </TableCell>
                <TableCell
                  style={{
                    width: "10%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Total Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objective.objectiveTeamChallenges
                .sort((a, b) => a.rank - b.rank) // Sort by rank
                .map((challenge, index) => {
                  // Determine the background color for the top 3 ranks
                  let backgroundColor;
                  switch (index) {
                    case 0:
                      backgroundColor = "#ffed89"; // Gold
                      break;
                    case 1:
                      backgroundColor = "#dddddd"; // Silver
                      break;
                    case 2:
                      backgroundColor = "#e0b081"; // Bronze
                      break;
                    default:
                      backgroundColor = "#fff"; // White for others
                  }

                  return (
                    <TableRow key={challenge.id} style={{ backgroundColor }}>
                      <TableCell
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {challenge.rank}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar
                            alt={challenge.user.fullName || "Anonymous"}
                            src={
                              challenge.user.avatar
                                ? `data:image/png;base64,${challenge.user.avatar}`
                                : "/default-avatar.png"
                            }
                            sx={{ width: 35, height: 35, mr: 1.5 }}
                          />
                          <strong>{truncateTarget(challenge.target)}</strong>
                        </Box>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {roundToDecimals(challenge.deadlineComplianceScore, 5)}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {roundToDecimals(challenge.objectiveQualityScore, 5)}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {roundToDecimals(challenge.juryPanelAssessmentScore, 5)}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {formatDateTime(challenge.completionTime)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {roundToDecimals(challenge.totalScore, 3)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
