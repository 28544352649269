import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import NavBar from "./ui/NavBar";
import { OKRContext } from "../../context/OKRContext";

export default function OKRPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("all");
  const [sortBy, setSortBy] = useState("default");

  // Xác định đường dẫn hiện tại và tự động điều hướng đến `okrdashboard` nếu chỉ có `/okr`
  useEffect(() => {
    if (location.pathname === "/okr") {
      navigate("okrdashboard");
    }
  }, [location, navigate]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleStatusChange = (selectedStatus) => {
    setStatus(selectedStatus);
  };

  const handleSortChange = (selectedSort) => {
    setSortBy(selectedSort);
  };

  const handleRefreshClick = () => {
    setSearchQuery("");
    setStatus("all");
    setSortBy("default");
  };

  return (
    <>
      <NavBar
        searchQuery={searchQuery}
        status={status}
        sortBy={sortBy}
        onSearch={handleSearch}
        onStatusChange={handleStatusChange}
        onSortChange={handleSortChange}
        onRefreshClick={handleRefreshClick}
      />
      <Outlet context={{ searchQuery, status, sortBy }} />
    </>
  );
}
