import React, { useState } from "react";
import { Avatar, Typography, Box, Button, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyResultsModal from "./KeyResultsModal";
import { useNavigate } from "react-router-dom";

export default function RelatedOKRs({ parentObjective, keyResults }) {
  const [expanded, setExpanded] = useState({});
  const [openKeyResultsModal, setOpenKeyResultsModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenKeyResultsModal = () => {
    setOpenKeyResultsModal(true);
  };

  const handleCloseKeyResultsModal = () => {
    setOpenKeyResultsModal(false);
  };

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderKeyResult = (keyResult, level = 0) => {
    const paddingLeft = `${level * 20}px`;

    return (
      <Box key={keyResult.id} sx={{ mb: 1 }}>
        <Box display="flex" alignItems="center" sx={{ paddingLeft }}>
          {keyResult.parentKeyResults?.length > 0 && (
            <IconButton size="small" onClick={() => toggleExpand(keyResult.id)}>
              {expanded[keyResult.id] ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          )}
          <Typography
            variant={level === 0 ? "body1" : "body2"}
            fontWeight={level === 0 ? "bold" : "normal"}
          >
            {keyResult.title}
          </Typography>
        </Box>

        {expanded[keyResult.id] && keyResult.parentKeyResults?.length > 0 && (
          <Box sx={{ pl: 4, ml: 3 }}>
            {keyResult.parentKeyResults.map((childKeyResult) =>
              renderKeyResult(childKeyResult, level + 1)
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Superior Objective
      </Typography>
      <Box
        sx={{
          mt: 2,
          p: 3,
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          mb: 2,
        }}
      >
        <Box
          display="flex"
          // alignItems="center"
          justifyContent="left"
          gap={5}
          sx={{ mb: 2 }}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              alt={parentObjective.parentUser?.firstName || "Anonymous"}
              src={
                parentObjective.parentUser?.avatar
                  ? `data:image/png;base64,${parentObjective.parentUser.avatar}`
                  : "/default-avatar.png"
              }
              sx={{ width: 40, height: 40, mr: 2, cursor: "pointer" }}
              onClick={() => {
                if (parentObjective.id) {
                  navigate(`/okr-detail/${parentObjective.id}`);
                }
              }}
            />
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {parentObjective.target}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Created by {parentObjective.parentUser?.firstName}{" "}
                {parentObjective.parentUser?.lastName}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="outlined"
            size="small"
            onClick={handleOpenKeyResultsModal}
          >
            {parentObjective.keyResults?.length} Key Results
          </Button>
        </Box>

        {/* <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Key Results List
        </Typography>

        {keyResults.length > 0 ? (
          keyResults.map((keyResult) => renderKeyResult(keyResult))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No key results available
          </Typography>
        )} */}

        <Box
          display="flex"
          justifyContent="left"
          // alignItems="center"
          sx={{ mt: 3 }}
          gap={3}
        >
          <Typography variant="body1" fontWeight="bold">
            Related Key Results List:
          </Typography>
          {keyResults.length > 0 ? (
            <Box>
              {keyResults.map((keyResult) => renderKeyResult(keyResult))}
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No key results available
            </Typography>
          )}
        </Box>
      </Box>
      <KeyResultsModal
        open={openKeyResultsModal}
        onClose={handleCloseKeyResultsModal}
        objectiveId={parentObjective.id} // Pass the parent objective ID
      />
    </Box>
  );
}
