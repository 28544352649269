import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  CircularProgress,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import Logo from "../../assets/images/LIM.svg";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router
import "../../assets/css/Form.css";

const ForgotPassword = ({ show }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendOTP = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);
    setLoading(true);

    try {
      const response = await apiAuth.post(
        "/User/send-otp-forgotpassword",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/verify-otp", { state: { email } });
        }, 2000);
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "An error occurred while sending the OTP."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition in={show} timeout={300} classNames="form" unmountOnExit>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "50%", height: "auto" }}
            />
          </Box>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSendOTP}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Send OTP"}
            </Button>
            {error && <Alert severity="error">{error}</Alert>}
            {success && (
              <Alert severity="success">
                OTP sent successfully. Redirecting to verify...
              </Alert>
            )}
          </Box>
        </Box>
      </Container>
    </CSSTransition>
  );
};

export default ForgotPassword;
