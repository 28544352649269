import React, { useContext, useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAuth } from "../../customs/authService";
import { NotificationContext } from "../../context/NotificationContext";

const Notification = () => {
  const { t } = useAuth();
  const { notifications, setNotifications, markNotificationAsRead, fetchNotifications } = useContext(NotificationContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const token = getCookie("jwtToken");
  const decodedToken = token ? jwtDecode(token) : null;
  const userId = decodedToken?.jti;

  const getAvatarSrc = (avatarBytes) => {
    if (!avatarBytes) return '';
    return `data:image/png;base64,${btoa(
      String.fromCharCode(...new Uint8Array(avatarBytes))
    )}`;
  };

  // Sử dụng useRef để giữ fetchNotifications ổn định
  const fetchNotificationsRef = useRef(fetchNotifications);

  // Cập nhật tham chiếu nếu fetchNotifications thay đổi
  useEffect(() => {
    fetchNotificationsRef.current = fetchNotifications;
  }, [fetchNotifications]);

  useEffect(() => {
    let intervalId;

    const intervalCallback = () => {
      if (fetchNotificationsRef.current) {
        fetchNotificationsRef.current();
      }
    };

    // Gọi lần đầu và thiết lập interval
    if (fetchNotificationsRef.current) {
      fetchNotificationsRef.current();
      intervalId = setInterval(intervalCallback, 10000); // 10 giây
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const handleNotificationClick = async (event) => {
    setAnchorEl(event.currentTarget);
    await fetchNotifications(); // Gọi API để lấy thông báo mới nhất
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClickInternal = async (notification) => {
    const notificationId = notification.notificationId; // Sử dụng đúng trường NotificationId

    if (!notification.isRead) {
      await markNotificationAsRead(notificationId);

      setNotifications(prevNotifications =>
        prevNotifications.map(n =>
          n.notificationId === notificationId ? { ...n, isRead: true } : n
        )
      );
    }

    if (notification.linkUrl) {
      window.location.href = notification.linkUrl;
    }
    handleNotificationClose();
  };

  const sortedNotifications = [...notifications].sort((a, b) => {
    if (!a.isRead && b.isRead) return -1;
    if (a.isRead && !b.isRead) return 1;
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const unreadNotificationsCount = notifications.filter(n => !n.isRead && n.senderResponse?.senderId !== userId).length;

  return (
    <Box sx={{ mr: 1 }}>
      <Tooltip title={t("notification")}>
        <span>
          <IconButton
            size="large"
            edge="end"
            aria-haspopup="true"
            onClick={handleNotificationClick}
            color="info"
          >
            <Badge badgeContent={unreadNotificationsCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationClose}
        PaperProps={{ style: { width: "600px", maxHeight: "450px" } }}
      >
        <List sx={{ maxHeight: '80vh' }}>
          {sortedNotifications.length > 0 ? sortedNotifications.map((notification, index) => (
            <ListItem
              key={`notification-${index}`}
              onClick={() => handleNotificationClickInternal(notification)}
              sx={{
                padding: "16px",
                backgroundColor: notification.isRead ? "white" : "#f0f0f0",
                "&:hover": { cursor: "pointer", backgroundColor: notification.isRead ? "#f0f0f0" : "#e0e0e0" },
              }}
            >
              <ListItemAvatar>
                {notification.senderResponse && notification.senderResponse.avatar ? (
                  <Avatar
                    src={getAvatarSrc(notification.senderResponse.avatar)}
                    alt={notification.senderResponse.fullName}
                  />
                ) : (
                  <Avatar />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
                secondary={
                  <>
                    <span>{notification.senderResponse?.fullName || "Unknown"}</span>
                    <br />
                    <span>{notification.isRead ? "Seen" : "Not Seen"}</span>
                    <br />
                    <span>{new Date(notification.createdAt).toLocaleString("vi-VN", { timeZone: "Asia/Ho_Chi_Minh" })}</span>
                  </>
                }
                sx={{ fontSize: "1.25rem", whiteSpace: "normal" }}
              />
            </ListItem>
          )) : (
            <ListItem sx={{ padding: "16px" }}>
              <ListItemText
                primary={t('noNotificationFound')}
                sx={{
                  fontSize: "1.25rem",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  whiteSpace: "normal",
                }}
              />
            </ListItem>
          )}
        </List>
      </Menu>
    </Box>
  );
};

export default Notification;


