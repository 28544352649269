import React, { useContext } from "react";
import OKRScoringList from "./ui/OKRScoringList";
import { OKRContext } from "../../context/OKRContext";
import { Box } from "@mui/material";

export default function OKRScoringPage() {
  const { OKRLabChallengeScoringList, OKRTeamChallengeScoringList } =
    useContext(OKRContext);

  return (
    <Box mt={5}>
      <OKRScoringList
        fetchParentObjectives={OKRLabChallengeScoringList}
        fetchChildObjectives={OKRTeamChallengeScoringList}
      />
    </Box>
  );
}
