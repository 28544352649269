import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { OKRContext } from "../../context/OKRContext";
import { formatDateForInput } from "../../components/Okr/ui/format";
import KeyResultTable from "./ui/KeyResultTable";
import {
  Box,
  IconButton,
  Typography,
  Avatar,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ObjectiveTable from "./ui/ObjectiveTable";
import ObjectivesEvaluation from "./ui/ObjectivesEvaluation";

const OKRChallengeDetailPage = () => {
  const navigate = useNavigate();
  const { objectiveId } = useParams();
  const { TeamObjectiveChallengeDetailById } = useContext(OKRContext);
  const [objectiveDetails, setObjectiveDetails] = useState(null);

  useEffect(() => {
    const loadObjectiveDetails = async () => {
      try {
        const details = await TeamObjectiveChallengeDetailById(objectiveId);
        setObjectiveDetails(details);
      } catch (error) {
        console.error("Failed to fetch objective details:", error);
      }
    };

    loadObjectiveDetails();
  }, [objectiveId, TeamObjectiveChallengeDetailById]);

  if (!objectiveDetails) return null;

  return (
    <Box sx={{ padding: 5 }}>
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          p: 3,
          mb: 3,
          backgroundColor: "#f9f9f9",
        }}
      >
        {/* Header với nút quay lại trang trước, Avatar và Target */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          {/* Nút quay lại trang trước */}
          <IconButton onClick={() => navigate(-1)} sx={{ marginRight: 1 }}>
            <ArrowBackIosIcon />
          </IconButton>

          <Typography
            variant="h4"
            component="h1"
            sx={{ wordWrap: "break-word", fontWeight: "bold" }}
          >
            Team: {objectiveDetails.team.name}
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          {/* Avatar và Title cùng nằm trong một dòng */}
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Avatar
              alt={objectiveDetails.user.fullName}
              src={
                objectiveDetails.user.avatar
                  ? `data:image/png;base64,${objectiveDetails.user.avatar}`
                  : "/default-avatar.png"
              }
              sx={{ width: 40, height: 40, mr: 2 }}
            />
            <Typography
              variant="h5"
              component="h1"
              sx={{ wordWrap: "break-word" }}
            >
              {objectiveDetails.target}
            </Typography>
          </Box>
        </Box>

        {/* Mô tả và chi tiết của Objective */}
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={objectiveDetails.description || "No Description"}
            sx={{ mb: 2 }}
            InputProps={{
              readOnly: true,
            }}
            multiline
            rows={3}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Start Date"
                variant="outlined"
                type="datetime-local"
                value={formatDateForInput(objectiveDetails.startDate)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="End Date"
                variant="outlined"
                type="datetime-local"
                value={formatDateForInput(objectiveDetails.endDate)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <h3>Key Results</h3>
          <KeyResultTable keyResults={objectiveDetails.keyResults} />
        </Box>

        <Box>
          <h3>Intern Objectives</h3>
          <ObjectiveTable objectives={objectiveDetails.internObjectives} />
        </Box>

        <Box>
          <h3>Objective Assessment</h3>
          <ObjectivesEvaluation
            objectiveId={objectiveDetails.id}
            juryMemberAssessment={objectiveDetails.juryMemberAssessment}
          />
        </Box>

        {/* Nút quay lại trang trước */}
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
          sx={{ marginTop: 3 }}
        >
          Go Back
        </Button> */}
      </Box>
    </Box>
  );
};

export default OKRChallengeDetailPage;
