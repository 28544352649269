import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  Grid,
  Container,
  CircularProgress,
  Backdrop
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cameraAuth from "../../customs/axiosBackendAI";

const AddCamera = () => {
  const [currentScreen, setCurrentScreen] = useState("add");
  const [cameraId, setCameraId] = useState("");
  const [cameraUrl, setCameraUrl] = useState("");
  const [cameras, setCameras] = useState([]);
  const [allCameras, setAllCameras] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Chuyển đổi giữa màn hình "Thêm Camera" và "Danh sách Camera"
  const handleNavigation = (screen) => {
    setCurrentScreen(screen);
    if (screen === "list") {
      fetchAllCameras();
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  // Kết nối camera
  const handleConnectCamera = async () => {
    setIsLoading(true);
    try {
      const token = getCookie("jwtToken");
      const response = await cameraAuth.post("/set_camera_url/", null, {
        params: {
          camera_id: cameraId,
          camera_url: cameraUrl,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.message) {
        toast.success(`Connection to camera ${cameraId} successful!`);
        setCameras((prevCameras) => [
          ...prevCameras,
          { id: cameraId, url: null }
        ]);
        setCameraId("");
        setCameraUrl("");
      }
    } catch (error) {
      toast.error(`Unable to connect to camera ${cameraId}. Check the URL again.`);
      console.error("Error connecting to camera:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Lấy danh sách tất cả các camera từ API
  const fetchAllCameras = async () => {
    setIsLoading(true);
    try {
      const token = getCookie("jwtToken");
      const response = await cameraAuth.get("/get_all_cameras/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllCameras(response.data);
      setCameras(response.data.filter(camera => camera.connected).map(camera => ({
        id: camera.camera_id,
        url: null // Khởi tạo không có khung hình cho đến khi người dùng bấm nút xem
      })));
    } catch (error) {
      console.error("Error fetching camera list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Xử lý bấm nút xem khung hình
  const handleViewFrame = (cameraId) => {
    const token = getCookie("jwtToken");
    setCameras(prevCameras =>
      prevCameras.map(camera =>
        camera.id === cameraId
          ? { ...camera, url: `${cameraAuth.defaults.baseURL}/stream_camera?camera_id=${cameraId}` }
          : camera
      )
    );
  };

  // Hành động kết nối hoặc dừng camera
  const handleAction = async (cameraId, action) => {
    const token = getCookie("jwtToken");
    setIsLoading(true);
    try {
      const camera = allCameras.find((cam) => cam.camera_id === cameraId);

      if (action === "connect") {
        if (camera && camera.camera_url) {
          await cameraAuth.post(
            "/set_camera_url/",
            null,
            {
              params: { camera_id: cameraId, camera_url: camera.camera_url },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success(`Camera ${cameraId} is connected.`);
        } else {
          toast.error(`URL for camera ${cameraId} not found.`);
          return;
        }
      } else if (action === "stop") {
        await cameraAuth.post(
          "/stop_camera_url/",
          null,
          {
            params: { camera_id: cameraId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(`Camera ${cameraId} has stopped connecting.`);
      }
      // Cập nhật danh sách camera sau khi hành động
      fetchAllCameras();
    } catch (error) {
      toast.error(`Unable to perform ${action} on camera ${cameraId}.`);
      console.error(`Error with ${action} action on camera:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setCameraId(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    fetchAllCameras();
  }, []);

  return (
    <Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Camera Management
          </Typography>
          <Button color="inherit" onClick={() => handleNavigation("add")}>
            Add Camera
          </Button>
          <Button color="inherit" onClick={() => handleNavigation("list")}>
            Camera List
          </Button>
        </Toolbar>
      </AppBar>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {currentScreen === "add" && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">Add Camera</Typography>
          <TextField
            label="Camera Name"
            variant="outlined"
            value={cameraId}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
            inputProps={{ maxLength: 50 }}
            error={error}
            helperText={error ? "Camera ID cannot exceed 50 characters" : ""}
          />
          <TextField
            label="Camera URL"
            variant="outlined"
            value={cameraUrl}
            onChange={(e) => setCameraUrl(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnectCamera}
            sx={{ mb: 3 }}
          >
            Connect to Camera
          </Button>

          <Grid container spacing={3}>
            {cameras.map((camera) => (
              <Grid item xs={12} md={4} key={camera.id}>
                <Typography variant="h6">Camera {camera.id}:</Typography>
                {camera.url ? (
                  <img
                    src={camera.url}
                    alt={`Camera ${camera.id} Feed`}
                    style={{ width: "100%", height: "auto" }}
                  />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewFrame(camera.id)}
                  >
                    View frame
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {currentScreen === "list" && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5">Camera List</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Camera Name</TableCell>
                <TableCell>Camera URL</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allCameras.map((camera) => (
                <TableRow key={camera.camera_id}>
                  <TableCell>{camera.camera_id}</TableCell>
                  <TableCell>{camera.camera_url}</TableCell>
                  <TableCell>
                    <Typography color={camera.connected ? "green" : "red"}>
                      {camera.connected ? "Connected" : "Not connection"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {camera.connected ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAction(camera.camera_id, "stop")}
                      >
                        Stop
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAction(camera.camera_id, "connect")}
                      >
                        Connect
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Container>
  );
};

export default AddCamera;

