import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  Event,
  Forum,
  ImportContacts,
  ImportExport,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MyCalendar from "../../components/Event/EventCalendar";
import Profile from "../../components/User/Profile";
import AttendanceDashboard from "../../components/Attendance/AttendanceDashBoard";
import ImportCsvComponent from "../../components/Import/ImportCsvComponent";
import ImportLaboratory from "../../components/Import/ImportLaboratory";
import ImportMember from "../../components/Import/ImportMember";
import CandidateList from "../../components/Import/CandidateList";
import OKRPage from "../../components/Okr/OKRPage";
import CreateObjective from "../../components/Okr/CreateObjective";
import OKRDashboardPage from "../../components/Okr/OKRDashboardPage";
import ReportOKRsPage from "../../components/Okr/ReportOKRsPage";
import OKRRankingPage from "../../components/Okr/OKRRankingPage";
import OKRScoringPage from "../../components/Okr/OKRScoringPage";
import OKRDetailPage from "../../components/Okr/OKRDetailPage";
import OKRChallengeDetailPage from "../../components/Okr/OKRChallengeDetailPage";
import ReportObjective from "../../components/Okr/Report/ReportObjective";
import ReportOverview from "../../components/Okr/Report/ReportOverview";
import OKRFeedback from "../../components/Okr/Feedback/OKRFeedback";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import StyleIcon from "@mui/icons-material/Style";
import RequestPage from "../../components/RequestModal/RequestPage";
import ReportTable from "../../components/Report/ReportDashBoard";
import DashboardAll from "../../components/Dashboard/DashboardAll";
import AddReportPage from "../../components/Report/AddReport";
import SemesterAd from "../../components/Report/SemesterAd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MentorInternTabs from "../../components/User/Members";
import PeopleIcon from "@mui/icons-material/People";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraConnect from "../../components/CameraConnect/AddCamera";
import PeopleCheckIcon from "@mui/icons-material/PersonRemove";
import Attendance from "../../components/Attendance/VerifyCameraUsers";
import CleaningDuty from "../../components/CleaningDuty/ScheduleCleaning";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ApproveImage from "../../components/Import/ApporveImage";
import InternOnTeam from "../../components/User/InternOnTeam";
export const menuItems = [
  {
    icon: <HomeIcon />,
    primaryText: "home",
    href: "/",
    allowedRoles: ["Mentor", "Intern", "Lab Manager"],
  },
  {
    icon: <PeopleIcon />,
    primaryText: "members",
    href: "/members",
    allowedRoles: ["Lab Manager"],
  },
  {
    icon: <PeopleIcon />,
    primaryText: "members",
    href: "/intern-on-team",
    allowedRoles: ["Mentor"],
  },
  {
    icon: <DomainVerificationIcon />,
    primaryText: "attendance",
    href: "/attendance-list",
    allowedRoles: ["Intern", "Lab Manager", "Mentor"],
  },
  {
    icon: <Forum />,
    primaryText: "okr",
    href: "/okr",
    allowedRoles: ["Intern", "Mentor", "Lab Manager", "Jury Panel"],
  },

  {
    icon: <BarChartIcon />,
    primaryText: "report",
    href: "/report",
    allowedRoles: ["Mentor", "Lab Manager"],
  },
  {
    icon: <Event />,
    primaryText: "event",
    href: "/events",
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
  },
  {
    icon: <ImportContacts />,
    primaryText: "importIntern",
    href: "/importCSV",
    allowedRoles: ["System Admin"],
  },
  {
    icon: <ImportExport />,
    primaryText: "importLab",
    href: "/importLaboratory",
    allowedRoles: ["System Admin"],
  },
  {
    icon: <ImportExport />,
    primaryText: "import_member",
    href: "/import-member",
    allowedRoles: ["Lab Manager"],
  },
  {
    icon: <GroupAddIcon />,
    primaryText: "candidate",
    href: "/candidates",
    allowedRoles: ["Lab Manager", "Mentor"],
  },
  {
    icon: <StyleIcon />,
    primaryText: "Request",
    href: "/request",
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
  },

  {
    icon: <DashboardIcon />,
    primaryText: "semester",
    href: "/semester",
    allowedRoles: ["System Admin"],
  },
  {
    icon: <PeopleCheckIcon />,
    primaryText: "CheckUsers",
    href: "/check-user",
    allowedRoles: ["Lab Manager"],
  },
  {
    icon: <CleaningServicesIcon />,
    primaryText: "Cleaning",
    href: "/cleaning-duty",
    allowedRoles: ["Intern", "Lab Manager"],
  },
  {
    icon: <AddPhotoAlternateIcon />,
    primaryText: "ApproveImage",
    href: "/approve-image",
    allowedRoles: ["Mentor"],
  },
  {
    icon: <CameraAltIcon />,
    primaryText: "connectCamera",
    href: "/camera-connect",
    allowedRoles: ["Lab Manager"],
  },
];

export const protectedRoutes = [
  // {
  //   allowedRoles: ["Mentor", "Intern"],
  //   component: <DashboardAll />,
  //   path: "/dashboard",
  // },
  // {
  //   allowedRoles: ["Lab Manager"],
  //   component: <DashBoardLabManage />,
  //   path: "/dashboardLab",
  // },
  {
    allowedRoles: ["Lab Manager"],
    component: <MentorInternTabs />,
    path: "/members",
  },
  {
    allowedRoles: ["Mentor"],
    component: <InternOnTeam />,
    path: "/intern-on-team",
  },
  {
    allowedRoles: ["System Admin"],
    component: <SemesterAd />,
    path: "/semester",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <DashboardAll />,
    path: "/",
  },

  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <MyCalendar />,
    path: "/events",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <Profile />,
    path: "/user-profile",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor", "Intern", "Jury Panel"],
    component: <OKRPage />,
    path: "/okr",
    children: [
      {
        allowedRoles: ["Lab Manager", "Mentor", "Intern", "Jury Panel"],
        component: <OKRDashboardPage />,
        path: "okrdashboard",
      },
      {
        allowedRoles: ["Lab Manager", "Mentor", "Intern"],
        component: <ReportOKRsPage />,
        path: "okrreport",
      },
      {
        allowedRoles: ["Lab Manager", "Mentor", "Intern"],
        component: <OKRRankingPage />,
        path: "okrranking",
      },
      {
        allowedRoles: ["Jury Panel"],
        component: <OKRScoringPage />,
        path: "okrscoring",
      },
    ],
  },
  {
    allowedRoles: ["Lab Manager", "Mentor", "Intern", "Jury Panel"],
    component: <OKRDetailPage />,
    path: "/okr-detail/:objectiveId",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor", "Intern", "Jury Panel"],
    component: <OKRChallengeDetailPage />,
    path: "/okrchallenge-detail/:objectiveId",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor", "Intern"],
    component: <ReportObjective />,
    path: "/okr-report/:objectiveId",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor", "Intern"],
    component: <ReportOverview />,
    path: "/okr-report/okr-report-overview/:objectiveId",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor", "Intern"],
    component: <OKRFeedback />,
    path: "/okr-report/okr-feedback/:objectiveId",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor"],
    component: <OKRDetailPage />,
    path: "/okr-detail/:objectiveId?action=isApproved",
  },
  {
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
    component: <CreateObjective />,
    path: "/create-objective",
  },

  {
    allowedRoles: ["Lab Manager", "Mentor"],
    component: <CandidateList />,
    path: "/candidates",
  },
  {
    allowedRoles: ["Lab Manager", "Intern", "Mentor"],
    component: <AttendanceDashboard />,
    path: "/attendance-list",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportCsvComponent />,
    path: "/importCSV",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportLaboratory />,
    path: "/importLaboratory",
  },
  {
    allowedRoles: ["Lab Manager"],
    component: <ImportMember />,
    path: "import-member",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportCsvComponent />,
    path: "/importCSV",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportLaboratory />,
    path: "/importLaboratory",
  },
  {
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
    component: <RequestPage />,
    path: "/request",
  },
  {
    allowedRoles: ["Mentor", "Lab Manager"],
    component: <ReportTable />,
    path: "/report",
  },
  {
    allowedRoles: ["Mentor", "Lab Manager"],
    component: <AddReportPage />,
    path: "/add-report",
  },
  {
    allowedRoles: ["Lab Manager"],
    component: <Attendance />,
    path: "/check-user",
  },
  {
    allowedRoles: ["Intern", "Lab Manager"],
    component: <CleaningDuty />,
    path: "/cleaning-duty",
  },
  {
    allowedRoles: ["Mentor"],
    component: <ApproveImage />,
    path: "/approve-image",
  },
  {
    allowedRoles: ["Lab Manager"],
    component: <CameraConnect />,
    path: "/camera-connect",
  },
];

export const accountMenus = [
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    label: "profile",
    path: "/user-profile",
  },
];
