import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  TableRow,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { showLoading, hideLoading } from "../Layout/LoadingScreen";
import Badge from "@mui/material/Badge";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import plugin utc
import timezone from "dayjs/plugin/timezone"; // Import plugin timezone (nếu cần)
import { BloodtypeOutlined } from "@mui/icons-material";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from "chart.js";
dayjs.extend(utc); // Kích hoạt plugin utc
dayjs.extend(timezone); // Kích hoạt plugin timezone (nếu cần)
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const MyCalendar = () => {
  const { auth } = useAuth();
  const [events, setEvents] = useState([]);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [openSwapDialog, setOpenSwapDialog] = useState(false); // Popup for Swap requests
  const [swapRequests, setSwapRequests] = useState([]); // Store swap requests
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [openCreateSwapDialog, setOpenCreateSwapDialog] = useState(false); // Popup for Create Swap Schedule
  const [swapFromDate, setSwapFromDate] = useState(""); // Date to swap from
  const [swapToDate, setSwapToDate] = useState(""); // Date to swap to
  const [responseInternId, setResponseInternId] = useState(""); // Intern to swap with
  const [availableInterns, setAvailableInterns] = useState([]);
  const [swapRequestCount, setSwapRequestCount] = useState(0); // Số lượng yêu cầu hoán đổi
  const [openStatusDialog, setOpenStatusDialog] = useState(false); // Kiểm soát việc mở popup
  const [selectedSchedule, setSelectedSchedule] = useState(null); // Lưu trữ lịch dọn dẹp được chọn
  const [newStatus, setNewStatus] = useState(""); // lưu trạng thái mới
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Điều khiển popup xóa
  const [openProofDialog, setOpenProofDialog] = useState(false);
  const [proofImage, setProofImage] = useState("");
  const [proofImageFile, setProofImageFile] = useState(null);
  const [proofImagePreview, setProofImagePreview] = useState(null);
  const [openStatisticsDialog, setOpenStatisticsDialog] = useState(false); // Popup thống kê
  const [internStatistics, setInternStatistics] = useState([]); // Dữ liệu thống kê
  const [highlightDate, setHighlightDate] = useState("");
  const [noSchedule, setNoSchedule] = useState(false);
  const [swapHistory, setSwapHistory] = useState([]);
  const [openSwapHistoryDialog, setOpenSwapHistoryDialog] = useState(false);

  const isLabManager = auth?.user?.roles.includes("Lab Manager");
  const isIntern = auth?.user?.roles.includes("Intern");
  const calendarRef = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (auth.user) {
      fetchCleaningSchedule(); // Fetch the cleaning schedule when the page loads
      fetchSwapRequestCount();
    }
  }, [auth.user]);

  const handleDateChange = (date) => {
    setHighlightDate(date);

    // Kiểm tra nếu calendarRef đã sẵn sàng và chuyển đến ngày đã chọn
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(date); // Điều hướng đến ngày đã chọn
    } else {
      console.warn("Calendar API is not ready.");
    }

    // Kiểm tra nếu có sự kiện nào vào ngày được chọn
    const hasEventOnDate = events.some((event) =>
      dayjs(event.start).isSame(dayjs(date), "day")
    );

    // Nếu không có sự kiện nào, thêm sự kiện giả "No schedule"
    if (!hasEventOnDate) {
      setEvents([
        ...events,
        {
          title: "No schedule",
          start: date,
          display: "background",
          backgroundColor: "#f0f0f0",
          textColor: "gray",
        },
      ]);
    } else {
      setEvents(events.filter((event) => event.title !== "No schedule"));
    }
  };
  const fetchSwapHistory = async () => {
    try {
      showLoading();
      const response = await apiAuth.get(`/CleaningSchedule/getSwapHistoryForIntern`);
      setSwapHistory(response.data); // Save swap history to state
      setOpenSwapHistoryDialog(true); // Open swap history dialog
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.info("No swap history available.");
      } else {
        toast.error("Error fetching swap history.");
      }
    } finally {
      hideLoading();
    }
  };

  const fetchSwapRequestCount = async () => {
    if (!isIntern) return;
    try {
      showLoading();
      const response = await apiAuth.get(
        `/CleaningSchedule/getSwapRequestsForIntern`
      );

      // Đếm số lượng yêu cầu và lưu vào state, mặc định là 0 nếu không có dữ liệu
      setSwapRequestCount(response.data ? response.data.length : 0);
    } catch (error) {
      if (!error.response || error.response.status !== 404) {
        toast.error("Error fetching swap requests.");
      }
      // Trường hợp lỗi 404 hoặc các lỗi khác sẽ không hiển thị thông báo và thiết lập count là 0
      setSwapRequestCount(0);
    } finally {
      hideLoading();
    }
  };

  const handleSaveStatusChange = async () => {
    const formData = new FormData();
    formData.append("scheduleId", parseInt(selectedSchedule.id));
    formData.append("status", newStatus);

    if (proofImageFile) {
      // Chuyển đổi ảnh sang chuỗi Base64
      const base64Image = await convertToBase64(proofImageFile);
      formData.append("proofImage", base64Image); // Thêm chuỗi Base64 vào FormData
    }

    try {
      showLoading();
      const response = await apiAuth.put(
        `/CleaningSchedule/updateStatus`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Status updated successfully!");
        fetchCleaningSchedule();
        setOpenStatusDialog(false);
        setProofImagePreview(null); // Clear preview after saving
        setProofImageFile(null); // Clear file after saving
      } else {
        toast.error("Failed to update status.");
      }
    } catch (error) {
      toast.error("An error occurred while updating status");
    } finally {
      hideLoading();
    }
  };
  // Hàm chuyển đổi file ảnh thành Base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result); // Chuỗi Base64 sẽ được trả về qua result
      reader.onerror = (error) => reject(error);
    });
  };

  const handleProofImageChange = (event) => {
    const file = event.target.files[0]; // Ensure file is defined here
    if (file) {
      // Check if file exists
      setProofImageFile(file); // Set the uploaded file to state
      setProofImagePreview(URL.createObjectURL(file)); // Create preview URL for the image
    }
  };

  const handleEventClick = (info) => {
    const eventDate = dayjs(info.event.start).format("YYYY-MM-DD"); // Ngày của sự kiện
    const today = dayjs().format("YYYY-MM-DD"); // Ngày hiện tại
    const eventStatus = info.event.extendedProps.status; // Trạng thái của sự kiện
    const eventId = info.event.id; // ID của lịch dọn dẹp

    setSelectedSchedule(info.event); // Lưu trữ lịch dọn dẹp hiện tại

    if (isIntern) {
      // Chỉ cho phép Intern thay đổi trạng thái
      if (eventDate === today) {
        setNewStatus(eventStatus); // Lấy trạng thái hiện tại
        if (eventStatus === "Completed" && info.event.extendedProps.image) {
          setProofImagePreview(info.event.extendedProps.image); // Hiển thị ảnh đã lưu
        } else {
          setProofImagePreview(null); // Xóa preview nếu không có ảnh
        }
        setOpenStatusDialog(true); // Mở popup thay đổi trạng thái
      } else if (eventDate > today) {
        toast.info("Cleaning not scheduled yet");
      } else {
        // Kiểm tra trạng thái của sự kiện nếu ngày đã qua
        if (eventStatus === "Completed" && info.event.extendedProps.image) {
          setProofImagePreview(`${info.event.extendedProps.image}`);
          setOpenProofDialog(true); // Mở popup để hiển thị ảnh
        } else {
          toast.info("It’s past cleaning time");
        }
      }
    } else if (isLabManager) {
      if (eventDate < today) {
        if (eventStatus === "Not Completed") {
          toast.info("Intern not cleaning up");
        } else if (eventStatus === "Completed") {
          fetchProofImage(eventId);
        }
      } else if (eventDate === today) {
        if (eventStatus === "Completed" && info.event.extendedProps.image) {
          fetchProofImage(eventId);
        } else if (eventStatus === "Not Completed") {
          toast.info("Intern not cleaning up");
        }
      } else if (eventDate > today) {
        setOpenDeleteDialog(true);
      }
    }
  };

  const fetchProofImage = async (eventId) => {
    try {
      showLoading();
      const response = await apiAuth.get(
        `/CleaningSchedule/getProofImage/${eventId}`
      );
      if (response.status === 200 && response.data.proofImage) {
        setProofImage(`${response.data.proofImage}`); // Thêm định dạng cho chuỗi base64
        setOpenProofDialog(true); // Mở popup hiển thị hình ảnh bằng chứng
      } else {
        toast.info("No proof image available.");
      }
    } catch (error) {
      toast.error("Error fetching proof image.");
    } finally {
      hideLoading();
    }
  };

  const handleDeleteSchedule = async () => {
    try {
      showLoading();
      const response = await apiAuth.delete(
        `/CleaningSchedule/deleteSchedule/${selectedSchedule.id}`
      ); // Gọi API xóa lịch dọn dẹp

      if (response.status === 200) {
        toast.success("Cleanup schedule deleted successfully");
        fetchCleaningSchedule(); // Tải lại danh sách sau khi xóa
        setOpenDeleteDialog(false); // Đóng popup sau khi xóa
      } else {
        toast.error("Delete failed cleaning schedule");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the cleaning schedule");
    } finally {
      hideLoading();
    }
  };

  const fetchCleaningSchedule = async () => {
    try {
      showLoading();
      const response = await apiAuth.get(
        "/CleaningSchedule/getCleaningSchedule"
      );
      const cleaningScheduleData = response.data.schedules.map((schedule) => ({
        id: schedule.id,
        internId: schedule.internId,
        title: `${schedule.internName}`,
        start: schedule.cleaningDate,
        status: schedule.status,
        image: schedule.status === "Completed" ? schedule.image : null,
      }));
      setEvents(cleaningScheduleData);
    } catch (error) {
      if (error.response?.status === 404) {
        toast.info("No Data");
      } else {
        toast.error("Error fetching cleaning schedule.");
      }
    } finally {
      hideLoading();
    }
  };

  const fetchSwapRequests = async () => {
    if (!isIntern) return;
    try {
      showLoading();
      const response = await apiAuth.get(
        `/CleaningSchedule/getSwapRequestsForIntern`
      );
      setSwapRequests(response.data);
      setOpenSwapDialog(true); // Mở popup yêu cầu hoán đổi khi nhấn nút
    } catch (error) {
      if (error.response.status === 404) {
        toast.info("No reschedule requests");
      } else {
        toast.error("Error fetching swap requests.");
      }
    } finally {
      hideLoading();
    }
  };

  const formatDate = (dateString) => {
    return dayjs.utc(dateString).tz("Asia/Ho_Chi_Minh").format("DD-MM-YYYY"); // Chuyển đổi giờ UTC sang múi giờ Việt Nam (+7)
  };

  const handleApproveSwap = async (swapId) => {
    try {
      showLoading();
      const swapResponseDTO = {
        RequestId: swapId,
        IsAccepted: true,
      };

      const response = await apiAuth.post(
        "/CleaningSchedule/respondSwap",
        swapResponseDTO
      );

      if (response.status === 200) {
        toast.success("Swap approved successfully!");

        // Cập nhật danh sách yêu cầu swap
        const updatedSwapRequests = swapRequests.filter(
          (request) => request.swapId !== swapId
        );
        setSwapRequests(updatedSwapRequests);

        // Cập nhật số lượng swap requests
        setSwapRequestCount(swapRequestCount - 1);

        // Nếu không còn yêu cầu nào, đóng popup
        if (updatedSwapRequests.length === 0) {
          setOpenSwapDialog(false);
        }

        // Tải lại lịch dọn dẹp sau khi swap thành công
        fetchCleaningSchedule();
      } else {
        toast.error("Failed to approve swap.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "Error approving swap.";
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred.");
      }
    } finally {
      hideLoading();
    }
  };

  const handleRejectSwap = async (swapId) => {
    try {
      showLoading();

      const swapResponseDTO = {
        RequestId: swapId,
        IsAccepted: false,
      };

      const response = await apiAuth.post(
        "/CleaningSchedule/respondSwap",
        swapResponseDTO
      );

      if (response.status === 200) {
        toast.success("Swap rejected successfully!");

        // Cập nhật danh sách yêu cầu swap
        const updatedSwapRequests = swapRequests.filter(
          (request) => request.swapId !== swapId
        );
        setSwapRequests(updatedSwapRequests);

        // Cập nhật số lượng swap requests
        setSwapRequestCount(swapRequestCount - 1);

        // Nếu không còn yêu cầu nào, đóng popup
        if (updatedSwapRequests.length === 0) {
          setOpenSwapDialog(false);
        }
      } else {
        toast.error("Failed to reject swap.");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "Error rejecting swap.";
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred.");
      }
    } finally {
      hideLoading();
    }
  };

  // Gửi yêu cầu đổi lịch
  const handleCreateSwapSchedule = async () => {
    try {
      showLoading();
      const swapData = {
        requestInternId: auth.user.userId, // Intern yêu cầu đổi
        responseInternId:
          availableInterns.length > 0 ? availableInterns[0].internId : "", // Intern mà họ muốn đổi lịch
        requestedFromDate: swapFromDate, // Ngày bắt đầu của yêu cầu
        requestedToDate: swapToDate, // Ngày kết thúc của yêu cầu
      };

      const response = await apiAuth.post(
        "/CleaningSchedule/requestSwap",
        swapData
      ); // Gọi API requestSwap

      if (response.status === 200) {
        toast.success("Swap request created successfully!");
        setOpenCreateSwapDialog(false); // Đóng popup sau khi gửi
      } else {
        toast.error("Failed to create swap request.");
      }
    } catch (error) {
      // Kiểm tra nếu có phản hồi lỗi từ backend
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "Error creating swap request.";
        toast.info(errorMessage);
      } else {
        // Nếu không có phản hồi từ backend (ví dụ: lỗi mạng)
        toast.error("An unexpected error occurred.");
      }
    } finally {
      hideLoading();
    }
  };

  const handleCreateCleaningSchedule = () => {
    setOpenDateDialog(true); // Open date selection popup
  };

  const handleSaveSchedule = async () => {
    const cleaningScheduleData = {
      labmanagerId: auth.user.userId,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    try {
      showLoading();
      const response = await apiAuth.post(
        "/CleaningSchedule/createCleaningSchedule",
        cleaningScheduleData
      );

      if (response.status === 200) {
        toast.success("Cleaning schedule created successfully!");
        fetchCleaningSchedule();
        setOpenDateDialog(false);
      } else {
        toast.error("Failed to create cleaning schedule.");
      }
    } catch (error) {
      // Kiểm tra nếu có phản hồi từ backend
      if (error.response) {
        const errorMessage =
          error.response.data.message || "Error creating cleaning schedule.";
        toast.info(errorMessage);
      } else {
        // Lỗi không có phản hồi (ví dụ: không có kết nối mạng)
        toast.error("An unexpected error occurred.");
      }
    } finally {
      hideLoading();
    }
  };

  const handleSwapFromDateChange = async (swapFromDate) => {
    setSwapFromDate(swapFromDate);
    // Gọi hàm để kiểm tra và lấy danh sách Intern
    if (swapFromDate && swapToDate) {
      await fetchAvailableInterns(swapFromDate, swapToDate);
    }
  };

  const handleSwapToDateChange = async (swapToDate) => {
    setSwapToDate(swapToDate);
    // Gọi hàm để kiểm tra và lấy danh sách Intern
    if (swapFromDate && swapToDate) {
      await fetchAvailableInterns(swapFromDate, swapToDate);
    }
  };

  const fetchAvailableInterns = async (swapFromDate, swapToDate) => {
    try {
      showLoading();
      const response = await apiAuth.get(
        `/CleaningSchedule/getInternsByCleaningDate`,
        {
          params: {
            swapFromDate: swapFromDate, // Gửi Swap From Date
            cleaningDate: swapToDate, // Gửi Swap To Date
          },
        }
      );
      if (response.status === 200) {
        setAvailableInterns(response.data); // Lưu danh sách intern
      } else {
        toast.info("No interns found for the selected date.");
        setAvailableInterns([]); // Xóa danh sách nếu không có intern
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "Error fetching interns for the selected date.";
        toast.info(errorMessage); // Hiển thị thông báo lỗi từ backend
      } else {
        toast.error("An unexpected error occurred.");
      }
      setAvailableInterns([]); // Xóa danh sách nếu có lỗi
    } finally {
      hideLoading();
    }
  };

  const fetchStatistics = async () => {
    try {
      showLoading();
      const response = await apiAuth.get("/CleaningSchedule/getCleaningSchedule");
      const schedules = response.data.schedules;

      const today = dayjs();
      const userId = auth.user.jti;

      if (isIntern) {
        // Tính toán cho Intern
        let completedDays = 0;
        let notCompletedDays = 0;

        schedules.forEach((schedule) => {
          const { status, cleaningDate, internId } = schedule;
          if (internId === userId) { // Chỉ tính cho Intern hiện tại
            const cleaningDateObject = dayjs(cleaningDate);
            if (cleaningDateObject.isBefore(today, "day") || cleaningDateObject.isSame(today, "day")) {
              if (status === "Completed") {
                completedDays += 1;
              } else {
                notCompletedDays += 1;
              }
            }
          }
        });

        setChartData({
          labels: ["Completed Days", "Not Completed Days"],
          datasets: [
            {
              data: [completedDays, notCompletedDays],
              backgroundColor: ["#28a745", "#dc3545"], // Màu cho các phân khúc
            },
          ],
        });
      } else if (isLabManager) {
        // Tính toán cho Lab Manager
        const internStatistics = {};

        schedules.forEach((schedule) => {
          const { internName, status, cleaningDate } = schedule;
          const cleaningDateObject = dayjs(cleaningDate);

          if (cleaningDateObject.isBefore(today, "day") || cleaningDateObject.isSame(today, "day")) {
            if (!internStatistics[internName]) {
              internStatistics[internName] = { completedDays: 0, notCompletedDays: 0 };
            }
            if (status === "Completed") {
              internStatistics[internName].completedDays += 1;
            } else {
              internStatistics[internName].notCompletedDays += 1;
            }
          }
        });

        const labels = Object.keys(internStatistics);
        const completedData = labels.map((name) => internStatistics[name].completedDays);
        const notCompletedData = labels.map((name) => internStatistics[name].notCompletedDays);

        setChartData({
          labels,
          datasets: [
            {
              label: "Completed Days",
              data: completedData,
              backgroundColor: "#28a745",
            },
            {
              label: "Not Completed Days",
              data: notCompletedData,
              backgroundColor: "#dc3545",
            },
          ],
        });
      }

      setOpenStatisticsDialog(true);
    } catch (error) {
      toast.info("No list data.");
    } finally {
      hideLoading();
    }
  };

  // Khi người dùng chọn intern từ danh sách
  const handleInternSelect = (event) => {
    setResponseInternId(event.target.value); // Cập nhật intern được chọn
  };

  const handleCloseDialog = () => {
    setOpenDateDialog(false);
    setSelectedStartDate("");
    setSelectedEndDate("");
  };

  const today = dayjs().format("YYYY-MM-DD"); // Định dạng ngày hiện tại
  const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD"); // Lấy ngày sau ngày hiện tại 1 ngày

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Approved':
        return { backgroundColor: '#d4edda', color: '#155724' }; // Green background
      case 'Denied':
        return { backgroundColor: '#f8d7da', color: '#721c24' }; // Red background
      case 'Pending':
        return { backgroundColor: '#fff3cd', color: '#856404' }; // Yellow background
      default:
        return {};
    }
  };


  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="center" // Căn giữa tiêu đề
        alignItems="center"
        mb={2} // Margin dưới để cách tiêu đề với khu vực bên dưới
      >
        <h1 style={{ fontSize: "2rem", margin: 0 }}>CLEANING DUTY SCHEDULE</h1>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m={2}
        p={2} // Padding inside the container
        bgcolor="white" // Background color to white
        boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)" // Optional shadow to make it stand out
        borderRadius="8px" // Optional rounded corners for aesthetics
      >
        <Box display="flex" alignItems="center">
          {isLabManager && (
            <Button
              variant="contained"
              color="primary"
              style={{
                alignSelf: "flex-start",
                marginBottom: "10px",
                marginRight: "20px",
                zIndex: 10,
              }}
              onClick={handleCreateCleaningSchedule}
            >
              + Create cleaning schedule
            </Button>
          )}

          {/* Nút "Xem Thống Kê" hiển thị cho cả Intern và Lab Manager */}
          {(isLabManager || isIntern) && (
            <Button
              variant="contained"
              color="primary"
              style={{
                alignSelf: "flex-start",
                marginBottom: "10px",
                marginRight: "30px",
                zIndex: 10,
              }}
              onClick={fetchStatistics} // Mở popup thống kê
            >
              View statistics
            </Button>
          )}

          {isIntern && (
            <>
              <Badge
                badgeContent={swapRequestCount}
                color="error"
                showZero
                sx={{
                  "& .MuiBadge-badge": {
                    //right: -10, // Điều chỉnh vị trí phải
                    // top: 10,    // Điều chỉnh vị trí trên
                    border: `2px solid white`, // Viền để tách biệt số với nút
                    padding: "0 4px", // Khoảng cách padding cho số
                    marginRight: "30px",
                    zIndex: 10,
                  },
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    alignSelf: "flex-start",
                    marginBottom: "10px",
                    marginRight: "30px",
                    zIndex: 10,
                  }}
                  onClick={fetchSwapRequests}
                >
                  Notification Swap
                </Button>
              </Badge>
              <Button
                variant="contained"
                color="primary"
                style={{
                  alignSelf: "flex-start",
                  marginBottom: "10px",
                  zIndex: 10,
                }}
                onClick={() => setOpenCreateSwapDialog(true)} // Open Create Swap Schedule Dialog
              >
                + Create Swap Schedule
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  alignSelf: "flex-start",
                  marginBottom: "10px",
                  marginLeft: "30px",
                  zIndex: 10,
                }}
                onClick={fetchSwapHistory}
              >
                View Swap History
              </Button>
            </>
          )}
        </Box>
        <TextField
          variant="outlined"
          label="search schedule"
          type="date"
          value={highlightDate}
          onChange={(e) => handleDateChange(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            width: "300px",
            marginLeft: "20px",
          }}
        />
      </Box>

      {/* Main Calendar */}
      <Box flexGrow={1} position="relative">
        <FullCalendar
          ref={calendarRef}
          height={"90vh"}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          selectable={true}
          events={events}
          eventClick={handleEventClick}
          eventContent={(eventInfo) => {
            if (eventInfo.event.title === "No schedule") {
              return (
                <div
                  style={{
                    color: "#ce1126",
                    fontSize: "1rem",
                    textAlign: "center",
                    padding: "5px",
                    background: "yellow",
                    fontSize: "30px"
                  }}
                >
                  No schedule
                </div>
              );
            }
            const isHighlighted = highlightDate && dayjs(eventInfo.event.start).isSame(dayjs(highlightDate), "day");
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: isHighlighted ? "#1ab7ea" : "transparent",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <b>{eventInfo.event.title}</b>
                <div
                  style={{
                    marginTop: "5px",
                    padding: "3px 5px",
                    borderRadius: "5px",
                    textAlign: "center",
                    backgroundColor:
                      eventInfo.event.extendedProps.status === "Completed"
                        ? "#28a745"
                        : "#dc3545",
                    color: "white",
                  }}
                >
                  {eventInfo.event.extendedProps.status}
                </div>
              </div>
            );
          }}
        />
      </Box>
      <Dialog
        open={openStatusDialog}
        onClose={() => setOpenStatusDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Change Status</DialogTitle>
        <DialogContent>
          <Typography>
            Update status for <b>{selectedSchedule?.title}</b>
          </Typography>
          <select
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            style={{
              marginTop: "10px",
              padding: "10px",
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#f0f0f0",
              color: "black",
              border: "1px solid #ccc",
            }}
          >
            <option
              value="Completed"
              style={{ color: "white", backgroundColor: "#28a745" }}
            >
              Completed
            </option>
            <option
              value="Not Completed"
              style={{ color: "white", backgroundColor: "#dc3545" }}
            >
              Not Completed
            </option>
          </select>
          <Box mt={2}>
            <Typography>Upload Proof Image:</Typography>
            <input
              type="file"
              accept="image/*"
              onChange={handleProofImageChange}
              style={{ marginTop: "10px" }}
            />
            {proofImagePreview && (
              <Box mt={2} style={{ textAlign: "center" }}>
                <img
                  src={proofImagePreview}
                  alt="Proof Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveStatusChange}
            color="primary"
            disabled={!proofImagePreview}
          >
            Save
          </Button>
          <Button onClick={() => setOpenStatusDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup for Swap Requests */}
      <Dialog
        open={openSwapDialog}
        onClose={() => setOpenSwapDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Swap Requests</DialogTitle>
        <DialogContent>
          {swapRequests.length > 0 ? (
            swapRequests.map((request) => (
              <Box key={request.swapId} mb={2}>
                <Typography>
                  <b>Request from:</b> Intern {request.requestInternName}
                </Typography>
                <Typography>
                  <b>Swap from:</b> {formatDate(request.requestToDate)} to{" "}
                  {formatDate(request.requestFromDate)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleApproveSwap(request.swapId)}
                  style={{ marginRight: "10px" }}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRejectSwap(request.swapId)}
                >
                  Reject
                </Button>
              </Box>
            ))
          ) : (
            <Typography>No pending swap requests.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSwapDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup for Date Selection */}
      <Dialog
        open={openDateDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Select a schedule time</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={selectedStartDate}
            onChange={(e) => setSelectedStartDate(e.target.value)}
            inputProps={{
              min: today, // Giới hạn ngày tối thiểu là ngày hôm nay
            }}
          />
          <TextField
            margin="dense"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={selectedEndDate}
            onChange={(e) => setSelectedEndDate(e.target.value)}
            inputProps={{
              min: selectedStartDate || today, // Giới hạn ngày kết thúc phải sau ngày bắt đầu hoặc hôm nay
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSaveSchedule}
            color="primary"
            disabled={!selectedStartDate || !selectedEndDate}
          >
            Save
          </Button>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup for Create Swap Schedule */}
      <Dialog
        open={openCreateSwapDialog}
        onClose={() => setOpenCreateSwapDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create swap schedule</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Swap From Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={swapFromDate}
            onChange={(e) => handleSwapFromDateChange(e.target.value)}
            inputProps={{
              min: today,
            }}
          />
          <TextField
            margin="dense"
            label="Swap To Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={swapToDate}
            onChange={(e) => handleSwapToDateChange(e.target.value)} // Gọi hàm khi chọn Swap To Date
            inputProps={{
              min: today,
            }}
          />

          <TextField
            margin="dense"
            label="Response Intern Name"
            fullWidth
            value={
              availableInterns.length > 0 ? availableInterns[0].internName : ""
            } // Hiển thị internName nếu có intern
            InputProps={{
              readOnly: true, // Chỉ đọc, không cho phép chỉnh sửa
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCreateSwapSchedule}
            color="primary"
            disabled={
              !swapFromDate || !swapToDate || availableInterns.length === 0
            } // Vô hiệu hóa nút nếu bất kỳ trường nào trống
          >
            Create
          </Button>
          <Button
            onClick={() => setOpenCreateSwapDialog(false)}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup for Delete Schedule */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm deletion of cleaning schedule</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this cleaning schedule?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSchedule} color="primary">
            Yes
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup for Proof Image */}
      <Dialog
        open={openProofDialog}
        onClose={() => setOpenProofDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Cleaning Proof Image</DialogTitle>
        <DialogContent>
          {proofImage ? (
            <img
              src={proofImage}
              alt="Proof"
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            <Typography>No proof image available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenProofDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStatisticsDialog}
        onClose={() => setOpenStatisticsDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Work Completion Statistics</DialogTitle>
        <DialogContent>
          {chartData ? (
            isIntern ? (
              <div style={{ width: "300px", margin: "0 auto", textAlign: "center" }}>
                <Pie
                  data={chartData}
                  options={{
                    plugins: {
                      datalabels: {
                        display: true,
                        color: "white",
                        formatter: (value) => `${value} days`,
                      },
                      legend: {
                        position: "bottom",
                        labels: {
                          boxWidth: 25, 
                          padding: 10,
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <Bar
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      position: "bottom",
                    },
                    datalabels: {
                      display: true,
                      color: "black",
                      formatter: (value) => `${value} days`,
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: "Interns",
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Days",
                      },
                    },
                  },
                }}
              />
            )
          ) : (
            <Typography>No data available for statistics.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStatisticsDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openSwapHistoryDialog}
        onClose={() => setOpenSwapHistoryDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Swap History</DialogTitle>
        <DialogContent
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
            }}
        >
          {swapHistory.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Requested From Date</TableCell>
                    <TableCell align="center">Requested To Date</TableCell>
                    <TableCell align="center">Request To Intern</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {swapHistory.map((history, index) => (
                    <TableRow key={history.swapId}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{dayjs(history.requestFromDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell align="center">{dayjs(history.requestToDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell align="center">{history.requestInternName}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          ...getStatusStyle(history.status),
                          padding: '8px',
                          borderRadius: '4px',
                        }}
                      >
                        {history.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No swap history found.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSwapHistoryDialog(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyCalendar;
