import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import KeyResultsModal from "./KeyResultsModal";
import {
  formatDate,
  truncateTarget,
  getProgressColor,
  statusStyles,
  formatProgress,
} from "./format";
import { useNavigate } from "react-router-dom";

export default function MyOKRList({ title, objectives, loading }) {
  // const [objectives, setObjectives] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [objectiveId, setObjectiveId] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchMyObjectives = async () => {
  //     setLoading(true);
  //     try {
  //       const data = await fetchObjectives();
  //       setObjectives(data);
  //     } catch (err) {
  //       console.error("Error fetching My Objectives:", err);
  //       setError("Failed to fetch My Objectives.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchMyObjectives();
  // }, [fetchObjectives]);

  const handleOpenModal = (objectiveId) => {
    setObjectiveId(objectiveId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // fetchObjectives();
  };

  const handleOpenDetailPage = (objectiveId) => {
    navigate(`/okr-detail/${objectiveId}`);
  };

  if (error) {
    return (
      <Typography color="error" variant="body1" align="center">
        {error}
      </Typography>
    );
  }

  return (
    <div>
      <h2>{title}</h2>

      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "500px",
          overflowY: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "40%", fontWeight: "bold" }}>
                Target
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Creator
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Progress
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Key Results
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Start Date
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                End Date
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Loading indicator in the center
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress size={25} />
                </TableCell>
              </TableRow>
            ) : objectives.length > 0 ? (
              objectives.map((okr) => (
                <TableRow key={okr.Id}>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center">
                        <Avatar
                          alt={okr.User?.FirstName || "Anonymous"}
                          src={
                            okr.User?.Avatar
                              ? `data:image/png;base64,${okr.User.Avatar}`
                              : "/default-avatar.png"
                          }
                          sx={{ width: 40, height: 40, mr: 2 }}
                        />
                        <strong
                          onClick={() => handleOpenDetailPage(okr.Id)}
                          style={{ cursor: "pointer" }}
                        >
                          {truncateTarget(okr.Target)}
                        </strong>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {okr.User?.FirstName} {okr.User?.LastName || "Anonymous"}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Box width="100%" mr={1}>
                        {/* <LinearProgress
                          variant="determinate"
                          value={okr.ProgressPercentage || 0}
                          sx={{
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: getProgressColor(
                                okr.ProgressPercentage || 0
                              ),
                            },
                          }}
                        /> */}
                        <LinearProgress
                          variant="determinate"
                          value={Math.min(okr.ProgressPercentage || 0, 100)} // Hiển thị tối đa 100%
                          sx={{
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: getProgressColor(
                                Math.min(okr.ProgressPercentage || 0, 100)
                              ),
                            },
                          }}
                        />
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {`${formatProgress(okr.ProgressPercentage || 0)}%`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      sx={{ padding: "2px 4px" }}
                      onClick={() => handleOpenModal(okr.Id)}
                    >
                      {okr.KeyResults?.length || 0} Key Results
                    </Button>
                  </TableCell>
                  <TableCell>{formatDate(okr.StartDate)}</TableCell>
                  <TableCell>{formatDate(okr.EndDate)}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        ...statusStyles[okr.ProcessStatus],
                      }}
                    >
                      {okr.ProcessStatus.replace("_", " ")}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No {title} available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <KeyResultsModal
        open={openModal}
        onClose={handleCloseModal}
        objectiveId={objectiveId}
      />
    </div>
  );
}
