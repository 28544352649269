import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FeedbackIcon from "@mui/icons-material/Feedback";
import VerifiedIcon from "@mui/icons-material/Verified";
import { toast } from "react-toastify";
const now = new Date(new Date().getTime());

const ReportHeader = ({
  type,
  parentObjectiveId,
  objectiveData,
  openHistoryModal,
  isOverview,
  handleAccomplish,
  historyData,
  auth,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const startDateCheck = new Date(objectiveData?.objective.endDate)
  const isEditable = objectiveData?.objective?.createBy===auth;
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmAccomplish = () => {

    if (!isTypeAllowed && historyData.length === 0 && !isOverview) {
      toast.error(
        "Cannot accomplish the report. The history report list is empty."
      );
    } else {
      if (
        !objectiveData.progressPerformances ||
        objectiveData.progressPerformances.length === 0
      ) {
        toast.error("You have not created any key result yet.");
      } else {
        handleAccomplish();
      }
    }

    setOpenConfirmDialog(false);
  };

  const isTypeAllowed = parentObjectiveId === null;

  return (
    <Card sx={{ p: "0" }}>
      {!isOverview && (
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <IconButton
                onClick={() => window.history.back()}
                color="primary"
                sx={{ pt: 0, pb: 0 }}
              >
                <ArrowBackIosIcon sx={{ pb: 1 }} />
              </IconButton>
              <Box
                component="span"
                sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Report Objective:{" "}
              </Box>
              <Box component="span" sx={{ fontSize: "1.5rem" }}>
                {objectiveData.objective.target}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {isEditable &&type !== "Team_Challenge_Objective" && startDateCheck>=now && (
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleOpenConfirmDialog}
                  startIcon={<VerifiedIcon />}
                  sx={{
                    paddingRight: "2px",
                    flex: 1,
                    height: "50px",
                    lineHeight: 1,
                    fontSize: { xs: "0.75rem", sm: "0.85rem", md: "1rem" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "150px",
                  }}
                >
                  Accomplish Objective
                </Button>
              )}
              {!isTypeAllowed &&type !== "Team_Challenge_Objective" && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openHistoryModal}
                  startIcon={<FeedbackIcon />}
                  sx={{
                    flex: 1,
                    height: "50px",
                    lineHeight: 1,
                    fontSize: { xs: "0.75rem", sm: "0.85rem", md: "1rem" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "150px",
                  }}
                >
                  {!isEditable ? "Feedback Report" : "History Report"}
                </Button>
              )}
            </Box>
          </Box>

          <Typography>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Start Date:
            </Box>{" "}
            {new Date(objectiveData.objective.startDate).toLocaleDateString(
              "en-GB"
            )}
            {" - "}
            <Box component="span" sx={{ fontWeight: "bold" }}>
              End Date:
            </Box>{" "}
            {new Date(objectiveData.objective.endDate).toLocaleDateString(
              "en-GB"
            )}
          </Typography>

          <Typography>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Next Report Date:
            </Box>{" "}
            {new Date(objectiveData.nextReportDate).toLocaleDateString("en-GB")}
          </Typography>
        </CardContent>
      )}
      {isOverview && (
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: 2,
            }}
          >
            <Box>
              <IconButton
                onClick={() => window.history.back()}
                color="primary"
                sx={{ pt: 0, pb: 1 }}
              >
                <ArrowBackIosIcon sx={{ pb: 1 }} />
              </IconButton>
              <Box
                component="span"
                sx={{ fontWeight: "bold", fontSize: "2rem" }}
              >
                Report Objective:{" "}
              </Box>
              <Box component="span" sx={{ fontSize: "2rem" }}>
                {objectiveData.objective.target}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {isEditable &&type !== "Team_Challenge_Objective" && startDateCheck>=now &&(
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleOpenConfirmDialog}
                  startIcon={<VerifiedIcon />}
                  sx={{
                    paddingRight: "2px",
                    flex: 1,
                    height: "50px",
                    lineHeight: 1,
                    fontSize: { xs: "0.75rem", sm: "0.85rem", md: "1rem" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "150px",
                  }}
                >
                  Accomplish Objective
                </Button>
              )}
              {!isTypeAllowed &&type !== "Team_Challenge_Objective" && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openHistoryModal}
                  startIcon={<FeedbackIcon />}
                  sx={{
                    flex: 1,
                    height: "50px",
                    lineHeight: 1,
                    fontSize: { xs: "0.75rem", sm: "0.85rem", md: "1rem" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "150px",
                  }}
                >
                  {!isEditable ? "Feedback Report" : "History Report"}
                </Button>
              )}
            </Box>
          </Box>
          <Typography sx={{ paddingLeft: "40px" }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 1,
              }}
            >
              <Box>
                <Box
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  Start Date:
                </Box>{" "}
                <Box component="span" sx={{ fontSize: "1.2rem" }}>
                  {new Date(
                    objectiveData.objective.startDate
                  ).toLocaleDateString("en-GB")}
                  {" - "}
                </Box>
                <Box
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  End Date:
                </Box>{" "}
                <Box component="span" sx={{ fontSize: "1.2rem" }}>
                  {new Date(objectiveData.objective.endDate).toLocaleDateString(
                    "en-GB"
                  )}
                </Box>
              </Box>
              <Box>
                <Box
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    color: "#00BB00",
                  }}
                >
                  Completion Time:
                </Box>{" "}
                <Box component="span" sx={{ fontSize: "1.2rem" }}>
                  {new Date(
                    objectiveData.objective.completionTime
                  ).toLocaleDateString("en-GB")}
                </Box>
              </Box>
            </Typography>
            <Typography>
              <Box
                component="span"
                sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
              >
                Status:
              </Box>{" "}
              <Box component="span" sx={{ fontSize: "1.2rem" }}>
                {objectiveData.objective.processStatus}
              </Box>
              <Box
                component="span"
                sx={{ fontWeight: "bold", fontSize: "1.2rem", paddingLeft: 5 }}
              >
                Progress:
              </Box>{" "}
              <Box component="span" sx={{ fontSize: "1.2rem" }}>
              {objectiveData.objective.progressPercentage.toFixed(2)}%
              </Box>
            </Typography>
          </Typography>
        </CardContent>
      )}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          Confirm Accomplish Objective
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to accomplish this objective?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAccomplish} color="success" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ReportHeader;
