import React, { useState, useContext, useEffect, Suspense } from "react";
import { OKRContext } from "../../context/OKRContext";
import { JuryPanelContext } from "../../context/JuryPanelContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../customs/authService";
import AddIcon from "@mui/icons-material/Add";
import Reply from "@mui/icons-material/Reply";
import Save from "@mui/icons-material/Save";

import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  CircularProgress,
  IconButton,
} from "@mui/material";
// Sử dụng React.lazy để lazy load các component
const KeyResultForm = React.lazy(() => import("./KeyResultForm"));
const ObjectiveTypeSelector = React.lazy(() =>
  import("./ObjectiveTypeSelector")
);
const SuperiorObjectiveSelector = React.lazy(() =>
  import("./SuperiorObjectiveSelector")
);
const JuryPanelSelector = React.lazy(() => import("./JuryPanelSelector"));
const DatePickerField = React.lazy(() => import("./DatePickerField"));
const ConfirmationDialog = React.lazy(() => import("./ConfirmationDialog"));
const ConfirmationDialogCancel = React.lazy(() =>
  import("./ConfirmationDialogCancel")
);
const CreateObjective = () => {
  const { t } = useAuth();
  const {
    addMyObjective,
    fetchAllParentObjects,
    fetchObjectiveTypes,
    fetchMeasurementUnits,
    auth,
    currentSemester,
  } = useContext(OKRContext);
  const { fetchJuryPanelGroup } = useContext(JuryPanelContext);

  const [okrData, setOkrData] = useState({
    objectiveType: "",
    superiorObjective: "",
    target: "",
    description: "",
    registrationStartDate: "",
    registrationEndDate: "",
    startDate: "",
    endDate: "",
    milestoneDate: "",
    reportStartDate: "",
    reportEndDate: "",
    gradeStartTime: "",
    gradeEndTime: "",
    juryPanelGroupId: "",
    createBY: auth?.user?.jti,
  });

  const [objectiveTypeOptions, setObjectiveTypeOptions] = useState([]);
  const [superiorObjectiveOptions, setSuperiorObjectiveOptions] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [keyResults, setKeyResults] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [keyResultToDelete, setKeyResultToDelete] = useState(null);
  const [juryPanelOptions, setJuryPanelOptions] = useState([]);
  const [relatedKeyResultsOptions, setRelatedKeyResultsOptions] = useState([]);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [startDateData, setStartDateData] = useState(null);
  const [endDateData, setEndDateData] = useState(null);
  const [minStartDate, setMinStartDate] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(null);
  const [minEndDate, setMinEndDate] = useState(null);
  const [minMilestoneDate, setMinMilestoneDate] = useState(null);
  const [maxMilestoneDate, setMaxMilestoneDate] = useState(null);
  const [minResStartDate, setMinResStartDate] = useState(null);
  const [minResEndDate, setMinResEndDate] = useState(null);
  const [minReportStartDate, setMinReportStartDate] = useState(null);
  const [minReportEndDate, setMinReportEndDate] = useState(null);

  const [minGradeStartTime, setMinGradeStartTime] = useState(null);
  const [minGradeEndTime, setMinGradeEndTime] = useState(null);

  const [minStartDateKR, setMinStartDateKR] = useState(null);
  const [maxStartDateKR, setMaxStartDateKR] = useState(null);

  const navigate = useNavigate();
  const now = new Date(new Date().getTime());
  const addOneDayAndSevenHours = (dateValue) =>
    new Date(new Date(dateValue).getTime() + 7 * 60 * 60 * 1000);

  useEffect(() => {
    const fetchTypeObjectData = async () => {
      const types = await fetchObjectiveTypes();
      if (types) {
        setObjectiveTypeOptions(types);
      }
    };
    fetchTypeObjectData();
    const fetchUnits = async () => {
      const units = await fetchMeasurementUnits();
      if (units) {
        setMeasurementUnits(units);
      }
    };
    fetchUnits();
    setMinStartDate(now);
    setMinEndDate(now);

    if (!okrData.startDate) {
      setMinStartDateKR(now);
      setMaxStartDateKR(
        addOneDayAndSevenHours(new Date(currentSemester.endDate))
      );
    } else {
      setMinStartDateKR(addOneDayAndSevenHours(new Date(okrData.startDate)));
      setMaxStartDateKR(addOneDayAndSevenHours(new Date(okrData.endDate)));
    }

    setMinResEndDate(now);
    setMinMilestoneDate(now);
    setMinReportStartDate(now);
    setMinReportEndDate(now);
    setMinGradeStartTime(now);
  }, [fetchObjectiveTypes, fetchMeasurementUnits]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedData = { ...okrData, [name]: value === "none" ? null : value };

    if (name === "startDate") {
      const startDateAdjusted = value;
      const startTime = new Date(value);
      setMinStartDateKR(addOneDayAndSevenHours(new Date(startDateAdjusted)));
      setMinEndDate(startDateAdjusted);

      if (okrData.endDate) {
        const endDateAdjusted = okrData.endDate;
        const endTime = new Date(okrData.endDate);
        setMinMilestoneDate(startDateAdjusted);
        setMaxMilestoneDate(endDateAdjusted);
        const duration = endTime - startTime;
        const milestone = new Date(startTime.getTime() + (2 / 3) * duration);
        updatedData.milestoneDate = formatDateForInput(milestone);
        setErrors((prevErrors) => ({ ...prevErrors, milestoneDate: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          milestoneDate: "Please select Start Date and End Date first.",
        }));
      }
    }

    if (name === "endDate") {
      const endDateAdjusted = value;

      const endTime = new Date(value);

      setMaxStartDateKR(addOneDayAndSevenHours(new Date(endDateAdjusted)));
      setMinReportStartDate(endDateAdjusted);
      setMinReportEndDate(endDateAdjusted);
      if (okrData.startDate) {
        const startDateAdjusted = okrData.startDate;
        const startTime = new Date(okrData.startDate);

        setMinMilestoneDate(startDateAdjusted);
        setMaxMilestoneDate(endDateAdjusted);
        const duration = endTime - startTime;
        const milestone = new Date(startTime.getTime() + (2 / 3) * duration);
        updatedData.milestoneDate = formatDateForInput(milestone);
        setErrors((prevErrors) => ({ ...prevErrors, milestoneDate: "" }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          milestoneDate: "Please select Start Date and End Date first.",
        }));
      }
    }

    if (name === "registrationStartDate") {
      setMinResEndDate(value);
    }
    if (name === "registrationEndDate") {
      const registrationEndDateAdjusted = value;
      setMinStartDate(registrationEndDateAdjusted);
      setMinReportStartDate(registrationEndDateAdjusted);
    }
    if (name === "reportStartDate") {
      const reportStartDateAdjusted = value;
      setMinReportEndDate(reportStartDateAdjusted);
    }
    if (name === "reportEndDate") {
      setMinGradeStartTime(value);
    }
    if (name === "gradeStartTime") {
      setMinGradeEndTime(value);
    }

    if (name === "objectiveType") {
      if (
        [
          "Team Objective",
          "Team Challenge Objective",
          "Intern Objective",
        ].includes(value)
      ) {
        updateSuperiorObjectives(value);
      } else {
        updatedData.superiorObjective = null;
        setMinStartDate(now);
        setMaxStartDate(null);
        setMinStartDateKR(now);
        setMaxStartDateKR(null);
      }

      if (value === "Challenge Objective") {
        setKeyResults([]);
        updateJuryPanel();
        setMinStartDate(now);
        setMinEndDate(now);
        setMinResStartDate(now);

        if (okrData.startDate) {
          setMinStartDateKR(now);
        } else {
          setMinStartDateKR(
            addOneDayAndSevenHours(new Date(okrData.startDate))
          );
        }

        setMinResEndDate(now);
        setMinMilestoneDate(now);
        setMinReportStartDate(now);
        setMinReportEndDate(now);
        setMinGradeStartTime(now);
        updatedData.superiorObjective = null;
      } else {
        updatedData.juryPanelOptions = null;
        updatedData.registrationStartDate = null;
        updatedData.registrationEndDate = null;
        updatedData.milestoneDate = null;
        updatedData.reportStartDate = null;
        updatedData.reportEndDate = null;
        updatedData.gradeStartTime = null;
        updatedData.gradeEndTime = null;
        setMinStartDate(now);
        setMaxStartDate(null);
        setMinStartDateKR(now);
        setMaxStartDateKR(null);
      }
    }
    if (name === "target" && value.length >= 100) {
      setErrors({
        ...errors,
        target: t("createObjective.maxLengthError", { maxLength: 100 }),
      });
      return;
    } else {
      setErrors({ ...errors, target: "" });
    }
    if (name === "description" && value.length >= 500) {
      setErrors({
        ...errors,
        description: t("createObjective.maxLengthDescription", { maxLength: 500 }),
      });
      return;
    } else {
      setErrors({ ...errors, description: "" });
    }

    setOkrData(updatedData);
    setErrors({ ...errors, [name]: "" });
  };

  const updateSuperiorObjectives = async (TypeOfObjective) => {
    const objectives = await fetchAllParentObjects(TypeOfObjective);
    const allKeyResults = objectives.reduce((acc, objective) => {
      return acc.concat(objective.keyResults);
    }, []);
    setSuperiorObjectiveOptions(objectives);
    setRelatedKeyResultsOptions(allKeyResults);
  };

  const updateJuryPanel = async () => {
    const juryPanelData = await fetchJuryPanelGroup();
    setJuryPanelOptions(juryPanelData);
  };
  const isValidURL = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)" + // protocol (HTTP hoặc HTTPS bắt buộc)
        "((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*))\\.?)+([a-zA-Z]{2,})" + // domain name
        "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*" + // port và path
        "(\\?[;&a-zA-Z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-zA-Z\\d_]*)?$",
      "i"
    );
    return pattern.test(url);
  };

  const handleKeyResultChange = (index, event) => {
    const { name, value } = event.target;
    const newKeyResults = [...keyResults];
    if (name === "target" && value && !/^\d*\.?\d*$/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`target_${index}`]: t(
          "createObjective.validateKeyresultTargetDecimal"
        ),
      }));
      return;
    }

    newKeyResults[index][name] = value === "none" ? null : value;
    setKeyResults(newKeyResults);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`target_${index}`]: "",
    }));
  };

  const handleAddKeyResult = () => {
    setKeyResults([
      ...keyResults,
      {
        title: "",
        target: "",
        unit: measurementUnits[0] || "",
        startDate: "",
        endDate: "",
        planLink: "",
        resultLink: "",
        relatedKeyResults: [],
      },
    ]);
  };
  const handleJuryPanelChange = async (event) => {
    const { value } = event.target;
    let updatedOkrData = {
      ...okrData,
      juryPanelGroupId: value === "none" ? null : value,
    };
    setOkrData(updatedOkrData);
  };

  const handleSuperiorObjectiveChange = async (event) => {
    const { value } = event.target;
    let updatedOkrData = {
      ...okrData,
      superiorObjective: value === "none" ? null : value,
    };

    if (value === "none") {
      setMinStartDate(now);
      setMinResStartDate(now);
      setMaxStartDateKR(null);
      setMaxStartDate(null);
      setKeyResults((prevKeyResults) =>
        prevKeyResults.map((keyResult) => ({
          ...keyResult,
          relatedKeyResults: [],
        }))
      );
      setMinStartDateKR(now);
      setMaxStartDateKR(null);
      setRelatedKeyResultsOptions([]);
    } else {
      const selectedObjective = superiorObjectiveOptions.find(
        (obj) => obj.id === value
      );

      if (selectedObjective) {
        const superiorStartDate = new Date(selectedObjective.startDate);
        setMinStartDate(superiorStartDate);
        setMinEndDate(superiorStartDate);
        setMaxStartDate(new Date(selectedObjective.endDate));
        setMinStartDateKR(addOneDayAndSevenHours(superiorStartDate));
        setMaxStartDateKR(
          addOneDayAndSevenHours(new Date(selectedObjective.endDate))
        );
        updatedOkrData.startDate =
          superiorStartDate > now
            ? setStartDateData(selectedObjective.startDate)
            : setStartDateData(null);
        updatedOkrData.endDate = setEndDateData(selectedObjective.endDate);
        const relatedKeyResults =
          selectedObjective.keyResults.length > 0
            ? selectedObjective.keyResults.map((kr) => ({
                id: kr.id,
                title: kr.title,
              }))
            : [];

        setRelatedKeyResultsOptions(relatedKeyResults);

        const updatedKeyResults = keyResults.map((keyResult) => ({
          ...keyResult,
          relatedKeyResults: relatedKeyResults,
        }));
        setKeyResults(updatedKeyResults);
        setRelatedKeyResultsOptions(relatedKeyResults);
      }
    }
    setOkrData(updatedOkrData);
  };

  const handleDeleteClick = (index) => {
    setKeyResultToDelete(index);
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    setKeyResults((prevKeyResults) =>
      prevKeyResults.filter((_, i) => i !== keyResultToDelete)
    );
    setOpenDialog(false);
    setKeyResultToDelete(null);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setKeyResultToDelete(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    let validationErrors = {};
    if (
      okrData.objectiveType === "Team Challenge Objective" &&
      keyResults.length === 0
    ) {
      toast.error("Must create key result for objective");
      setIsSubmitting(false);
      return;
    }

    let startDateObj = new Date(okrData.startDate);
    startDateObj.setMinutes(startDateObj.getMinutes());
    const startDatecheck = new Date(okrData.startDate).getTime();
    const startDate = startDateObj;
    const endDate = new Date(okrData.endDate).getTime();
    const registrationStartDate = okrData.registrationStartDate
      ? new Date(okrData.registrationStartDate).getTime()
      : null;
    const registrationEndDate = okrData.registrationEndDate
      ? new Date(okrData.registrationEndDate).getTime()
      : null;
    const reportStartDate = okrData.reportStartDate
      ? new Date(okrData.reportStartDate).getTime()
      : null;

    const reportEndDate = okrData.reportEndDate
      ? new Date(okrData.reportEndDate).getTime()
      : null;
    const milestoneDate = okrData.milestoneDate
      ? new Date(okrData.milestoneDate).getTime()
      : null;
    const gradeEndTime = okrData.gradeEndTime
      ? new Date(okrData.gradeEndTime).getTime()
      : null;
    const gradeStartTime = okrData.gradeStartTime
      ? new Date(okrData.gradeStartTime).getTime()
      : null;
    if (!okrData.objectiveType || okrData.objectiveType.trim() === "") {
      validationErrors.objectiveType = t(
        "createObjective.selectObjectiveTypeError"
      );
    }

    if (
      okrData.objectiveType === "Challenge Objective" &&
      registrationStartDate &&
      registrationEndDate &&
      registrationEndDate <= registrationStartDate
    ) {
      validationErrors.registrationEndDate = t(
        "createObjective.validateRegistrationEnd_4"
      );
    }
    if (
      okrData.objectiveType === "Challenge Objective" &&
      registrationEndDate &&
      startDate <= registrationEndDate
    ) {
      validationErrors.startDate = t("createObjective.validateStartDate_3");
    }
    if (
      okrData.objectiveType === "Challenge Objective" &&
      registrationEndDate &&
      endDate <= registrationEndDate
    ) {
      validationErrors.endDate = t("createObjective.validateEndDate_1");
    }
    if (reportStartDate && reportStartDate <= endDate) {
      validationErrors.reportStartDate = t(
        "createObjective.validateReportStartDate_1"
      );
    }
    if (reportEndDate && reportEndDate <= endDate) {
      validationErrors.reportEndDate = t(
        "createObjective.validateReportEndDate_1"
      );
    }
    if (reportEndDate && reportEndDate <= endDate) {
      validationErrors.reportEndDate = t(
        "createObjective.validateReportEndDate_1"
      );
    }
    if (
      okrData.objectiveType === "Challenge Objective" &&
      milestoneDate &&
      milestoneDate === endDate
    ) {
      validationErrors.milestoneDate = t("createObjective.validateMilestone_2");
    }
    if (
      okrData.objectiveType === "Challenge Objective" &&
      milestoneDate &&
      milestoneDate === startDatecheck
    ) {
      validationErrors.milestoneDate = t("createObjective.validateMilestone_1");
    }

    if (gradeStartTime && gradeStartTime <= reportEndDate) {
      validationErrors.gradeStartTime = t(
        "createObjective.validateGradeStartTime_1"
      );
    }
    if (gradeEndTime && gradeEndTime <= reportEndDate) {
      validationErrors.gradeEndTime = t(
        "createObjective.validateGradeEndTime_1"
      );
    }

    if (
      okrData.objectiveType === "Challenge Objective" &&
      registrationStartDate &&
      registrationStartDate < now
    ) {
      validationErrors.registrationStartDate = t(
        "createObjective.validateRegistrationStart_2"
      );
    }
    if (
      !["Team Challenge Objective"].includes(okrData.objectiveType) &&
      !okrData.startDate
    ) {
      validationErrors.startDate = t("createObjective.validateStartDate_4");
    }

    if (
      !["Team Challenge Objective"].includes(okrData.objectiveType) &&
      !okrData.endDate
    ) {
      validationErrors.endDate = t("createObjective.validateEndDate_1");
    }

    if (okrData.endDate && endDate <= startDate) {
      validationErrors.endDate = t("createObjective.validateRegistrationEnd_3");
    }
    if (okrData.endDate && endDate <= startDate) {
      validationErrors.endDate = t("createObjective.validateStartDate_3");
    }
    if (!okrData.target || okrData.target.trim() === "") {
      validationErrors.target = t("createObjective.validateTargetObjective");
    }
    if (okrData.reportEndDate && reportEndDate <= reportStartDate) {
      validationErrors.reportEndDate = t(
        "createObjective.validateReportEndDate_2"
      );
    }
    if (okrData.gradeEndTime && gradeEndTime <= gradeStartTime) {
      validationErrors.gradeEndTime = t(
        "createObjective.validateGradeEndTime_2"
      );
    }

    let updatedOkrData = { ...okrData };

    if (
      ["Intern Objective"].includes(okrData.objectiveType) &&
      !okrData.superiorObjective
    ) {
      validationErrors.superiorObjective = t(
        "createObjective.requiredSuperiorObjective"
      );
    }
    if (
      okrData.objectiveType === "Challenge Objective" &&
      !okrData.juryPanelGroupId
    ) {
      validationErrors.juryPanel = t("createObjective.requiredJuryPanelGroup");
    }
    if (
      ["Team Challenge Objective"].includes(okrData.objectiveType) &&
      okrData.superiorObjective === ""
    ) {
      validationErrors.superiorObjective = t(
        "createObjective.requiredSuperiorObjective"
      );
    }
    if (updatedOkrData.objectiveType === "Challenge Objective") {
      if (!okrData.milestoneDate) {
        validationErrors.milestoneDate = t(
          "createObjective.validateMilestoneDate"
        );
      }
      if (!reportStartDate) {
        validationErrors.reportStartDate = t(
          "createObjective.validateReportStart"
        );
      }
      if (!okrData.reportEndDate) {
        validationErrors.reportEndDate = t("createObjective.validateReportEnd");
      }
      if (!okrData.gradeStartTime) {
        validationErrors.gradeStartTime = t(
          "createObjective.validateGradeStartTime"
        );
      }
      if (!okrData.gradeEndTime) {
        validationErrors.gradeEndTime = t(
          "createObjective.validateGradeEndTime"
        );
      }
      if (!updatedOkrData.registrationStartDate) {
        validationErrors.registrationStartDate = t(
          "createObjective.validateRegistrationStart"
        );
      }
      if (!updatedOkrData.registrationEndDate) {
        validationErrors.registrationEndDate = t(
          "createObjective.validateRegistrationEnd"
        );
      }
      if (
        updatedOkrData.registrationStartDate >=
        updatedOkrData.registrationEndDate
      ) {
        validationErrors.registrationEndDate = t(
          "createObjective.validateRegistrationEnd_2"
        );
      }

      if (
        okrData.objectiveType === "Challenge Objective" &&
        updatedOkrData.registrationEndDate &&
        startDate <= registrationEndDate
      ) {
        validationErrors.startDate = t("createObjective.validateStartDate_3");
      }
    }
    if (startDateData && okrData.startDate < startDateData) {
      validationErrors.startDate = `Start date must be after the designated start date. (${formatDate(
        startDateData
      )})`;
    }
    if (endDateData && okrData.endDate > endDateData) {
      validationErrors.endDate = `End date must be before the designated end date.(${formatDate(
        endDateData
      )})`;
    }
    keyResults.forEach((keyResult, index) => {
      const keyResultStartDate = new Date(keyResult.startDate);
      const keyResultEndDate = new Date(keyResult.endDate);

      if (keyResultStartDate <= startDate) {
        validationErrors[`startDate_${index}`] = `${t(
          "createObjective.validateKeyresultStartDate_1_1"
        )}  ${t("createObjective.validateKeyresultStartDate_1_2")}`;
      }

      if (keyResultEndDate >= endDate) {
        validationErrors[`endDate_${index}`] = `${t(
          "createObjective.validateKeyresultEndDate_1_1"
        )}  ${t("createObjective.validateKeyresultEndDate_1_2")}`;
      }

      if (keyResultEndDate <= keyResultStartDate) {
        validationErrors[`endDate_${index}`] = `${t(
          "createObjective.validateKeyresultEndDate_1_1"
        )} ${t("createObjective.validateKeyresultEndDate_1_2")}`;
      }

      if (!keyResult.title) {
        validationErrors[`title_${index}`] = `${t(
          "createObjective.validateKeyresultTitle_1"
        )} ${t("createObjective.validateIsRequied")}`;
      }
      if (!keyResult.target || keyResult.target === "0") {
        validationErrors[`target_${index}`] = `${t(
          "createObjective.validateKeyresultTarget_1"
        )} ${t("createObjective.validateIsRequied")}`;
      }
      if (!keyResult.unit) {
        validationErrors[`unit_${index}`] = `${t(
          "createObjective.validateKeyresultUnit_1"
        )}  ${t("createObjective.validateIsRequied")}`;
      }
      if (!keyResult.startDate) {
        validationErrors[`startDate_${index}`] = `${t(
          "createObjective.validateKeyresultStartDate_1"
        )}  ${t("createObjective.validateIsRequied")}`;
      }
      if (!keyResult.endDate) {
        validationErrors[`endDate_${index}`] = `${t(
          "createObjective.validateKeyresultEndDate_1"
        )}  ${t("createObjective.validateIsRequied")}`;
      }
      if (keyResult.planLink && !isValidURL(keyResult.planLink)) {
        validationErrors[`planLink_${index}`] = t(
          "createObjective.validateLinkError"
        );
      }
      if (keyResult.resultLink && !isValidURL(keyResult.resultLink)) {
        validationErrors[`resultLink_${index}`] = t(
          "createObjective.validateLinkError"
        );
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors);
      setErrors(validationErrors);
      setIsSubmitting(false);

      return;
    }

    const dataToSubmit = {
      ...updatedOkrData,
      keyResults: keyResults.map((keyResult) => ({
        ...keyResult,
        measurementUnit: keyResult.unit,
        parternKeyResults:
          keyResult.relatedKeyResults?.length > 0
            ? keyResult.relatedKeyResults
            : null,
      })),
    };

    try {
      const response = await addMyObjective(dataToSubmit);
      if (response.status === 200) {
        setErrors({});
        toast.success(t("okr.toast_add_o_s"));
        navigate("/okr/okrdashboard");
      } else {
        toast.error(t("okr.toast_add_f"));
      }
    } catch (err) {
      toast.error(t("okr.toast_add_f"));
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleCancelClick = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    navigate("/okr/okrdashboard");
  };
  const formatDateForInput = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${hours}:${minutes}:${seconds} - ${day}/${month}/${year}`;
  }
  return (
    <Container>
      <Paper
        elevation={3}
        sx={{ p: 3, backgroundColor: "#f9f9f9", borderRadius: "12px" }}
      >
        <Suspense fallback={<CircularProgress />}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  {t("createObjective.createObjective")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ObjectiveTypeSelector
                  objectiveType={okrData.objectiveType}
                  objectiveTypeOptions={objectiveTypeOptions}
                  handleInputChange={handleInputChange}
                  error={errors.objectiveType}
                />
              </Grid>

              {/* Superior Objective Selector */}
              {["Team Objective"].includes(okrData.objectiveType) && (
                <Grid item xs={12}>
                  <SuperiorObjectiveSelector
                    superiorObjective={okrData.superiorObjective}
                    superiorObjectiveOptions={[
                      { id: "none", name: t("createObjective.optionNone") },
                      ...superiorObjectiveOptions,
                    ]}
                    handleInputChange={handleSuperiorObjectiveChange}
                    error={errors.superiorObjective}
                    disabled={false}
                  />
                </Grid>
              )}
              {["Team Challenge Objective", "Intern Objective"].includes(
                okrData.objectiveType
              ) && (
                <Grid item xs={12}>
                  <SuperiorObjectiveSelector
                    superiorObjective={okrData.superiorObjective}
                    superiorObjectiveOptions={superiorObjectiveOptions}
                    handleInputChange={handleSuperiorObjectiveChange}
                    error={errors.superiorObjective}
                    disabled={false}
                  />
                </Grid>
              )}

              {/* Trường nhập Target */}
              <Grid item xs={12} sm={12}>
                <TextField
                  label={t("createObjective.targetObjective")}
                  name="target"
                  value={okrData.target}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                  sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
                  error={!!errors.target}
                  helperText={
                    errors.target ? (
                      <span style={{ color: "red" }}>{errors.target}</span>
                    ) : (
                      `${okrData.target.length}/100`
                    )
                  }
                />
              </Grid>

              {/* Trường nhập Description */}
              <Grid item xs={12} sm={12}>
                <TextField
                  label={t("createObjective.description")}
                  name="description"
                  value={okrData.description}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
                  error={!!errors.description}
                  helperText={
                    errors.description ? (
                      <span style={{ color: "red" }}>{errors.description}</span>
                    ) : (
                      `${okrData.description.length}/500`
                    )
                  }
                />
              </Grid>

              {/* Nếu là Challenge Objective thì hiển thị thêm các trường đăng ký */}
              {okrData.objectiveType === "Challenge Objective" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.registrationStart")}
                      name="registrationStartDate"
                      value={okrData.registrationStartDate}
                      handleInputChange={handleInputChange}
                      error={errors.registrationStartDate}
                      minDate={minResStartDate}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.registrationEnd")}
                      name="registrationEndDate"
                      value={okrData.registrationEndDate}
                      handleInputChange={handleInputChange}
                      error={errors.registrationEndDate}
                      minDate={minResEndDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerField
                      label={t("createObjective.startDate")}
                      name="startDate"
                      value={okrData.startDate}
                      handleInputChange={handleInputChange}
                      error={errors.startDate}
                      minDate={minStartDate}
                      maxDate={maxStartDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerField
                      label={t("createObjective.endDate")}
                      name="endDate"
                      value={okrData.endDate}
                      handleInputChange={handleInputChange}
                      error={errors.endDate}
                      minDate={minEndDate}
                      maxDate={maxStartDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerField
                      label={t("createObjective.milestoneDate")}
                      name="milestoneDate"
                      value={okrData.milestoneDate}
                      handleInputChange={handleInputChange}
                      error={errors.milestoneDate}
                      minDate={minMilestoneDate}
                      maxDate={maxMilestoneDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.reportStartDate")}
                      name="reportStartDate"
                      value={okrData.reportStartDate}
                      handleInputChange={handleInputChange}
                      error={errors.reportStartDate}
                      minDate={minReportStartDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.reportEndDate")}
                      name="reportEndDate"
                      value={okrData.reportEndDate}
                      handleInputChange={handleInputChange}
                      error={errors.reportEndDate}
                      minDate={minReportEndDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.gradeStartTime")}
                      name="gradeStartTime"
                      value={okrData.gradeStartTime}
                      handleInputChange={handleInputChange}
                      error={errors.gradeStartTime}
                      minDate={minGradeStartTime}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.gradeEndTime")}
                      name="gradeEndTime"
                      value={okrData.gradeEndTime}
                      handleInputChange={handleInputChange}
                      error={errors.gradeEndTime}
                      minDate={minGradeEndTime}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <JuryPanelSelector
                      juryPanel={okrData.juryPanelGroupId}
                      juryPanelOptions={juryPanelOptions}
                      handleInputChange={handleJuryPanelChange}
                      error={errors.juryPanel}
                      disabled={okrData.objectiveType !== "Challenge Objective"}
                    />
                  </Grid>
                </>
              )}
              {!["Challenge Objective", "Team Challenge Objective"].includes(
                okrData.objectiveType
              ) && (
                <>
                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.startDate")}
                      name="startDate"
                      value={okrData.startDate}
                      handleInputChange={handleInputChange}
                      error={errors.startDate}
                      minDate={minStartDate}
                      maxDate={maxStartDate}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DatePickerField
                      label={t("createObjective.endDate")}
                      name="endDate"
                      value={okrData.endDate}
                      handleInputChange={handleInputChange}
                      error={errors.endDate}
                      minDate={minEndDate}
                      maxDate={maxStartDate}
                    />
                  </Grid>
                </>
              )}

              {/* Danh sách các Key Result đã thêm */}
              {keyResults.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {t("createObjective.keyNavBar")}
                  </Typography>
                  {keyResults.map((keyResult, index) => (
                    <KeyResultForm
                      key={index}
                      measurementUnits={measurementUnits}
                      index={index}
                      keyResult={keyResult}
                      errors={errors}
                      handleKeyResultChange={handleKeyResultChange}
                      handleDeleteClick={handleDeleteClick}
                      relatedKeyResultsOptions={relatedKeyResultsOptions}
                      objectiveType={okrData.objectiveType}
                      minDate={minStartDateKR}
                      maxDate={maxStartDateKR}
                    />
                  ))}
                </Grid>
              )}

              {/* Nút thêm Key Result */}
              {okrData.objectiveType !== "Challenge Objective" && (
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      onClick={handleAddKeyResult}
                      sx={{
                        backgroundColor: "#4caf50",
                        color: "#fff",
                        ":hover": { backgroundColor: "#45a049" },
                        borderRadius: "50%",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        padding: "5px",
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    <Typography
                      variant="body1"
                      sx={{ marginLeft: "10px", color: "#000" }}
                    >
                      {t("createObjective.addKeyresult")}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {/* Nút Cancel và Save */}
              <Grid item xs={12}>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCancelClick}
                    sx={{
                      borderRadius: "8px",
                      ":hover": { backgroundColor: "#d32f2f" },
                    }}
                    startIcon={<Reply />}
                  >
                    {t("createObjective.buttonCancenl")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    startIcon={<Save />}
                    sx={{
                      borderRadius: "8px",
                      ":hover": { backgroundColor: "#1e88e5" },
                    }}
                  >
                    {t("createObjective.buttonSave")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Suspense>
      </Paper>
      <ConfirmationDialog
        open={openDialog}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />

      <ConfirmationDialogCancel
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={handleConfirmCancel}
        message={t("createObjective.confirmCancel")}
      />
    </Container>
  );
};

export default CreateObjective;
