import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, Modal, List, ListItem, ListItemText, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import JuryPanelContext from "../../../context/JuryPanelContext";

const JuryPanelAddModal = ({ open, onClose, selectedGroupId, juryGroups }) => {
  const [juryPanels, setJuryPanels] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchJuryPanel } = useContext(JuryPanelContext);

  useEffect(() => {
    const fetchJuryPanels = async () => {
      setLoading(true);
      try {
        const data = await fetchJuryPanel();

        // Filter out jury panels already part of the selected group
        const selectedGroup = juryGroups.find(group => group.id === selectedGroupId);
        const existingPanelIds = selectedGroup ? selectedGroup.juryPanels.map(panel => panel.id) : [];
        const filteredPanels = data.filter(panel => !existingPanelIds.includes(panel.id));

        setJuryPanels(filteredPanels);
      } catch (error) {
        console.error("Error fetching jury panels:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchJuryPanels();
    }
  }, [open, fetchJuryPanel, selectedGroupId, juryGroups]);

  const handleSelect = (fullName) => {
    console.log("Selected Jury Panel:", fullName);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" gutterBottom>
          Jury Panel List
        </Typography>
        
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {juryPanels.map((panel) => (
              <ListItem key={panel.id} sx={{ cursor: "pointer" }}>
                <Box
                  sx={{
                    width: "100%",
                    height: 50,
                    padding: 2,
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  onClick={() => handleSelect(panel.fullName || "N/A")}
                >
                  <ListItemText primary={panel.fullName || "N/A"} />
                </Box>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Modal>
  );
};

export default JuryPanelAddModal;
