import React, { createContext, useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import apiAuth, { getCookie } from "../customs/axiosCustom";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    try {
      const response = await apiAuth.get("/Notification");
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      await apiAuth.put(`/Notification/${notificationId}`);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.NotificationId === notificationId
            ? { ...notification, isRead: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const notificationConnect = async () => {
    const token = getCookie("jwtToken");
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BE}/notificationHub`, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("ReceiveNotification", (notificationDto) => {
      setNotifications((prevNotifications) => {
        const updatedNotifications = [notificationDto, ...prevNotifications];
        console.log("Updated notifications list:", updatedNotifications);
        return updatedNotifications;
      });
      console.log("New notification received:", notificationDto);
    });

    await connection.start();
    console.log("SignalR connected.");
    window.signalRConnection = connection;
  };

  useEffect(() => {
    fetchNotifications();
    notificationConnect();
    return () => {
      if (window.signalRConnection && window.signalRConnection.state === signalR.HubConnectionState.Connected) {
        window.signalRConnection.stop();
      }
    };
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, setNotifications, markNotificationAsRead, fetchNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
