import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import apiAuth from "../../customs/axiosCustom";
import { toast, ToastContainer } from "react-toastify";
const VerifyOtp = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const email = state?.email || ""; // Email từ trang trước
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);
    setLoading(true);

    try {
      const response = await apiAuth.post(
        "/User/verify-otp-forgotpassword",
        { email, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        toast.success(`New password sent to email successfully.`);
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "Invalid OTP or verification failed."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "90vh",
        }}
      >
        <Typography component="h1" variant="h5">
          Verify OTP
        </Typography>
        <Box
          component="form"
          onSubmit={handleVerifyOtp}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="otp"
            label="OTP"
            name="otp"
            autoFocus
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Verify OTP"}
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">
              OTP verified successfully. Redirecting to login...
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyOtp;
