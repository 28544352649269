// Define status styles
export const statusStyles = {
  Not_Started: { color: "#A9A9A9", fontWeight: "bold" }, // Gray
  In_Progress: { color: "#FFc200", fontWeight: "bold" }, // Yellow
  Accomplished: { color: "#4CAF50", fontWeight: "bold" }, // Green
  Not_Achieved: { color: "#DC143C", fontWeight: "bold" }, // Red
  Unknown: { color: "#FF5700", fontWeight: "bold" }, // Orange
};

export const statusObjectiveChallenge = {
  Not_Started: { color: "#A9A9A9", fontWeight: "bold" }, // Gray
  Register_Time: { color: "#1E90FF", fontWeight: "bold" }, // Blue
  In_Progress: { color: "#FFc200", fontWeight: "bold" }, // Yellow
  Milestone_Time: { color: "#6A5ACD", fontWeight: "bold" }, // SlateBlue
  Report_Time: { color: "#FF4500", fontWeight: "bold" }, // OrangeRed
  Grade_Time: { color: "#8B0000", fontWeight: "bold" }, // DarkRed
  Accomplished: { color: "#4CAF50", fontWeight: "bold" }, // Green
  Unknown: { color: "#FF5700", fontWeight: "bold" }, // Orange
};

// Hàm định dạng ngày tháng theo dd/mm/yyyy
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("vi-VN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const formatDateForInput = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Ensure 2 digits for month
  const day = `0${date.getDate()}`.slice(-2); // Ensure 2 digits for day
  const hours = `0${date.getHours()}`.slice(-2); // Ensure 2 digits for hours
  const minutes = `0${date.getMinutes()}`.slice(-2); // Ensure 2 digits for minutes
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const truncateTarget = (target) => {
  if (!target) {
    return "";
  }
  if (target.length > 70) {
    return target.substring(0, 70) + "...";
  }
  return target;
};

export const progressColors = {
  low: "red", // 0-49%
  medium: "orange", // 50-99%
  high: "green", // 100%
};

export const getProgressColor = (progress) => {
  if (progress >= 100) return progressColors.high; // Màu xanh lá cây cho 100%
  if (progress >= 50) return progressColors.medium; // Màu cam cho 50-99%
  return progressColors.low; // Màu đỏ cho 0-49%
};

export const getStatusApproveStyle = (status) => {
  switch (status) {
    case "Pending":
      return { color: "orange", fontWeight: "bold" };
    case "Rejected":
      return { color: "red", fontWeight: "bold" };
    case "Approved":
      return { color: "green", fontWeight: "bold" };
    default:
      return { color: "black", fontWeight: "normal" };
  }
};

// Define report status styles
export const reportStatusStyles = {
  Not_Available: { backgroundColor: "#DC143C", color: "white" }, // Red
  Draft: { backgroundColor: "#A9A9A9", color: "white" }, // Gray
  Review: { backgroundColor: "#FFD700", color: "white" }, // Yellow
  Decision: { backgroundColor: "#1E90FF", color: "white" }, // Blue
  Summary: { backgroundColor: "#4CAF50", color: "white" }, // Green
};

// Usage in ReportOKRsList component
export const getReportStatusStyle = (statusReport) =>
  reportStatusStyles[statusReport] || { color: "black" };

export const formatProgress = (progress) => {
  return progress >= 100
    ? Math.floor(progress)
    : Math.round(progress * 10) / 10;
};

export const formatDateTime = (dateString) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-GB", options).replace(",", "");

  // Chuyển đổi am/pm thành AM/PM
  return formattedDate.replace(/(am|pm)/i, (match) => match.toUpperCase());
};

export const roundToDecimals = (number, decimals) => {
  if (typeof number !== "number" || typeof decimals !== "number") return null; // Kiểm tra kiểu dữ liệu
  if (decimals < 0) return null; // Không cho phép số âm
  const factor = Math.pow(10, decimals); // Tính toán hệ số
  return Math.round(number * factor) / factor; // Làm tròn theo số chữ số yêu cầu
};
