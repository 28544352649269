import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

const SpeedEvaluation = ({ value, onChange,disabled }) => (
  <RadioGroup value={value || "Slow"} onChange={(e) => onChange(e.target.value)}>
    <FormControlLabel
      value="Fast"
      control={<Radio sx={{ color: "#94d87a", "&.Mui-checked": { color: "#4caf50" } }} />}
      label="Fast"
      sx={{ "& .MuiFormControlLabel-label": { color: "black" } }} // Keep label text black
      disabled={disabled}
    />
    <FormControlLabel
      value="Normal"
      control={<Radio sx={{ color: "#ffa726", "&.Mui-checked": { color: "#fb8c00" } }} />}
      label="Normal"
      sx={{ "& .MuiFormControlLabel-label": { color: "black" } }} // Keep label text black
      disabled={disabled}
    />
    <FormControlLabel
      value="Slow"
      control={<Radio sx={{ color: "#e57373", "&.Mui-checked": { color: "#f44336" } }} />}
      label="Slow"
      sx={{ "& .MuiFormControlLabel-label": { color: "black" } }} // Keep label text black
      disabled={disabled}
    />
  </RadioGroup>
);

export default SpeedEvaluation;
