import { useState } from "react";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  LinearProgress,
  Button,
  Typography,
  Box,
} from "@mui/material";
import KeyResultsModal from "./KeyResultsModal";
import {
  formatDate,
  truncateTarget,
  getProgressColor,
  formatProgress,
} from "./format";

export default function ObjectiveTable({ objectives }) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);

  const handleOpenModal = (objectiveId) => {
    setSelectedObjectiveId(objectiveId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedObjectiveId(null);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "40%", fontWeight: "bold" }}>
              Target
            </TableCell>
            <TableCell style={{ width: "15%", fontWeight: "bold" }}>
              Creator
            </TableCell>
            <TableCell style={{ width: "12%", fontWeight: "bold" }}>
              Progress
            </TableCell>
            <TableCell style={{ width: "13%", fontWeight: "bold" }}>
              Key Results
            </TableCell>
            <TableCell style={{ width: "10%", fontWeight: "bold" }}>
              Start Date
            </TableCell>
            <TableCell style={{ width: "10%", fontWeight: "bold" }}>
              End Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {objectives.length > 0 ? (
            objectives.map((objective) => (
              <TableRow key={objective.id}>
                <TableCell>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      <Avatar
                        alt={objective.user?.fullName || "Anonymous"}
                        src={
                          objective.user?.avatar
                            ? `data:image/png;base64,${objective.user?.avatar}`
                            : "/default-avatar.png"
                        }
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <strong
                      //   onClick={() => handleOpenDetailPage(okr.Id)}
                      //   style={{ cursor: "pointer" }}
                      >
                        {truncateTarget(objective.target)}
                      </strong>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>{objective.user.fullName}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <LinearProgress
                        variant="determinate"
                        value={Math.min(objective.progressPercentage || 0, 100)}
                        sx={{
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: getProgressColor(
                              objective.progressPercentage
                            ),
                          },
                        }}
                      />
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                      {`${formatProgress(objective.ProgressPercentage || 0)}%`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    sx={{ padding: "2px 4px" }}
                    onClick={() => handleOpenModal(objective.id)}
                  >
                    {objective.keyResults.length} Key Results
                  </Button>
                </TableCell>
                <TableCell>{formatDate(objective.startDate)}</TableCell>
                <TableCell>{formatDate(objective.endDate)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography variant="body1" color="textSecondary">
                  No intern objectives available
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <KeyResultsModal
        open={openModal}
        onClose={handleCloseModal}
        objectiveId={selectedObjectiveId}
      />
    </TableContainer>
  );
}
