import React, { createContext, useState } from "react";
import apiAuth from "../customs/axiosCustom";
import { hideLoading, showLoading } from "../components/Layout/LoadingScreen";
import { useAuth } from "../customs/authService";
import { toast } from "react-toastify";

export const MemberImportContext = createContext();

export const MemberImportProvider = ({ children }) => {
  // Function to handle errors with notifications
  const handleError = (message, err) => {
    console.error(message, err);
    toast.error(message);
  };
  const importMentor = async (dataToSubmit) => {
    const data = {
      firstName: dataToSubmit.FirstName,
      lastName: dataToSubmit.LastName,
      email: dataToSubmit.Email,
    };
    try {
      showLoading();
      const response = await apiAuth.post("/Import/ImportMentor", data);
      return response.status;
    } catch (error) {
      return {
        status: error.response ? error.response.status : 500,
        message: error.message,
      };
    } finally {
      hideLoading();
    }
  };
  const fetchTemplate = async ({ nameFile }) => {
    if (typeof nameFile !== "string") {
      console.error("Invalid nameFile parameter. Expected a string.");
      return;
    }
    try {
      const response = await apiAuth.get(
        `/Template/download/${encodeURIComponent(nameFile)}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nameFile}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };
  // Function to import Jury Panel CSV
  const fetchImportCsvJuryPanel = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    // Log the entire FormData contents
    console.log("FormData content:");
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
    }

    try {
      showLoading();
      const response = await apiAuth.post(
        "/Import/importJuryPanelsCSV",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Jury Panel CSV imported successfully.");
      return response;
    } catch (error) {
      console.error(
        "Error details:",
        error.response ? error.response.data : error.message
      );
      // Handle errors with more details if the error response contains a list of errors
      if (error.response && error.response.data && error.response.data.errors) {
        const errorList = error.response.data.errors;
        return {
          status: error.response.status,
          errors: errorList,
        };
      } else {
        return {
          status: error.response ? error.response.status : 500,
          message: error.message,
        };
      }
    } finally {
      hideLoading();
    }
  };

  // Function to import Mentor CSV
  const fetchImportCsvMentor = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      showLoading();
      const response = await apiAuth.post("/Import/csv", formData);
      console.log("Response from fetchImportCsvMentor:", response);
      toast.success("Mentor CSV imported successfully.");
      return response;
    } catch (error) {
      handleError("Failed to import Mentor CSV.", error);
      return {
        status: error.response ? error.response.status : 500,
        message: error.message,
      };
    } finally {
      hideLoading();
    }
  };
  const fetchUpdateJuryPanel = async (row) => {
    try {
      showLoading();
      const response = await apiAuth.post("/Import/importJuryPanelsCSV/update", row);
      return response.status;
    } catch (error) {
      console.error("Error Update Jury Panel:", error);
      toast.error("Failed to Update Jury Panel.");
      return {
        status: error.response ? error.response.status : 500,
        message: error.message,
      };
    } finally {
      hideLoading();
    }
  };


  return (
    <MemberImportContext.Provider
      value={{
        importMentor,
        fetchTemplate,
        fetchImportCsvJuryPanel,
        fetchImportCsvMentor,
        fetchUpdateJuryPanel,
      }}
    >
      {children}
    </MemberImportContext.Provider>
  );
};

export default MemberImportContext;
