import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Box,
} from "@mui/material";
import CompletionEvaluation from "./CompletionEvaluation";
import SpeedEvaluation from "./SpeedEvaluation";

const ReportTable = ({
  type,
  parentObjectiveId,
  performance,
  questionsData = [],
  isEditable,
  handleCurrentValueChange,
  completionEvaluations,
  handleCompletionEvaluationChange,
  speedEvaluations,
  handleSpeedEvaluationChange,
  answersContent,
  handleAnswerContentChange,
  wordCounts,
  errors,
  isOverview,
}) => {
  // Initialize currentValues as state with the default value
  const [currentValues, setCurrentValues] = useState({
    [performance.id]: performance.currentValue || "",
  });

  useEffect(() => {
    // Update state when the performance data changes
    setCurrentValues((prevValues) => ({
      ...prevValues,
      [performance.id]:
        prevValues[performance.id] || performance.currentValue || "",
    }));
  }, [performance]);

  // Calculate progress as a percentage
  const calculateProgress = (currentValue, targetValue) => {
    if (!currentValue || parseFloat(currentValue) === 0) {
      return 0;
    }
    return targetValue ? (parseFloat(currentValue) / targetValue) * 100 : 0;
  };

  const progressPercentage = calculateProgress(
    currentValues[performance.id],
    performance.targetValue
  );

  const formattedProgress =
    progressPercentage % 1 === 0
      ? progressPercentage.toFixed(0)
      : progressPercentage.toFixed(2);

  const handleInputChange = (e, id, targetValue) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    if ((value.match(/\./g) || []).length > 1) {
      return; // Prevent multiple decimals
    }
    setCurrentValues((prevValues) => ({
      ...prevValues,
      [id]: value || performance.currentValue || "",
    }));
    handleCurrentValueChange(e, id, targetValue);
  };
  const isTypeAllowed = parentObjectiveId === null;

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 1, maxWidth: "100%", overflowX: "auto" }}
    >
      <Typography variant="h6" gutterBottom sx={{ ml: 3, mt: 1, mb: 0 }}>
        <Box component="span" sx={{ fontWeight: "bold" }}>
          Key Results:{" "}
        </Box>
        {performance.keyResult.title}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Target Value
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Current Value
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Progress (%)
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Completion Evaluation
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Speed Evaluation
            </TableCell>
            {!isTypeAllowed &&type !== "Team_Challenge_Objective" &&
              !isOverview &&
              questionsData &&
              questionsData.map((question) => (
                <TableCell
                  key={question.id}
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  {question.content}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{performance.targetValue}</TableCell>
            <TableCell align="center">
              <TextField
                value={currentValues[performance.id] || ""}
                onChange={(e) =>
                  handleInputChange(e, performance.id, performance.targetValue)
                }
                size="small"
                error={!!errors[performance.id]}
                helperText={errors[performance.id]}
                InputProps={{
                  readOnly: !isEditable,
                }}
              />
            </TableCell>
            <TableCell align="center">{formattedProgress || 0}%</TableCell>
            <TableCell align="center">
              <CompletionEvaluation
                value={
                  completionEvaluations[performance.id] ||
                  performance.evaluateCompletion
                }
                onChange={(value) =>
                  handleCompletionEvaluationChange(performance.id, value)
                }
                disabled={!isEditable}
              />
            </TableCell>
            <TableCell align="center">
              <SpeedEvaluation
                value={
                  speedEvaluations[performance.id] ||
                  performance.evaluateCompletionSpeed
                }
                onChange={(value) =>
                  handleSpeedEvaluationChange(performance.id, value)
                }
                disabled={!isEditable}
              />
            </TableCell>

            {!isTypeAllowed &&type !== "Team_Challenge_Objective" &&
              !isOverview &&
              questionsData &&
              questionsData.map((question) => (
                <TableCell key={question.id} align="center">
                  <textarea
                    value={
                      answersContent[`${performance.id}-${question.id}`] || ""
                    }
                    onChange={(e) =>
                      isEditable &&
                      handleAnswerContentChange(e, question.id, performance.id)
                    }
                    style={{
                      width: "100%",
                      height: "100px",
                      resize: "vertical",
                    }}
                    readOnly={!isEditable}
                  />

                  <Typography variant="caption" color="textSecondary">
                    {wordCounts[`${performance.id}-${question.id}`] || 0}{" "}
                    characters entered
                  </Typography>
                </TableCell>
              ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
