import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";

const now = new Date(new Date().getTime());
const tomorrow = new Date(new Date().getTime());
tomorrow.setDate(now.getDate() + 1);
const tomorrowDate = tomorrow.toISOString().split("T")[0];

const FooterControls = ({
  isEditable,
  type,
  parentObjectiveId,
  okrsCompletionChecked,
  setOkrsCompletionChecked,
  nextCheckInDate,
  handleNextCheckInDateChange,
  handleSaveLabOrMyObjective,
  handleSave,
  handleSaveREPORTDONE,
  objectiveData,
  startDate,
  nextCheckInDateError,
  loading,
}) => {
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    
    if (!nextCheckInDate || nextCheckInDate < tomorrowDate) {
      setDateTime(tomorrowDate);
      console.log(objectiveData?.objective?.startDate)
    } else {
      setDateTime(nextCheckInDate);
      console.log("abc2")
    }

  }, []);

  const isTypeAllowed = parentObjectiveId === null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        bgcolor: "#f5f5f5",
        p: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
        gap: 2,
      }}
    >
      {/* Next Report Date and Date Picker */}
      {!isTypeAllowed && type !== "Team_Challenge_Objective" &&(
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>Next Report Date</Typography>
          <TextField
            type="date"
            onChange={handleNextCheckInDateChange}
            error={!!nextCheckInDateError}
            helperText={nextCheckInDateError}
            inputProps={{
              min: dateTime||tomorrowDate,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "32px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "5px",
              },
            }}
          />
        </Box>
      )}

      {/* Action Buttons with Icons */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mr: 2 }}>

          <Tooltip
            title={new Date(startDate) > now ? "Not yet report date" : ""} 
            arrow
          >
            <span>
              {" "}
              {/* <span> để tránh vô tình bấm vào Button khi disabled */}
              <Button
                variant="contained"
                onClick={() => {
                  if (type === "Team_Challenge_Objective") {
                    handleSaveLabOrMyObjective(type);
                  } else {
                    isTypeAllowed
                    ? handleSaveLabOrMyObjective(type)
                    : handleSaveREPORTDONE("Review")
                  }
                }}
                disabled={new Date(objectiveData?.objective?.startDate) >= now || loading} 
                startIcon={<DoneIcon />}
                sx={{
                  bgcolor: "#4caf50",
                  color: "white",
                  borderRadius: "8px",
                  border: "1px solid #4caf50",
                  "&:hover": {
                    bgcolor: "#388e3c",
                  },
                }}
              >
                {isTypeAllowed ? "REPORT DONE" : "SUBMIT REPORT"}
              </Button>
            </span>
          </Tooltip>
        {!isTypeAllowed && type !== "Team_Challenge_Objective" &&(
          <Button
            variant="contained"
            onClick={() => handleSave("Drafting")}
            disabled={loading}
            startIcon={<SaveIcon />}
            sx={{
              bgcolor: "#1976d2", // Blue color for 'Save Draft' button
              color: "white",
              borderRadius: "8px",
              border: "1px solid #1976d2",
              "&:hover": {
                bgcolor: "#1565c0", // Darker blue on hover
              },
            }}
          >
            SAVE DRAFT
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FooterControls;
