import React, { createContext, useState } from "react";
import apiAuth from "../customs/axiosCustom";
import { toast } from "react-toastify";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = (message, err) => {
    setError(message);
    console.error(message, err);
    toast.error(message);
  };

  const fetchUserRoles = async () => {
    try {
      const response = await apiAuth.get(`/User/get-roles`);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch roles:", error);
      return [];
    }
  };

  const fetchSwitchRole = async (selectedValue) => {
    try {
      const response = await apiAuth.post(`/User/switch-role?role=${selectedValue}`);
      const token = response.data.token; 
      return token;
    } catch (error) {
      console.error("Failed to fetch roles:", error);
      toast.error("Failed to switch role");
      return null;
    }
  };

  const getUserInfo = async () => {
    try {
      const response = await apiAuth.get("/User/lab-info");
      console.log("Info API: ",response.data);
      return response.data;

    } catch (error) {
      console.error("lab-info error", error);
    }
  };
  const fetchUpdateStatusJuryPanel = async (juryId) => {
    try {
      const response = await apiAuth.put(`/User/update-status-juryPanel?juryId=${juryId}`);
      return response.data;
    } catch (error) {
      console.error("update-status-juryPanel", error);
    }
  };
  


  return (
    <UserContext.Provider
      value={{
        fetchUserRoles,
        fetchSwitchRole,
        fetchUpdateStatusJuryPanel,
        getUserInfo
      }}
    >
      {children}
    </UserContext.Provider>
  );
};


export default UserContext;
