import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, Card, CardContent, CardMedia, Tabs, Tab, Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import apiAuth from "../../customs/axiosCustom";

const CameraConnect = () => {
  const [userData, setUserData] = useState([]); // State để lưu dữ liệu người dùng
  const [loading, setLoading] = useState(false); // Trạng thái loading
  const [tabValue, setTabValue] = useState(0); // State để lưu giá trị tab hiện tại
  const [selectedDate, setSelectedDate] = useState(new Date()); // State để lưu ngày được chọn
  const [openDialog, setOpenDialog] = useState(false); // State để điều khiển popup xác nhận

  // Hàm gọi API dựa trên tab được chọn và ngày được chọn
  const fetchData = async (url, date) => {
    setLoading(true);
    try {
      const response = await apiAuth.get(url, { params: { dateSelected: date } });
      setUserData(response.data); // Lưu dữ liệu vào state
      setLoading(false);
      toast.success("Data fetched successfully!");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch data. Please try again.");
      console.error("Error fetching data:", error);
    }
  };

  // Gọi API khi component được render lần đầu (tab 0 - Người Lạ, ngày hôm nay)
  useEffect(() => {
    fetchData("/Recognized/StrangerRecognition", selectedDate);
  }, [selectedDate]);

  // Hàm chuyển đổi tab và gọi API tương ứng với ngày
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      fetchData("/Recognized/StrangerRecognition", selectedDate); // API cho Người lạ
    } else if (newValue === 1) {
      fetchData("/Recognized/CheckUserInLab", selectedDate); // API cho Người trong Lab
    }
  };

  // Hàm xử lý khi ngày được chọn
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    // Gọi API dựa trên tab hiện tại và ngày được chọn
    if (tabValue === 0) {
      fetchData("/Recognized/StrangerRecognition", newDate);
    } else if (tabValue === 1) {
      fetchData("/Recognized/CheckUserInLab", newDate);
    }
  };

  // Hàm mở popup xác nhận xóa
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Hàm đóng popup xác nhận xóa
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Hàm xóa tất cả user dựa trên tab hiện tại và ngày được chọn
  const handleDeleteAll = async () => {
    try {
      const url = tabValue === 0
        ? `/Attendance/DeleteAllStrangers?dateSelected=${selectedDate.toISOString()}`
        : `/Attendance/DeleteAllRecognizedUsers?dateSelected=${selectedDate.toISOString()}`;
      
      await apiAuth.delete(url);
      setUserData([]); // Xóa toàn bộ dữ liệu sau khi xóa
      toast.success("All users deleted successfully!");
      handleCloseDialog(); // Đóng popup sau khi xóa
    } catch (error) {
      toast.error("Failed to delete all users.");
      console.error("Error deleting all users:", error);
    }
  };

  // Giải mã chuỗi base64 để hiển thị ảnh
  const convertBase64ToImage = (base64String) => {
    return `data:image/jpeg;base64,${base64String}`;
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">Authenticate Users In LAB</Typography>

      {/* Component DatePicker để chọn ngày */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Select date"
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <input {...params} />}
        />
      </LocalizationProvider>

      {/* Thanh điều hướng tab */}
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Camera Tabs">
        <Tab label="Stranger" />
        <Tab label="In the laboratory" />
      </Tabs>

      {/* Nút xóa tất cả, chỉ hiển thị khi có dữ liệu */}
      {userData.length > 0 && (
        <Button 
          variant="contained" 
          color="error" 
          onClick={handleOpenDialog} 
          sx={{ mt: 2 }}
        >
          Delete ALL
        </Button>
      )}

      {/* Hiển thị vòng quay loading khi dữ liệu đang tải */}
      {loading && <CircularProgress />}

      {/* Hiển thị dữ liệu người dùng */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {!loading && userData.length > 0 && (
          userData.map((user) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={user.id}>  {/* Cột điều chỉnh theo kích thước màn hình */}
              <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                <CardMedia
                  component="img"
                  image={convertBase64ToImage(user.faceImage)}
                  alt="User Face"
                  style={{ width: 200, height: 200, borderRadius: '8px', objectFit: 'cover' }} 
                />
                <CardContent>
                  <Typography variant="h6">RollName: {user.rollName || "UN_KNOWN"}</Typography>
                  <Typography variant="body1">Email: {user.userName || "UN_KNOWN"}</Typography>
                  <Typography variant="body1">Date: {user.date}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Popup xác nhận xóa */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Do you want to delete data on {selectedDate.toLocaleDateString()} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">No</Button>
          <Button onClick={handleDeleteAll} color="error">Yes</Button>
        </DialogActions>
      </Dialog>

      {/* Thêm ToastContainer vào JSX */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Box>
  );
};

export default CameraConnect;

