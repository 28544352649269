import React from "react";
import { useAuth } from "../../customs/authService";
import Home from "./Home";
import DashboardMentor from "./DashboardMentor";
import ImportCsvComponent from "../Import/ImportCsvComponent";
import { useNavigate } from "react-router-dom";
import DashBoradLab from "./DashBoardLab";

const DashboardAll = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const roles = auth.user.roles;
  return (
    <>
      {roles.includes("Intern") && <Home />}
      {roles.includes("Mentor") && <DashboardMentor />}
      {roles.includes("Lab Manager") && <DashBoradLab />}
      {roles.includes("System Admin") && <ImportCsvComponent />}
    </>
  );
};

export default DashboardAll;
