import React, { useContext, useState, useEffect } from "react";
import { OKRContext } from "../../context/OKRContext";
import apiAuth from "../../customs/axiosCustom";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import ChallengeObjectiveRanking from "./ui/ChallengeObjectiveRanking";
import { toast } from "react-toastify";

export default function OKRRankingPage() {
  const { currentSemester } = useContext(OKRContext);
  const [semesterId, setSemesterId] = useState(currentSemester.semesterId); // Giá trị mặc định từ currentSemester
  const [semesterName, setSemesterName] = useState(
    currentSemester.semesterName
  ); // Thêm state cho semesterName
  const [challengeOKRTarget, setChallengeOKRTarget] = useState("");
  const [semesters, setSemesters] = useState([]);
  const [challengeObjectives, setChallengeObjectives] = useState([]); // Mặc định là mảng rỗng
  const [rankingData, setRankingData] = useState(null);

  // Hàm lấy danh sách học kỳ từ API
  const semesterList = async () => {
    try {
      const response = await apiAuth.get("/Semester");
      return response.data;
    } catch (error) {
      console.error("Error fetching semesters:", error);
      return [];
    }
  };

  // Hàm lấy danh sách mục tiêu thách thức theo semesterId
  const challengeObjectiveListBySemesterId = async (id) => {
    try {
      const response = await apiAuth.get(`/OKR/rank/${id}`);
      if (Array.isArray(response.data)) {
        setChallengeObjectives(response.data); // Đảm bảo rằng dữ liệu là mảng
      } else {
        console.error("Expected an array but got:", response.data);
        setChallengeObjectives([]); // Đặt lại về mảng rỗng nếu không đúng định dạng
      }
    } catch (error) {
      console.error("Error fetching challenge objectives:", error);
      setChallengeObjectives([]); // Đặt lại về mảng rỗng khi có lỗi
    }
  };

  const getRankingBySemesterIdAndObjectiveChallengeId = async (
    semesterId,
    objectiveChallengeId
  ) => {
    try {
      const response = await apiAuth.get(
        `/OKR/rank/${semesterId}/${objectiveChallengeId}`
      );

      setRankingData(response.data);
    } catch (error) {
      console.error("Error fetching ranking data:", error);
      toast.error(error.response.data.Message);
      setRankingData(null); // Reset ranking data on error
    }
  };

  // Gọi hàm semesterList khi component được mount
  useEffect(() => {
    const fetchSemesters = async () => {
      const fetchedSemesters = await semesterList();
      setSemesters(fetchedSemesters);

      // Kiểm tra xem currentSemester có trong danh sách fetchedSemesters
      const currentSemesterExists = fetchedSemesters.some(
        (sem) => sem.semesterId === currentSemester.semesterId
      );

      // Nếu có, cập nhật semesterId và semesterName
      if (currentSemesterExists) {
        setSemesterId(currentSemester.semesterId); // Đảm bảo semesterId được cập nhật
        setSemesterName(currentSemester.semesterName); // Cập nhật semesterName
        challengeObjectiveListBySemesterId(currentSemester.semesterId);
      }
    };
    fetchSemesters();
  }, [currentSemester]); // Gọi lại khi currentSemester thay đổi

  const handleSemesterChange = (event) => {
    const selectedSemesterId = event.target.value;
    setSemesterId(selectedSemesterId);

    // Cập nhật semesterName tương ứng với semesterId được chọn
    const selectedSemester = semesters.find(
      (sem) => sem.semesterId === selectedSemesterId
    );
    setSemesterName(selectedSemester ? selectedSemester.semesterName : "");

    // Gọi hàm lấy danh sách mục tiêu thách thức khi học kỳ thay đổi
    challengeObjectiveListBySemesterId(selectedSemesterId);
  };

  const handleChallengeOKRTargetChange = (event) => {
    setChallengeOKRTarget(event.target.value);
  };

  const handleFilterApply = () => {
    if (semesterId && challengeOKRTarget) {
      getRankingBySemesterIdAndObjectiveChallengeId(
        semesterId,
        challengeOKRTarget
      );
    } else {
      console.warn("Please select both a semester and a challenge OKR target.");
    }
    console.log("Selected Semester ID:", semesterId);
    console.log("Selected Challenge OKR Target ID:", challengeOKRTarget);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Challenge OKR Ranking
      </Typography>

      {/* Semester Filter */}
      <FormControl variant="outlined" sx={{ minWidth: 200, marginRight: 2 }}>
        <InputLabel>Semester</InputLabel>
        <Select
          value={semesterId} // Giá trị mặc định là semesterId từ currentSemester
          onChange={handleSemesterChange}
          label="Semester"
        >
          {semesters.map((sem) => (
            <MenuItem key={sem.semesterId} value={sem.semesterId}>
              {sem.semesterName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Challenge OKR Target Filter */}
      <FormControl variant="outlined" sx={{ minWidth: 200, marginRight: 2 }}>
        <InputLabel>Challenge OKR Target</InputLabel>
        <Select
          value={challengeOKRTarget}
          onChange={handleChallengeOKRTargetChange}
          label="Challenge OKR Target"
        >
          {challengeObjectives.length > 0 ? (
            challengeObjectives.map((objective) => (
              <MenuItem key={objective.id} value={objective.id}>
                {objective.target}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              <em>No objectives available</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>

      {/* Button to apply filters */}
      <Button variant="contained" onClick={handleFilterApply}>
        Apply Filters
      </Button>

      {/* Placeholder for the ranking data */}
      <Box sx={{ marginTop: 5 }}>
        {/* <Typography>Ranking data will be displayed here...</Typography> */}
        {rankingData != null && (
          <ChallengeObjectiveRanking objective={rankingData} />
        )}
      </Box>
    </Box>
  );
}
