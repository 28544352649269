import React, { useEffect, useContext, useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { OKRContext } from "../../../context/OKRContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ConfirmationModal from "../Feedback/ConfirmationModal ";
import Reply from "@mui/icons-material/Reply";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

const OKRFeedback = () => {
  const navigate = useNavigate();
  const { objectiveId } = useParams();
  const [objectiveData, setReportObjectiveDetails] = useState(null);
  const [questionsData, setQuestionsData] = useState(null);
  const {
    fetchFeedBackReportObjectiveDetail,
    fetchQuestionsReport,
    fetchCreateFeedback,
    auth,
  } = useContext(OKRContext);

  const [currentValues, setCurrentValues] = useState({});
  const [check,setCheck]=useState({});
  const [progressPercentages, setProgressPercentages] = useState({});
  const [completionEvaluations, setCompletionEvaluations] = useState({});
  const [speedEvaluations, setSpeedEvaluations] = useState({});
  const [answersContent, setAnswersContent] = useState({});
  const [wordCounts, setWordCounts] = useState({});
  const [feedbackContent, setFeedbackContent] = useState("");
  const [reportObjectiveId, setReportObjectiveId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [contentFeedbackError, setContentFeedbackError] = useState("");

  useEffect(() => {
    const loadReportObjectiveDetail = async () => {
      try {
        const draftDetails = await fetchFeedBackReportObjectiveDetail(
          objectiveId
        );

        const chosenObjectiveData = {
          id: draftDetails.id,
          title: draftDetails.title,

          reportDate: draftDetails.reportDate,
          nextReportDate: draftDetails.nextReportDate,
          status: draftDetails.status,
          reportObjectiveId: draftDetails.reportObjectiveId,
          feedbackId: draftDetails.feedbackId,
          reportObjective: {
            id: draftDetails.reportObjective.id,
            objectiveId: draftDetails.reportObjective.objectiveId,
            parentId: draftDetails.reportObjective.parentId,
            nextReportDate: draftDetails.reportObjective.nextReportDate,
            target: draftDetails.reportObjective.targetSnapshot,
            description: draftDetails.reportObjective.descriptionSnapshot,
            registrationStartDate:
              draftDetails.reportObjective.registrationStartDateSnapshot,
            registrationEndDate:
              draftDetails.reportObjective.registrationEndDateSnapshot,
            startDate: draftDetails.reportObjective.startDateSnapshot,
            endDate: draftDetails.reportObjective.endDateSnapshot,
            createdAtSnapshot: draftDetails.reportObjective.createdAtSnapshot,
            completionTimeSnapshot:
              draftDetails.reportObjective.completionTimeSnapshot,
            type: draftDetails.reportObjective.typeSnapshot,
            // progressPercentage:
            //   draftDetails.reportProgressPerformanceDTOs[0]
            //     ?.progressPercentageSnapshot || 0,
          },
          progressPerformances:
            draftDetails.reportObjective.reportProgressPerformanceDTOs.map(
              (perf) => ({
                id: perf.id,
                progressPerformanceId: perf.progressPerformanceId,
                reportObjectiveId: perf.reportObjectiveId,
                targetValue: perf.targetValueSnapshot,
                currentValue: perf.currentValueSnapshot,
                progressPercentage: perf.progressPercentageSnapshot,
                evaluateCompletion: perf.evaluateCompletionSnapshot || "Good",
                evaluateCompletionSpeed:
                  perf.evaluateCompletionSpeedSnapshot || "Normal",
                answers: perf.answerDTOs,
                keyResult: {
                  title: perf.keyResult ? perf.keyResult.title : "",
                  measurementUnit: perf.keyResult
                    ? perf.keyResult.measurementUnit
                    : "",
                },
              })
            ),
          feedback: {
            contentFeedback: draftDetails.feedback?.contentFeedback || "",
          },
        };
        setReportObjectiveDetails(chosenObjectiveData);
        const questions = await fetchQuestionsReport();
        setQuestionsData(questions);
        setFeedbackContent(chosenObjectiveData?.feedback?.contentFeedback);
        setCheck(chosenObjectiveData?.feedback?.contentFeedback)
        const initialCurrentValues = {};
        const initialProgressPercentages = {};
        const initialCompletionEvaluations = {};
        const initialSpeedEvaluations = {};
        const initialAnswersContent = {};
        const initialWordCounts = {};

        chosenObjectiveData.progressPerformances.forEach((performance) => {
          initialCurrentValues[performance.id] = performance.currentValue || "";
          initialProgressPercentages[performance.id] =
            performance.progressPercentage || 0;
          initialCompletionEvaluations[performance.id] =
            performance.evaluateCompletion || "Good";
          initialSpeedEvaluations[performance.id] =
            performance.evaluateCompletionSpeed || "Normal";
          performance.answers.forEach((answer) => {
            const content = answer.content || "";
            initialAnswersContent[`${performance.id}-${answer.questionId}`] =
              content;
            initialWordCounts[`${performance.id}-${answer.questionId}`] =
              content.length;
          });
        });

        setCurrentValues(initialCurrentValues);
        setProgressPercentages(initialProgressPercentages);
        setCompletionEvaluations(initialCompletionEvaluations);
        setSpeedEvaluations(initialSpeedEvaluations);
        setAnswersContent(initialAnswersContent);
        setWordCounts(initialWordCounts);

        chosenObjectiveData.progressPerformances.forEach((performance) => {
          setReportObjectiveId(performance.reportObjectiveId);
        });
      } catch (error) {
        console.error("Failed to fetch objective details:", error);
      }
    };

    if (
      !objectiveData ||
      (objectiveData.objective &&
        objectiveData.objective.id !== parseInt(objectiveId, 10))
    ) {
      loadReportObjectiveDetail();
    }
  }, [fetchFeedBackReportObjectiveDetail, fetchQuestionsReport]);

  const isEditable =
    objectiveData?.reportObjective?.parentId === auth?.user?.jti;
  const handleSave = async (status, reportObjectiveId) => {
    try {
      const feedbackData = {
        status,
        feedback: {
          contentFeedback: feedbackContent,
          evaluationCriteria: "example criteria",
        },
      };

      // Gọi API để gửi feedback
      const response = await fetchCreateFeedback(
        reportObjectiveId,
        feedbackData
      );

      // Kiểm tra trạng thái HTTP của phản hồi
      if (response === 200) {
        toast.success("Feedback saved successfully.");
        navigate("/okr/okrreport"); // Chuyển trang chỉ khi thành công
      } else {
        throw new Error("Failed to save feedback");
      }
    } catch (error) {
      console.error("Error saving feedback:", error);
      toast.error("Error saving feedback. Please try again.");
    }
  };

  const handleModalOpen = (action) => {
    setModalAction(action);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = () => {
    if (!feedbackContent.trim()) {
      setContentFeedbackError("Feedback content cannot be empty.");
      handleModalClose();
      return;
    }
    setContentFeedbackError("");
    handleSave(modalAction, reportObjectiveId);
    handleModalClose();
  };
  const formatProgress = (progress) => {
    const progressValue = parseFloat(progress);
    if (isNaN(progressValue)) return 0;
    return Number.isInteger(progressValue)
      ? progressValue
      : progressValue.toFixed(2);
  };

  if (!objectiveData || !objectiveData.reportObjective)
    return <CircularProgress />;

  return (
    <Box sx={{ paddingBottom: "50px" }}>
      <Box mt={1}>
        <Card sx={{ p: "0" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {/* Nút Back với padding top và bottom = 0 */}
                <IconButton
                  onClick={() => window.history.back()}
                  color="primary"
                  sx={{ pt: 0, pb: 0 }}
                >
                  <ArrowBackIosIcon sx={{ pb: 1 }} />
                </IconButton>
                <Box
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
                >
                  Feedback Objective:{" "}
                </Box>
                <Box component="span" sx={{ fontSize: "1.5rem" }}>
                  {objectiveData.reportObjective.target}
                </Box>
              </Box>
            </Box>
            <Typography>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Start Date:
              </Box>{" "}
              {new Date(objectiveData.reportObjective.startDate).toLocaleString(
                "en-GB",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }
              )}
              {" - "}
              <Box component="span" sx={{ fontWeight: "bold" }}>
                End Date:
              </Box>{" "}
              {new Date(objectiveData.reportObjective.endDate).toLocaleString(
                "en-GB",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }
              )}
            </Typography>
            <Typography>
              <Box component="span" sx={{ fontWeight: "bold" }}>
                Next Report Date:
              </Box>{" "}
              {new Date(objectiveData.nextReportDate).toLocaleString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Typography>
          </CardContent>
        </Card>

        {objectiveData.progressPerformances?.map((performance) => (
          <Box mt={1} key={performance.id}>
            <TableContainer
              component={Paper}
              sx={{ mt: 1, maxWidth: "100%", overflowX: "auto" }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ ml: 3, mt: 1, mb: 0 }}
              >
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  Key Results:{" "}
                </Box>
                {performance.keyResult.title}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Target Value
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Current Value
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Progress
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Completion Evaluation
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Speed Evaluation
                    </TableCell>
                    {questionsData?.map((question, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        {question.content}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {performance.targetValue}
                      <br />
                      <Typography variant="caption">
                        {performance.keyResult.measurementUnit}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        variant="outlined"
                        size="small"
                        value={currentValues[performance.id] || ""}
                        InputProps={{ readOnly: true }}
                        sx={{ mt: 1 }}
                      />
                      <br />
                      <Typography variant="caption">
                        {performance.keyResult.measurementUnit}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {formatProgress(progressPercentages[performance.id] || 0)}
                      %
                    </TableCell>
                    <TableCell align="center">
                      <RadioGroup
                        value={completionEvaluations[performance.id] || "Good"}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.8rem",
                            color: "black",
                          },
                        }}
                      >
                        <FormControlLabel
                          value="Very_Good"
                          control={
                            <Radio
                              checked={
                                completionEvaluations[performance.id] ===
                                "Very_Good"
                              }
                              disableRipple
                              sx={{
                                color: "#94d87a",
                                "&.Mui-checked": { color: "green" },
                              }}
                            />
                          }
                          label="Very Good"
                        />
                        <FormControlLabel
                          value="Good"
                          control={
                            <Radio
                              checked={
                                completionEvaluations[performance.id] === "Good"
                              }
                              disableRipple
                              sx={{
                                color: "#fad181",
                                "&.Mui-checked": { color: "orange" },
                              }}
                            />
                          }
                          label="Good"
                        />
                        <FormControlLabel
                          value="Not_Good"
                          control={
                            <Radio
                              checked={
                                completionEvaluations[performance.id] ===
                                "Not_Good"
                              }
                              disableRipple
                              sx={{
                                color: "#ff7171",
                                "&.Mui-checked": { color: "red" },
                              }}
                            />
                          }
                          label="Not Good"
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell align="center">
                      <RadioGroup
                        value={speedEvaluations[performance.id] || "Normal"}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.8rem",
                            color: "black",
                          },
                        }}
                      >
                        <FormControlLabel
                          value="Fast"
                          control={
                            <Radio
                              checked={
                                speedEvaluations[performance.id] === "Fast"
                              }
                              sx={{
                                color: "#94d87a",
                                "&.Mui-checked": { color: "green" },
                              }}
                            />
                          }
                          label="Fast"
                        />
                        <FormControlLabel
                          value="Normal"
                          control={
                            <Radio
                              checked={
                                speedEvaluations[performance.id] === "Normal"
                              }
                              disableRipple
                              sx={{
                                color: "#fad181",
                                "&.Mui-checked": { color: "orange" },
                              }}
                            />
                          }
                          label="Normal"
                        />
                        <FormControlLabel
                          value="Slow"
                          control={
                            <Radio
                              checked={
                                speedEvaluations[performance.id] === "Slow"
                              }
                              disableRipple
                              sx={{
                                color: "#ff7171",
                                "&.Mui-checked": { color: "red" },
                              }}
                            />
                          }
                          label="Slow"
                        />
                      </RadioGroup>
                    </TableCell>
                    {questionsData?.map((question, index) => (
                      <TableCell key={index} align="center">
                        <textarea
                          value={
                            answersContent[
                              `${performance.id}-${question.id}`
                            ] || ""
                          }
                          readOnly
                          style={{
                            marginTop: "8px",
                            height: "150px",
                            width: "100%",
                            resize: "vertical",
                          }}
                        />
                        <Typography variant="caption" color="textSecondary">
                          {wordCounts[`${performance.id}-${question.id}`] || 0}{" "}
                          characters entered
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Box>

      <Card sx={{ p: "0", mt: "10px" }}>
  <CardContent>
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Box
        component="span"
        sx={{
          fontWeight: "bold",
          fontSize: "1rem",
          width: "20%",
          lineHeight: "1.5",
          paddingTop: "8px",
        }}
      >
        Feedback Objective:
      </Box>
      <TextField
        variant="outlined"
        multiline
        minRows={4}
        fullWidth
        value={feedbackContent}
        onChange={(e) => setFeedbackContent(e.target.value)}
        error={!!contentFeedbackError}
        helperText={contentFeedbackError}
        disabled={!isEditable}
        sx={{ marginTop: "8px" }}
      />
    </Box>
  </CardContent>

  {/* Kiểm tra feedbackContent và isEditable */}
  {!check && isEditable && (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        justifyContent: "end",
        mr: 10,
        mb: 2,
      }}
    >
      <Button
        variant="contained"
        color="success"
        onClick={() => window.history.back()}
        startIcon={<Reply />}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => handleModalOpen("Not_Passed")}
        startIcon={<ClearIcon />}
      >
        Not Passed
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleModalOpen("Passed")}
        startIcon={<CheckIcon />}
      >
        Passed
      </Button>
    </Box>
  )}
</Card>


      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          bgcolor: "#f5f5f5",
          p: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
        }}
      ></Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        message={`Are you sure you want to respond to this report objective?`}
      />
    </Box>
  );
};

export default OKRFeedback;
