import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OKRContext } from "../../../context/OKRContext";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";

export default function ObjectivesEvaluation({
  objectiveId,
  juryMemberAssessment,
}) {
  const {
    AssessmentQuestions,
    objectiveEvaluationByJuryPanel,
    updateObjectiveEvaluationByJuryPanel,
  } = useContext(OKRContext);
  const [questions, setQuestions] = useState([]);
  const [juryScores, setJuryScores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      const fetchedQuestions = await AssessmentQuestions();
      setQuestions(fetchedQuestions);

      // Khởi tạo juryScores dựa trên juryMemberAssessment
      const initialScores = fetchedQuestions.map((question) => {
        if (juryMemberAssessment) {
          const scoreData = juryMemberAssessment.juryScores.find(
            (score) => score.questionId === question.id
          );
          return {
            questionId: question.id,
            score: scoreData ? scoreData.score : 0,
            comment: scoreData ? scoreData.comment : "",
          };
        } else {
          return {
            questionId: question.id,
            score: 0,
            comment: "",
          };
        }
      });

      setJuryScores(initialScores);
    };

    fetchQuestions();
  }, [AssessmentQuestions, juryMemberAssessment]);

  const handleScoreChange = (index, value) => {
    const updatedScores = [...juryScores];
    updatedScores[index].score = value;
    setJuryScores(updatedScores);
  };

  const handleCommentChange = (index, value) => {
    const updatedScores = [...juryScores];
    updatedScores[index].comment = value;
    setJuryScores(updatedScores);
  };

  const handleSave = async () => {
    let hasError = false;

    juryScores.forEach((score, index) => {
      // Kiểm tra điểm số
      if (isNaN(score.score) || score.score < 0 || score.score > 10) {
        toast.error(
          `The score for question ${index + 1} must be between 0 and 10.`
        );
        hasError = true;
      }

      // Kiểm tra bình luận
      if (score.comment.length > 150) {
        toast.error(
          `The comment for question ${index + 1} cannot exceed 150 characters.`
        );
        hasError = true;
      }
    });

    // Nếu có lỗi, dừng lại và không lưu
    if (hasError) return;

    try {
      if (juryMemberAssessment) {
        // Nếu juryMemberAssessment không null, cập nhật đánh giá
        const updatePayload = {
          juryScores: juryScores.map((score, index) => ({
            id: juryMemberAssessment.juryScores[index].id, // Lấy id từ juryMemberAssessment
            score: Number(score.score), // Chuyển đổi score thành số
            comment: score.comment,
          })),
        };

        console.log("Payload cập nhật trước khi gọi API:", updatePayload);

        await updateObjectiveEvaluationByJuryPanel(
          juryMemberAssessment.id,
          updatePayload
        );
        toast.success(
          "The objective evaluation has been successfully updated!"
        );
      } else {
        // Nếu juryMemberAssessment là null, tạo một đánh giá mới
        const createPayload = {
          objectiveId: objectiveId,
          juryScores: juryScores.map((score) => ({
            questionId: score.questionId, // Sử dụng questionId
            score: Number(score.score), // Chuyển đổi score thành số
            comment: score.comment,
          })),
        };

        console.log("Payload tạo mới trước khi gọi API:", createPayload);

        await objectiveEvaluationByJuryPanel(
          objectiveId,
          createPayload.juryScores
        );
        toast.success("The objective evaluation has been successfully saved!");
      }

      navigate("/okr/okrscoring");
    } catch (error) {
      console.error("Lỗi khi lưu đánh giá:", error);
      // toast.error("Lỗi khi lưu đánh giá!");
    }
  };

  const handleCancel = () => {
    navigate("/okr/okrscoring");
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", width: "40%" }}>
                Criteria
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "20%" }}
                align="center"
              >
                Score (scale of 10)
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", width: "40%" }}
                align="center"
              >
                Comment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {juryScores.map((score, index) => (
              <TableRow key={score.questionId}>
                <TableCell>
                  {questions.find((q) => q.id === score.questionId)?.content}
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={score.score}
                    onChange={(e) => handleScoreChange(index, e.target.value)}
                    inputProps={{ min: 0, max: 10 }}
                    sx={{ textAlign: "center" }}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    value={score.comment}
                    onChange={(e) => handleCommentChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ marginTop: 2, display: "flex", justifyContent: "center", gap: 5 }}
      >
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{ color: "error.main", borderColor: "error.main" }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
