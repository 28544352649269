import React, { createContext, useState, useEffect } from "react";
import apiAuth from "../customs/axiosCustom";
import { hideLoading, showLoading } from "../components/Layout/LoadingScreen";
import { useAuth } from "../customs/authService";
import { toast } from "react-toastify";

export const OKRContext = createContext();

export const OKRProvider = ({ children }) => {
  const { auth, currentSemester } = useAuth();
  const now = new Date(new Date().getTime());
  const [currentSemesterApi, setCurrentSemester] = useState({});
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  };
  const truOneDayAndSevenHours = (dateValue) =>
    new Date(new Date(dateValue).getTime() - 7 * 60 * 60 * 1000);

  const [objectives, setObjectives] = useState([]);

  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(false);

  // Function to handle errors with notifications
  const handleError = (message, err) => {
    setError(message);
    console.error(message, err);
    // toast.error(message);
  };
  const fetchTimeService = async () => {
    try {
      const response = await apiAuth.get(`/Time/get-vietnam-time`);
      return response.data || [];
    } catch (error) {
      handleError("Failed to fetch objective types.", error);
    }
  };

  // Fetch all parent objectives based on the type of objective
  const fetchAllParentObjects = async (TypeOfObjective) => {
    let teamId = null;
    let laboratoryId = null;

    const userResponse = await apiAuth.get("/User");
    const user = userResponse.data;

    teamId = user.teamId || auth.user.teamId;
    laboratoryId = user.laboratoryId || auth.user.labId;
    try {
      showLoading();
      let filter = "";
      let apiUrl = "/OKR";
      if (TypeOfObjective === "Team Challenge Objective") {
        apiUrl = "/OKR/challenge";
        filter = `(Type eq 'Challenge_Objective')  and LaboratoryId eq ${laboratoryId} and SemesterId eq ${
          currentSemester.semesterId
        } and isHidden eq false and registrationStartDate le ${formatDate(
          truOneDayAndSevenHours(now)
        )} and registrationEndDate ge ${formatDate(
          truOneDayAndSevenHours(now)
        )} and StatusApprove eq 'Approved'&$select=id,target,startDate,endDate`;
      } else if (TypeOfObjective === "Team Objective") {
        filter = `(Type eq 'Lab_Objective') and LaboratoryId eq ${laboratoryId} and SemesterId eq ${
          currentSemester.semesterId
        } and isHidden eq false and endDate ge ${formatDate(
          truOneDayAndSevenHours(now)
        )} and StatusApprove eq 'Approved'&$select=id,target,startDate,endDate&$expand=keyResults($select=id, title, targetValue)`;
      } else if (TypeOfObjective === "Intern Objective") {
        filter = `(Type eq 'Team_Challenge_Objective' or Type eq 'Team_Objective') and teamId eq ${teamId} and LaboratoryId eq ${laboratoryId} and SemesterId eq ${
          currentSemester.semesterId
        } and isHidden eq false and endDate ge ${formatDate(
          truOneDayAndSevenHours(now)
        )} and StatusApprove eq 'Approved'&$select=id,target,startDate,endDate&$expand=keyResults($select=id, title, targetValue)`;
      } else {
        filter = `Type eq '${TypeOfObjective}'  and LaboratoryId eq ${laboratoryId} and SemesterId eq ${
          currentSemester.semesterId
        } and isHidden eq false and endDate ge ${formatDate(
          truOneDayAndSevenHours(now)
        )} and StatusApprove eq 'Approved'&$select=id,target,startDate,endDate&$expand=keyResults($select=id, title, targetValue)`;
      }
      const response = await apiAuth.get(apiUrl, {
        params: {
          $filter: filter,
        },
        paramsSerializer: (params) => `$filter=${filter}`,
      });

      const objectives =
        response.data?.map((item) => ({
          id: item.Id,
          name: item.Target,
          startDate: item.StartDate,
          endDate: item.EndDate,

          keyResults:
            item?.KeyResults?.map((kr) => ({
              id: kr.Id,
              title: kr.Title,
            })) || [],
        })) || [];

      return objectives;
    } catch (error) {
      handleError("Failed to fetch objectives", error);
      return [];
    } finally {
      hideLoading();
    }
  };

  const fetchObjectiveTypes = async () => {
    try {
      const response = await apiAuth.get(`/OKR/types/objective`);
      return response.data || [];
    } catch (error) {
      handleError("Failed to fetch objective types.", error);
    }
  };

  const fetchMeasurementUnits = async () => {
    try {
      const response = await apiAuth.get(`/OKR/key-result/measurement-units`);
      return response.data || [];
    } catch (error) {
      handleError("Failed to fetch measurement units.", error);
    }
  };

  const formatToLocalISOString = (date) => {
    // if (!date) return null;
    // const localDate = new Date(date);
    // localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
    // return localDate.toISOString().slice(0, 19);
    return addOneDayAndSevenHours(date).toISOString();
  };

  const addOneDayAndSevenHours = (dateValue) =>
    new Date(new Date(dateValue).getTime() + 7 * 60 * 60 * 1000);

  const addMyObjective = async (objective) => {
    try {
      const userId = auth?.user?.jti;
      const {
        objectiveType,
        superiorObjective,
        target,
        description,
        registrationStartDate,
        registrationEndDate,
        startDate,
        endDate,
        milestoneDate,
        reportStartDate,
        reportEndDate,
        gradeStartTime,
        gradeEndTime,
        keyResults,
        juryPanelGroupId,
      } = objective;

      // Convert start and end dates to ISO format
      const formattedStartDate = startDate
        ? formatToLocalISOString(startDate)
        : null;
      const formattedEndDate = endDate ? formatToLocalISOString(endDate) : null;
      const formattedRegistrationStartDate = registrationStartDate
        ? formatToLocalISOString(registrationStartDate)
        : null;
      const formattedRegistrationEndDate = registrationEndDate
        ? formatToLocalISOString(registrationEndDate)
        : null;
      const formattedMilestoneDate = milestoneDate
        ? formatToLocalISOString(milestoneDate)
        : null;
      const formattedReportStartDate = reportStartDate
        ? formatToLocalISOString(reportStartDate)
        : null;
      const formattedreportEndDate = reportEndDate
        ? formatToLocalISOString(reportEndDate)
        : null;
      const formattedGradeStartTime = gradeStartTime
        ? formatToLocalISOString(gradeStartTime)
        : null;
      const formattedGradeEndTime = gradeEndTime
        ? formatToLocalISOString(gradeEndTime)
        : null;
      // Prepare the full objective object for submission, including key results
      const formattedObjective = {
        target,
        parentObjectiveId: superiorObjective || null,
        description,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        registrationStartDate: formattedRegistrationStartDate,
        registrationEndDate: formattedRegistrationEndDate,
        milestoneDate: formattedMilestoneDate,
        reportStartDate: formattedReportStartDate,
        reportEndDate: formattedreportEndDate,
        gradeStartTime: formattedGradeStartTime,
        gradeEndTime: formattedGradeEndTime,
        createBy: userId,
        juryPanelGroupId: juryPanelGroupId || null,
        semesterId: currentSemester.semesterId,
        keyResultDtos: keyResults.map((keyResult) => ({
          title: keyResult.title,
          planLink: keyResult.planLink || null,
          resultLink: keyResult.resultLink || null,
          targetValue: Number(keyResult.target),
          measurementUnit: keyResult.unit,
          startDate: keyResult.startDate
            ? formatToLocalISOString(keyResult.startDate)
            : null,
          endDate: keyResult.endDate
            ? formatToLocalISOString(keyResult.endDate)
            : null,
          parentKeyResultDTOs:
            keyResult.parternKeyResults &&
            keyResult.parternKeyResults.length > 0
              ? keyResult.parternKeyResults
              : null,
        })),
      };

      const apiEndpoints = {
        "Team Objective": "/OKR/team-okr",
        "Lab Objective": "/OKR/lab-okr",
        "My Objective": "/OKR/my-okr",
        "Intern Objective": "/OKR/my-okr",
        "Challenge Objective": "/OKR/Challenge-okr",
        "Team Challenge Objective": "/OKR/Challenge-team-okr",
      };

      const apiEndpoint = apiEndpoints[objectiveType];
      const response = await apiAuth.post(apiEndpoint, formattedObjective);
      setObjectives((prevObjectives) => [...prevObjectives, response.data]);
      return response;
    } catch (error) {
      handleError(
        "Failed to add objective. Please check the data and try again.",
        error
      );
    }
  };

  const fetchMyOKRs = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    if (!auth || !auth.user) {
      console.error("Auth object is not available");
      return;
    }

    try {
      const userId = auth.user.jti;
      const semesterId = currentSemester ? currentSemester.semesterId : null;

      if (!semesterId) {
        console.error("Semester ID is not available");
        return;
      }

      let filter = `Type eq 'My_Objective' and SemesterId eq ${semesterId} and userId eq '${userId}' and isHidden eq false`;

      // Thêm điều kiện tìm kiếm nếu có
      if (searchQuery) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        filter += ` and contains(tolower(target), '${lowerCaseSearchQuery}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? "" // Không thêm $orderby nếu là mặc định
          : sortBy === "date"
          ? "startDate asc"
          : "target asc";

      const response = await apiAuth.get(`/OKR`, {
        params: {
          $filter: filter,
          ...(orderBy && { $orderby: orderBy }), // Chỉ thêm $orderby nếu có giá trị
        },
        paramsSerializer: (params) => {
          return Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        },
      });

      // setMyObjectives(response.data);
      return response.data;
    } catch (err) {
      handleError("Failed to fetch My OKRs.", err);
    }
  };

  const fetchLabOKRs = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const semesterId = await handleCurrentSemester();
      let laboratoryId = null;

      // Fetch user data
      const userResponse = await apiAuth.get("/User");
      const user = userResponse.data;

      // Determine laboratoryId from user data or auth
      laboratoryId = user.laboratoryId || auth.user.labId;

      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      let filter = `Type eq 'Lab_Objective' and SemesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and isHidden eq false`;

      // Thêm điều kiện tìm kiếm nếu có
      if (lowerCaseSearchQuery) {
        filter += ` and contains(tolower(target), '${lowerCaseSearchQuery}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? "" // Không thêm $orderby nếu là mặc định
          : sortBy === "target"
          ? "target asc"
          : "startDate asc";

      const response = await apiAuth.get(`/OKR`, {
        params: {
          $filter: filter,
          $expand: `user,keyResults,semester,childObjectives($filter=StatusApprove eq 'Approved' and isHidden eq false;$expand=user,keyResults,semester,childObjectives($filter=StatusApprove eq 'Approved' and isHidden eq false;$expand=user,keyResults,semester,childObjectives))`,
          ...(orderBy && { $orderby: orderBy }),
        },
        paramsSerializer: (params) => {
          return Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        },
      });

      // setLabObjectives(response.data);
      return response.data;
    } catch (error) {
      handleError("Failed to fetch Lab OKRs.", error);
    }
  };

  // const MyObjectiveList = async () => {
  //   try {
  //     const semesterId = currentSemester?.semesterId; // Get the current semester ID
  //     const userId = auth?.user?.jti; // Get the current user's ID
  //     const laboratoryId = auth?.user?.labId; // Get the laboratory ID from the auth object

  //     if (!semesterId || !userId || !laboratoryId) {
  //       throw new Error(
  //         "Missing required parameters (semesterId, userId, laboratoryId)"
  //       );
  //     }

  //     const response = await apiAuth.get("/OKR/dashboard", {
  //       params: {
  //         $filter: `type eq 'My_Objective' and semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and userId eq '${userId}' and isHidden eq false`,
  //         $select:
  //           "id,target,progressPercentage,processStatus,startDate,endDate",
  //         $expand:
  //           "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
  //       },
  //     });

  //     // Kiểm tra xem có thông điệp không
  //     if (response.data.Message) {
  //       console.warn(response.data.Message);
  //       return []; // Trả về mảng rỗng nếu không có objectives
  //     }

  //     return response.data; // Directly return the API response
  //   } catch (error) {
  //     handleError("Failed to fetch My Objectives.", error);
  //     return []; // Return an empty array if there is an error
  //   }
  // };

  const MyObjectiveList = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const semesterId = await handleCurrentSemester();
      const userId = auth?.user?.jti; // Lấy ID người dùng hiện tại
      const laboratoryId = auth?.user?.labId; // Lấy ID phòng thí nghiệm từ đối tượng auth

      if (!semesterId || !userId || !laboratoryId) {
        throw new Error(
          "Missing required parameters (semesterId, userId, laboratoryId)"
        );
      }

      let filter = `type eq 'My_Objective' and semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and userId eq '${userId}' and isHidden eq false`;

      // Thêm điều kiện tìm kiếm nếu có
      if (searchQuery) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        filter += ` and contains(tolower(target), '${lowerCaseSearchQuery}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? "" // Không thêm $orderby nếu là mặc định
          : sortBy === "date"
          ? "startDate asc"
          : "target asc";

      const response = await apiAuth.get("/OKR/dashboard", {
        params: {
          $filter: filter,
          ...(orderBy && { $orderby: orderBy }), // Chỉ thêm $orderby nếu có giá trị
          $select:
            "id,target,progressPercentage,processStatus,startDate,endDate",
          $expand:
            "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
        },
      });

      // Kiểm tra xem có thông điệp không
      if (response.data.Message) {
        console.warn(response.data.Message);
        return []; // Trả về mảng rỗng nếu không có objectives
      }

      return response.data; // Trả về dữ liệu API trực tiếp
    } catch (error) {
      handleError("Failed to fetch My Objectives.", error);
      return []; // Trả về mảng rỗng nếu có lỗi
    }
  };

  // const LabObjectiveList = async () => {
  //   try {
  //     const semesterId = currentSemester.semesterId;
  //     const laboratoryId = auth?.user?.labId; // Lấy laboratoryId từ auth.user.labId

  //     const response = await apiAuth.get("/OKR/dashboard", {
  //       params: {
  //         $filter: `type eq 'Lab_Objective' and semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and isHidden eq false and statusApprove eq 'Approved'`,
  //         $select:
  //           "id,target,progressPercentage,processStatus,startDate,endDate",
  //         $expand:
  //           "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
  //       },
  //     });

  //     // Kiểm tra xem có thông điệp không
  //     if (response.data.Message) {
  //       console.warn(response.data.Message);
  //       return []; // Trả về mảng rỗng nếu không có objectives
  //     }

  //     return response.data; // Trả về dữ liệu trực tiếp
  //   } catch (error) {
  //     handleError("Failed to fetch Lab Objectives.", error);
  //     return [];
  //   }
  // };

  const LabObjectiveList = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const semesterId = await handleCurrentSemester();
      const laboratoryId = auth?.user?.labId; // Lấy ID phòng thí nghiệm từ auth.user.labId

      if (!semesterId || !laboratoryId) {
        throw new Error(
          "Missing required parameters (semesterId or laboratoryId)."
        );
      }

      let filter = `type eq 'Lab_Objective' and semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and isHidden eq false and statusApprove eq 'Approved'`;

      // Thêm điều kiện tìm kiếm nếu có
      if (searchQuery) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        filter += ` and contains(tolower(target), '${lowerCaseSearchQuery}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? "" // Không thêm $orderby nếu là mặc định
          : sortBy === "target"
          ? "target asc"
          : "startDate asc";

      const response = await apiAuth.get("/OKR/dashboard", {
        params: {
          $filter: filter,
          $select:
            "id,target,progressPercentage,processStatus,startDate,endDate",
          $expand:
            "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
          ...(orderBy && { $orderby: orderBy }), // Chỉ thêm $orderby nếu có giá trị
        },
      });

      // Kiểm tra xem có thông điệp không
      if (response.data.Message) {
        console.warn(response.data.Message);
        return []; // Trả về mảng rỗng nếu không có objectives
      }

      return response.data; // Trả về dữ liệu API trực tiếp
    } catch (error) {
      handleError("Failed to fetch Lab Objectives.", error);
      return []; // Trả về mảng rỗng nếu có lỗi
    }
  };

  const ChildObjectivesList = async (parentObjectiveId) => {
    try {
      const response = await apiAuth.get("/OKR/dashboard", {
        params: {
          $filter: `parentObjectiveId eq ${parentObjectiveId} and statusApprove eq 'Approved' and isHidden eq false`,
          $select:
            "id,target,progressPercentage,type,processStatus,statusReport,startDate,endDate",
          $expand:
            "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
        },
      });

      return response.data; // Trả về dữ liệu trực tiếp
    } catch (error) {
      handleError("Failed to fetch Child Objectives.", error);
      return [];
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await apiAuth.get("/User");
      return response.data; // Return the full user object
    } catch (error) {
      handleError("Failed to fetch user details.", error);
      return null; // Return null in case of an error
    }
  };

  // const TeamObjectiveList = async () => {
  //   try {
  //     const userDetails = await fetchUserDetails();
  //     if (!userDetails) {
  //       throw new Error("User details could not be fetched.");
  //     }

  //     const semesterId = currentSemester.semesterId; // Current semester ID
  //     const laboratoryId = userDetails.laboratoryId; // Laboratory ID from user details
  //     const teamId = userDetails.teamId; // Team ID from user details

  //     const response = await apiAuth.get("/OKR/dashboard", {
  //       params: {
  //         $filter: `type eq 'Team_Objective' and semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and teamId eq ${teamId} and parentObjectiveId eq null and isHidden eq false`,
  //         $select:
  //           "id,target,progressPercentage,processStatus,startDate,endDate",
  //         $expand:
  //           "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
  //       },
  //     });

  //     // Kiểm tra xem có thông điệp không
  //     if (response.data.Message) {
  //       console.warn(response.data.Message);
  //       return []; // Trả về mảng rỗng nếu không có objectives
  //     }

  //     return response.data; // Directly return the API response
  //   } catch (error) {
  //     handleError("Failed to fetch Team Objectives.", error);
  //     return []; // Return an empty array in case of an error
  //   }
  // };

  const handleCurrentSemester = async () => {
    try {
      const response = await apiAuth.get(`Semester/currentSemester`);
      return response.data.semesterId
    } catch (error) {
      console.error(error.response?.data);
      setCurrentSemester({});
    }
  };

  const TeamObjectiveList = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const userDetails = await fetchUserDetails();
      const semester = await handleCurrentSemester();
      if (!userDetails) {
        throw new Error("User details could not be fetched.");
      }
      const laboratoryId = userDetails.laboratoryId; 
      const teamId = userDetails.teamId; 

      // Bắt đầu xây dựng filter
      let filter = `type eq 'Team_Objective' and semesterId eq ${semester} and laboratoryId eq ${laboratoryId} and teamId eq ${teamId} and parentObjectiveId eq null and isHidden eq false`;

      // Thêm điều kiện tìm kiếm nếu có
      if (searchQuery) {
        const lowerCaseSearchQuery = searchQuery.toLowerCase();
        filter += ` and contains(tolower(target), '${lowerCaseSearchQuery}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }
      console.log(filter);

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? "" // Không thêm $orderby nếu là mặc định
          : sortBy === "target"
          ? "target asc"
          : "startDate asc";

      const response = await apiAuth.get("/OKR/dashboard", {
        params: {
          $filter: filter,
          $select:
            "id,target,progressPercentage,processStatus,startDate,endDate",
          $expand:
            "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
          ...(orderBy && { $orderby: orderBy }), // Chỉ thêm $orderby nếu có giá trị
        },
      });

      // Kiểm tra xem có thông điệp không
      if (response.data.Message) {
        console.warn(response.data.Message);
        return []; // Trả về mảng rỗng nếu không có objectives
      }

      return response.data; // Trả về dữ liệu API trực tiếp
    } catch (error) {
      handleError("Failed to fetch Team Objectives.", error);
      return []; // Trả về mảng rỗng nếu có lỗi
    }
  };

  const fetchTeamOKRs = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const semesterId = await handleCurrentSemester();
      let teamId = null;

      const userResponse = await apiAuth.get("/User");
      const user = userResponse.data;

      teamId = user.teamId || auth.user.teamId;

      const lowerCaseSearchQuery = searchQuery.toLowerCase();

      // Bắt đầu xây dựng filter
      let filter = `Type eq 'Team_Objective' and SemesterId eq ${semesterId} and teamId eq ${teamId} and parentObjectiveId eq null and isHidden eq false`;

      // Thêm điều kiện tìm kiếm nếu có
      if (lowerCaseSearchQuery) {
        filter += ` and contains(tolower(target), '${lowerCaseSearchQuery}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? "" // Không thêm $orderby nếu là mặc định
          : sortBy === "target"
          ? "target asc"
          : "startDate asc";

      const response = await apiAuth.get(`/OKR`, {
        params: {
          $filter: filter,
          $expand: `user,keyResults,semester,childObjectives($filter=StatusApprove eq 'Approved' and isHidden eq false;$expand=user,keyResults,semester,childObjectives)`,
          ...(orderBy && { $orderby: orderBy }), // Chỉ thêm $orderby nếu có giá trị
        },
        paramsSerializer: (params) => {
          return Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        },
      });

      // setTeamObjectives(response.data);
      return response.data;
    } catch (error) {
      handleError("Failed to fetch Team OKRs.", error);
    }
  };

  // const ChallengeObjectiveList = async () => {
  //   try {
  //     const semesterId = currentSemester?.semesterId; // Lấy semesterId từ context
  //     const laboratoryId = auth?.user?.labId; // Lấy laboratoryId từ auth

  //     if (!semesterId || !laboratoryId) {
  //       throw new Error(
  //         "Missing required parameters (semesterId or laboratoryId)."
  //       );
  //     }

  //     const response = await apiAuth.get("/OKR/challenge", {
  //       params: {
  //         $filter: `semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and isHidden eq false and statusApprove eq 'Approved'`,
  //         $expand: `user($select=firstName,lastName,avatar)`,
  //         $select: `id,target,registrationStartDate,registrationEndDate,startDate,endDate,processStatus`,
  //       },
  //     });

  //     // Kiểm tra xem có thông điệp không
  //     if (response.data.Message) {
  //       console.warn(response.data.Message);
  //       return []; // Trả về mảng rỗng nếu không có objectives
  //     }

  //     // Trả về dữ liệu API trực tiếp
  //     return response.data;
  //   } catch (error) {
  //     handleError("Failed to fetch Challenge Objectives.", error);
  //     return []; // Trả về mảng rỗng nếu lỗi
  //   }
  // };

  const ChallengeObjectiveList = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const semesterId = await handleCurrentSemester();
      const laboratoryId = auth?.user?.labId; // Lấy laboratoryId từ auth

      if (!semesterId || !laboratoryId) {
        throw new Error(
          "Missing required parameters (semesterId or laboratoryId)."
        );
      }

      let filter = `semesterId eq ${semesterId} and laboratoryId eq ${laboratoryId} and isHidden eq false and statusApprove eq 'Approved'`;

      // Thêm điều kiện tìm kiếm nếu có
      if (searchQuery) {
        filter += ` and contains(tolower(target), '${searchQuery.toLowerCase()}')`;
      }

      // Thêm điều kiện trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? ""
          : sortBy === "date"
          ? "startDate asc"
          : "target asc";

      const response = await apiAuth.get("/OKR/challenge", {
        params: {
          $filter: filter,
          $expand: `user($select=firstName,lastName,avatar)`,
          $select: `id,target,registrationStartDate,registrationEndDate,startDate,endDate,processStatus`,
          ...(orderBy && { $orderby: orderBy }), // Thêm $orderby nếu có giá trị
        },
      });

      // Kiểm tra xem có thông điệp không
      if (response.data.Message) {
        console.warn(response.data.Message);
        return []; // Trả về mảng rỗng nếu không có objectives
      }

      // Trả về dữ liệu API trực tiếp
      return response.data;
    } catch (error) {
      handleError("Failed to fetch Challenge Objectives.", error);
      return []; // Trả về mảng rỗng nếu có lỗi
    }
  };

  const fetchMyOKRsRequestwithRoleIntern = async () => {
    if (!currentSemester || !auth || !auth.user) {
      console.error("Semester or auth data is not available");
      return [];
    }

    try {
      const userId = auth.user.jti; // Lấy userId từ auth
      const semesterId = await handleCurrentSemester(); // Lấy semesterId từ currentSemester

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `type eq 'Intern_Objective' and SemesterId eq ${semesterId} and userId eq '${userId}' and isHidden eq false`,
          $orderby: "id desc",
        },
      });

      return response.data; // Trả về dữ liệu
    } catch (error) {
      handleError("Failed to fetch Intern OKRs.", error);
      return [];
    }
  };

  const fetchMyOKRsRequestwithRoleMentor = async () => {
    if (!currentSemester || !auth || !auth.user) {
      console.error("Semester or auth data is not available");
      return [];
    }

    try {
      const userId = auth.user.jti;
      const semesterId = await handleCurrentSemester();

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `((type eq 'Team_Objective' or type eq 'Team_Challenge_Objective') and SemesterId eq ${semesterId} and userId eq '${userId}' and parentObjectiveId ne null and isHidden eq false)`,
          $orderby: "id desc",
        },
      });

      return response.data;
    } catch (error) {
      handleError("Failed to fetch Mentor OKRs.", error);
      return [];
    }
  };

  const TeamChallengePendingList = async () => {
    try {
      const semesterId = currentSemester?.semesterId;
      const userId = auth?.user?.jti;
      const laboratoryId = auth?.user?.labId;

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `Type eq 'Challenge_Objective' and UserId eq '${userId}' and SemesterId eq ${semesterId} and LaboratoryId eq ${laboratoryId}`,
          $expand: `childObjectives($filter=StatusApprove eq 'Pending' and isHidden eq false;$select=Id,Target,StartDate,EndDate;$expand=user($select=FirstName,LastName,Avatar),keyResults($select=Id,Title))`,
        },
      });

      // Flatten all child objectives into a single array
      const allChildObjectives = response.data.reduce((acc, objective) => {
        if (objective.ChildObjectives && objective.ChildObjectives.length > 0) {
          acc.push(...objective.ChildObjectives);
        }
        return acc;
      }, []);

      return allChildObjectives;
    } catch (error) {
      handleError(
        "Failed to fetch list of OKRs requests for Lab Manager.",
        error
      );
      return [];
    }
  };

  const InternChallengePendingList = async () => {
    try {
      const semesterId = currentSemester?.semesterId;
      const userId = auth?.user?.jti;

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `type eq 'Team_Challenge_Objective' and SemesterId eq ${semesterId} and userId eq '${userId}' and parentObjectiveId ne null and StatusApprove eq 'Approved'`,
          $expand: `childObjectives($filter=StatusApprove eq 'Pending' and isHidden eq false;$select=Id,Target,StartDate,EndDate;$expand=user($select=FirstName,LastName,Avatar),keyResults($select=Id,Title))`,
        },
      });

      // Tập hợp tất cả các `ChildObjectives` từ các `Team_Objective`
      const allChildObjectives = response.data.reduce((acc, objective) => {
        if (objective.ChildObjectives && objective.ChildObjectives.length > 0) {
          acc.push(...objective.ChildObjectives);
        }
        return acc;
      }, []);

      return allChildObjectives;
    } catch (error) {
      handleError(
        "Failed to fetch list of OKRs requests for Mentor in Lab OKRs.",
        error
      );
      return [];
    }
  };

  const fetchListOKRsRequestToLabManagerInLabOKRs = async () => {
    try {
      const semesterId = currentSemester?.semesterId;
      const userId = auth?.user?.jti;
      const laboratoryId = auth?.user?.labId;

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `Type eq 'Lab_Objective' and UserId eq '${userId}' and SemesterId eq ${semesterId} and LaboratoryId eq ${laboratoryId}`,
          $expand: `childObjectives($filter=StatusApprove eq 'Pending' and isHidden eq false;$select=Id,Target,StartDate,EndDate;$expand=user($select=FirstName,LastName,Avatar),keyResults($select=Id,Title))`,
        },
      });

      // Flatten all child objectives into a single array
      const allChildObjectives = response.data.reduce((acc, objective) => {
        if (objective.ChildObjectives && objective.ChildObjectives.length > 0) {
          acc.push(...objective.ChildObjectives);
        }
        return acc;
      }, []);

      return allChildObjectives;
    } catch (error) {
      handleError(
        "Failed to fetch list of OKRs requests for Lab Manager.",
        error
      );
      return [];
    }
  };

  const fetchListOKRsRequestToMentorInLabOKRs = async () => {
    try {
      const semesterId = currentSemester?.semesterId;
      const userId = auth?.user?.jti;

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `type eq 'Team_Objective' and SemesterId eq ${semesterId} and userId eq '${userId}' and parentObjectiveId ne null and StatusApprove eq 'Approved'`,
          $expand: `childObjectives($filter=StatusApprove eq 'Pending' and isHidden eq false;$select=Id,Target,StartDate,EndDate;$expand=user($select=FirstName,LastName,Avatar),keyResults($select=Id,Title))`,
        },
      });

      // Tập hợp tất cả các `ChildObjectives` từ các `Team_Objective`
      const allChildObjectives = response.data.reduce((acc, objective) => {
        if (objective.ChildObjectives && objective.ChildObjectives.length > 0) {
          acc.push(...objective.ChildObjectives);
        }
        return acc;
      }, []);

      return allChildObjectives;
    } catch (error) {
      handleError(
        "Failed to fetch list of OKRs requests for Mentor in Lab OKRs.",
        error
      );
      return [];
    }
  };

  const fetchListOKRsRequestToMentorInTeamOKRs = async () => {
    try {
      const semesterId = currentSemester?.semesterId;
      const userId = auth?.user?.jti;

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: `type eq 'Team_Objective' and SemesterId eq ${semesterId} and userId eq '${userId}' and parentObjectiveId eq null and StatusApprove eq 'Approved'`,
          $expand: `childObjectives($filter=StatusApprove eq 'Pending' and isHidden eq false;$select=Id,Target,StartDate,EndDate;$expand=user($select=FirstName,LastName,Avatar),keyResults($select=Id,Title))`,
        },
      });

      // Tập hợp tất cả các `ChildObjectives` từ các `Team_Objective`
      const allChildObjectives = response.data.reduce((acc, objective) => {
        if (objective.ChildObjectives && objective.ChildObjectives.length > 0) {
          acc.push(...objective.ChildObjectives);
        }
        return acc;
      }, []);

      return allChildObjectives;
    } catch (error) {
      handleError(
        "Failed to fetch list of OKRs requests for Mentor in Lab OKRs.",
        error
      );
      return [];
    }
  };

  const updateChildKeyResult = async (objectiveId, payload) => {
    try {
      const response = await apiAuth.put(
        `/OKR/insert/child-key-result/${objectiveId}`,
        payload
      );
      return response.data;
    } catch (error) {
      handleError("Error updating child key result.", error);
      throw error;
    }
  };

  const updateParternKeyResult = async (objectiveId, payload) => {
    try {
      const response = await apiAuth.put(
        `/OKR/insert/partern-key-result/${objectiveId}`,
        payload
      );
      return response.data;
    } catch (error) {
      handleError("Error updating partern key result.", error);
      throw error;
    }
  };

  const fetchKeyResultsByObjectiveId = async (objectiveId) => {
    try {
      const response = await apiAuth.get(`/OKR?$filter=id eq ${objectiveId}`);
      return response.data?.[0]?.keyResults || []; // return keyResults array or empty array if none
    } catch (err) {
      handleError("Failed to fetch key results for objective.", err);
      throw err;
    }
  };

  const fetchObjectiveById = async (objectiveId) => {
    try {
      const response = await apiAuth.get(`/OKR/${objectiveId}`);
      return response.data;
    } catch (error) {
      handleError("Failed to fetch objective details.", error);
      throw error;
    }
  };

  const DetailObjectiveChallengeById = async (objectiveId) => {
    try {
      const response = await apiAuth.get(`/OKR/challenge/${objectiveId}`);
      return response.data; // Return the fetched data
    } catch (error) {
      handleError("Failed to fetch challenge objective details.", error);
      throw error;
    }
  };

  const fetchOKRsLinkRequestListByObjectiveId = async (objectiveId) => {
    try {
      showLoading();
      const response = await apiAuth.get(`/OKR`, {
        params: {
          $filter: `id eq ${objectiveId}`,
          $expand: `childObjectives($filter=StatusApprove eq 'Pending';$expand=user,keyResults,semester)`,
        },
      });

      return response.data?.[0] || {}; // Trả về dữ liệu đầu tiên hoặc đối tượng rỗng nếu không có dữ liệu
    } catch (error) {
      handleError(
        "Failed to fetch OKRs link request list by objective ID.",
        error
      );
      throw error;
    } finally {
      hideLoading();
    }
  };

  // const ReportObjectiveList = async () => {
  //   try {
  //     const semesterId = currentSemester?.semesterId;
  //     const userId = auth?.user?.jti;

  //     if (!semesterId || !userId) {
  //       throw new Error("Missing required parameters (semesterId or userId).");
  //     }

  //     const response = await apiAuth.get("/OKR/dashboard", {
  //       params: {
  //         $filter: `semesterId eq ${semesterId} and userId eq '${userId}' and statusApprove eq 'Approved' and isHidden eq false`,
  //         $select:
  //           "id,target,progressPercentage,processStatus,statusReport,startDate,endDate",
  //         $expand:
  //           "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
  //       },
  //     });

  //     return response.data; // Return the API response data directly
  //   } catch (error) {
  //     handleError("Failed to fetch Report Objectives.", error);
  //     return []; // Return an empty array on failure
  //   }
  // };

  const ReportObjectiveList = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    try {
      const semesterId = await handleCurrentSemester();
      const userId = auth?.user?.jti;

      if (!semesterId || !userId) {
        throw new Error("Missing required parameters (semesterId or userId).");
      }

      let filter = `semesterId eq ${semesterId} and userId eq '${userId}' and statusApprove eq 'Approved' and isHidden eq false`;

      // Thêm điều kiện tìm kiếm nếu có
      if (searchQuery) {
        filter += ` and contains(tolower(target), '${searchQuery.toLowerCase()}')`;
      }

      // Thêm điều kiện lọc trạng thái nếu không phải là "All"
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định cách sắp xếp
      const orderBy =
        sortBy === "default"
          ? ""
          : sortBy === "date"
          ? "startDate asc"
          : "target asc";

      const response = await apiAuth.get("/OKR/dashboard", {
        params: {
          $filter: filter,
          $select:
            "id,target,progressPercentage,type,processStatus,statusReport,startDate,endDate",
          $expand:
            "user($select=firstName,lastName,avatar),keyResults($select=id,title)",
          ...(orderBy && { $orderby: orderBy }), // Thêm $orderby nếu có giá trị sắp xếp
        },
      });

      return response.data; // Trả về dữ liệu API trực tiếp
    } catch (error) {
      handleError("Failed to fetch Report Objectives.", error);
      return []; // Trả về mảng rỗng nếu có lỗi
    }
  };

  const fetchListReportOKRs = async (
    searchQuery = "",
    status = "all",
    sortBy = "default"
  ) => {
    if (!currentSemester || !auth || !auth.user) {
      console.error("Semester or auth data is not available");
      return [];
    }

    try {
      const userId = auth.user.jti;
      const semesterId = await handleCurrentSemester();

      let filter = `semesterId eq ${semesterId} and userId eq '${userId}' and statusApprove eq 'Approved' and isHidden eq false`;

      // Thêm điều kiện tìm kiếm
      if (searchQuery) {
        filter += ` and contains(tolower(target), '${searchQuery.toLowerCase()}')`;
      }

      // Thêm điều kiện lọc trạng thái
      if (status !== "all") {
        filter += ` and processStatus eq '${status}'`;
      }

      // Xác định sắp xếp
      let orderBy = "";
      if (sortBy === "date") {
        orderBy = "startDate asc";
      } else if (sortBy === "target") {
        orderBy = "target asc";
      }

      const response = await apiAuth.get("/OKR", {
        params: {
          $filter: filter,
          $expand: `user,keyResults,semester,childObjectives($filter=StatusApprove eq 'Approved' and isHidden eq false;$expand=user,keyResults,semester,childObjectives($filter=StatusApprove eq 'Approved' and isHidden eq false;$expand=user,keyResults,semester,childObjectives))`,
          ...(orderBy && { $orderby: orderBy }), // Thêm $orderby nếu có giá trị sắp xếp
        },
        paramsSerializer: (params) => {
          return Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join("&");
        },
      });

      // setReportObjectives(response.data);
      // setError(null);
      return response.data;
    } catch (error) {
      handleError("Failed to fetch report OKRs.", error);
      return [];
    }
  };

  const fetchReportObjectiveDetail = async (objectiveId) => {
    try {
      const response = await apiAuth.get(`/ReportObjective/${objectiveId}`);

      return response.data;
    } catch (error) {
      console.error("Failed to fetch objective data:", error);
    }
  };

  const fetchReportObjectiveDraftingDetail = async (objectiveId) => {
    try {
      const response = await apiAuth.get(
        `/ReportObjective/drafting/${objectiveId}`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch objective data:", error);
    }
  };

  const fetchReportObjectiveSummarizingDetail = async (objectiveId) => {
    try {
      const response = await apiAuth.get(
        `/ReportObjective/feedback/Review/${objectiveId}`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch objective data:", error);
    }
  };

  const fetchFeedBackReportObjectiveDetail = async (objectiveId) => {
    try {
      const response = await apiAuth.get(
        `/ReportObjective/history/detail/${objectiveId}`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch objective data:", error);
    }
  };

  const fetchHistoryReportObjective = async (objectiveId) => {
    try {
      const response = await apiAuth.get(
        `/ReportObjective/history/${objectiveId}`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch objective data:", error);
    }
  };

  const fetchQuestionsReport = async () => {
    try {
      const response = await apiAuth.get(
        `/ReportObjective/questions/ReportProgressPerformance`
      );
      return response.data || [];
    } catch (error) {
      handleError("Failed to fetch objective types.", error);
    }
  };

  const fetchCreateReportObjective = async (status, data) => {
    try {
      showLoading();
      const response = await apiAuth.post(
        `/ReportObjective/statusReport?statusReport=${status}`,
        data
      );

      return response.status;
    } catch (error) {
      console.error("Failed to update report objective:", error);
      handleError(
        "Failed to update report objective. Please check your network connection or try again later.",
        error
      );

      throw error;
    } finally {
      hideLoading();
    }
  };

  const fetchCreateReportLabOrMyObjective = async (data) => {
    try {
      showLoading();
      const response = await apiAuth.post(`/ReportObjective/patern`, data);

      return response.status;
    } catch (error) {
      console.error("Failed to update report objective:", error);
      handleError(
        "Failed to update report objective. Please check your network connection or try again later.",
        error
      );

      throw error;
    } finally {
      hideLoading();
    }
  };
  const fetchCreateReportTeamChallengeObjective = async (data) => {
    try {
      showLoading();
      const response = await apiAuth.post(
        `/ReportObjective/team-challenge`,
        data
      );
      return response.status;
    } catch (error) {
      console.error("Failed to update report objective:", error);
      handleError(
        "Failed to update report objective. Please check your network connection or try again later.",
        error
      );

      throw error;
    } finally {
      hideLoading();
    }
  };

  const fetchUpdateDraftReportObjective = async (data) => {
    try {
      showLoading();
      const response = await apiAuth.put(`/ReportObjective/Draft`, data);

      return response.status;
    } catch (error) {
      console.error("Failed to update report objective:", error);
      handleError(
        "Failed to update report objective. Please check your network connection or try again later.",
        error
      );

      throw error;
    } finally {
      hideLoading();
    }
  };

  const fetchUpdateSummarizingReportObjective = async (data) => {
    try {
      showLoading();
      console.log("Request Data for Update Report Objective:", data);

      const response = await apiAuth.put(`/ReportObjective/Review`, data);

      return response.status;
    } catch (error) {
      console.error("Failed to update report objective:", error);
      handleError(
        "Failed to update report objective. Please check your network connection or try again later.",
        error
      );

      throw error;
    } finally {
      hideLoading();
    }
  };

  const updateStatusApproveObjective = async (objectiveId, statusApprove) => {
    try {
      const response = await apiAuth.put(
        `/OKR/approve/${objectiveId}/${statusApprove}`
      );
      toast.success("Status updated successfully!");
      return response.data;
    } catch (error) {
      handleError("Error updating approval status.", error);
      throw error;
    }
  };

  const updateObjective = async (objectiveId, updatedData) => {
    try {
      showLoading();
      const response = await apiAuth.put(
        `/OKR/UpdateObjective/${objectiveId}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "Error update objective";
        toast.info(errorMessage);
        throw new Error(errorMessage);
      } else {
        toast.error("An unexpected error occurred.");
        throw new Error("An unexpected error occurred.");
      }
    } finally {
      hideLoading();
    }
  };

  const deleteObjective = async (objectiveId) => {
    try {
      showLoading();
      await apiAuth.delete(`/OKR/DeleteObjective/${objectiveId}`);
      toast.success("Objective deleted successfully!");

      // Nếu cần cập nhật danh sách sau khi xóa, bạn có thể gọi lại fetchMyOKRs hoặc setObjectives
      setObjectives((prevObjectives) =>
        prevObjectives.filter((objective) => objective.id !== objectiveId)
      );
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message || "Error deleting objective.";
        toast.error(errorMessage);
      } else {
        toast.error("An unexpected error occurred.");
      }
    } finally {
      hideLoading();
    }
  };

  const updateKeyResult = async (keyResultId, payload) => {
    try {
      const response = await apiAuth.put(
        `/OKR/UpdateKeyResult/${keyResultId}`,
        payload
      );
      // toast.success("Key Result updated successfully!");
      return response.data;
    } catch (error) {
      handleError("Error updating key result.", error);
      throw error;
    }
  };

  const deleteKeyResult = async (keyResultId) => {
    try {
      const response = await apiAuth.delete(
        `/OKR/DeleteKeyResult/${keyResultId}`
      );
      toast.success("Key Result deleted successfully!");
      return response.data;
    } catch (error) {
      handleError("Error deleting key result.", error);
      throw error;
    }
  };

  const fetchCreateFeedback = async (
    reportObjectiveId,
    { status, feedback }
  ) => {
    try {
      showLoading();
      const response = await apiAuth.post(
        `/ReportObjective/feedback/${reportObjectiveId}`,
        {
          status,
          feedback,
        }
      );
      return response.status;
    } catch (error) {
      handleError("Failed to submit feedback.", error);
      throw error;
    } finally {
      hideLoading();
    }
  };

  const fetchUpdateStatusAccomplishObjective = async (objectiveId) => {
    try {
      const response = await apiAuth.put(`OKR/finished/${objectiveId}`);
      return response.status;
    } catch (error) {
      handleError("Error UpdateStatus Accomplish Objective", error);
      throw error;
    }
  };

  const OKRLabChallengeScoringList = async () => {
    try {
      const semesterId = currentSemester?.semesterId;
      const laboratoryId = auth?.user?.labId;

      if (!semesterId || !laboratoryId) {
        throw new Error(
          "Missing required parameters (semesterId or laboratoryId)."
        );
      }

      const response = await apiAuth.get("/OKR/grade/challenge", {
        params: {
          $filter: `isHidden eq false and semester/id eq ${semesterId} and laboratoryId eq ${laboratoryId}`,
        },
      });

      return response.data;
    } catch (error) {
      handleError("Failed to fetch Lab Challenge Scoring List.", error);
      return [];
    }
  };

  const OKRTeamChallengeScoringList = async (parentObjectiveId) => {
    try {
      const response = await apiAuth.get(
        `/OKR/grade/team-challenge/${parentObjectiveId}`
      );
      return response.data;
    } catch (error) {
      handleError("Failed to fetch Team Challenge Scoring List.", error);
      return [];
    }
  };

  const TeamObjectiveChallengeDetailById = async (id) => {
    try {
      const response = await apiAuth.get(
        `/OKR/grade/detail/team-challenge/${id}`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch Team Objective Challenge details:", error);
      return null;
    }
  };

  const AssessmentQuestions = async () => {
    try {
      const response = await apiAuth.get(
        "/ReportObjective/questions/ScoringOKR"
      );
      return response.data;
    } catch (error) {
      handleError("Failed to fetch questions.", error);
      return [];
    }
  };

  const objectiveEvaluationByJuryPanel = async (objectiveId, juryScores) => {
    try {
      const requestBody = {
        objectiveId: objectiveId,
        juryScores: juryScores,
      };

      const response = await apiAuth.post("/JuryPanel/grade/okr", requestBody);

      return response.data;
    } catch (error) {
      handleError("Failed to evaluate objective by Jury Panel.", error);
      return null;
    }
  };

  const updateObjectiveEvaluationByJuryPanel = async (
    juryMemberAssessmentId,
    evaluationData
  ) => {
    try {
      const response = await apiAuth.put(
        `/JuryPanel/grade/okr/${juryMemberAssessmentId}`,
        evaluationData
      );
      return response.data;
    } catch (error) {
      handleError("Failed to update objective evaluation.", error);
      throw error;
    }
  };

  return (
    <OKRContext.Provider
      value={{
        auth,
        currentSemester,
        objectives,
        // myObjectives,
        // labObjectives,
        // teamObjectives,
        error,
        // loading,
        setObjectives,
        addMyObjective,
        fetchAllParentObjects,
        fetchObjectiveTypes,
        fetchMeasurementUnits,
        fetchMyOKRs,
        fetchLabOKRs,
        fetchTeamOKRs,

        LabObjectiveList,
        ChildObjectivesList,
        TeamObjectiveList,
        MyObjectiveList,
        ReportObjectiveList,
        ChallengeObjectiveList,
        TeamChallengePendingList,
        InternChallengePendingList,
        DetailObjectiveChallengeById,
        OKRLabChallengeScoringList,
        OKRTeamChallengeScoringList,
        TeamObjectiveChallengeDetailById,
        AssessmentQuestions,
        objectiveEvaluationByJuryPanel,
        updateObjectiveEvaluationByJuryPanel,

        updateChildKeyResult,
        updateParternKeyResult,
        fetchKeyResultsByObjectiveId,
        fetchObjectiveById,
        fetchOKRsLinkRequestListByObjectiveId,
        fetchReportObjectiveDetail,
        fetchQuestionsReport,
        fetchCreateReportObjective,
        fetchCreateReportLabOrMyObjective,
        fetchCreateReportTeamChallengeObjective,
        fetchUpdateDraftReportObjective,
        updateStatusApproveObjective,
        fetchReportObjectiveDraftingDetail,
        fetchListReportOKRs,
        fetchMyOKRsRequestwithRoleIntern,
        fetchMyOKRsRequestwithRoleMentor,
        fetchListOKRsRequestToLabManagerInLabOKRs,
        fetchListOKRsRequestToMentorInLabOKRs,
        fetchListOKRsRequestToMentorInTeamOKRs,
        fetchHistoryReportObjective,
        fetchReportObjectiveSummarizingDetail,
        fetchFeedBackReportObjectiveDetail,
        updateObjective,
        deleteObjective,
        updateKeyResult,
        deleteKeyResult,
        fetchUpdateSummarizingReportObjective,
        fetchCreateFeedback,
        fetchUpdateStatusAccomplishObjective,
        fetchTimeService,
      }}
    >
      {children}
    </OKRContext.Provider>
  );
};

export default OKRContext;
