import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Button,
  Select,
  Avatar,
  FormControl,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const MentorInternTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="tabs example">
          <Tab label="MY INTERN" />
        </Tabs>
      </Box>
      <Box sx={{ p: 2 }}>
        {value === 0 && <InternTab />}
      </Box>
    </Box>
  );
};

const InternTab = () => {
  const { t } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isActive, setIsActive] = useState(null);
  const [semesterId, setSemesterId] = useState(""); 
  const [interns, setInterns] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [semesters, setSemesters] = useState([]);

  const fetchInterns = async () => {
    try {
      const response = await apiAuth.get("/member/internsOnTeam", {
        params: {
          PageNumber: page + 1,
          PageSize: rowsPerPage,
          Keyword: searchKeyword,
          isActive,
          SemesterId: semesterId || null,
        },
      });
      setInterns(response.data.items);
      setTotalItems(response.data.totalItemCount);
    } catch (error) {
      toast.error(t(error.data?.message));
      console.error("Error fetching interns:", error);
    }
  };

  const changeStatus = async (memberId) => {
    try {
      showLoading();
      const response = await apiAuth.put(`/member/changeStatus/${memberId}`);
      toast.success(t(response.data?.message));
      fetchInterns();
    } catch (error) {
      toast.error(t(error.data?.message));
      console.error("Error changing status:", error);
    } finally {
      hideLoading();
    }
  };

  const dispel = async (memberId) => {
    try {
      showLoading();
      if (!window.confirm("Are you sure to dispel this user?")) {
        return;
      }
      const response = await apiAuth.put(`/member/dispel/${memberId}`);
      toast.success(t(response.data?.message));
      fetchInterns();
    } catch (error) {
      toast.error(t(error.data?.message));
      console.error("Error dispelling intern:", error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    const fetchSemesters = async () => {
      try {
        const response = await apiAuth.get("/Semester");
        const data = response.data || [];
        setSemesters(data);
      } catch (err) {
        console.error(`Error fetching semesters: ${err.message}`);
      }
    };

    fetchSemesters();
  }, []);

  useEffect(() => {
    fetchInterns();
  }, [page, rowsPerPage, isActive, semesterId]);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchInterns();
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <TextField
          label={t("members.searchKey")}
          variant="outlined"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          sx={{ mr: 2, minWidth: 400 }}
        />

        {/* Active Status Filter */}
        <FormControl sx={{ mr: 2, minWidth: 200 }}>
          <Select
            displayEmpty
            value={isActive !== null ? isActive : ""}
            onChange={(e) =>
              setIsActive(e.target.value === "" ? null : e.target.value)
            }
          >
            <MenuItem value="">{t("members.status.all")}</MenuItem>
            <MenuItem value={true}>{t("members.status.true")}</MenuItem>
            <MenuItem value={false}>{t("members.status.false")}</MenuItem>
          </Select>
        </FormControl>

        {/* Semester Filter */}
        <FormControl sx={{ mr: 2, minWidth: 200 }} variant="outlined">
          <Select
            value={semesterId}
            displayEmpty
            onChange={(e) => {
              const semesterName = e.target.value;
              setSemesterId(semesterName);
            }}
          >
            <MenuItem value="">{t("members.intern.allSemes")}</MenuItem>
            {semesters.map((semester) => (
              <MenuItem key={semester.semesterId} value={semester.semesterId}>
                {semester.semesterName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" onClick={handleSearch} sx={{ mt: 1 }}>
          {t("members.search")}
        </Button>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 600, overflowY: "auto", pb: 2 }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.avatar")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.fullName")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.rollName")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.email")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.birthDate")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.mentor")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.semester")}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                {t("members.intern.status")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interns.length > 0 ? (
              interns.map((intern) => (
                <TableRow key={"intern-" + intern.id}>
                  <TableCell>
                    <Avatar
                      src={intern.avatar ? `${intern.avatar}` : ""}
                      alt={intern.fullName}
                      sx={{
                        width: 50,
                        height: 50,
                        backgroundColor: !intern.avatar
                          ? getRandomColor()
                          : undefined,
                      }}
                    >
                      {!intern.avatar && intern.fullName.charAt(0)}
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    {intern.fullName !== ""
                      ? intern.fullName
                      : intern.email.split("@")[0]}
                  </TableCell>
                  <TableCell>{intern.rollName}</TableCell>
                  <TableCell>{intern.email}</TableCell>
                  <TableCell>{intern.birthDate}</TableCell>
                  <TableCell>{intern.mentor.fullname}</TableCell>
                  <TableCell>{intern.semester}</TableCell>
                  <TableCell>
                    {intern.status === true
                      ? t("members.status.true")
                      : t("members.status.false")}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9}>{t("members.noInternFound")}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MentorInternTabs;
