import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      semesterWait: "Waiting",
      systemError: "System error acquired, please try again",
      emailIsInvalid: "Email is invalid or not exist",
      welcome: "Welcome",
      userDeactivated: "User has been deactivated in the system",
      language: "Language",
      profile: "User Profile",
      en: "English",
      vi: "Vietnamese",
      system: "System Language",
      email: "Email Address",
      fname: "First Name",
      lname: "Last Name",
      loginSuccess: "Welcome {{user}}",
      noUserFound: "User is not found",
      validate: "Validate failed, please try again",
      tokenExpired: "Token has been expired, please re-login",
      langChange: "Changed to English",
      errors: {
        404: "The page you are looking for does not exist.",
        401: "You are not authorized to view this page. Please log in to continue.",
        403: "You do not have permission to view this page.",
        required: "Required",
        passNotMatch: "New Password and Confirmed Password are not match",
        emailValidate: "Wrong Email Format",
        passwordRegex:
          "Password must have at least 1 lower letter, 1 upper letter, 1 digit, 1 special character and its length at least 8 characters",
      },
      buttonUpdate: "Update",
      ipStrictError:
        "You do not have permission to attend. Please check your IP address.",
      drawerItems: {
        dashboard: "Dashboard",
        home: "Home",
        event: "Event",
        report: "Report",
        Request: "Request",
        okr: "OKR",
        candidate: "Candidate Intern",
        addOKR: "Add OKR",
        importIntern: "Import Intern",
        importLab: "Import Laboratory",
        importMentor: "Import Mentor",
        attendance: "Attendance",
        semester: "Semester",
        dashboardLab: "Dashboard Lab",
        members: "Members",
        copyright: `@${new Date().getFullYear()} Copyright by SEP490-G29 and DXLab`,
        ApproveImage: "Approve Images",
        Cleaning: "Cleaning Duty",
        import_member: "Import Member",
        CheckUsers: "Lab security",
        connectCamera: "Camera Connections",
      },
      userProfile: {
        receiveEmailNotify: "Send Notification To Email",
        confirmPassword: "Confirm Password",
        newPassword: "New Password",
        fileUpload: "Drop or click to upload file",
        btnUpdate: "Update Profile",
        btnSave: "Save Changes",
        btnCancel: "Cancel",
      },
      notification: "Notifications",
      account: "Account",
      updateSuccess: "Updated Successfully",
      pageModule: {
        page: "Page",
        pagePrev: "Previous Page",
        pageNext: "Next Page",
      },
      otpSent: "OTP sent, please check your email",
      checkInDone: "Checked in successfully",
      wrongIpMac:
        "Invalid IP/Mac address, please contact to Lab Manager or check your connection/device",
      wrongFaceVector: "Invalid Face Vector",
      wrongOtp: "Invalid OTP, please try again or re-send OTP",
      noLabFound: "Lab is not found",
      notInTime: "Action is not available now",
      checkInExisted: "Checked in, cannot check in again",
      checkOutDone: "Checked out successfully",
      otpSent5min: "OTP sent, please try again after 5 minutes",
      getOtp: "Get OTP",
      faceScan: "Face Scanning",
      otp: "OTP",
      approve: "Approve",
      attendanceStatus: {
        1: "Attended",
        2: "Late In",
        3: "Soon Out",
        4: "Absent",
      },
      importMember: {
        addMemberTitle: "Add Member",
        firstName: "Firt Name",
        lastName: "Last Name",
        email: "Email",
        role: {
          title: "Role",
          roleMentor: "Mentor",
          roleJuryPanel: "Jury Panel",
        },
        juryPanelGroupTitle: "Jury Panel Group",
        optionNone: "None",
        buttonAddMember: "Add Member",
      },

      importJuryPanel: {
        importJuryPanelCsvTitle: "Import Jury Panel CSV",
        downloadTemplate: "Download CSV form",
        csv: {
          errorParseCSV: "Failed to parse CSV file.",
          successfullyCSV: "",
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email",
          labName: "Lab Name",
          errorMessage: "Error Message",
          options: "Options",
        },
      },
      importMentor: {
        importMentorCsvTitle: "Import Mentor CSV",
        noRecordFound: "No Record Found",
        selectFile: "Please select a file before submitting",
        invalidFileFormat: "Invalid File Format, make sure it's .csv file",
        rowsAreInvalid: "Data rows are invalid, please check them below",
        invalidHeader: "Invalid header, please check csv file",
        importSuccess: "Created Mentor Successfully",
        emailIsNull: "Email field is null",
        emailIsNotValid: "Email is invalid or not existed",
        emailIsExistInDatabase: "Email is existed in system",
        emailNotExist: "Email is not exist",
        mentorAddSuccess: "Added Mentor Successfully",
        headerException: "Header(s) wrong, please try again",
      },
      importIntern: {
        noRecordFound: "No Record Found",
        emailNotExist: "Email is not exist",
        rollNumberIsExisted: "Roll Number is Existed",
        selectFileToImport: "Please select a file before submitting",
        invalidFileFormat: "Invalid File Format, make sure it's .csv file",
        rowsAreInvalid: "Data rows are invalid, please check them below",
        invalidHeader: "Invalid header, please check csv file",
        uploadAndSendMailSuccess:
          "Uploaded Candidate Interns and Send Mail Successfully",
        failedToLoad: "Failed to load, please try again",
        labNameNotExist: "Lab name is not exist",
        rollNumberIsNull: "Roll Number is null",
        dobIsNull: "Date of birth is null",
        emailIsNull: "Email is null",
        invalidEmailFormat: "Email is invalid or not existed",
        emailIsExistInDataBase: "Email is existed in system",
        labNameIsNull: "Lab Name is null",
        importFailed: "Import Failed",
        status: {
          true: "Succeed",
          false: "Failed",
        },
        headerException: "Header(s) wrong, please try again",
      },
      importLab: {
        noRecordFound: "No Record Found",
        invalidLeaveRequestNumb:
          "Leave Request Number must be positive and higher than 0",
        selectFileToImport: "Please select a file before submitting",
        invalidFileFormat: "Invalid File Format, make sure it's .csv file",
        rowsAreInvalid: "Data rows are invalid, please check them below",
        invalidHeader: "Invalid header, please check csv file",
        failedToLoad: "Failed to load, please try again",
        labNameIsExist: "Lab name is existed",
        userExisted: "User is existed",
        labManagerEmailIsNull: "Lab Manager email is null",
        uploadSuccess: "Uploaded Successfully",
        labManagerEmailIsInvalid: "Email is invalid or not existed",
        labNameIsNull: "Lab Name is null",
        importFailed: "Import Failed",
        headerException: "Header(s) wrong, please try again",
      },
      candidate: {
        confirmMultiple:
          "Are you sure you want to approve/reject this list of candidate interns? This action cannot be undone.",
        multiSelect:
          "Select checkbox(s)/all checkbox for approving/rejecting a list of candidate intern",
        mustHaveData: "Must have at least a Candidate Intern to continue",
        noSemesterFoundFuture:
          "No Current/Future Semester Found, Can't Create Account",
        updateSuccess: "Update Succeed",
        assignSuccess: "Assign Succeed",
        failedToCreateIdentityUser: "Failed to create intern, please try again",
        notFound: "No resource found",
        noUserFound: "User not found",
        candidateWaiting: "Candidate interns waiting to approve",
        candidateAssign: "Candidate interns waiting to assign",
        table: {
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email Address",
          actions: "Actions",
          assignTo: "Assign To",
          noCandidate: "No Candidate Found",
          candidateCv: "Candidate CV",
          approve: "Approve",
          reject: "Reject",
          labName: "Lab Name",
          showCv: "Show CV",
        },
        search: "Search by first name, last name and email",
        cvNotFound: "CV not found",
      },
      noNotificationFound: "No notification(s) found",
      notFoundInformation: "No information found",
      okrStatus: {
        1: "Not Started",
        2: "In Progress",
        3: "Completed",
        4: "Stuck",
      },
      createObjective: {
        createObjective: "Create Objective",
        typeofObjective: "Types of Objectives",
        targetObjective: "Target Objective *",
        description: "Description",
        registrationStart: "Registration Start *",
        registrationEnd: "Registration End *",
        startDate: "Start Date *",
        milestoneDate: "Milestone Date",
        endDate: "End Date *",
        reportStartDate: "Report Start Date *",
        reportEndDate: "Report End Date *",
        addKeyresult: "Add new Key Result",
        superiorObjectiveInputLabel: "Superior Objectives",
        juryPanelInputLabel: "Jury Panel",
        juryPanelOptions: "Jury Panel",
        keyNavBar: "Key Results",
        keyResultTitle: "Title Key Result *",
        keyResultTarget: "Target Key Result *",
        keyResultUnit: "Unit  *",
        keyResultStartDate: "Start Date Key Result  *",
        keyResultEndDate: "End Date Key Result  *",
        keyResultTooltip: "Link to your result", //
        keyResultPlanLink: "Plan Link to link this result",
        keyResultResultLink: "Result Link to link this result",
        keyresultRelated: "Related Key Results",
        buttonCancenl: "Cancel",
        buttonSave: "Save",
        buttonDelete: "Delete",
        buttonYes: "Yes",
        buttonNo: "No",
        buttonConfirnt: "Confirm",
        confirmDelete: "Confirm key result deletion",
        confirmDeleteContent:
          "Are you sure you want to delete this key result?",
        validateTargetObjective: "Target cannot be empty",
        validateRegistrationStart: "Registration Start cannot be empty",
        validateRegistrationStart_2:
          "Registration start time after present time",
        validateRegistrationEnd: "Registration End cannot be empty",
        validateRegistrationEnd_2:
          "Registration End Date must be after the Registration Start Date",
        validateRegistrationEnd_3: "End Date must be after Start Date",
        validateRegistrationEnd_4:
          "Registration End Date must be after Registration Start Date",
        validateStartDate_1: "Start Date must be after Registration End Date",
        validateStartDate_2: "Start Date must be after current time",
        validateStartDate_3: "Start Date must be after Registration End Date",
        validateStartDate_4: "Start date cannot be empty",
        validateEndDate_1: "End date cannot be empty",
        validateReportEndDate_2:"Report End Date must be after Report Start Date",
        validateReportStartDate_1:
          "Report start date must be after the end date.",
        validateReportEndDate_1: "Report end date must be after the end date.",
        selectObjectiveTypeError: "Type of objective cannot be empty",
        validateKeyresultStartDate_1_1: "Start Date of Key Result",
        validateKeyresultStartDate_1_2: "must be after Objective Start Date",
        validateKeyresultEndDate_1_1: "End Date of Key Result",
        validateKeyresultEndDate_1_2: "must be after Start Date of Key Result",
        gradeStartTime: "Grade Start Time *",
        gradeEndTime: "Grade End Time * ",
        validateGradeStartTime_1:
          "The grading start time cannot be after the report end time",
        validateGradeEndTime_1:
          "The grading end time cannot be after the report end time",
        validateMilestoneDate: "Milestone Date cannot be empty",
        validateReportStart: "Report Start Date cannot be empty",
        validateReportEnd: "Report End Date cannot be empty",
        validateGradeStartTime: "Grade Start Time cannot be empty",
        validateGradeEndTime: "Grade End Time cannot be empty",
        validateGradeEndTime_2:"Grade End Time must be after Grade Start Time",
        validateKeyresultTitle_1: "Title for key result",
        validateKeyresultTarget_1: "Target for key result",
        validateKeyresultUnit_1: "Title for key result",
        validateKeyresultStartDate_1: "Start date for key result",
        validateKeyresultEndDate_1: "End date for key result",
        validateKeyresultTargetDecimal: "Target is decimal type",
        validateLinkError: "Wrong URL (eg: https://www.example.com)",
        validateIsRequied: "is required",
        electObjectiveTypeError: "Type of Objective cannot be empty",
        optionNone: "Not linked to anyone",
        maxLengthError: "Exceeded 100 character limit",
        maxLengthDescription: "Exceeded 500 character limit",
        confirmCancel:
          "When you press confirm, the new target creation will be canceled.",
        confirmCancelTitle: "Confirm Cancel",
        requiredSuperiorObjective: "Superior Object cannot be empty ",
        requiredJuryPanelGroup:"Jury Panel cannot be empty ",
        noSuperiorObjectiveYet:"No Superior Objective yet",
        validateMilestone_1:"Milestone is not equal to start date",
        validateMilestone_2:"Milestone is not equal to end date"
      },
      okr: {
        okr: "OKR Module",
        main_Table: "Main Table",
        timeline: "Timeline",
        dashboard: "Dashboard",
        log_Dialog: "OKR Logs",
        team_Dialog: "Team Management",
        add_O_Button: "Add Objective",
        add_kr_Button: "New Key Result",
        search: "Search",
        filter_Priority: "Filter Priority",
        filter_1: "All",
        filter_2: "High",
        filter_3: "Medium",
        filter_4: "Low",
        sort_By: "Sort By",
        sort_1: "Title",
        sort_2: "Priority",
        sort_3: "Status",
        title: "Title",
        assign_to: "Assigned To",
        assign_to_def: "Not Assigned",
        priority: "Priority",
        progress: "Progress",
        estimate_Man_Day: "Estimate Man Day",
        actual_Man_Day: "Actual Man Day",
        difference: "Difference (Est. vs Act.)",
        notes: "Notes",
        attached_Files: "Attached Files",
        no_Commnet: "No comments yet.",
        comments: "Comments",
        reply: "Reply",
        comment_Error: "Comment content cannot be empty.",
        reply_Error: "Reply content cannot be empty.",
        actions: "Actions",
        save: "Save",
        update: "Update",
        cancel: "Cancel",
        delete: "Delete",
        description: "Description",
        start_Date: "Start Date",
        end_Date: "End Date",
        status: "Status",
        approved: "Is Approved",
        error_title: "Title is required",
        error_description: "Description is required",
        error_startDate: "Start Date is required",
        error_endDate: "End Date is required",
        error_ManDay: "Must be a positive number",
        error_endBeforeStart: "Start date need before End date",
        error_startDateInPast: "Start date cannot be in the past.",
        error_endDateInPast: "End date cannot be in the past.",
        errordateInPast: "Date time cannot be in past",
        rf_con: "Are you sure you want to refresh?",
        del_con: "Are you want to delete this",
        t_search: "Search by name or email",
        t_title: "Invite board members",
        t_invite: "Invite new member by email",
        t_b_invite: "Invite",
        t_b_close: "Close",
        l_title: "Activity Log",
        l_default: "No logs available.",
        ch_kr: "Key Results Progress",
        ch_o: "Objective Progress",
        ch_o_1: "Objectives",
        ch_o_2: "Progress (%)",
        ch_pd: "Priority Distribution",
        ch_c_t: "Current Value vs. Target Value",
        ch_pp: "Progress Performance",
        ch_pp_1: "Report Date",
        ch_pp_2: "Value",
        select_team: "Select Team",
        tl_Duration: "Duration",
        toast_add_o_s: "Objective added successfully!",
        toast_add_o_f: "Failed to add objective.",
        toast_up_o_s: "Objective updated successfully!",
        toast_up_o_f: "Failed to update objective.",
        toast_del_o_s: "Objective deleted successfully!",
        toast_del_o_f: "Failed to delete Objective.",
        toast_add_kr_s: "Key Result added successfully!",
        toast_add_kr_f: "Failed to add Key Result.",
        toast_up_kr_s: "Key Result updated successfully!",
        toast_del_kr_s: "Key Result deleted successfully!",
        toast_save_kr_f: "Failed to save Key Result.",
        toast_del_kr_f: "Failed to delete Key Result.",
        toast_add_kr_wr: "You can only add Key Results to approved objectives.",
        assign_default: "Not Assigned",
        toast_approve_obj_failed: "Approved Objective Failed",
        toast_approve_obj_succeed: "Approved Objective Successfully",
        toast_reject_obj_failed: "Rejected Objective Failed",
        toast_reject_obj_succeed: "Rejected Objective Successfully",
        toast_not_found: "Objective is not found",
        toast_add_f:"Add Objective Failed"
      },
      event: {
        eventsNotFound: "Event(s) not found",
        attendance: "Attendance",
        delete: "Delete",
        take_Attendance: "Take Attendance",
        save: "Save",
        cancel: "Cancel",
        edit: "Edit",
        close: "Close",
        update_Event: "Update Event",
        event_Details: "Event Details",
        add_Event: "Add Event",
        select_Users: "Select Users",
        roll: "Roll Name",
        name: "Name",
        avatar: "Avatar",
        e_Attendance_Status1: "Attended",
        e_Attendance_Status2: "Late",
        e_Attendance_Status3: "Absent",
        title: "Event Title",
        description: "Description",
        start_Date: "Start Date",
        end_Date: "End Date",
        start_Time: "Start Time",
        location: "Location",
        end_Time: "End Time",
        start: "Start at",
        end: "End at",
        toast_NotFound: "Not Found",
        toast_Event_add_s: "Event added successfully!",
        toast_Event_add_f: "Event added failed.",
        toast_Event_up_s: "Event updated successfully!",
        toast_Event_up_f: "Event updated failed.",
        toast_Event_del_s: "Event deleted successfully!",
        toast_Event_attendance_s: "Attendance recorded successfully!",
        toast_Event_attendance_f: "Attendance recorded failed.",
        toast_Event_datevalidate:
          "Start date and time must be earlier than end date and time.",
        toast_Event_past_date_error: "Event must start after present",
      },
      report: {
        internDetail: {
          title: "Intern Details",
          name: "Full Name",
          email: "Email Address",
          lab: "Lab",
          mentor: "Mentor",
          rollName: "Roll Name",
        },
        failed: "Failed",
        addReport: "Add Report",
        delReport: "Delete Report",
        editReport: "Edit Report",
        semesterEnded: "Semester ended",
        commentOfCompany: "Comment of Mentor",
        majorSkill: "Major Skill",
        softSkill: "Soft Skill",
        attitude: "Attitude",
        back: "Back",
        keyResultsOverview: "Key Results Overview",
        attendanceOverview: "Attendance Overview",
        toggleChartType: "Toggle Chart Type",
        attended: "Attended",
        absent: "Absent",
        lateIn: "Late In",
        soonOut: "Soon Out",
        inProgress: "In Progress",
        completed: "Completed",
        notStarted: "Not Started",
      },
      reportV: {
        listReport: "Reports",
        reportTitle: "Report Type",
        reportType: {
          1: "Mid-term Report",
          2: "End-term Report",
        },
        updateSemester: "Update Semester Successfully",
        listOfLab: "List of laboratories",
        labName: "Lab Name",
        listOfSemesters: "List of Semesters",
        semesterID: "Semester ID",
        semesterName: "Semester Name",
        addSemester: "Add Semester",
        sd: "Start Date",
        ed: "End Date",
        ac: "Action",
        viewIntern: "View Intern",
        internsforSemester: "Interns for Semester",
        rollName: "Roll Name",
        email: "Email Address",
        lstMentor: "List of Mentors",
        nameM: "Mentor Name",
        nameI: "Name Intern",
        noLab: "No labs are found",
        noM: "No mentors found",
        noi: "No interns found for this semester.",
        close: "Close",
        exporttoExcel: "Export to Excel final",
        exporttoExcel1: "Export to Excel midterm",
        searchKey: "Search by Roll Name and Email",
        exportSuccess: "Report is exported successfully",
        search: "Search",
        rID: "Report ID",
        sID: "Staff ID",
        lName: "Lab Name",
        mName: "Mentor Name",
        allowance: "Allowance",
        finalResult: "Final Result",
        delete: "Delete",
        dashBoard: "Performance summary",
        internsList: "Interns List",
        totalObjectives: "Total Objectives",
        createdDate: "Created Date",
        excelNotFound: "Resource not found.",
        btnAdd: "Save",
        sAddSucc: "Semester added successfully!",
        sExcel400: "Error generating Excel file. Please try again.",
        sUnex: "Unexpected response structure.",
        sDelete: "Are you sure you want to delete this report?",
        sDeleteSuc: "Report deleted successfully.",
        sSearch: "No reports found for the given Roll Name.",
        aReportSuc: "Report added successfully!",
        aInvail: "Invalid report data.",
        errors: {
          400: "Report information is required.",
          409: "A report for this period already exists.",
          422: "Invalid report data.",
          500: "The semester has ended. Reports cannot be added after the semester period.",
        },
        unexpectedError:
          "An unexpected error occurred. Please check your connection.",
        reportAddTime:
          "Reports can only be added between {{startMidterm}} and {{endMidterm}}, or between {{startEnd}} and {{endEnd}}.",
        errors1: {
          400: "Bad request. Please check the input data.",
          409: "No interns found for this semester.",
        },
        edit: "Edit",
      },
      attendance: {
        date: "Attendance Date: {{date}}",
        updateAttendance: "Update Intern Attendance",
        invalidTime: "Invalid setting times",
        cleanBatch: "Resetted attendance records",
        checkIn: "Check In",
        checkOut: "Check Out",
        test: "Error fetching attendance data",
        updateSuccess: "Update Success",
        updateFailse: "Update Failse",
        validateTimeCheckInCheckOut: "Time check-in must below time check-out",
        pageNotExist: "Page Not Exits",
        noUserFound: "No User Found",
        notFoundInformation: "Not Found Information",
        otpSent5min: "OTP sent 5 min",
        wrongIpMac: "Wrong Ip Mac",
        otpSent: "Otp Sent",
        wrongFaceVector: "Wrong Face Vector",
        wrongOtp: "Wrong Otp",
        noLabFound: "No Lab Found",
        checkInDone: "Check-In Done",
        notInTime: "Not In Time",
        checkInExisted: "Check-In Existed",
        checkOutDone: "Check-Out Done",
        notCheckInYet: "Not Check-In Yet",
        statusBatchNullOrEmpty: "Status Batch Null Or Empty",
        batchEnd: "Success, attendance batch ended",
        successBatch: "Success, attendance batch on",
        checkBatch: "Attendance batch is stopped. Happy Holiday/Weekend!",
        rollName: "Roll Name",
        name: "Name",
        email: "Email",
        absent: "Absent",
        attended: "Attended",
        lateIn: "Late In",
        soonOut: "Soon Out",
        actions: "Actions",
        search: "Search",
        month: "Month",
        year: "Year",
        otAttendance: "Open Ticket Attendance",
        c_t_Attendance: "Create Tickets Attendance",
        attendanceList: "Attendance List",
        noRecordsFound: "No records found",
      },
      ticket: {
        statusText: {
          1: "Pending",
          2: "Approved",
          3: "In Progress",
          4: "Done",
          5: "Reject",
          all: "All",
        },
        createSuccessTicket: "Created Ticket Successfully",
        ticketNotFound: "No ticket found",
        titleAndDateCannotBeBlank: "Title and Date cannot be blank.",
        validateDate: "Please select a valid date (today or later).",
        titleIsRequired: "Title is required",
        ticketList: "Ticket List",
        confirmDelete: "Confirm Delete",
        toDeleteThisTicket: " Are you sure you want to delete this ticket?",
        delete: "Delete",
        edit: "Edit",
        saveChanges: "Save Changes",
        ticketDetails: "Ticket Details",
        ticketComments: "Ticket Comments",
        comment: "Comment",
        noDescription: "No description",
        cancel: "Cancel",
        create: "Create",
        createTicketAttendance: "Create Ticket Attendance",
        createDate: "Create Date",
        createTicket: "Create Ticket",
        details: "Details",
        approve: "Approve",
        reject: "Reject",
        iD: "ID",
        creator: "Creator",
        assignee: "Assignee",
        title: "Title",
        description: "Description",
        dateRequire: "Date Require",
        status: "Status",
        actions: "Actions",
        date: "Date",
        checkInTime: "Check-In Time",
        checkOutTime: "Check-Out Time",
        reason: "Reason",
        listAttendanceTicket: "List Attendance Ticket",
        needLoginFirst: "Need Login First!",
        notFoundTickets: "Tickets Not Found",
        checkPermissionToView:
          "You don't has permission to view ticket attendance",
        notFoundCommentTicket: "Not Found Comment Ticket!",
        updateTicketSuccessfully: "Update Ticket Successfully!",
        descriptionEmpty: "Description Empty",
        commentSuccess: "Comment Success",
        spamComment: "Spam Comment",
        inputEmpty: "Input Empty",
        approveTicketSuccessfully: "Approve Ticket Successfully",
        rejectTicketSuccessfully: "Reject Ticket Successfully",
        dontHaveLabInfor: "User does not have lab information yet",
        createSuccessTicketAttendance: "Create success ticket Attendance.",
        createFaileTicketAttendance:
          "Can't not create ticket Attendance! Try again.",
        dontHaveMentor: "User don't have mentor",
        duplicateTicket: "Duplicate Ticket",
        notFoundLabManager: "Not found Lab Manager!",
        createTicketFailse: "Create Ticket Failse!",
        updateTicketFailse: "Update Ticket Failse",
        success: "Success",
        failseAPI: "Error fetching tickets.",
        close: "Close",
      },
      settingLab: {
        timeRequired: "Time Required",
        nothingChange: "Nothing Change",
        cancel: "Cancel",
        save: "Save",
        leaveRequestNum: "Leave Request Num",
        noDayOffsAvailable: "No day offs available",
        settingDayOff: "Setting DayOff",
        settingTimeLab: "Setting Time Lab",
        leaveRequestNumValidate: "Leave Request Num must be greater than 0",
        theDayOffDateAlreadyExists: "The day off date already exists.",
        CreatedSuccess: "Dayoff created successfully!",
        CreateFailse: "DayOff Create Failse",
        noDayOffFound: "No DayOff Found",
        deleteDayOffSuccess: "Delete DayOff Success",
        checkInTime: "Check-In Time",
        checkOutTime: "Check-Out Time",
      },
      currentSemester: {
        name: "Current Semester: {{name}}",
        period: "Start Date: {{startDate}} - End Date: {{endDate}}",
      },
      members: {
        internLabel: "Intern",
        mentorLabel: "Mentor",
        candidateLabel: "Candidate Intern",
        JuryPanelLabel: "Judy Panel",
        JuryPanelGroupLabel: "Judy Panel Groups",
        noInternFound: "No Intern Found",
        noMentorFound: "No Mentor Found",
        search: "Search",
        active:"Active",
        inactive :"Inactive",
        intern: {
          avatar: "Avatar",
          email: "Email Address",
          fullName: "Full Name",
          birthDate: "Birth Date",
          rollName: "Roll Name",
          mentor: "Mentor",
          semester: "Semester",
          status: "Status",
          allSemes: "All Semester",
        },
        mentor: {
          avatar: "Avatar",
          email: "Email Address",
          fullName: "Full Name",
          birthDate: "Birth Date",
          status: "Status",
          internNumb: "Number of Interns",
        },
        jury: {
          avatar: "Avatar",
          fullName: "Full Name",
          email: "Email Address",
          group: "Groups",
          status: "Status",
        },
        actionLabel: "Actions",
        searchKey: "Search by First Name, Last Name, Email",
        status: {
          all: "All Status",
          true: "Active",
          false: "Deactive",
        },
        actions: {
          dispel: "Dispel out of Lab",
          active: "Active",
          deactive: "Deactivate",
        },
        noJuryFound: "No Jury Panel Found.",
      },
      importCv: {
        maxSize: "File size exceeds 5MB limit. Please choose a smaller file.",
        onlyPdf: "Please select PDF file only.",
        intern: {
          rollName: "Roll Number",
          fullName: "Full Name",
          dob: "Date Of Birth",
          email: "Email Address",
          labName: "Lab Name",
          expiredAt: "Expired At",
        },
        cvImportSuccess: "CV Imported Successfully",
        overTimeToUploadCv: "Uploading CV time is over ({{time}})",
        invalidFileFormat: "Please select PDF file only.",
        noInternFound: "No Intern Found.",
        components: {
          importLabel: "Import CV (PDF)",
          importBtn: "Import CV",
          clearBtn: "Clear Current CV",
          internLabel: "Intern Information",
          preview: "CV Preview",
          noPreview: "No Preview",
        },
      },
    },
  },
  vi: {
    translation: {
      semesterWait: "Đang chờ",
      systemError: "Lỗi hệ thống xảy ra, xin vui lòng thử lại",
      emailIsInvalid: "Email sai hoặc không tồn tại",
      welcome: "Chào mừng",
      userDeactivated: "Người dùng không còn hoạt động trong hệ thống",
      language: "Ngôn ngữ",
      profile: "Thông tin người dùng",
      en: "Tiếng Anh",
      vi: "Tiếng Việt",
      system: "Ngôn ngữ hệ thống",
      email: "Địa chỉ email",
      fname: "Họ",
      lname: "Tên",
      loginSuccess: "Chào mừng quay trở lại",
      noUserFound: "Người dùng không tồn tại",
      validate: "Xác thực thất bại, vui lòng thử lại",
      tokenExpired: "Token đã hết hạn, vui lòng đăng nhập lại",
      langChange: "Đã đổi sang tiếng Việt",
      errors: {
        404: "Trang không tồn tại",
        401: "Bạn không được phép xem trang này, hãy đăng nhập để tiếp tục",
        403: "Bạn không có quyền xem trang này",
        required: "Bắt buộc",
        passNotMatch: "Mật khẩu mới và mật khẩu xác nhận không giống nhau",
        emailValidate: "Sai định dạng email",
        passwordRegex:
          "Mật khẩu cần có ít nhất 1 chữ thường, 1 chữ in hoa, 1 ký tự số, 1 ký tự đặc biệt và dài ít nhất 8 ký tự",
      },
      buttonUpdate: "Cập nhật",
      drawerItems: {
        dashboard: "Dashboard",
        home: "Trang chủ",
        event: "Sự kiện",
        report: "Báo cáo",
        ticket: "Phiếu hỗ trợ",
        okr: "Mục tiêu và kết quả",
        addOKR: "Thêm Mục tiêu",
        candidate: "Ứng viên",
        importIntern: "Nhập thực tập sinh",
        importLab: "Nhập Phòng Lab",
        importMentor: "Nhập Cố vấn",
        attendance: "Điểm danh",
        semester: "Kỳ học",
        dashboardLab: "Dashboard Quản lý",
        members: "Thành viên",
        copyright: `@${new Date().getFullYear()} Bản quyền thuộc về SEP490-G29 và DXLab`,
        ApproveImage: "Phê duyệt hình ảnh",
        Cleaning: "Trực nhật vệ sinh",
        import_member: "Thêm thành viên",
        CheckUser: "An ninh phòng lab",
        connectCamera: "Kết nối Camera",
      },
      userProfile: {
        receiveEmailNotify: "Gửi Thông Báo Qua Email",
        confirmPassword: "Xác nhận mật khẩu",
        newPassword: "Mật khẩu mới",
        fileUpload: "Thả hoặc nhấn vào để tải lên tệp",
        btnUpdate: "Cập nhật thông tin",
        btnSave: "Lưu thay đổi",
        btnCancel: "Hủy bỏ",
      },
      ipStrictError:
        "Bạn không có quyền để điểm danh. Hãy kiểm tra lại địa chỉ IP của bạn.",
      notification: "Thông báo",
      account: "Tài khoản",
      updateSuccess: "Cập nhật thành công",
      pageModule: {
        page: "Trang",
        pagePrev: "Trang trước",
        pageNext: "Trang sau",
      },
      otpSent: "Mã OTP đã được gửi, hãy kiểm tra email của bạn",
      checkInDone: "Điểm danh vào thành công",
      wrongIpMac:
        "Địa chỉ IP/Mac không hợp lệ, hãy liên hệ người quản lý phòng lab hoặc kiểm tra lại thiết bị/mạng của bạn",
      wrongFaceVector: "Vector khuôn mặt không hợp lệ",
      wrongOtp: "OTP không hợp lệ, hãy nhập lại hoặc gửi lại mã",
      noLabFound: "Phòng Lab không tồn tại",
      notInTime: "Hành động này không khả dụng ở hiện tại",
      checkInExisted: "Đã điểm danh vào, không thể điểm danh thêm",
      checkOutDone: "Điểm danh ra thành công",
      otpSent5min: "Mã OTP đã được gửi, hãy thử lại sau 5 phút",
      getOtp: "Lấy mã OTP",
      faceScan: "Quét khuôn mặt",
      otp: "OTP",
      approve: "Xác nhận",
      okrStatus: {
        1: "Chưa bắt đầu",
        2: "Đang thực hiện",
        3: "Hoàn Thành",
        4: "Mắc kẹt",
      },
      attendanceStatus: {
        1: "Đã điểm danh",
        2: "Vào muộn",
        3: "Ra sớm",
        4: "Vắng mặt",
      },
      importMember: {
        addMemberTitle: "Thêm Thành Viên",
        firstName: "Tên",
        lastName: "Họ",
        email: "Email",
        role: {
          title: "Vai Trò",
          roleMentor: "Cố Vấn",
          roleJuryPanel: "Hội Đồng Giám Khảo",
        },
        juryPanelGroupTitle: "Nhóm Hội Đồng Giám Khảo",
        optionNone: "Không",
        buttonAddMember: "Thêm Thành Viên",
      },

      importJuryPanel: {
        importJuryPanelCsvTitle: "Nhập Tệp CSV Hội Đồng Giám Khảo",
        downloadTemplate: "Tải mẫu CSV",
        csv: {
          errorParseCSV: "Không thể phân tích tệp CSV.",
          successfullyCSV: "",
          firstName: "Tên",
          lastName: "Họ",
          email: "Email",
          labName: "Tên Phòng Lab",
          errorMessage: "Lỗi",
          options: "Tùy Chọn",
        },
      },

      importMentor: {
        importMentorCsvTitle: "Thêm người hướng dẫn bằng file CSV",
        noRecordFound: "Không tìm thấy bản ghi",
        selectFile: "Hãy chọn một tập tin trước khi gửi",
        invalidFileFormat: "Sai định dạng tập tin, hãy chắc chắn là tệp .csv",
        rowsAreInvalid: "Dữ liệu sai, xin vui lòng kiểm tra bên dưới",
        invalidHeader: "Sai tên header, hãy kiểm tra lại tệp tin csv",
        importSuccess: "Tạo Mentor thành công",
        emailIsNull: "Trường Email đang bị trống",
        emailIsNotValid: "Email không hợp lệ hoặc không tồn tại",
        emailIsExistInDatabase: "Email đã tồn tại trong hệ thống",
        mentorAddSuccess: "Thêm Cố vấn thành công",
        headerException: "Đầu tiêu đề csv bị sai, vui lòng thử lại",
      },
      importIntern: {
        noRecordFound: "Không tìm thấy bản ghi",
        emailNotExist: "Emali không tồn tại",
        rollNumberIsExisted: "Mã số sinh viễn đã tồn tại",
        selectFileToImport: "Hãy chọn một tập tin trước khi gửi",
        invalidFileFormat: "Sai định dạng tập tin, hãy chắc chắn là tệp .csv",
        rowsAreInvalid: "Dữ liệu sai, xin vui lòng kiểm tra bên dưới",
        invalidHeader: "Sai tên header, hãy kiểm tra lại tệp tin csv",
        uploadAndSendMailSuccess: "Đã thêm ứng viên và gửi mail thành công",
        failedToLoad: "Tải thất bại, vui lòng thử lại",
        labNameNotExist: "Tên lab không tồn tại",
        rollNumberIsNull: "Mã số sinh viên bị trống",
        dobIsNull: "Ngày sinh bị trống",
        emailIsNull: "Trường Email bị trống",
        invalidEmailFormat: "Email không hợp lệ hoặc không tồn tại",
        emailIsExistInDataBase: "Email đã tồn tại trong hệ thống",
        labNameIsNull: "Tên lab bị trống",
        importFailed: "Nhập dữ liệu thất bại",
        status: {
          true: "Hoàn thành",
          false: "Thất bại",
        },
        headerException: "Đầu tiêu đề csv bị sai, vui lòng thử lại",
      },
      importLab: {
        noRecordFound: "Không tìm thấy bản ghi",
        invalidLeaveRequestNumb:
          "Số lượt nghỉ phép phải là số dương và lớn hơn 0",
        selectFileToImport: "Hãy chọn một tập tin trước khi gửi",
        invalidFileFormat: "Sai định dạng tập tin, hãy chắc chắn là tệp .csv",
        rowsAreInvalid: "Dữ liệu sai, xin vui lòng kiểm tra bên dưới",
        invalidHeader: "Sai tên header, hãy kiểm tra lại tệp tin csv",
        failedToLoad: "Tải thất bại, vui lòng thử lại",
        labNameIsExist: "Tên phòng nghiêm cứu đã tồn tại",
        userExisted: "Người dùng đã tồn tại",
        labManagerEmailIsNull: "Email Quản lý phòng nghiêm cứu trống",
        uploadSuccess: "Tải lên thành công",
        labManagerEmailIsInvalid: "Email sai định dạng hoặc không tồn tại",
        labNameIsNull: "Tên phòng nghiêm cứu trống",
        importFailed: "Nhập thất bại",
        headerException: "Đầu tiêu đề csv bị sai, vui lòng thử lại",
      },
      candidate: {
        confirmMultiple:
          "Bạn có chắc chắn muốn phê duyệt/từ chối danh sách ứng viên thực tập này không? Hành động này không thể được hoàn tác.",
        multiSelect:
          "Chọn từng ô/chọn tất cả để có thể chấp thuận/từ chối một loạt ứng viên",
        mustHaveData: "Bắt buộc có ít nhất một ứng viên được chọn để tiếp tục",
        noSemesterFoundFuture:
          "Không có kỳ học ở hiện tại và tương lai, không thể tạo tài khoản",
        updateSuccess: "Cập nhật thành công",
        assignSuccess: "Bàn giao thành công",
        failedToCreateIdentityUser:
          "Tạo thực tập sinh thất bại, vui lòng kiểm tra lại dữ liệu",
        notFound: "Không tìm thấy tài nguyên",
        noUserFound: "Không tìm thấy người dùng",
        candidateWaiting: "Ứng viên tạm thời đang chờ phê duyệt",
        candidateAssign: "Ứng viên tạm thời đang chờ được bàn giao",
        table: {
          firstName: "Họ",
          lastName: "Tên",
          email: "Địa chỉ Email",
          actions: "Hành động",
          assignTo: "Bàn giao tới",
          noCandidate: "Không tìm thấy ứng viên",
          candidateCv: "CV của ứng viên",
          approve: "Chấp thuận",
          reject: "Từ chối",
          labName: "Tên Lab",
          showCv: "Xem CV",
        },
        search: "Tìm kiếm bằng họ tên và email",
        cvNotFound: "Không tìm thấy CV",
      },
      noNotificationFound: "Không có thông báo",
      notFoundInformation: "Không tìm thấy thông tin",
      okr: {
        okr: "Các mục tiêu và kết quả then chốt",
        main_Table: "Màn hình chính",
        timeline: "Mốc thời gian",
        dashboard: "Bảng biểu đồ",
        log_Dialog: "Nhật ký",
        team_Dialog: "Quản lý nhóm",
        add_O_Button: "Thêm mục tiêu",
        add_kr_Button: "kết quả mới",
        search: "Tìm kiếm",
        filter_Priority: "Lọc theo độ quan trọng",
        filter_1: "Toàn bộ",
        filter_2: "Cao",
        filter_3: "Trung bình",
        filter_4: "Thấp",
        sort_By: "Sắp xếp theo",
        sort_1: "Tiêu đề",
        sort_2: "Độ quan trọng",
        sort_3: "Trạng thái",
        title: "Tiêu Đề",
        assign_to: "Giao cho",
        assign_to_def: "Chưa giao",
        priority: "Độ quan trọng",
        progress: "Tiến độ",
        estimate_Man_Day: "Số ngày dự kiến",
        actual_Man_Day: "Số ngày thực tế",
        difference: "Khác biệt giữa dự kiến và thực tế",
        notes: "Ghi chú",
        attached_Files: "Tệp đính kèm (links)",
        no_Commnet: "Hiện chưa có bình luận.",
        comments: "Bình luận",
        reply: "Trả lời",
        comment_Error: "Nội dung của bình luận không thể trống.",
        reply_Error: "Nội dung của trả lời không thể trống.",
        actions: "Hành động",
        save: "Lưu",
        update: "Cập nhật",
        cancel: "Hủy",
        delete: "Xóa",
        description: "Miêu tả",
        start_Date: "Ngày bắt đầu",
        end_Date: "Ngày kết thúc",
        status: "Trạng thái",
        approved: "Chấp thuận",
        error_title: "Tiêu đề là bắt buộc",
        error_description: "Miêu tả là bắt buộc",
        error_startDate: "Ngày bắt đầu là bắt buộc",
        error_endDate: "Ngày kết thúc là bắt buộc",
        error_ManDay: "Nên là số dương",
        error_endBeforeStart: "Ngày bắt đầu nên trước ngày kết thúc",
        error_startDateInPast: "Ngày bắt đầu không thể ở quá khứ",
        error_endDateInPast: "Ngày kết thúc không thể ở quá khứ",
        errordateInPast: "Thời gian không thể ở quá khứ",
        rf_con: "Bạn có muốn làm mới",
        del_con: "Bạn có muốn xóa",
        t_search: "Tìm kiếm bằng tên hoặc email",
        t_title: "Mời thành viên",
        t_invite: "Mời thành viên bằng email",
        t_b_invite: "Mời",
        t_b_close: "Đóng",
        l_title: "Nhật ký hoạt động",
        l_default: "Hiện chưa có nhật ký nào",
        ch_kr: "Tiến độ của kết quả",
        ch_o: "Tiến độ của mục tiêu",
        ch_o_1: "Mục tiêu",
        ch_o_2: "Kết quả (%)",
        ch_pd: "Phân phối của độ quan trọng",
        ch_c_t: "Giá trị hiện tại so với giá trị mục tiêu",
        ch_pp: "Hiệu suất của tiến độ",
        ch_pp_1: "Ngày báo cáo",
        ch_pp_2: "Giá trị",
        select_team: "Chọn nhóm",
        tl_Duration: "Khoảng thời gian",
        toast_add_o_s: "Thêm mục tiêu thành công!",
        toast_add_o_f: "Thêm mục tiêu thất bại.",
        toast_up_o_s: "Cập nhật mục tiêu thành công!",
        toast_up_o_f: "Cập nhật mục tiêu thất bại.",
        toast_del_o_s: "Xóa mục tiêu thành công!",
        toast_del_o_f: "Xóa mục tiêu thất bại.",
        toast_add_kr_s: "Thêm kết quả thành công!",
        toast_add_kr_f: "Thêm kết quả thất bại.",
        toast_up_kr_s: "Cập nhật kết quả thành công!",
        toast_del_kr_s: "Xóa kết quả thành công!",
        toast_save_kr_f: "Lưu kết quả thất bại",
        toast_del_kr_f: "Xóa kết quả thất bại",
        toast_add_kr_wr:
          "Bạn chỉ có thể thêm kết quả cho mục tiêu đã chấp thuận",
        assign_default: "Không phân công",
        toast_approve_obj_failed: "Chấp thuận mục tiêu thất bại",
        toast_approve_obj_succeed: "Chấp thuận mục tiêu thành công",
        toast_reject_obj_failed: "Từ chối mục tiêu thất bại",
        toast_reject_obj_succeed: "Từ chối mục tiêu thành công",
        toast_not_found: "Mục tiêu không tồn tại",
        toast_add_f:"Thêm mới mục tiêu không thành công"
      },
      event: {
        eventsNotFound: "Không tìm thấy sự kiện",
        attendance: "Tham dự",
        delete: "Xóa",
        take_Attendance: "Điểm danh",
        save: "Lưu",
        cancel: "Hủy",
        edit: "Cập nhật",
        close: "Đóng",
        update_Event: "Cập nhật sự kiện",
        event_Details: "Thông tin sự kiện",
        add_Event: "Thêm sự kiện",
        select_Users: "Lựa chọn người tham gia",
        roll: "Mã sinh viên",
        name: "Tên",
        avatar: "Hình đại diện",
        e_Attendance_Status1: "Tham dự",
        e_Attendance_Status2: "Muộn",
        e_Attendance_Status3: "Nghỉ",
        title: "Tiêu đề sự kiện",
        description: "Miêu tả",
        start_Date: "Ngày bắt đầu",
        end_Date: "Ngày kết thúc",
        start_Time: "Thời gian bắt đầu",
        location: "Địa điểm",
        end_Time: "Thời gian kết thúc",
        start: "Bắt đầu vào",
        end: "Kết thúc lúc",
        toast_NotFound: "Không tìm thấy",
        toast_Event_add_s: "Thêm sự kiện thành công!",
        toast_Event_add_f: "Thêm sự kiện thất bại.",
        toast_Event_up_s: "Cập nhật sự kiện thành công!",
        toast_Event_up_f: "Cập nhật sự kiện thất bại!",
        toast_Event_del_s: "Xóa sự kiện thành công.",
        toast_Event_attendance_s: "Điểm danh thành công!",
        toast_Event_attendance_f: "Điểm danh thất bại",
        toast_Event_datevalidate:
          "Thời gian bắt đầu nên sớm hơn thời gian kết thúc",
        toast_Event_past_date_error: "Sự kiện nên bắt đầu sau hiện tại",
      },
      report: {
        internDetail: {
          title: "Thông tin Thực tập sinh",
          name: "Họ và tên",
          email: "Địa chỉ Email",
          lab: "Phòng Lab",
          mentor: "Cố vấn",
          rollName: "MSSV",
        },
        failed: "Thất bại",
        addReport: "Thêm báo cáo",
        delReport: "Xóa báo cáo",
        editReport: "Sửa báo cáo",
        semesterEnded: "Kỳ học đã kết thúc",
        commentOfCompany: "Nhận xét của Cố vấn",
        majorSkill: "Kỹ năng chuyên ngành",
        softSkill: "Kỹ năng mềm",
        attitude: "Thái độ",
        back: "Trở về",
        keyResultsOverview: "Tổng quan về kết quả chính",
        attendanceOverview: "Tổng quan về điểm danh",
        toggleChartType: "Chuyển đổi loại biểu đồ",
        attended: "Có mặt",
        absent: "Vắng mặt",
        lateIn: "Vào muộn",
        soonOut: "Về sớm",
        inProgress: "Đang tiến hành",
        completed: "Hoàn thành",
        notStarted: "Chưa bắt đầu",
      },
      reportV: {
        listReport: "Báo Cáo",
        reportTitle: "Loại Báo Cáo",
        reportType: {
          1: "Báo Cáo Giữa Kỳ",
          2: "Báo Cáo Cuối Kỳ",
        },
        updateSemester: "Cập nhật kỳ học thành công",
        listOfLab: "Danh sách phòng lab",
        labName: "Tên phòng lab",
        listOfSemesters: "Danh sách học kỳ",
        semesterID: "ID học kỳ",
        semesterName: "Tên học kỳ",
        addSemester: "Thêm học kỳ",
        sd: "Ngày bắt đầu",
        ed: "Ngày kết thúc",
        ac: "Hành động",
        viewIntern: "Xem thực tập sinh",
        internsforSemester: "Thực tập sinh trong học kỳ",
        rollName: "Mã sinh viên",
        email: "Địa chỉ Email",
        nameI: "Tên thực tập sinh",
        noLab: "Không tìm thấy lab",
        noM: "Không tìm thấy Cố vấn",
        noi: "Không tìm thấy thực tập sinh nào trong học kỳ này.",
        close: "Đóng",
        exporttoExcel: "Xuất sang Excel cuối kỳ",
        exporttoExcel1: "Xuất sang Excel giữa kỳ",
        searchKey: "Tìm kiếm theo mã sinh viên, email và mã nhân viên",
        exportSuccess: "Xuất báo cáo thành công",
        search: "Tìm kiếm",
        rID: "ID báo cáo",
        sID: "ID nhân viên",
        lName: "Tên phòng nghiên cứu",
        mName: "Tên cố vấn",
        allowance: "Trợ cấp",
        finalResult: "Điểm Tổng Kết",
        delete: "Xóa",
        dashBoard: "Tóm tắt hiệu suất",
        internsList: "Danh Sách thực tập sinh",
        totalObjectives: "Tổng số mục tiêu",
        createdDate: "Ngày tạo",
        excelNotFound: "Không tìm thấy báo cáo",
        sExcel400: "Có lỗi khi tạo tệp Excel. Vui lòng thử lại.",
        btnAdd: "Lưu",
        lstMentor: "Danh sách Cố vấn",
        nameM: "Tên Cố vấn",
        sUnex: "Cấu trúc phản hồi không mong đợi.",
        sDelete: "Bạn có chắc chắn muốn xóa báo cáo này không?",
        sDeleteSuc: "Báo cáo đã được xóa thành công.",
        sSearch: "Không tìm thấy báo cáo nào cho mã sinh viên đã cho.",
        aReportSuc: "Báo cáo đã được thêm thành công!",
        aInvail: "Dữ liệu báo cáo không hợp lệ.",
        errors1: {
          400: "Yêu cầu không hợp lệ. Vui lòng kiểm tra dữ liệu đầu vào.",
          409: "Không tìm thấy thực tập sinh nào cho học kỳ này.",
        },
        errors: {
          400: "Thông tin báo cáo là bắt buộc.",
          409: "Một báo cáo cho giai đoạn này đã tồn tại.",
          422: "Dữ liệu báo cáo không hợp lệ.",
          500: "Học kỳ đã kết thúc. Không thể thêm báo cáo sau thời gian học kỳ.",
        },
        unexpectedError:
          "Đã xảy ra lỗi bất ngờ. Vui lòng kiểm tra kết nối của bạn.",
        reportAddTime:
          "Báo cáo chỉ có thể được thêm vào giữa {{startMidterm}} và {{endMidterm}}, hoặc giữa {{startEnd}} và {{endEnd}}.",
        edit: "Chỉnh sửa",
      },
      createObjective: {
        createObjective: "Tạo Mục tiêu",
        typeofObjective: "Các loại Mục tiêu",
        targetObjective: "Mục tiêu cần đạt *",
        description: "Mô tả",
        registrationStart: "Ngày bắt đầu đăng ký *",
        registrationEnd: "Ngày kết thúc đăng ký *",
        startDate: "Ngày bắt đầu *",
        milestoneDate: "Ngày cột mốc tính điểm bonus",
        reportStartDate: "Ngày bắt đầu báo cáo *",
        reportEndDate: "Ngày kết thúc báo cáo *",
        endDate: "Ngày kết thúc *",
        gradeStartTime: "Thời gian bắt đầu chấm điểm *",
        gradeEndTime: "Thời gian kết thúc chấm điểm *",
        addKeyresult: "Thêm Kết quả chính mới",
        superiorObjectiveInputLabel: "Mục tiêu Cấp trên",
        juryPanelInputLabel: "Ban giám khảo",
        juryPanelOptions: "Ban giám khảo",
        keyNavBar: "Kết quả chính",
        keyResultTitle: "Tiêu đề Kết quả chính *",
        keyResultTarget: "Mục tiêu Kết quả chính *",
        keyResultUnit: "Đơn vị Kết quả chính *",
        keyResultStartDate: "Ngày bắt đầu Kết quả chính *",
        keyResultEndDate: "Ngày kết thúc Kết quả chính *",
        keyResultTooltip: "Liên kết đến kết quả của bạn",
        keyResultPlanLink: "Kế hoạch liên kết kết quả này",
        keyResultResultLink: "Kết quả liên kết kết quả này",
        keyresultRelated: "Kết quả chính liên quan",
        buttonCancenl: "Hủy bỏ",
        buttonSave: "Lưu",
        buttonYes: "Có",
        buttonNo: "Không",
        buttonDelete: "Xóa",
        buttonConfirnt: "Xác nhận",
        confirmDelete: "Xác nhận xóa kết quả chính",
        confirmDeleteContent:
          "Bạn có chắc chắn muốn xóa kết quả chính này không?",
        validateTargetObjective: "Mục tiêu cần đạt không được để trống",
        validateRegistrationStart: "Ngày bắt đầu đăng ký không được để trống",
        validateRegistrationStart_2:
          "Ngày bắt đầu đăng ký sau thời điểm hiện tại",
        validateRegistrationEnd: "Ngày kết thúc đăng ký không được để trống",

        validateRegistrationEnd_2:
          "Ngày kết thúc đăng ký phải sau ngày bắt đầu đăng ký",
        validateRegistrationEnd_3: "Ngày kết thúc phải sau ngày bắt đầu",
        validateRegistrationEnd_4:
          "Ngày kết thúc đăng ký phải sau ngày bắt đầu đăng ký",
        validateStartDate_1: "Ngày bắt đầu phải sau ngày kết thúc đăng ký",

        validateStartDate_2: "Ngày bắt đầu phải sau thời gian hiện tại",
        validateStartDate_3: "Ngày bắt đầu phải sau ngày kết thúc đăng ký",

        validateStartDate_4: "Ngày bắt đầu không được để trống",
        validateEndDate_1: "Ngày kết thúc không được để trống",
        validateMilestoneDate: "Ngày mốc không được để trống",
        validateReportStartDate_1:
          "Ngày bắt đầu báo cáo phải sau ngày kết thúc",
        validateReportEndDate_1: "Ngày kết thúc báo cáo phải sau ngày kết thúc",
        validateReportStart: "Ngày bắt đầu báo cáo không được để trống",
        validateReportEnd: "Ngày kết thúc báo cáo không được để trống",
        validateGradeStartTime:
          "Thời gian bắt đầu chấm điểm không được để trống",
        validateGradeEndTime:
          "Thời gian kết thúc chấm điểm không được để trống",
        validateGradeStartTime_1:
          "Thời gian bắt đầu chấm điểm không được sau thời gian kết thúc báo cáo",
        validateGradeEndTime_1:
          "Thời gian kết thúc chấm điểm không được sau thời gian kết thúc báo cáo",
          validateReportEndDate_2:"Thời gian kết thúc báo cáo phải sau thời gian bắt đầu báo cáo",
          validateGradeEndTime_2:"Thời gian kết thúc chấm điểm phải sau thời gian bắt đầu chấm điểm",
        validateKeyresultStartDate_1_1: "Ngày bắt đầu của Kết quả chính",
        validateKeyresultStartDate_1_2: "phải sau ngày bắt đầu của Mục tiêu",
        validateKeyresultEndDate_1_1: "Ngày kết thúc của Kết quả chính",
        validateKeyresultEndDate_1_2: "phải sau ngày bắt đầu của Kết quả chính",
        validateKeyresultTitle_1: "Tiêu đề của kết quả chính",
        validateKeyresultTarget_1: "Mục tiêu của kết quả chính",
        validateKeyresultTargetDecimal: "Target là kiểu số thập phân",
        validateKeyresultUnit_1: "Tiêu đề của kết quả chính",
        validateLinkError: "Sai đường dẫn(VD: https://www.example.com)",
        validateKeyresultStartDate_1: "Ngày bắt đầu của kết quả chính",
        validateKeyresultEndDate_1: "Ngày kết thúc của kết quả chính",
        validateIsRequied: "là bắt buộc",
        selectObjectiveTypeError: "Loại Mục tiêu không được để trống",
        optionNone: "Không liên kết với bất cứ ai",
        maxLengthError: "Vượt quá giới hạn 100 ký tự",
        maxLengthDescription: "Vượt quá giới hạn 500 ký tự",
        confirmCancel: "Khi bạn ấn xác nhận thì tạo mới mục tiêu sẽ bị hủy bỏ",
        confirmCancelTitle: "Xác nhận hủy bỏ",
        requiredSuperiorObjective:
          "Liên kết đến mục tiêu cấp trên không được để trống",
        requiredJuryPanelGroup:"Nhóm ban giám khảo không được để trống",
        noSuperiorObjectiveYet:"Chưa có mục tiêu cấp trên",
        validateMilestone_1:"Milestone không được bằng ngày bắt đầu",
        validateMilestone_2:"Milestone không được bằng ngày kết thúc"
      },
      attendance: {
        date: "Ngày điểm danh: {{date}}",
        updateAttendance: "Cập nhật thực tập sinh điểm danh",
        invalidTime: "Điều chỉnh giờ sai",
        cleanBatch: "Đã thiết lập lại bản ghi điểm danh ngày hôm nay",
        checkIn: "Điểm danh vào",
        checkOut: "Điểm danh ra",
        test: "Lỗi khi lấy dữ liệu điểm danh",
        updateSuccess: "Cập nhật thành công",
        updateFailse: "Cập nhật lỗi",
        validateTimeCheckInCheckOut:
          "Thời gian check-in cần sớm hơn thời gian check-out",
        pageNotExist: "Trang không tồn tại",
        noUserFound: "Không tìm thấy user",
        notFoundInformation: "Không thấy thông tin",
        otpSent5min: "OTP đã được gửi, có hiệu lực trong 5 phút.",
        wrongIpMac: "Địa chỉ IP hoặc MAC không đúng.",
        otpSent: "Otp đã được gửi",
        wrongFaceVector: "Dữ liệu vector khuôn mặt không chính xác.",
        wrongOtp: "Mã OTP không chính xác.",
        noLabFound: "Không tìm thấy phòng lab",
        checkInDone: "Đã hoàn thành Check-In.",
        notInTime: "Không đúng giờ.",
        checkInExisted: "Đã check-in rồi.",
        checkOutDone: "Đã check-out xong.",
        notCheckInYet: "Chưa check-in.",
        statusBatchNullOrEmpty: "Trạng thái batch không hợp lệ hoặc trống.",
        batchEnd: "Thành công, batch điểm danh đã được tắt.",
        successBatch: "Thành công, batch điểm danh đã được bật.",
        checkBatch:
          "Batch điểm danh đã dừng. Chúc bạn kỳ nghỉ lễ/cuối tuần vui vẻ!",
        rollName: "Mã sinh viên",
        email: "Email",
        name: "Tên",
        absent: "Vắng mặt",
        attended: "Có mặt",
        lateIn: "Đến muộn",
        soonOut: "Về sớm",
        actions: "Hành động",
        search: "Tìm kiếm",
        month: "Tháng",
        year: "Năm",
        otAttendance: "Mở đơn xin điểm danh",
        c_t_Attendance: "Tạo đơn xin điểm danh",
        attendanceList: "Danh sách điểm danh",
        noRecordsFound: "Không tìm thấy bản ghi nào",
      },
      ticket: {
        statusText: {
          1: "Đang chờ",
          2: "Chấp thuận",
          3: "Đang trong quá trình",
          4: "Hoàn thành",
          5: "Từ chối",
          all: "Tất cả",
        },
        createSuccessTicket: "Tạo phiếu hỗ trợ thành công",
        ticketNotFound: "Không tìm thấy phiếu hỗ trợ",
        titleAndDateCannotBeBlank: "Tiêu đề và Ngày không được để trống.",
        validateDate: "Vui lòng chọn ngày hợp lệ (hôm nay hoặc muộn hơn)",
        titleIsRequired: "Tiêu đề là bắt buộc",
        ticketList: "Danh sách ticket",
        confirmDelete: "Xác nhận xóa",
        toDeleteThisTicket: "Bạn có chắc rằng bạn muốn xóa ticket này không?",
        delete: "Xóa",
        edit: "Sửa",
        saveChanges: "Lưu",
        ticketDetails: "Chi tiết ticket",
        ticketComments: "Bình luận",
        comment: "Bình luận",
        noDescription: "Không có bình luận",
        cancel: "Hủy",
        create: "Tạo",
        createTicketAttendance: "Tạo ticket điểm danh",
        createTicket: "Tạo ticket",
        createDate: "Ngày tạo",
        details: "Chi tiết",
        approve: "Chấp nhận",
        reject: "Từ chối",
        listAttendanceTicket: "Danh sách ticket điểm danh",
        iD: "Mã Ticket",
        creator: "Người tạo",
        assignee: "Người nhận",
        title: "Tiêu đề",
        description: "Nội dung",
        dateRequire: "Ngày yêu cầu",
        status: "Trạng thái",
        actions: "Hành động",
        date: "Ngày",
        checkInTime: "Thời gian Check-In",
        checkOutTime: "Thời gian Check-Out",
        reason: "Lý do",
        needLoginFirst: "Cần đăng nhập trước!",
        checkPermissionToView: "Bạn không có thẩm quyền xem thông tin yêu cầu.",
        notFoundCommentTicket: "Không tìm thấy bình luận về yêu cầu.",
        updateTicketSuccessfully: "Cập nhật yêu cầu thành công!",
        descriptionEmpty: "Mô tả trống",
        commentSuccess: "Bình luận thành công",
        spamComment: "Bình luận bị đánh dấu là spam",
        inputEmpty: "Dữ liệu đầu vào trống",
        approveTicketSuccessfully: "Phê duyệt yêu cầu thành công",
        rejectTicketSuccessfully: "Từ chối vé thành công",
        dontHaveLabInfor: "Người dùng chưa có thông tin phòng lab",
        createSuccessTicketAttendance: "Tạo thành công phiếu điểm danh.",
        createFaileTicketAttendance:
          "Không thể tạo phiếu điểm danh! Vui lòng thử lại.",
        dontHaveMentor: "Người dùng không có mentor.",
        duplicateTicket: "Vé bị trùng lặp.",
        notFoundLabManager: "Không tìm thấy Quản lý phòng thí nghiệm.",
        createTicketFailse: "Tạo vé không thành công!",
        updateTicketFailse: "Sửa vé không thành công",
        success: "Thành công",
        failseAPI: "Lỗi khi tải ticket",
        notFoundTickets: "Không có ticket nào.",
        close: "Đóng",
      },
      settingLab: {
        timeRequired: "Thời gian yêu cầu",
        nothingChange: "Không có gì thay đổi",
        cancel: "Hủy",
        save: "Lưu",
        leaveRequestNum: "Số buổi nghỉ",
        noDayOffsAvailable: "Không có ngày nghỉ nào",
        settingDayOff: "Cài đặt ngày nghỉ",
        settingTimeLab: "Cài đặt thời gian phòng Lab",
        leaveRequestNumValidate: "Số lượng yêu cầu nghỉ phải lớn hơn 0.",
        theDayOffDateAlreadyExists: "Ngày nghỉ đã tồn tại.",
        CreatedSuccess: "Ngày nghỉ đã được tạo thành công!",
        CreateFailse: "Tạo ngày nghỉ thất bại!",
        noDayOffFound: "Không tìm thấy ngày nghỉ.",
        deleteDayOffSuccess: "Xóa ngày nghỉ thành công.",
        checkInTime: "Thời gian Check-in",
        checkOutTime: "Thời gian Check-out",
      },
      currentSemester: {
        name: "Kỳ hiện tại: {{name}}",
        period: "Bắt Đầu: {{startDate}} - Kết thúc: {{endDate}}",
      },
      members: {
        internLabel: "Thực tập sinh",
        mentorLabel: "Cố vấn",
        candidateLabel: "Ứng viên",
        JuryPanelLabel: "Ban giám khảo",
        JuryPanelGroupLabel: "Nhóm ban giám khảo",
        noInternFound: "Không có thực tập sinh",
        noMentorFound: "Không có thực tập sinh",
        noJuryFound: "Không có ban giám khảo.",
        search: "Tìm",
        active:"Kích hoạt",
        inactive :"Không Kích hoạt",
        intern: {
          avatar: "Ảnh đại diện",
          email: "Địa chỉ email",
          fullName: "Họ và tên",
          birthDate: "Ngày sinh",
          rollName: "MSSV",
          mentor: "Cố vấn",
          semester: "Kỳ Học",
          status: "Trạng thái",
          allSemes: "Tất cả các kỳ",
        },
        jury: {
          avatar: "Ảnh đại diện",
          fullName: "Họ và tên",
          email: "Địa chỉ email",
          group: "Nhóm",
          status: "Trạng thái",
        },
        mentor: {
          avatar: "Ảnh đại diện",
          email: "Địa chỉ email",
          fullName: "Họ và tên",
          birthDate: "Ngày sinh",
          status: "Trạng thái",
          internNumb: "Số lượng thực tập sinh",
        },
        actionLabel: "Hành động",
        searchKey: "Tìm kiếm bằng họ, tên và email",
        status: {
          all: "Tất cả trạng thái",
          true: "Hoạt động",
          false: "Ngừng hoạt động",
        },
        actions: {
          dispel: "Trục xuất khỏi lab",
          active: "Hoạt động",
          deactive: "Ngừng hoạt động",
        },
      },
      importCv: {
        maxSize:
          "Kích thước tệp bị giới hạn là 5MB. Hãy chọn tệp khác nhỏ hơn.",
        onlyPdf: "Chỉ được chọn tệp định dạng PDF.",
        intern: {
          rollName: "MSSV",
          fullName: "Họ và Tên",
          dob: "Ngày Sinh",
          email: "Địa chỉ Email",
          labName: "Tên Lab",
          expiredAt: "Thời hạn",
        },
        cvImportSuccess: "Nhập CV thành công",
        overTimeToUploadCv: "Thời gian tải lên CV đã kết thúc ({{time}})",
        invalidFileFormat: "Chỉ được chọn tệp định dạng PDF.",
        noInternFound: "Không có thực tập sinh nào được tìm thấy",
        components: {
          importLabel: "Nhập CV (PDF)",
          importBtn: "Nhập CV",
          clearBtn: "Xóa CV Hiện tại",
          internLabel: "Thông tin thực tập sinh",
          preview: "Xem trước CV",
          noPreview: "Không có bản xem trước",
        },
      },
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
