import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Chip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close"; // Import biểu tượng Close
import { toast } from "react-toastify";
import apiAuth from "../../customs/axiosCustom";
import { useAuth } from "../../customs/authService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const DropdownSettings = ({ labInfo = {}, setLabInfo }) => {
  const { t } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDayOffDialog, setOpenDayOffDialog] = useState(false);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [leaveRequestNum, setLeaveRequestNum] = useState("");
  const [dayOffDate, setDayOffDate] = useState("");
  const [dayOffReason, setDayOffReason] = useState("");
  const [dayOffs, setDayOffs] = useState([]);
  const [jobSchedules, setJobSchedules] = useState([]);
  const [openJobAutomationDialog, setOpenJobAutomationDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const [editedJobs, setEditedJobs] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setAnchorEl(null);
    setCheckInTime(labInfo?.checkInTime);
    setCheckOutTime(labInfo?.checkOutTime);
    setLeaveRequestNum(labInfo?.leaveRequestNum);
  };

  const handleDayOffDialogOpen = () => {
    fetchDayOffs(); // Fetch day offs when opening dialog
    setOpenDayOffDialog(true);
    setAnchorEl(null);
  };

  const handleDayOffDialogClose = () => {
    setOpenDayOffDialog(false);
    setDayOffDate("");
    setDayOffReason("");
  };

  const fetchDayOffs = async () => {
    try {
      const response = await apiAuth.get("/DayOff/getDayOff");
      setDayOffs(response.data || []);
    } catch (error) {
      console.error("Error fetching day offs:", error);
      toast.error("Error fetching day offs");
    }
  };

  const handleSaveTime = async () => {
    if (!checkInTime || !checkOutTime) {
      toast.error(t("settingLab.timeRequired"));
      return;
    }

    if (checkInTime >= checkOutTime) {
      toast.error(t("attendance.validateTimeCheckInCheckOut"));
      return;
    }

    if (leaveRequestNum <= 0) {
      toast.error(t("settingLab.leaveRequestNumValidate"));
      return;
    }

    try {
      const response = await apiAuth.put(
        "/Attendance/updateTime",
        {
          checkInTime: checkInTime,
          checkOutTime: checkOutTime,
          leaveRequestNum: leaveRequestNum,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success(t(response.data));
        setOpenDialog(false);
        setLabInfo({
          checkInTime: checkInTime,
          checkOutTime: checkOutTime,
          leaveRequestNum: leaveRequestNum
        })
      } else {
        toast.error(t(response.data.message));
      }
    } catch (error) {
      console.error("Error updating time and leave request num:", error);
      toast.error(t(error.response?.data?.message));
    }
  };

  const handleSaveDayOff = async () => {
    // Kiểm tra xem ngày nghỉ có bị trùng không
    const isDateExists = dayOffs.some(
      (dayOff) =>
        new Date(dayOff.date).toLocaleDateString() ===
        new Date(dayOffDate).toLocaleDateString()
    );

    if (isDateExists) {
      toast.error(t("settingLab.theDayOffDateAlreadyExists"));
      return;
    }

    try {
      const token = localStorage.getItem("token");

      const response = await apiAuth.post(
        `/DayOff/createDayOff/${dayOffDate}`,
        {
          labId: 1, // Bạn có thể thay đổi giá trị này tùy theo yêu cầu của API
          year: new Date(dayOffDate).getFullYear(), // Bạn có thể thay đổi giá trị này tùy theo yêu cầu của API
          laboratory: dayOffReason, // Sử dụng reason làm trường laboratory, nếu cần thay đổi thì cần điều chỉnh API
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(t(response.data));
        fetchDayOffs(); // Refresh the list after saving
        // Không gọi handleDayOffDialogClose để giữ modal mở
      } else {
        toast.error(t(response.data));
      }
    } catch (error) {
      console.error("Error creating day off:", error);
      toast.error(t(error.response.data));
    }
  };

  const handleDeleteDayOff = async (id) => {
    try {
      const response = await apiAuth.delete(`/DayOff/deleteDayOff/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        toast.success(t(response.data));
        fetchDayOffs(); // Refresh the list after deleting
      } else {
        toast.error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error(
        "Error deleting day off:",
        error.response ? error.response.data : error.message
      );
      toast.error(t(error.response.data));
    }
  };

  const handleSettingClick = (settingType) => {
    if (settingType === "Time Lab") {
      handleDialogOpen();
    } else if (settingType === "DayOff") {
      handleDayOffDialogOpen();
    } else if (settingType === "Job Automation") {
      fetchJobSchedules();
    }
  };

  const fetchJobSchedules = async () => {
    try {
      const response = await apiAuth.get("/JobScheduleAutomation/jobs");
      setJobSchedules(response.data || []);
      setOpenJobAutomationDialog(true);
      setAnchorEl(null);
    } catch (error) {
      console.error("Error fetching job schedules:", error);
      toast.error("Error fetching job schedules.");
    }
  };

  const handleJobAutomationDialogClose = () => {
    setOpenJobAutomationDialog(false);
  };

  const handleTimeChange = (jobId, time) => {
    if (!time) return;

    const hours = String(time.getHours()).padStart(2, "0");
    const minutes = String(time.getMinutes()).padStart(2, "0");
    const cronExpression = `0 ${minutes} ${hours} * * ?`;

    setEditedJobs((prev) => ({
      ...prev,
      [jobId]: cronExpression,
    }));

    // Reset lỗi nếu cron hợp lệ
    setErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[jobId];
      return updatedErrors;
    });
  };

  const saveCronExpression = async (jobId) => {
    const newCronExpression = editedJobs[jobId];
    if (!newCronExpression) {
      toast.error("Please select a valid time.");
      return;
    }
    try {
      const response = await apiAuth.put(`/JobScheduleAutomation/jobs/${jobId}`, {
        CronExpression: newCronExpression,
      });

      if (response.status === 200) {
        toast.success("Job updated successfully");
        setEditedJobs((prev) => {
          const updated = { ...prev };
          delete updated[jobId];
          return updated;
        });
        setJobSchedules((prev) =>
          prev.map((job) =>
            job.id === jobId ? { ...job, cronExpression: newCronExpression } : job
          )
        );
      } else {
        toast.error("Failed to update job schedule.");
      }
    } catch (error) {
      console.error("Error updating job schedule:", error);
      toast.error("Error updating job schedule.");
    }
  };

  const parseCronToTime = (cronExpression) => {
    const parts = cronExpression.split(" ");
    const hours = parseInt(parts[2], 10);
    const minutes = parseInt(parts[1], 10);
    return new Date(1970, 0, 1, hours, minutes);
  };

  return (
    <Box>
      <IconButton color="primary" onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleSettingClick("DayOff")}>
          {t("settingLab.settingDayOff")}
        </MenuItem>
        <MenuItem onClick={() => handleSettingClick("Time Lab")}>
          {t("settingLab.settingTimeLab")}
        </MenuItem>
        <MenuItem onClick={() => handleSettingClick("Job Automation")}>
          Setting Job Automation
        </MenuItem>
      </Menu>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{t("settingLab.settingTimeLab")}</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 2 }}
            label={t("settingLab.checkInTime")}
            InputLabelProps={{ shrink: true }}
            type="time"
            value={checkInTime}
            onChange={(e) => setCheckInTime(e.target.value)}
            fullWidth
          />
          <TextField
            label={t("settingLab.checkOutTime")}
            InputLabelProps={{ shrink: true }}
            type="time"
            value={checkOutTime}
            onChange={(e) => setCheckOutTime(e.target.value)}
            fullWidth
            style={{ marginTop: 16 }}
          />
          <TextField
            label={t("settingLab.leaveRequestNum")}
            type="number"
            value={leaveRequestNum}
            onChange={(e) => setLeaveRequestNum(e.target.value)}
            fullWidth
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("settingLab.cancel")}
          </Button>
          <Button onClick={handleSaveTime} color="primary">
            {t("settingLab.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDayOffDialog} onClose={handleDayOffDialogClose}>
        <DialogTitle>{t("settingLab.settingDayOff")}</DialogTitle>
        <DialogContent>
          {dayOffs.length > 0 ? (
            <Box>
              {dayOffs.map((dayOff) => (
                <Chip
                  key={dayOff.id}
                  label={new Date(dayOff.date).toLocaleDateString()}
                  onDelete={() => handleDeleteDayOff(dayOff.id)}
                  deleteIcon={<CloseIcon />}
                  style={{ marginBottom: 8 }}
                />
              ))}
            </Box>
          ) : (
            <p>{t("settingLab.noDayOffsAvailable")}</p>
          )}
          <TextField
            label={t("ticket.date")}
            type="date"
            value={dayOffDate}
            onChange={(e) => setDayOffDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ marginTop: 16 }}
          />
          <TextField
            label={t("ticket.reason")}
            type="text"
            value={dayOffReason}
            onChange={(e) => setDayOffReason(e.target.value)}
            fullWidth
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDayOffDialogClose} color="primary">
            {t("settingLab.cancel")}
          </Button>
          <Button onClick={handleSaveDayOff} color="primary">
            {t("settingLab.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openJobAutomationDialog}
        onClose={handleJobAutomationDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Setting Job Automation</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {jobSchedules.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Job Name</TableCell>
                    <TableCell>Select Time</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobSchedules.map((job) => (
                    <TableRow key={job.id}>
                      <TableCell>{job.id}</TableCell>
                      <TableCell>{job.jobName}</TableCell>
                      <TableCell>
                        <TimePicker
                          label="Select Time"
                          value={
                            editedJobs[job.id]
                              ? parseCronToTime(editedJobs[job.id])
                              : parseCronToTime(job.cronExpression)
                          }
                          onChange={(newTime) => handleTimeChange(job.id, newTime)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => saveCronExpression(job.id)}
                          disabled={!editedJobs[job.id]}
                        >
                          Save
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <p>No Job Schedules Available</p>
            )}
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleJobAutomationDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DropdownSettings;
