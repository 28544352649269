import React from "react";
import { Box, Typography } from "@mui/material";
import DifferenceIcon from '@mui/icons-material/Difference';
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../customs/authService";

const DropPDF = ({ onDrop }) => {
  const { t } = useAuth();
  
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']  // Explicitly accept .csv files and text/csv MIME type
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        console.error(`Rejected file: ${file.file.name}, Error: Invalid file type`);
      });
    },
  });

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: "2px dashed grey",
        bgcolor: "whitesmoke",
        padding: 2,
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} />
      <DifferenceIcon color="inherit" />
      {isDragActive ? (
        <Typography>{t('userProfile.dropFileHere')}</Typography>
      ) : (
        <Typography>{t('userProfile.fileUpload')}</Typography>
      )}
      {fileRejections.length > 0 && (
        <Typography color="error">Invalid file type. Please upload a CSV file.</Typography>
      )}
    </Box>
  );
};

export default DropPDF;
