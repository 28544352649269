import React, { useEffect, useState, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import ReportOKRsList from "./ui/ReportOKRsList";
import { OKRContext } from "../../context/OKRContext";
import { useLocation } from "react-router-dom";

export default function ReportOKRsPage() {
  const { ReportObjectiveList } = useContext(OKRContext);
  const { searchQuery, status, sortBy } = useOutletContext();
  const location = useLocation();
  const [objectives, setObjectives] = useState([]); // Objectives state
  const [loading, setLoading] = useState(true);

  const refreshData = async () => {
    setLoading(true);
    try {
      const data = await ReportObjectiveList(searchQuery, status, sortBy); // Fetch parent objectives
      setObjectives(data || []);
    } catch (error) {
      console.error("Failed to fetch Report Objectives:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshData(); // Fetch data when the component mounts or pathname changes
  }, [searchQuery, status, sortBy, location.pathname]);

  return (
    <ReportOKRsList
      title="Report OKRs"
      objectives={objectives} // Pass objectives to ReportOKRsList
      loading={loading}
      refreshData={refreshData} // Pass refreshData function
    />
  );
}
