import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { formatDate, truncateTarget } from "./format";

const KeyResultTable = ({
  keyResults,
  title,
  showActions,
  onEdit,
  onDelete,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedKeyResultId, setSelectedKeyResultId] = useState(null);

  const handleOpenDialog = (id) => {
    setSelectedKeyResultId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedKeyResultId(null);
  };

  const handleConfirmDelete = () => {
    if (onDelete) {
      onDelete(selectedKeyResultId); // Gọi onDelete với ID của key result
    }
    handleCloseDialog(); // Đóng hộp thoại sau khi gọi API
  };

  if (!keyResults || keyResults.length === 0) {
    return <p>No Key Results.</p>;
  }

  return (
    <>
      {title && <h3>{title}</h3>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: "40%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Title
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Target
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Progress
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Start Date
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                End Date
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Plan Link
              </TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                Result Link
              </TableCell>
              {showActions && (
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {keyResults.map((kr) => (
              <TableRow key={kr.id}>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {truncateTarget(kr.title)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {kr.targetValue} {kr.measurementUnit}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {kr.progressPercentage}%
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {formatDate(kr.startDate)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {formatDate(kr.endDate)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {kr.planLink && kr.planLink !== "" ? (
                    <a
                      href={kr.planLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkIcon sx={{ color: "blue" }} />
                    </a>
                  ) : (
                    <LinkIcon />
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {kr.resultLink && kr.resultLink !== "" ? (
                    <a
                      href={kr.resultLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkIcon sx={{ color: "blue" }} />
                    </a>
                  ) : (
                    <LinkIcon />
                  )}
                </TableCell>
                {showActions && (
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      sx={{ color: "blue" }}
                      onClick={() => onEdit(kr)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      sx={{ color: "red" }}
                      // onClick={() => onDelete(kr.id)}
                      onClick={() => handleOpenDialog(kr.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={1}>
            <WarningAmberIcon color="error" />
            Confirm Deletion
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this key result? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: "#1976d2", // Blue color for the Cancel button
              color: "white",
              "&:hover": {
                backgroundColor: "#115293", // Darker blue on hover
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{
              backgroundColor: "#d32f2f", // Red color for the Delete button
              color: "white",
              "&:hover": {
                backgroundColor: "#9a0007", // Darker red on hover
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

KeyResultTable.propTypes = {
  keyResults: PropTypes.array.isRequired,
  title: PropTypes.string,
  showActions: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default KeyResultTable;
