import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
} from "@mui/material";
import { useAuth } from "../../customs/authService";

export const getStatusDisplay = (status) => {
  switch (status) {
    case 1:
      return <span style={{ color: "green" }}>Attended</span>;
    case 2:
      return <span style={{ color: "#facc15" }}>Late In</span>;
    case 3:
      return <span style={{ color: "gray" }}>Soon Out</span>;
    case 4:
      return <span style={{ color: "red" }}>Absent</span>;
    default:
      return <span>Unknown</span>;
  }
};

export const calculateAttendanceSummary = (attendances) => {
  const totalSessions = attendances.length;
  let absentCount = 0;
  let attendedCount = 0;
  let lateInCount = 0;
  let soonOutCount = 0;
  (attendances || []).forEach((attendance) => {
    switch (attendance.status) {
      case 1:
        attendedCount += 1;
        break;
      case 2:
        lateInCount += 1;
        break;
      case 3:
        soonOutCount += 1;
        break;
      case 4:
        absentCount += 1;
        break;
      default:
        break;
    }
  });
  const absentPercentage = totalSessions ? (absentCount / totalSessions) * 100 : 0;
  const attendedPercentage = totalSessions ? (attendedCount / totalSessions) * 100 : 0;
  const lateInPercentage = totalSessions ? (lateInCount / totalSessions) * 100 : 0;
  const soonOutPercentage = totalSessions ? (lateInCount / totalSessions) * 100 : 0;

  return { absentCount, attendedCount, lateInCount, soonOutCount,absentPercentage, attendedPercentage, lateInPercentage, soonOutPercentage };
};

const InternAttendance = ({ data }) => {
  const { t } = useAuth();

  const formatTime = (timeString) => {
    if (!timeString) return "N/A"; // Nếu không có giá trị thời gian

    // Tách giờ và phút từ chuỗi thời gian
    const [hours, minutes] = timeString.split(":");

    // Chuyển đổi sang định dạng "hh:mm a"
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Chuyển đổi 24h sang 12h
    return `${formattedHours}:${minutes} ${period}`;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("attendance.absent")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("attendance.attended")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("attendance.lateIn")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              {t("attendance.soonOut")}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Percentage absent</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Percentage attended</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Percentage lateIn</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Percentage soonOut</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((record, index) => {
              const { absentCount, attendedCount, lateInCount, soonOutCount,   absentPercentage,
                attendedPercentage,
                lateInPercentage,
                soonOutPercentage } =
                calculateAttendanceSummary(record.attendances || []);
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{absentCount}</TableCell>
                    <TableCell>{attendedCount}</TableCell>
                    <TableCell>{lateInCount}</TableCell>
                    <TableCell>{soonOutCount}</TableCell>
                    <TableCell sx={{ color: "red" }}>{absentPercentage.toFixed(2)}%</TableCell>
                    <TableCell sx={{ color: "green" }}>{attendedPercentage.toFixed(2)}%</TableCell>
                    <TableCell sx={{ color: "#facc15" }}>{lateInPercentage.toFixed(2)}%</TableCell>
                    <TableCell sx={{ color: "gray" }}>{soonOutPercentage.toFixed(2)}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                {t("ticket.date")}
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                {t("ticket.checkInTime")}
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                {t("ticket.checkOutTime")}
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                {t("ticket.status")}
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                {t("ticket.reason")}
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                {t("Verify")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(record.attendances || []).length > 0 ? (
                              record.attendances.map((attendance, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>
                                    {attendance.date.split("T")[0]}
                                  </TableCell>
                                  <TableCell>
                                    {formatTime(attendance.checkInTime)}
                                  </TableCell>
                                  <TableCell>
                                    {formatTime(attendance.checkOutTime)}
                                  </TableCell>
                                  <TableCell>
                                    {getStatusDisplay(attendance.status)}
                                  </TableCell>
                                  <TableCell>{attendance.reason}</TableCell>
                                  <TableCell>{attendance.verify}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={5}>
                                  {t("attendance.noRecordsFound")}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                {t("attendance.noRecordsFound")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InternAttendance;
