import React from "react";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";

const TicketList = ({ tickets = [], onOpenModal, setTickets }) => {
  const { t, auth } = useAuth();
  const isLabManager = auth?.user?.roles.includes("Lab Manager");

  const getStatusColor = (status) => {
    switch (status) {
      case 2: return "green";
      case 5: return "red";
      case 1: return "orange";
      case 3: return "blue";
      case 4: return "purple";
      default: return "black";
    }
  };

  const handleApprove = (id) => {
    apiAuth.post("/Application/checkStatusApplicationAttend", { id, status: "Approve" }, {
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status === 200) { 
        toast.success("Approve successfully ");
      }
    }).catch(() => {
      toast.error("Error approving request.");
    });
  };
  
  const handleReject = (id) => {
    apiAuth.post("/Application/checkStatusApplicationAttend", { id, status: "Reject" }, {
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
      if (response.status === 200) {
        setTickets((prevTickets) => prevTickets.filter((ticket) => ticket.id !== id));
        toast.success(t(response.data.message));
      }
    }).catch(() => {
      toast.error("Error rejecting request.");
    });
  };
  
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("ticket.title")}</TableCell>
            <TableCell>{t("ticket.description")}</TableCell>
            <TableCell>{t("ticket.creator")}</TableCell>
            <TableCell>{t("ticket.assignee")}</TableCell>
            <TableCell>{t("ticket.createDate")}</TableCell>
            <TableCell>{t("ticket.status")}</TableCell>
            <TableCell>{t("attendance.actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.length > 0 ? (
            tickets.map((ticket) => (
              <TableRow key={ticket.id}>
                <TableCell>{ticket.title}</TableCell>
                <TableCell>{ticket.description}</TableCell>
                <TableCell>{ticket.nameCreator}</TableCell>
                <TableCell>{ticket.nameAssignee}</TableCell>
                <TableCell>{new Date(ticket.dateRequire).toLocaleDateString()}</TableCell>
                <TableCell style={{ color: getStatusColor(ticket.status), fontWeight: "bold" }}>
                  {t(`ticket.statusText.${ticket.status}`)}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onOpenModal(ticket.id)}
                  >
                    {t("ticket.details")}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={7} style={{ textAlign: "center" }}>
                Request Not Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TicketList.propTypes = {
  tickets: PropTypes.array.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  setTickets: PropTypes.func.isRequired,
};

export default TicketList;
