import React, { useEffect, useState, useContext } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import KeyResultsModal from "./KeyResultsModal";
import { OKRContext } from "../../../context/OKRContext";
import { truncateTarget, formatDate } from "./format";
import { useNavigate } from "react-router-dom";

export default function ModalListPendingOKRs({
  open,
  onClose,
  fetchPendingOKRsFunction, // Receive API function as a prop
  title,
}) {
  const { updateStatusApproveObjective } = useContext(OKRContext);

  const [pendingOKRs, setPendingOKRs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [okrToReject, setOkrToReject] = useState(null);
  // const [openKeyResultsModal, setOpenKeyResultsModal] = useState(false);
  // const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPendingOKRs = async () => {
      setLoading(true);
      const data = await fetchPendingOKRsFunction();
      setPendingOKRs(data || []);
      setLoading(false);
    };

    if (open) {
      fetchPendingOKRs();
    }
  }, [open, fetchPendingOKRsFunction]);

  const handleApproveRequest = async (okrId) => {
    try {
      await updateStatusApproveObjective(okrId, "Approved");
      setPendingOKRs((prev) => prev.filter((okr) => okr.Id !== okrId));
    } catch (error) {
      console.error("Error approving OKR:", error);
    }
  };

  const handleOpenConfirmDialog = (okrId) => {
    setOkrToReject(okrId);
    setOpenConfirmDialog(true);
  };

  const handleRejectRequest = async () => {
    try {
      await updateStatusApproveObjective(okrToReject, "Rejected");
      setPendingOKRs((prev) => prev.filter((okr) => okr.Id !== okrToReject));
      setOpenConfirmDialog(false);
      setOkrToReject(null);
    } catch (error) {
      console.error("Error rejecting OKR:", error);
    }
  };

  // const handleOpenKeyResultsModal = (objectiveId) => {
  //   setSelectedObjectiveId(objectiveId);
  //   setOpenKeyResultsModal(true);
  // };

  // const handleCloseKeyResultsModal = () => {
  //   setOpenKeyResultsModal(false);
  //   setSelectedObjectiveId(null);
  // };

  const handleClose = () => {
    onClose();
    setPendingOKRs([]);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 1,
          width: "70vw",
          maxHeight: "70vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", textAlign: "left", mb: 2 }}
        >
          {title}
        </Typography>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : pendingOKRs.length === 0 ? (
          <Typography>No pending OKRs requests found.</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "40%",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Target
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Creator
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Key Results
                </TableCell> */}
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  Start Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  End Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                  StatusApprove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingOKRs.map((okr) => (
                <TableRow key={okr.Id}>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/okr-detail/${okr.Id}`)} // Điều hướng khi click
                    >
                      <Avatar
                        src={
                          okr.User.Avatar
                            ? `data:image/png;base64,${okr.User.Avatar}`
                            : "/default-avatar.png"
                        }
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <strong>{truncateTarget(okr.Target)}</strong>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {okr.User.FirstName} {okr.User.LastName}
                  </TableCell>
                  {/* <TableCell sx={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleOpenKeyResultsModal(okr.Id)}
                    >
                      {okr.KeyResults.length} Key Results
                    </Button>
                  </TableCell> */}
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatDate(okr.StartDate)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatDate(okr.EndDate)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Tooltip title="Approve">
                      <IconButton
                        color="success"
                        onClick={() => handleApproveRequest(okr.Id)}
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject">
                      <IconButton
                        color="error"
                        onClick={() => handleOpenConfirmDialog(okr.Id)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {/* Key Results Modal */}
        {/* <KeyResultsModal
          open={openKeyResultsModal}
          onClose={handleCloseKeyResultsModal}
          objectiveId={selectedObjectiveId}
        /> */}

        {/* Confirm Dialog for Rejecting Request */}
        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
        >
          <DialogTitle>
            <Box display="flex" alignItems="center" gap={1}>
              <WarningAmberIcon color="error" />
              Confirm Rejection
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to reject this request? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenConfirmDialog(false)}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                "&:hover": { backgroundColor: "#115293" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRejectRequest}
              sx={{
                backgroundColor: "#d32f2f",
                color: "white",
                "&:hover": { backgroundColor: "#9a0007" },
              }}
              autoFocus
            >
              Reject
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
}
