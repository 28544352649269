import React, { useContext, useEffect, useState } from "react";
import { Modal, Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OKRContext } from "../../../context/OKRContext";
import AddNewKeyResultModal from "./AddNewKeyResultModal";
import KeyResultTable from "../../Okr/ui/KeyResultTable";

const KeyResultsModal = ({ open, onClose, objectiveId }) => {
  const { auth, fetchObjectiveById, deleteKeyResult } = useContext(OKRContext);
  const [openAddNewKeyResultModal, setOpenAddNewKeyResultModal] =
    useState(false);
  const [objectiveDetails, setObjectiveDetails] = useState(null);
  const [selectedKeyResult, setSelectedKeyResult] = useState(null);

  useEffect(() => {
    const loadObjectiveDetails = async () => {
      if (objectiveId) {
        try {
          const details = await fetchObjectiveById(objectiveId);
          setObjectiveDetails(details);
        } catch (error) {
          console.error("Failed to fetch objective details:", error);
        }
      }
    };

    loadObjectiveDetails();
  }, [objectiveId, fetchObjectiveById]);

  const handleKeyResultAdded = async () => {
    try {
      const updatedDetails = await fetchObjectiveById(objectiveId);
      setObjectiveDetails(updatedDetails);
      setOpenAddNewKeyResultModal(false);
    } catch (error) {
      console.error("Failed to refresh objective details:", error);
    }
  };

  const handleEdit = (keyResult) => {
    console.log("Dữ liệu của key result khi ấn nút edit:", keyResult);
    setSelectedKeyResult(keyResult); // Đặt key result được chọn để chỉnh sửa
    setOpenAddNewKeyResultModal(true);
  };

  const handleAddNew = () => {
    setSelectedKeyResult(null); // Xóa key result được chọn để thêm mới
    setOpenAddNewKeyResultModal(true);
  };

  const handleDeleteKeyResult = async (keyResultId) => {
    try {
      await deleteKeyResult(keyResultId); // Gọi API xóa
      const updatedDetails = await fetchObjectiveById(objectiveId); // Lấy lại chi tiết OKR để cập nhật
      setObjectiveDetails(updatedDetails);
    } catch (error) {
      console.error("Lỗi khi xóa key result:", error);
    }
  };

  const isUserOwner = auth?.user?.jti === objectiveDetails?.user?.userId;

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            width: "70vw",
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "red",
            }}
          >
            <CloseIcon />
          </IconButton>

          {objectiveDetails ? (
            <>
              <Typography
                variant="h4"
                sx={{
                  marginBottom: "20px",
                  fontWeight: "bold",
                }}
              >
                {objectiveDetails.target}
              </Typography>

              <KeyResultTable
                keyResults={objectiveDetails.keyResults}
                title="Key Results"
                showActions={isUserOwner && !objectiveDetails.isFinished}
                onEdit={handleEdit}
                onDelete={handleDeleteKeyResult}
              />
            </>
          ) : (
            <Typography variant="body1">
              Loading objective details...
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            {isUserOwner && !objectiveDetails.isFinished && (
              <Button
                sx={{ marginRight: "10px" }}
                variant="contained"
                color="primary"
                onClick={handleAddNew}
              >
                Add Key Result
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <AddNewKeyResultModal
        open={openAddNewKeyResultModal}
        onClose={() => setOpenAddNewKeyResultModal(false)}
        // parentObjective={
        //   objectiveDetails
        //     ? {
        //         parentObjectiveId: objectiveDetails.parentObjectiveId,
        //         ObjectiveStartDate: objectiveDetails.startDate,
        //         ObjectiveEndDate: objectiveDetails.endDate,
        //       }
        //     : {}
        // }
        objective={
          objectiveDetails
            ? {
                objectiveId: objectiveDetails.id,
                parentObjective: objectiveDetails.parentObjective,
                objectiveStartDate: objectiveDetails.startDate,
                objectiveEndDate: objectiveDetails.endDate,
              }
            : {}
        }
        // parternObjectiveId={objectiveDetails?.parentObjectiveId}
        // objectiveId={objectiveId}
        // objectiveStartDate={objectiveDetails?.startDate}
        onKeyResultAdded={handleKeyResultAdded}
        initialData={selectedKeyResult} // Truyền key result được chọn để chỉnh sửa
      />
    </>
  );
};

export default KeyResultsModal;
