import React, { useState, useEffect } from "react";
import apiAuth from "../../../customs/axiosCustom";
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LabChallengeDetailModal from "./LabChallengeDetailModal";
import { formatDate, truncateTarget, formatDateTime } from "./format";
import { useNavigate } from "react-router-dom";

const OKRScoringList = ({
  fetchParentObjectives, // Fetch lab challenges
  fetchChildObjectives, // Fetch team challenges
}) => {
  const navigate = useNavigate();
  const [objectives, setObjectives] = useState([]);
  const [childObjectives, setChildObjectives] = useState({});
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [currentVietnamTime, setCurrentVietnamTime] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null); // State for selected objective ID

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchParentObjectives();
        setObjectives(data);
      } catch (error) {
        console.error("Failed to fetch parent objectives:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchParentObjectives]);

  useEffect(() => {
    const fetchCurrentVietnamTime = async () => {
      try {
        const response = await apiAuth.get("/Time/get-vietnam-time");
        setCurrentVietnamTime(new Date(response.data.vietnamTime));
      } catch (error) {
        console.error("Failed to fetch Vietnam time:", error);
      }
    };

    fetchCurrentVietnamTime();
  }, []);

  const handleViewDetails = (objectiveId) => {
    navigate(`/okrchallenge-detail/${objectiveId}`);
  };

  const toggleExpand = async (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));

    if (!childObjectives[id]) {
      try {
        const children = await fetchChildObjectives(id);
        setChildObjectives((prev) => ({ ...prev, [id]: children }));
      } catch (error) {
        console.error(`Failed to fetch child objectives for ID: ${id}`, error);
      }
    }
  };

  const handleTargetClick = (objectiveId) => {
    setSelectedObjectiveId(objectiveId);
    setModalOpen(true); // Open modal
  };

  const renderObjectiveRow = (okr, level = 0) => {
    const paddingLeft = `${level * 80}px`;

    return (
      <React.Fragment key={okr.id}>
        <TableRow>
          {/* Target */}
          <TableCell style={{ paddingLeft }}>
            <Box display="flex" alignItems="center">
              {level === 0 && (
                <IconButton onClick={() => toggleExpand(okr.id)}>
                  {expanded[okr.id] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </IconButton>
              )}
              <Avatar
                alt={okr.user?.fullName || "Anonymous"}
                src={
                  okr.user?.avatar
                    ? `data:image/png;base64,${okr.user.avatar}`
                    : "/default-avatar.png"
                }
                sx={{ width: 40, height: 40, mr: 2 }}
              />
              <Typography
                variant="body1"
                fontWeight="bold"
                onClick={() => level === 0 && handleTargetClick(okr.id)} // Open modal on click
                sx={{ cursor: "pointer" }} // Add cursor pointer
              >
                {truncateTarget(okr.target)}
              </Typography>
            </Box>
          </TableCell>

          {/* Creator */}
          <TableCell>{okr.user?.fullName || "N/A"}</TableCell>

          {/* Grade Dates */}
          {level === 0 ? (
            <>
              <TableCell>
                {formatDateTime(okr.gradeStartDate) || "N/A"}
              </TableCell>
              <TableCell>{formatDateTime(okr.gradeEndDate) || "N/A"}</TableCell>
            </>
          ) : (
            <>
              <TableCell />
              <TableCell />
            </>
          )}

          {/* Action */}
          {/* {level > 0 ? (
            <TableCell>
              <Button
                variant="contained"
                color={okr.isGraded ? "success" : "error"}
                sx={{ textTransform: "none" }}
                onClick={() => handleViewDetails(okr.id)}
              >
                {okr.isGraded ? "Scored" : "Not Scored"}
              </Button>
            </TableCell>
          ) : (
            <TableCell />
          )} */}
          {level > 0 ? (
            <TableCell>
              {currentVietnamTime && (
                <>
                  {currentVietnamTime < new Date(okr.gradeStartDate) ? (
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="text.secondary"
                    >
                      Not Started
                    </Typography>
                  ) : currentVietnamTime > new Date(okr.gradeEndDate) ? (
                    okr.isGraded ? (
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="success.main"
                      >
                        Scored
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="error.main"
                      >
                        Overdue
                      </Typography>
                    )
                  ) : (
                    <Button
                      variant="contained"
                      color={okr.isGraded ? "success" : "error"}
                      sx={{ textTransform: "none" }}
                      onClick={() => handleViewDetails(okr.id)}
                    >
                      {okr.isGraded ? "Scored" : "Not Scored"}
                    </Button>
                  )}
                </>
              )}
            </TableCell>
          ) : (
            <TableCell />
          )}
        </TableRow>

        {/* Render child objectives */}
        {expanded[okr.id] &&
          childObjectives[okr.id]?.map((childOkr) =>
            renderObjectiveRow(childOkr, level + 1)
          )}
      </React.Fragment>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "40%", fontWeight: "bold" }}>
              Target
            </TableCell>
            <TableCell style={{ width: "15%", fontWeight: "bold" }}>
              Creator
            </TableCell>
            <TableCell style={{ width: "15%", fontWeight: "bold" }}>
              Grade Start Date
            </TableCell>
            <TableCell style={{ width: "15%", fontWeight: "bold" }}>
              Grade End Date
            </TableCell>
            <TableCell style={{ width: "15%", fontWeight: "bold" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <CircularProgress size={25} />
              </TableCell>
            </TableRow>
          ) : objectives.length > 0 ? (
            objectives.map((okr) => renderObjectiveRow(okr))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No objectives available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* Render Modal */}
      <LabChallengeDetailModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        objectiveId={selectedObjectiveId} // Pass the selected objective ID
      />
    </TableContainer>
  );
};

export default OKRScoringList;
