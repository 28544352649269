import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Reply from "@mui/icons-material/Reply";
import Save from "@mui/icons-material/Save";

const GroupModal = ({
  open,
  onClose,
  juryId,
  fetchGroupByjuryId,
  fetchJuryPanelGroup,
  fetchUpdatejuryPaneltoGroup,
  t,
}) => {
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(
      new Date(dateString)
    );
  };

  useEffect(() => {
    if (juryId) {
      const fetchData = async () => {
        try {
          const juryGroups = await fetchGroupByjuryId(juryId);
          const allGroups = await fetchJuryPanelGroup();
          const juryGroupIds = juryGroups.map(
            (group) => group.juryPanelGroupsId
          );

          const updatedGroupList = allGroups.map((group) => ({
            ...group,
            checked: juryGroupIds.includes(group.id),
          }));

          setGroupList(updatedGroupList);
          setSelectedGroups(juryGroupIds);
        } catch (error) {
          console.error("Error fetching group data:", error);
        }
      };

      fetchData();
    }
  }, [juryId, fetchGroupByjuryId, fetchJuryPanelGroup]);

  const handleClose = () => {
    onClose();
  };

  const handleCheckboxChange = (event, groupId) => {
    if (event.target.checked) {
      setSelectedGroups((prev) => [...prev, groupId]);
    } else {
      setSelectedGroups((prev) => prev.filter((id) => id !== groupId));
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        juryPanelId: juryId,
        groupIds: selectedGroups,
      };
      await fetchUpdatejuryPaneltoGroup(payload);
      toast.success("Updated Group of Jury Panel successfully");
      onClose();
      navigate("/members");
    } catch (error) {
      console.error("Error updating ", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>List Groups</DialogTitle>
      <DialogContent
        sx={{
          maxHeight: "400px", // Fixed height for scrollable content
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        {groupList.length > 0 ? (
          groupList.map((group) => (
            <Box
              key={group.id}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "16px",
                paddingTop: "5px",
                paddingBottom: "5px",
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: group.checked ? "#f0f8ff" : "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: group.checked ? "#1976d2" : "#000",
                  }}
                >
                  {group.name}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedGroups.includes(group.id)}
                      onChange={(event) =>
                        handleCheckboxChange(event, group.id)
                      }
                    />
                  }
                />
              </Box>

              <Typography variant="body2" color="textSecondary">
                Start Date: {formatDate(group.startDate)}
                {" - "}
                End Date: {formatDate(group.endDate)}
              </Typography>
            </Box>
          ))
        ) : (
          <p>No Group Found</p>
        )}
      </DialogContent>

      {/* Only show DialogActions if there are groups */}
      
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: "#d32f2f" },
            }}
            startIcon={<Reply />}
          >
            {t("createObjective.buttonCancenl")}
          </Button>
          {groupList.length > 0 && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
            startIcon={<Save />}
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: "#1e88e5" },
            }}
          >
            {t("createObjective.buttonSave")}
          </Button>
          )}
        </DialogActions>
    </Dialog>
  );
};

export default GroupModal;
