import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

const ConfirmationModal = ({ open, onClose, onConfirm, message }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="confirmation-modal-title"
    aria-describedby="confirmation-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        borderRadius: 1,
        boxShadow: 24,
        p: 3,
      }}
    >
      <Typography id="confirmation-modal-title" variant="h6" component="h2">
        Confirmation
      </Typography>
      <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
        {message}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 3 }}>
        <Button variant="contained" color="error" onClick={onClose}startIcon={<ClearIcon />}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}startIcon={<CheckIcon />}>
          Yes
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default ConfirmationModal;
