import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { OKRContext } from "../../context/OKRContext";
import KeyResultTable from "../../components/Okr/ui/KeyResultTable";
import AddNewKeyResultModal from "../../components/Okr/ui/AddNewKeyResultModal";
import {
  IconButton,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Build";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { formatDateForInput } from "../../components/Okr/ui/format";
import RelatedOKRs from "./ui/RelatedOKRs";
import OKRsLinkRequest from "./ui/OKRsLinkRequest";
import { toast } from "react-toastify";

const OKRDetailPage = () => {
  const navigate = useNavigate();
  const { objectiveId } = useParams();
  const location = useLocation();
  const { fetchObjectiveById, auth, deleteKeyResult } = useContext(OKRContext);
  const [objectiveDetails, setObjectiveDetails] = useState(null);
  const [openAddNewKeyResultModal, setOpenAddNewKeyResultModal] =
    useState(false);
  const [selectedKeyResult, setSelectedKeyResult] = useState(null); // State cho key result được chọn
  const { updateObjective, deleteObjective } = useContext(OKRContext);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [updateError, setUpdateError] = useState("");

  useEffect(() => {
    const loadObjectiveDetails = async () => {
      try {
        const details = await fetchObjectiveById(objectiveId);
        setObjectiveDetails(details);
        setUpdatedData({
          description: details.description,
          startDate: formatDateForInput(details.startDate),
          endDate: formatDateForInput(details.endDate),
        });
      } catch (error) {
        console.error("Failed to fetch objective details:", error);
      }
    };

    loadObjectiveDetails();
  }, [objectiveId, fetchObjectiveById]);

  const handleEditKeyResult = (keyResult) => {
    setSelectedKeyResult(keyResult); // Đặt key result được chọn để chỉnh sửa
    setOpenAddNewKeyResultModal(true);
  };

  const handleKeyResultAdded = async () => {
    // Hàm để cập nhật lại danh sách sau khi thêm/cập nhật
    const updatedDetails = await fetchObjectiveById(objectiveId);
    setObjectiveDetails(updatedDetails);
    setOpenAddNewKeyResultModal(false);
  };

  const isUserOwner = auth?.user?.jti === objectiveDetails?.user?.userId;

  const handleOpenUpdatePopup = () => {
    setOpenUpdatePopup(true);
  };

  const handleCloseUpdatePopup = () => {
    setOpenUpdatePopup(false);
  };

  const handleSaveChanges = async () => {
    try {
      await updateObjective(objectiveId, updatedData);

      setObjectiveDetails((prev) => ({
        ...prev,
        description: updatedData.description,
        startDate: updatedData.startDate,
        endDate: updatedData.endDate,
      }));
      toast.success("Objective updated successfully!");
      setOpenUpdatePopup(false);
    } catch (error) {
      setUpdateError(error.message || "An unexpected error occurred.");
    }
  };

  const handleFieldChange = (field, value) => {
    setUpdatedData((prev) => ({ ...prev, [field]: value }));

    if (field === "startDate") {
      const now = new Date();
      const selectedDate = new Date(value);

      if (selectedDate < now) {
        setStartDateError("Start date must be today or later.");
      } else {
        setStartDateError("");
      }
    }

    if (field === "endDate") {
      const selectedStartDate = new Date(updatedData.startDate);
      const selectedEndDate = new Date(value);

      if (selectedEndDate <= selectedStartDate) {
        setEndDateError("End date must be later than start date.");
      } else {
        setEndDateError("");
      }
    }
  };

  const handleDeleteObjective = async () => {
    try {
      await deleteObjective(objectiveId); // Gọi API xóa Objective
      setOpenDeletePopup(false); // Đóng popup xác nhận
      navigate(-1); // Điều hướng người dùng quay lại trang trước đó sau khi xóa
    } catch (error) {
      console.error("Error deleting objective:", error);
    }
  };

  // Kiểm tra URL để hiển thị ListOKRsApproved nếu action=isApproved
  const showListOKRsApproved =
    new URLSearchParams(location.search).get("action") === "isApproved";

  // Render a message if the objective has been marked as hidden (deleted)
  if (objectiveDetails?.isHidden) {
    return (
      <Box sx={{ padding: 5 }}>
        <Typography variant="h6" color="error">
          This Objective has been deleted.
        </Typography>
        <Button onClick={() => navigate(-1)} variant="contained" sx={{ mt: 2 }}>
          Go Back
        </Button>
      </Box>
    );
  }

  const handleDeleteKeyResult = async (keyResultId) => {
    try {
      await deleteKeyResult(keyResultId); // Call the delete API
      const updatedDetails = await fetchObjectiveById(objectiveId); // Fetch updated details
      setObjectiveDetails(updatedDetails); // Update the state with new data
    } catch (error) {
      console.error("Error deleting key result:", error);
    }
  };

  // Render nothing if data hasn't loaded yet
  if (!objectiveDetails) return null;

  return (
    <Box sx={{ padding: 5 }}>
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          p: 3,
          mb: 3,
          backgroundColor: "#f9f9f9",
        }}
      >
        {/* Header with back button, avatar, and title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </IconButton>
            {/* Avatar and target title */}
            <Avatar
              alt={objectiveDetails.user.firstName}
              src={
                objectiveDetails.user.avatar
                  ? `data:image/png;base64,${objectiveDetails.user.avatar}`
                  : "/default-avatar.png"
              }
              sx={{ width: 40, height: 40, mr: 2 }}
            />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h5"
                component="h1"
                sx={{ wordWrap: "break-word" }}
              >
                {objectiveDetails.target}
              </Typography>
            </Box>
          </Box>

          {/* Update and Delete buttons */}
          {isUserOwner &&
            (objectiveDetails.statusApprove === "Pending" ||
              objectiveDetails.parentObjectiveId === null) && (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SaveIcon />}
                  sx={{ borderColor: "#00bfff", color: "#00bfff", mr: 1 }}
                  onClick={handleOpenUpdatePopup}
                >
                  Update Objectives
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  sx={{ borderColor: "red", color: "red" }}
                  onClick={() => setOpenDeletePopup(true)}
                >
                  Delete
                </Button>
              </Box>
            )}
        </Box>

        {/* Description and date fields */}
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            value={objectiveDetails.description || "No Description"}
            sx={{ mb: 2 }}
            InputProps={{
              readOnly: true,
            }}
            multiline
            rows={3}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Start Date"
                variant="outlined"
                type="datetime-local"
                value={formatDateForInput(objectiveDetails.startDate)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="End Date"
                variant="outlined"
                type="datetime-local"
                value={formatDateForInput(objectiveDetails.endDate)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Update Objective Popup */}
        <Dialog open={openUpdatePopup} onClose={handleCloseUpdatePopup}>
          <DialogTitle>Update Objective</DialogTitle>
          <DialogContent>
            {/* Các trường input cho Description, Start Date, End Date */}
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              value={updatedData.description || ""}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              multiline
              rows={3}
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Start Date"
                  variant="outlined"
                  type="datetime-local"
                  fullWidth
                  value={updatedData.startDate || ""}
                  onChange={(e) =>
                    handleFieldChange("startDate", e.target.value)
                  }
                  InputProps={{
                    inputProps: { min: new Date().toISOString().slice(0, 16) },
                  }}
                  error={Boolean(startDateError)}
                  helperText={startDateError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="End Date"
                  variant="outlined"
                  type="datetime-local"
                  fullWidth
                  value={updatedData.endDate || ""}
                  onChange={(e) => handleFieldChange("endDate", e.target.value)}
                  error={Boolean(endDateError)}
                  helperText={endDateError}
                />
              </Grid>
            </Grid>

            {/* Hiển thị lỗi nếu có */}
            {updateError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {updateError}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUpdatePopup(false)}>Cancel</Button>
            <Button
              onClick={handleSaveChanges}
              color="primary"
              disabled={Boolean(startDateError || endDateError)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeletePopup}
          onClose={() => setOpenDeletePopup(false)}
        >
          {/* <DialogTitle>Delete Objective</DialogTitle> */}
          <DialogTitle>
            <Box display="flex" alignItems="center" gap={1}>
              <WarningAmberIcon color="error" /> {/* Biểu tượng cảnh báo */}
              Do you want to delete this Objective?
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Objectives linked to this Objective will also be deleted. This
              action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                "&:hover": { backgroundColor: "#115293" }, // Màu khi hover
              }}
              onClick={() => setOpenDeletePopup(false)}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#d32f2f",
                color: "white",
                "&:hover": { backgroundColor: "#9a0007" }, // Màu khi hover
              }}
              onClick={handleDeleteObjective}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Box>
          <h3>Key Results</h3>
          {/* Key results table */}
          <KeyResultTable
            keyResults={objectiveDetails.keyResults}
            // title="Key Results"
            showActions={
              isUserOwner &&
              (!objectiveDetails.isFinished ||
                objectiveDetails.parentObjectiveId === null)
            }
            onEdit={handleEditKeyResult}
            onDelete={handleDeleteKeyResult}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            {isUserOwner &&
              (!objectiveDetails.isFinished ||
                objectiveDetails.parentObjectiveId === null) && (
                <Button
                  sx={{ marginRight: "10px" }}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenAddNewKeyResultModal(true)}
                >
                  Add Key Result
                </Button>
              )}
          </Box>
        </Box>

        {/* Modal Thêm/Chỉnh sửa Key Result */}
        <AddNewKeyResultModal
          open={openAddNewKeyResultModal}
          onClose={() => setOpenAddNewKeyResultModal(false)}
          objective={{
            objectiveId: objectiveDetails.id,
            objectiveStartDate: objectiveDetails.startDate,
            objectiveEndDate: objectiveDetails.endDate,
            parentObjective: objectiveDetails.parentObjective || null, // Truyền `null` nếu không có `parentObjective`
          }}
          initialData={selectedKeyResult}
          onKeyResultAdded={handleKeyResultAdded}
        />

        {objectiveDetails.parentObjectiveId !== null && (
          <RelatedOKRs
            parentObjective={objectiveDetails.parentObjective}
            keyResults={objectiveDetails.keyResults}
          />
        )}

        {/* Hiển thị ListOKRsApproved nếu action là isApproved */}
        {showListOKRsApproved && <OKRsLinkRequest objectiveId={objectiveId} />}
      </Box>
    </Box>
  );
};

export default OKRDetailPage;
