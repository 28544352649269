import React, { createContext, useContext, useState } from "react";
import apiAuth from "../customs/axiosCustom";
import { hideLoading, showLoading } from "../components/Layout/LoadingScreen";
import { useAuth } from "../customs/authService";
import { toast } from "react-toastify";

export const CleaningScheduleContext = createContext();

export const CleaningScheduleProvider = ({ children }) => {
  const { auth } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const isLabManager = auth?.user?.roles.includes("Lab Manager");
  const isIntern = auth?.user?.roles.includes("Intern");
  const isMentor = auth?.user?.roles.includes("Mentor");

  const [swapRequestCount, setSwapRequestCount] = useState(0);

  // Function to handle errors with notifications
  const handleError = (message, err) => {
    setError(message);
    console.error(message, err);
    // toast.error(message);
  };

  const fetchSwapRequestCount = async () => {
    if (!isIntern) return;
    try {
      showLoading();
      const response = await apiAuth.get(
        `/CleaningSchedule/getSwapRequestsForIntern`
      );

      // Đếm số lượng yêu cầu và lưu vào state, mặc định là 0 nếu không có dữ liệu
      setSwapRequestCount(response.data ? response.data.length : 0);
    } catch (error) {
      if (!error.response || error.response.status !== 404) {
        toast.error("Error fetching swap requests.");
      }
      // Trường hợp lỗi 404 hoặc các lỗi khác sẽ không hiển thị thông báo và thiết lập count là 0
      setSwapRequestCount(0);
    } finally {
      hideLoading();
    }
  };

  return (
    <CleaningScheduleContext.Provider value={{ auth, error, loading }}>
      {children}
    </CleaningScheduleContext.Provider>
  );
};
