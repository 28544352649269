import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import apiAuth from "../../customs/axiosCustom";
import { useAuth } from "../../customs/authService";
import "./RequestPage.css"; // Import CSS

const TicketDetailsPage = ({ id, onClose, onUpdateSuccess }) => {
  const { t, auth } = useAuth();
  const [ticket, setTicket] = useState(null);
  const [originalTicket, setOriginalTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [dateRequireError, setDateRequireError] = useState("");
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [titleError, setTitleError] = useState("");

  useEffect(() => {
    const fetchTicket = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await apiAuth.get(`/Application/application-details/${id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setTicket(response.data);
        setOriginalTicket(response.data);
        setComment(t(response.data.Comment) || "");
        // Gọi fetchComments để load comments ngay khi trang load
        await fetchComments();
      } catch (error) {
        setError("Error fetching request details.");
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicket({ ...ticket, [name]: value });
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    const today = new Date().toISOString().split("T")[0];
    if (value < today) {
      setDateRequireError("Date cannot be in the past.");
    } else {
      setDateRequireError("");
      setTicket({ ...ticket, dateRequire: value });
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const validateComment = () => {
    if (!comment.trim()) {
      setCommentError("Comment cannot be blank.");
      return false;
    }
    setCommentError("");
    return true;
  };

  const handleSaveChanges = async () => {
    if (!ticket.title.trim()) {
      setTitleError("Request cannot be blank.");
      return;
    }

    try {
      const response = await apiAuth.put(
        `/Application/update-application/${id}`,
        {
          ...ticket,
          Comment: comment,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsEditing(false);
      toast.success(response.data.message);
      onUpdateSuccess();
      onClose();
    } catch (error) {
      toast.error(t(error?.response?.data?.message));
    }
  };

  const handleDelete = async () => {
    try {
      const response = await apiAuth.delete(`/Application/delete-application/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      onUpdateSuccess();
      onClose();
      toast.success(t(response.data));
    } catch (error) {
      setError("Error deleting request.");
      toast.error("Error deleting request.");
    }
    setOpenDeleteDialog(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setTicket(originalTicket);
    setIsEditing(false);
    setDateRequireError("");
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleAttendentApprove = (id) => {
    apiAuth.post(
      "/Application/checkStatusApplicationAttend",
      { id, status: "Approve" },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200 && response.data) {
          onUpdateSuccess();
          onClose();
          toast.success(t(response.data.message || "Approve Application Successfully"));
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message || "Error approving attendance request.");
      });
  };

  const handleAttendentReject = (id) => {
    apiAuth.post(
      "/Application/checkStatusApplicationAttend",
      { id, status: "Reject" },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          onUpdateSuccess();
          onClose();
          toast.success(t(response.data.message || "Reject Application Successfully"));
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.Message || "Error rejecting attendance request.");
      });
  };


  const handleApprove = async () => {
    try {
      await apiAuth.put(
        `/Application/update-application/${id}`,
        {
          ...ticket,
          Status: 2, // Approved
          Comment: comment,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      onUpdateSuccess();
      onClose();
      toast.success("Request approved successfully!");
    } catch (error) {
      setError("Error approving request.");
      toast.error("Error approving request.");
    }
  };

  const handleReject = async () => {
    try {
      await apiAuth.put(
        `/Application/update-application/${id}`,
        {
          ...ticket,
          Status: 5, // Rejected
          Comment: comment,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      onUpdateSuccess();
      onClose();
      toast.success("Request rejected successfully!");
    } catch (error) {
      setError("Error rejecting request.");
      toast.error("Error rejecting request.");
    }
  };

  const fetchComments = async () => {
    const response = await apiAuth.get(`/Application/application-comments/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setTicket((prev) => ({
      ...prev,
      ticketComments: response.data,
    }));
  };

  const handleAddComment = async () => {
    if (!validateComment()) return;

    try {
      const userId = auth?.user?.jti;
      var response = await apiAuth.post(
        `/Application/comment-application`,
        {
          TicketId: id,
          UserId: userId,
          Description: comment,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setComment("");
      toast.success(response.data.message);
      await fetchComments();
      // Không đóng modal ở đây để tránh việc modal tự động đóng
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ padding: 4, position: "relative" }}>
      <Typography variant="h4" gutterBottom>
        Request Detail
      </Typography>
      {ticket && (
        <Paper sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={ticket.id}
                InputProps={{
                  readOnly: true,
                  style: { display: 'none' },  
                }}
                fullWidth
                type="text" 
                inputProps={{
                  tabIndex: -1 
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("ticket.creator")}
                value={ticket.nameCreator}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("ticket.assignee")}
                value={ticket.nameAssignee}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("ticket.title")}
                name="title"
                value={ticket.title}
                onChange={handleInputChange}
                fullWidth
                InputProps={{ readOnly: !isEditing }}
                error={!!titleError}
                helperText={titleError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("ticket.description")}
                name="description"
                value={ticket.description}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                InputProps={{ readOnly: !isEditing }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("ticket.status")}
                value={t(`ticket.statusText.${ticket.status}`)}
                InputProps={{ readOnly: true }}
                fullWidth
                sx={{ backgroundColor: getStatusColor(ticket.status) }}
              />
            </Grid>
            {ticket?.status === 1 && auth?.user?.roles?.includes("Lab Manager") && (
              <Grid item xs={12}>
                <TextField
                  label={t("ticket.comment")}
                  name="comment"
                  value={comment}
                  onChange={handleCommentChange}
                  multiline
                  rows={4}
                  fullWidth
                  error={!!commentError}
                  helperText={commentError}
                />
              </Grid>
            )}
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Request Comment</Typography>
          <Box
            sx={{
              maxHeight: 300,
              overflowY: "auto",
              border: "1px solid #ddd",
              borderRadius: 1,
              p: 1,
            }}
          >
            {ticket.ticketComments && ticket.ticketComments.length > 0 ? (
              ticket.ticketComments.map((comment) => (
                <Box
                  key={comment.id}
                  sx={{ mb: 2, p: 1, borderBottom: "1px solid #ddd" }}
                >
                  <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                    {comment.description ? (
                      <span>
                        <strong>{comment.name}:</strong> {comment.description}
                      </span>
                    ) : (
                      comment.description || t("ticket.noDescription")
                    )}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography>{t("ticket.noDescription")}</Typography>
            )}
          </Box>
        </Paper>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          {!isEditing && !auth?.user?.roles.includes("Lab Manager") && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              disabled={ticket?.status !== 1 || comment?.Id === null}
              sx={{ fontSize: "16px", padding: "8px 16px" }}
            >
              {t("ticket.edit")}
            </Button>
          )}
          {(auth?.user?.roles.includes("Intern") || auth?.user?.roles.includes("Mentor")) &&
            !isEditing &&
            ticket?.status === 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDeleteDialog}
                sx={{ fontSize: "16px", padding: "8px 16px" }}
              >
                {t("ticket.delete")}
              </Button>
            )}
          {isEditing && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                sx={{ fontSize: "16px", padding: "8px 16px" }}
              >
                {t("ticket.saveChanges")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseEdit}
                sx={{ fontSize: "16px", padding: "8px 16px" }}
              >
                {t("ticket.close")}
              </Button>
            </>
          )}
          {auth?.user?.roles.includes("Lab Manager") &&
            ticket?.status === 1 &&
            !isEditing && (
              <>
                {ticket?.type === 1 ? (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleAttendentApprove(ticket.id)}
                      sx={{ fontSize: "16px", padding: "8px 16px" }}
                    >
                      {t("ticket.approve")}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleAttendentReject(ticket.id)}
                      sx={{ fontSize: "16px", padding: "8px 16px" }}
                    >
                      {t("ticket.reject")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleApprove(ticket.id)}
                      sx={{ fontSize: "16px", padding: "8px 16px" }}
                    >
                      {t("ticket.approve")}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleReject(ticket.id)}
                      sx={{ fontSize: "16px", padding: "8px 16px" }}
                    >
                      {t("ticket.reject")}
                    </Button>
                  </>
                )}
              </>
            )}
          {(auth?.user?.roles.includes("Lab Manager") ||
            ticket?.ticketComments?.length > 0) && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddComment}
                disabled={ticket?.status !== 1}
                sx={{ fontSize: "16px", padding: "8px 16px" }}
              >
                {t("ticket.comment")}
              </Button>
            )}
        </Box>
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        maxWidth="md" // Chỉnh kích thước tối đa của modal
        fullWidth
      >
        <DialogTitle>{t("ticket.confirmDelete")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("ticket.toDeleteThisTicket")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            {t("ticket.delete")}
          </Button>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            {t("ticket.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return "#ffcccc"; // Red for Pending
    case 2:
      return "#ccffcc"; // Green for Approved
    case 3:
      return "#ffffcc"; // Yellow for In Progress
    case 4:
      return "#ccffcc"; // Green for Done
    case 5:
      return "#ffcccc"; // Red for Rejected
    default:
      return "#f0f0f0"; // Default background color
  }
};

export default TicketDetailsPage;
