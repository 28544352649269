import React, { useEffect, useState ,useContext} from "react";
import { useAuth } from "../../customs/authService";
import CsvImport from "./CsvImport";
import ImportMemberForm from "./ImportMemberForm";
import { MemberImportContext } from "../../context/MemberImportContext";
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const ImportMember = () => {
  const { fetchImportCsvJuryPanel, fetchTemplate, fetchUpdateJuryPanel } = useContext(MemberImportContext);
  const { t } = useAuth();
  const [csvFile, setCsvFile] = useState(null);
  const [errorsCSV, setErrorsCSV] = useState([]);
  const [errors, setErrors] = useState([]);
  const [mentorData, setMentorData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
  });

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setCsvFile(selectedFile);
  };

  const handleMentorDataChange = (event) => {
    const { name, value } = event.target;
    setMentorData({
      ...mentorData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (csvFile) {
      handleCsvSubmit();
    }
  }, [csvFile]);

  const handleDownloadTemplate = () => {
    fetchTemplate({ nameFile: "Import_Menters" });
  };
  const handleCsvSubmit = async () => {
    const formData = new FormData();
    formData.append("file", csvFile);

    try {
      showLoading();
      const response = await apiAuth.post("/Import/importMentorCSV", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        toast.success(t(`importMentor.${response.data.message}`));
        setErrorsCSV([]);

      }
    } catch (error) {
      const { data } = error.response;
      setErrorsCSV(data?.errorMessages || []);
      if (data?.message === "systemError") {
        toast.error(t(data?.message));
      } else {
        toast.error(t(`importMentor.${data?.message}`));
      }
    } finally {
      hideLoading();
    }
  };

  // const handleMentorSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     showLoading();
  //     const response = await apiAuth.post("/Import/ImportMentor", mentorData);

  //     if (response.status === 200) {
  //       toast.success(t(`importMentor.${response.data.message}`));
  //       setErrors([]);
  //       setMentorData({
  //         FirstName: "",
  //         LastName: "",
  //         Email: "",
  //       });
  //     }
  //   } catch (error) {
  //     const { data } = error.response;
  //     Object.keys(data).forEach((key) => {
  //       setErrors(data.message || []);
  //     });
  //     console.error(t(`importMentor.rowsAreInvalid`));
  //   } finally {
  //     hideLoading();
  //   }
  // };

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h4" gutterBottom>
        {t("importMentor.importMentorCsvTitle")}
      </Typography>
      <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadTemplate}
        >
          {t("importJuryPanel.downloadTemplate")}
        </Button>
      </Box>
      
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Box mb={2}>
          <DropPDF onDrop={handleDrop} />
          {csvFile && <Typography variant="h6">{csvFile.name}</Typography>}
        </Box>
        {errorsCSV.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Line Number
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Errors
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorsCSV.map((error, index) => {
                  const parts = error.split(":");
                  const lineNumber = parts[0];
                  const errorMessage = parts.slice(1).join(".").trim();

                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        style={{ fontSize: "18px", color: "red" }}
                      >
                        {lineNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: "18px", color: "red" }}
                      >
                        {t(`importMentor.${errorMessage}`)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <CsvImport
        importUrl="/Import/importJuryPanelCSV"
        title={t(`importJuryPanel.importJuryPanelCsvTitle`)}
        onSuccess={() => {
          
        }}
      />

      <Typography variant="h4" gutterBottom>
        {t("importMember.addMemberTitle")}
      </Typography>
      <ImportMemberForm
        onSuccess={() => {
          toast.success(`Add Member successfully`);
        }}
      />
    </Container>
  );
};

export default ImportMember;
