import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Modal,
  Typography,
  CircularProgress,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OKRContext } from "../../../context/OKRContext";
import { formatDateForInput } from "../../Okr/ui/format";

export default function LabChallengeDetailModal({
  open,
  onClose,
  objectiveId,
}) {
  const { DetailObjectiveChallengeById } = useContext(OKRContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (objectiveId && open) {
      fetchObjectiveDetails();
    }
  }, [objectiveId, open]);

  const fetchObjectiveDetails = async () => {
    setLoading(true);
    try {
      const response = await DetailObjectiveChallengeById(objectiveId);
      setData(response);
    } catch (error) {
      console.error("Failed to fetch objective details:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!open) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60vw",
          maxHeight: "70vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        {/* Nút đóng modal */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "red",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Tiêu đề */}
        <Typography variant="h4" sx={{ fontWeight: "bold" }} mb={2}>
          Lab Challenge Details
        </Typography>

        {/* Nội dung */}
        {loading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          data && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                      alt={data.user?.firstName || "Anonymous"}
                      src={
                        data.user?.avatar
                          ? `data:image/png;base64,${data.user.avatar}`
                          : "/default-avatar.png"
                      }
                      sx={{ mr: 2, width: 40, height: 40 }}
                    />
                    <Typography variant="h5" fontWeight="bold">
                      {data.target || "N/A"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    multiline
                    rows={3}
                    fullWidth
                    value={data.description || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Registration Dates */}
                <Grid item xs={6}>
                  <TextField
                    label="Registration Start Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.registrationStartDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Registration End Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.registrationEndDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Start Date, End Date, Milestone */}
                <Grid item xs={4}>
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.startDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="End Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.endDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Milestone Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.milestoneDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Report Dates */}
                <Grid item xs={6}>
                  <TextField
                    label="Report Start Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.reportStartDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Report End Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.reportEndDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Grade Dates */}
                <Grid item xs={6}>
                  <TextField
                    label="Grade Start Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.gradeStartDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Grade End Date"
                    variant="outlined"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={formatDateForInput(data.gradeEndDate)}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )
        )}
      </Box>
    </Modal>
  );
}
