import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { showLoading, hideLoading } from "../Layout/LoadingScreen";
import { MemberImportContext } from "../../context/MemberImportContext";

const CsvImport = ({ importUrl, title, onSuccess }) => {
  const { t } = useAuth();
  const { fetchImportCsvJuryPanel, fetchTemplate, fetchUpdateJuryPanel } = useContext(MemberImportContext);
  const [parsedData, setParsedData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [errorsCSV, setErrorsCSV] = useState([]);
  const [dataError, setDataError] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    parseCsvFile(selectedFile);
    setCsvFile(selectedFile);
  };
  const requiredHeaders = ["FirstName", "LastName", "Email", "JuryPanelGroup"];

  const parseCsvFile = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const headers = result.meta.fields;
      const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header));
      if (missingHeaders.length > 0) {
        toast.error(`Missing required fields: ${missingHeaders.join(", ")}`);
        setCsvFile(null); 
        return;
      }

      setParsedData(result.data);
      },
      error: (error) => {
        console.error("Error parsing CSV file:", error);
      },
    });
  };

  useEffect(() => {
    if (csvFile) {
      handleCsvSubmit();
    }
  }, [csvFile]);

  const handleCsvSubmit = async () => {
    try {
      showLoading();
        const response = await fetchImportCsvJuryPanel(csvFile);
        console.log("Data from fetchImportCsvJuryPanel:", response);
        setDataError(response.errors || []);
      
        console.error("File imported successfully.");
      setErrorsCSV([]);
      onSuccess && onSuccess();
    } catch (error) {
      const { data } = error.response;
      console.error(t(`importMentor.${data?.message}`));
    } finally {
      hideLoading();
    }
  };

  const handleDownloadTemplate = () => {
      fetchTemplate({ nameFile: 'Import_JuryPanels' });
  };

  const handleUpdateJuryPanel = async (row) => {
    try {
      showLoading();
      console.log(row);
      await fetchUpdateJuryPanel(row);
      console.error("Entry updated successfully.");
      onSuccess && onSuccess();
    } catch (error) {
      console.error("Error updating entry:", error);
    } finally {
      hideLoading();
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadTemplate}
        >
          {t("importJuryPanel.downloadTemplate")}
        </Button>
      </Box>
      <Box sx={{ pb: 0 }}>
        <DropPDF onDrop={handleDrop} />
        {csvFile && <Typography variant="h6">{csvFile.name}</Typography>}
      </Box>

      {parsedData.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{t("importJuryPanel.csv.firstName")}</TableCell>
                <TableCell align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{t("importJuryPanel.csv.lastName")}</TableCell>
                <TableCell align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{t("importJuryPanel.csv.email")}</TableCell>
                
                  <TableCell align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{t("importJuryPanel.csv.labName")}</TableCell>
                
                <TableCell align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{t("importJuryPanel.csv.errorMessage")}</TableCell>
                <TableCell align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{t("importJuryPanel.csv.options")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedData.map((row, index) => {
                const error = dataError.find((e) => e.row === index + 1);
                return (
                  <TableRow key={index}>
                    <TableCell align="center" style={{ fontSize: "18px" }}>{row.FirstName}</TableCell>
                    <TableCell align="center" style={{ fontSize: "18px" }}>{row.LastName}</TableCell>
                    <TableCell align="center" style={{ fontSize: "18px" }}>{row.Email}</TableCell>
                   
                      <TableCell align="center" style={{ fontSize: "18px" }}>{row.LabName}</TableCell>
                    <TableCell align="center" style={{ fontSize: "18px", color: error ? "red" : "green" }}>
                      {error ? error.message : "New entry added successfully"}
                    </TableCell>
                    <TableCell align="center">
                      {error && error.message === "Email already exists." && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleUpdateJuryPanel(row)}
                        >
                          {t("buttonUpdate")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default CsvImport;
