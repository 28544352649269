import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./customs/authService";
import Unauthorized from "./components/Authenticate/Unauthorized";
import { ToastContainer } from "react-toastify";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Suspense, lazy } from "react";
import { protectedRoutes } from "./assets/configs/routes";
import { OKRProvider } from "./context/OKRContext";
import { UserProvider } from "./context/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PrivateRoute from "./components/Authenticate/PrivateRoute";
import NotFound from "./components/Authenticate/NotFound";
import { GlobalLoading } from "./components/Layout/LoadingScreen";
import { NotificationProvider } from "./context/NotificationContext";
import { CandidatesProvider } from "./context/CandidatesContext";
import Layout from "./components/Layout/Layout";
import ImportCV from "./components/Import/ImportCV";
import ForgotPassword from "./components/Authenticate/ForgotPassword";
import { Language } from "@mui/icons-material";
import LanguageSpeedDial from "./components/Layout/SpeedDialLang";
import { JuryPanelProvider } from "./context/JuryPanelContext";
import { MemberImportProvider } from "./context/MemberImportContext";
import { CleaningScheduleProvider } from "./context/CleaningScheduleContext";
import VerifyForgotPasswordOtp from "./components/Authenticate/VerifyForgotPasswordOTP";
// import NotificationComponent from "./context/NotificationComponent";
const theme = createTheme();
const AdminAuthenticate = lazy(() =>
  import("./components/Authenticate/AdminAuthenticate")
);
const Authenticate = lazy(() =>
  import("./components/Authenticate/Authenticate")
);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<div>Loading...</div>}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <AuthProvider>
              {/* <LanguageSpeedDial /> */}
              <NotificationProvider>
                <UserProvider>
                  <MemberImportProvider>
                    <OKRProvider>
                      <CleaningScheduleProvider>
                        <JuryPanelProvider>
                          <CandidatesProvider>
                            <Routes>
                              <Route path="/login" element={<Authenticate />} />
                              <Route
                                path="/admin/login"
                                element={<AdminAuthenticate />}
                              />
                              <Route path="/forgot-password" 
                              element={<ForgotPassword show={true} />} 
                              />
                              <Route path="/verify-otp" element={<VerifyForgotPasswordOtp />} />
                              <Route
                                path="/unauthorized"
                                element={<Unauthorized />}
                              />
                              <Route path="*" element={<NotFound />} />
                              <Route path="/" element={<Layout />}>
                                {protectedRoutes.map((item, index) => (
                                  <Route
                                    key={`path-${index}`}
                                    path={item.path}
                                    element={
                                      <PrivateRoute
                                        allowedRoles={item.allowedRoles}
                                        element={item.component}
                                      />
                                    }
                                  >
                                    {/* Xử lý các route con nếu có */}
                                    {item.children &&
                                      item.children.map((child, childIndex) => (
                                        <Route
                                          key={`child-path-${childIndex}`}
                                          path={child.path}
                                          element={
                                            <PrivateRoute
                                              allowedRoles={child.allowedRoles}
                                              element={child.component}
                                            />
                                          }
                                        />
                                      ))}
                                  </Route>
                                ))}
                              </Route>
                            </Routes>
                          </CandidatesProvider>
                        </JuryPanelProvider>
                      </CleaningScheduleProvider>
                    </OKRProvider>
                  </MemberImportProvider>
                </UserProvider>
              </NotificationProvider>
            </AuthProvider>
          </Router>
        </LocalizationProvider>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        newestOnTop={true}
        hideProgressBar
        pauseOnHover
        theme="colored"
      />
      <GlobalLoading />
    </ThemeProvider>
  );
};

export default App;
