import React, { useState, useContext } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { JuryPanelContext } from "../../context/JuryPanelContext";
import { MemberImportContext } from "../../context/MemberImportContext";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const ImportMemberForm = ({ onSuccess }) => {
  const { t } = useAuth();
  const { importMentor } = useContext(MemberImportContext); // Lấy hàm importMentor từ MemberContext
  const { importJuryPanel,fetchJuryPanelGroup } = useContext(JuryPanelContext); // Lấy hàm importJuryPanel từ JuryPanelContext

  const [memberData, setMemberData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Role: "Mentor", // Default role to Mentor
    JuryPanelGroup: "None", // Default to None
  });
  const [juryPanelOptions, setJuryPanelOptions] = useState([]);

  // Fetch Jury Panel data if the selected role is JuryPanel
  const updateJuryPanel = async () => {
    try {
      const juryPanelData = await fetchJuryPanelGroup();
      setJuryPanelOptions(juryPanelData); // Populate JuryPanelGroup options
    } catch (error) {
      console.error("Failed to fetch Jury Panel data:", error);
      console.error("Failed to fetch Jury Panel Group");
    }
  };

  // Handle changes in input fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedData = { ...memberData, [name]: value };

    // If Role is JuryPanel, fetch Jury Panel Group options
    if (name === "Role" && value === "JuryPanel") {
      updateJuryPanel();
    } else if (name === "Role") {
      // If Role is changed away from JuryPanel, set JuryPanelGroup to None
      updatedData.JuryPanelGroup = "None";
      setJuryPanelOptions([]);
    }

    setMemberData(updatedData);
  };

  // Handle form submission
  const handleMentorSubmit = async (event) => {
    event.preventDefault();
    try {
      showLoading();
      
      const dataToSubmit = {
        ...memberData,
        JuryPanelGroup: memberData.JuryPanelGroup === "None" ? null : memberData.JuryPanelGroup,
      };
      
      let response;
      // Kiểm tra Role và gọi API tương ứng
      if (memberData.Role === "Mentor") {
        response = await importMentor(dataToSubmit);
      } else if (memberData.Role === "JuryPanel") {
        response = await importJuryPanel(dataToSubmit);
      }
      
      // Kiểm tra mã trạng thái HTTP từ phản hồi API
      if (response === 200) {
        setMemberData({
          FirstName: "",
          LastName: "",
          Email: "",
          Role: "Mentor",
          JuryPanelGroup: "None",
        });
        onSuccess(); 
      } else {
        toast.error("Member data failed submitted.");
      }
    } catch (error) {
      // Hiển thị toast thông báo lỗi khi có sự cố
      toast.error("Member data failed submitted.");
    } finally {
      hideLoading();
    }
  };
  
  

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <form onSubmit={handleMentorSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("importMember.firstName")}
              name="FirstName"
              value={memberData.FirstName}
              required
              variant="outlined"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("importMember.lastName")}
              name="LastName"
              value={memberData.LastName}
              required
              variant="outlined"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              label={t("importMember.email")}
              name="Email"
              value={memberData.Email}
              required
              variant="outlined"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel id="role-label">{t("importMember.role.title")}</InputLabel>
              <Select
                labelId="role-label"
                label={t("importMember.role.title")}
                name="Role"
                value={memberData.Role}
                onChange={handleInputChange}
              >
                <MenuItem value="Mentor">{t("importMember.role.roleMentor")}</MenuItem>
                <MenuItem value="JuryPanel">{t("importMember.role.roleJuryPanel")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Jury Panel Group*/}
          {memberData.Role === "JuryPanel" && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="jury-panel-group-label">{t("importMember.juryPanelGroupTitle")}</InputLabel>
                <Select
                  labelId="jury-panel-group-label"
                  label={t("importMember.juryPanelGroupTitle")}
                  name="JuryPanelGroup"
                  value={memberData.JuryPanelGroup}
                  onChange={handleInputChange}
                >
                  <MenuItem value="None">{t("importMember.optionNone")}</MenuItem>
                  {juryPanelOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        {/* Submit Button */}
        <Box mt={2} mb={3}>
          <Button type="submit" variant="contained" color="primary">
          {t("importMember.buttonAddMember")}
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default ImportMemberForm;
