import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { OKRContext } from "../../../context/OKRContext";
import { toast } from "react-toastify";
import ReportHeader from "./ReportHeader";
import ReportTable from "./ReportTable";
import HistoryModal from "./HistoryModal";
import FooterControls from "./FooterControls";

const ReportList = () => {
  const { objectiveId } = useParams();
  const [objectiveData, setReportObjectiveDetails] = useState(null);
  const [createBy, setcreateBy] = useState(null);
  const [questionsData, setQuestionsData] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const {
    fetchReportObjectiveDetail,
    fetchReportObjectiveDraftingDetail,
    fetchQuestionsReport,
    fetchCreateReportObjective,
    fetchCreateReportLabOrMyObjective,
    fetchCreateReportTeamChallengeObjective,
    fetchUpdateDraftReportObjective,
    fetchHistoryReportObjective,
    fetchUpdateSummarizingReportObjective,
    fetchUpdateStatusAccomplishObjective,
    auth,
  } = useContext(OKRContext);

  const [currentValues, setCurrentValues] = useState({});
  const [errors, setErrors] = useState({});
  const [completionEvaluations, setCompletionEvaluations] = useState({});
  const [speedEvaluations, setSpeedEvaluations] = useState({});
  const [answersContent, setAnswersContent] = useState({});
  const [answersId, setAnswersId] = useState({});
  const [wordCounts, setWordCounts] = useState({});
  const [nextCheckInDate, setNextCheckInDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [nextCheckInDateError, setNextCheckInDateError] = useState("");
  const today = new Date();
  const todayDate = today.toISOString().split("T")[0];
  const tomorrow = new Date(new Date().getTime());
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toISOString().split("T")[0];
  const [okrsCompletionChecked, setOkrsCompletionChecked] = useState(false);

  useEffect(() => {
    setcreateBy(auth?.user?.jti);
    if (
      !objectiveData ||
      (objectiveData.objective &&
        objectiveData.objective.id !== parseInt(objectiveId, 10))
    ) {
      loadReportObjectiveDetail();
    }
  }, [
    objectiveId,
    fetchReportObjectiveDetail,
    fetchReportObjectiveDraftingDetail,
    fetchQuestionsReport,
  ]);

  const loadReportObjectiveDetail = async () => {
    try {
      setHistoryData((await fetchHistoryReportObjective(objectiveId)) || []);
      const draftDetails = await fetchReportObjectiveDraftingDetail(
        objectiveId
      );
      const details = await fetchReportObjectiveDetail(objectiveId);
      const chosenObjectiveData = draftDetails
        ? {
            objective: {
              typechuan: "draftDetails",
              parentObjectiveId: draftDetails.parentObjectiveId,
              createBy: draftDetails.createBy,
              id: draftDetails.id,
              objectiveId: draftDetails.objectiveId,
              target: draftDetails.target,
              description: draftDetails.description,
              registrationStartDate: draftDetails.registrationStartDate,
              registrationEndDate: draftDetails.registrationEndDate,
              startDate: draftDetails.startDate,
              endDate: draftDetails.endDate,
              type: draftDetails.type,
              progressPercentage: draftDetails.progressPercentage || 0,
            },
            progressPerformances: draftDetails.reportProgressPerformances.map(
              (perf) => ({
                id: perf.id,
                progressPerformanceId: perf.id,
                progressPerformanceIdDraft: perf.progressPerformanceId,
                targetValue: perf.targetValue,
                currentValue: perf.currentValue,
                progressPercentage: perf.progressPercentage,
                evaluateCompletion: perf.evaluateCompletion || "Good",
                evaluateCompletionSpeed:
                  perf.evaluateCompletionSpeed || "Normal",
                answers: perf.answers,
                keyResult: {
                  title: perf.keyResult ? perf.keyResult.title : "",
                  measurementUnit: perf.keyResult
                    ? perf.keyResult.measurementUnit
                    : "",
                },
              })
            ),
            nextReportDate: draftDetails.nextReportDate,
          }
        : details;
      setReportObjectiveDetails(chosenObjectiveData);
      const questions = await fetchQuestionsReport();
      setQuestionsData(questions);

      // Initialize state with data
      const initialCurrentValues = {};
      const initialProgressPercentages = {};
      const initialCompletionEvaluations = {};
      const initialSpeedEvaluations = {};
      const initialAnswersContent = {};
      const initialAnswersId = {};
      const initialWordCounts = {};

      chosenObjectiveData.progressPerformances.map((performance) => {
        initialCurrentValues[performance.id] = performance.currentValue || "";
        initialProgressPercentages[performance.id] =
          performance.progressPercentage || 0;
        initialCompletionEvaluations[performance.id] =
          performance.evaluateCompletion || "Good";
        initialSpeedEvaluations[performance.id] =
          performance.evaluateCompletionSpeed || "Normal";

        performance.answers.forEach((answer) => {
          const content = answer.content || "";
          const id = answer.id || "";
          initialAnswersContent[`${performance.id}-${answer.questionId}`] =
            content;
          initialWordCounts[`${performance.id}-${answer.questionId}`] =
            content.length;
          initialAnswersId[`${performance.id}-${answer.questionId}`] = id;
        });
      });

      setCurrentValues(initialCurrentValues);
      setCompletionEvaluations(initialCompletionEvaluations);
      setSpeedEvaluations(initialSpeedEvaluations);
      setAnswersContent(initialAnswersContent);
      setWordCounts(initialWordCounts);
      setAnswersId(initialAnswersId);
      if (chosenObjectiveData.nextReportDate) {
        const nextReportDate = new Date(chosenObjectiveData.nextReportDate);
      
        if (!isNaN(nextReportDate.getTime())) {
          const formattedDate = nextReportDate.toLocaleDateString('en-CA');  
          setNextCheckInDate(formattedDate);
        } else {
          console.error("nextReportDate không phải là một đối tượng Date hợp lệ");
        }
      } else {
        console.error("Không có nextReportDate trong chosenObjectiveData");
      }
      
    } catch (error) {
      console.error("Failed to fetch objective details:", error);
    }
  };
  const isEditable = objectiveData?.objective?.createBy === auth?.user?.jti;

  const handleNextCheckInDateChange = (e) => {
    const selectedDate = e.target.value;
    console.log(selectedDate)
    setNextCheckInDate(selectedDate);
    
    if (selectedDate&&selectedDate >= tomorrowDate) {
      setNextCheckInDateError("");
    } else {
      setNextCheckInDateError("Please select a date after today.");
    }
  };

  const openHistoryModal = async () => {
    try {
      // const history = await fetchHistoryReportObjective(objectiveId);
      // setHistoryData(history || []);
      setHistoryModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch history data:", error);
    }
  };
  const handleAccomplish = async () => {
    try {
      const response = await fetchUpdateStatusAccomplishObjective(objectiveId);

      // Kiểm tra mã trạng thái HTTP là 200
      if (response === 200) {
        toast.success("Update Status Accomplish Objective successfully.");
        navigate("/okr/okrreport"); // Chuyển trang chỉ khi thành công
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      console.error("Error Update Status Accomplish Objective:", error);
      toast.error(
        "Error Update Status Accomplish Objective. Please try again."
      );
    }
  };

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
  };

  const handleAnswerContentChange = (e, questionId, performanceId) => {
    const content = e.target.value;

    if (content.length <= 500) {
      setAnswersContent((prev) => ({
        ...prev,
        [`${performanceId}-${questionId}`]: content,
      }));
      setWordCounts((prev) => ({
        ...prev,
        [`${performanceId}-${questionId}`]: content.length,
      }));
    }
  };
  const handleCurrentValueChange = (e, performanceId, targetValue) => {
    const value = e.target.value;

    // Allow empty input or validate decimal format
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, [performanceId]: "" }));

      setCurrentValues((prevValues) => ({
        ...prevValues,
        [performanceId]: value,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [performanceId]: "Please enter a valid decimal number",
      }));
    }
  };

  const handleCompletionEvaluationChange = (performanceId, value) => {
    setCompletionEvaluations((prev) => ({
      ...prev,
      [performanceId]: value,
    }));
  };

  const handleSpeedEvaluationChange = (performanceId, value) => {
    setSpeedEvaluations((prev) => ({
      ...prev,
      [performanceId]: value,
    }));
  };
  const prepareReportData = () => {
    return {
      nextReportDate: nextCheckInDate,
      objectiveId: parseInt(objectiveId, 10),
      targetSnapshot: objectiveData.objective.target,
      descriptionSnapshot: objectiveData.objective.description,
      registrationStartDateSnapshot:
        objectiveData.objective.registrationStartDate,
      registrationEndDateSnapshot: objectiveData.objective.registrationEndDate,
      startDateSnapshot: objectiveData.objective.startDate,
      milestoneDateSnapshot: null,
      endDateSnapshot: objectiveData.objective.endDate,
      completionTimeSnapshot: null,
      typeSnapshot: objectiveData.objective.type,
      createdAtSnapshot: todayDate,
      reportProgressPerformanceDTOs: objectiveData.progressPerformances.map(
        (performance) => {
          const currentValue =
            currentValues[performance.id] ?? (performance.currentValue || 0);
          const progressPercentage =
            currentValue && performance.targetValue
              ? (parseFloat(currentValue) / performance.targetValue) * 100
              : 0; // Set to 0 if currentValue is empty or 0

          return {
            progressPerformanceId: performance.id || null,
            targetValueSnapshot: performance.targetValue,
            currentValueSnapshot: currentValue,
            progressPercentageSnapshot: progressPercentage, // Ensures progress is 0 if currentValue is 0
            evaluateCompletionSnapshot:
              completionEvaluations[performance.id] ??
              performance.evaluateCompletion, // Giá trị mặc định nếu không thay đổi
            evaluateCompletionSpeedSnapshot:
              speedEvaluations[performance.id] ??
              performance.evaluateCompletionSpeed,
            answerDTOs: questionsData.map((question) => ({
              content: answersContent[`${performance.id}-${question.id}`] || "",
              questionId: question.id,
            })),
          };
        }
      ),
    };
  };

  const prepareDraftReportData = () => {
    return {
      nextReportDate: nextCheckInDate,
      id: objectiveData.objective.id,
      objectiveId: parseInt(objectiveId, 10),
      targetSnapshot: objectiveData.objective.target,
      descriptionSnapshot: objectiveData.objective.description,
      registrationStartDateSnapshot:
        objectiveData.objective.registrationStartDate,
      registrationEndDateSnapshot: objectiveData.objective.registrationEndDate,
      startDateSnapshot: objectiveData.objective.startDate,
      milestoneDateSnapshot: null,
      endDateSnapshot: objectiveData.objective.endDate,
      completionTimeSnapshot: null,
      typeSnapshot: objectiveData.objective.type,
      createdAtSnapshot: todayDate,
      reportProgressPerformanceDTOs: objectiveData.progressPerformances.map(
        (performance) => {
          const currentValue =
            currentValues[performance.id] ?? (performance.currentValue || 0);
          // Set to 0 if empty
          const progressPercentage =
            currentValue && performance.targetValue
              ? (parseFloat(currentValue) / performance.targetValue) * 100
              : 0; // Set to 0 if currentValue is empty or 0

          return {
            id: performance.id,
            progressPerformanceId:
              performance.progressPerformanceIdDraft || null,
            reportObjectiveId: objectiveData.objective.id,
            progressPercentageSnapshot: progressPercentage, // Ensures progress is 0 if currentValue is 0
            targetValueSnapshot: performance.targetValue,
            currentValueSnapshot: currentValue,
            evaluateCompletionSnapshot:
              completionEvaluations[performance.id] ??
              performance.evaluateCompletion, // Giá trị mặc định nếu không thay đổi
            evaluateCompletionSpeedSnapshot:
              speedEvaluations[performance.id] ??
              performance.evaluateCompletionSpeed,
            answerDTOs: questionsData.map((question) => ({
              id: answersId[`${performance.id}-${question.id}`] || "",
              content: answersContent[`${performance.id}-${question.id}`] || "",
              questionId: question.id,
              createdAt: todayDate,
              reportProgressPerformanceId: performance.id || null,
            })),
          };
        }
      ),
    };
  };

  const handleSave = async (status) => {
    let hasError = false;

    // Kiểm tra lỗi ngày tháng
    if (nextCheckInDate < tomorrowDate) {
      console.log(nextCheckInDate)
      setNextCheckInDateError("Please select a date after today.");
      hasError = true;
      return;
    }

    // Kiểm tra lỗi keyresult
    if (objectiveData.progressPerformances.length === 0) {
      toast.error("You have not created any keyresult yet.");
      return;
    }

    // Nếu có lỗi trước đó, không tiếp tục
    if (hasError) {
      return;
    }

    try {
      setLoading(true);
      const finalStatus = status === "Review" ? "Review" : "Draft";
      let response;

      if (objectiveData?.objective?.typechuan === "draftDetails") {
        const data = prepareDraftReportData();
        response = await fetchUpdateDraftReportObjective(data);
      } else {
        const data = prepareReportData();
        response = await fetchCreateReportObjective(finalStatus, data);
      }

      // Kiểm tra mã trạng thái HTTP từ response
      if (response === 200) {
        toast.success(
          `Report successfully ${
            finalStatus === "Review" ? "summarized" : "saved as draft"
          }`
        );
        navigate("/okr/okrreport"); // Chuyển trang chỉ khi thành công
      } else {
        throw new Error("Failed to save the report");
      }
    } catch (error) {
      console.error("Failed to update report objective:", error);
      toast.error(
        "An error occurred while saving the report. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSaveREPORTDONE = async (status) => {
    console.log("handleSaveREPORTDONE")
    if (nextCheckInDate < tomorrowDate) {
     
      setNextCheckInDateError("Please select a date after today.");
      return;
    }
    if (objectiveData.progressPerformances.length === 0) {
      toast.error("You have not created any keyresult yet.");
      return;
    }
    try {
      setLoading(true);
      let response;

      // Kiểm tra nếu loại objective không phải là "draftDetails"
      if (objectiveData?.objective?.typechuan !== "draftDetails") {
        const data = prepareReportData();
        response = await fetchCreateReportObjective("Review", data);
      } else {
        const data = prepareDraftReportData();
        response = await fetchUpdateSummarizingReportObjective(data);
      }

      // Kiểm tra mã trạng thái HTTP là 200 (hoặc response.ok nếu sử dụng fetch API)
      if (response === 200) {
        toast.success("Report successfully reviewed");
        navigate("/okr/okrreport"); // Chuyển trang chỉ khi thành công
      } else {
        throw new Error("Failed to review report");
      }
    } catch (error) {
      console.error("Failed to update report objective:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveLabOrMyObjective = async (status) => {
    console.log("handleSaveLabOrMyObjective")
    if (objectiveData.progressPerformances.length === 0) {
      toast.error("You have not created any keyresult yet.");
      return;
    }
    try {
      setLoading(true);
      const data = prepareReportData();
      let response;
      if (status === "Team_Challenge_Objective") {
        response = await fetchCreateReportTeamChallengeObjective(data);
      } else {
        response = await fetchCreateReportLabOrMyObjective(data);
      }

      // Kiểm tra mã trạng thái HTTP là 200
      if (response === 200) {
        toast.success("Report successfully reviewed");
        navigate("/okr/okrreport"); // Chuyển trang chỉ khi thành công
      } else {
        throw new Error("Failed to create report");
      }
    } catch (error) {
      console.error("Failed to update report objective:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleNavigateToFeedback = (id) => {
    navigate(`/okr-report/okr-feedback/${id}`);
  };

  if (!objectiveData || !objectiveData.objective) return <CircularProgress />;

  return (
    <Box>
        <ReportHeader
          type={objectiveData.objective.type}
          parentObjectiveId={objectiveData.objective.parentObjectiveId}
          isEditable={isEditable}
          objectiveData={objectiveData}
          historyData={historyData}
          openHistoryModal={openHistoryModal}
          handleAccomplish={handleAccomplish}
          auth={auth?.user?.jti}
        />

      {objectiveData.progressPerformances &&
        objectiveData.progressPerformances.map((performance) => (
          <ReportTable
            type={objectiveData.objective.type}
            parentObjectiveId={objectiveData.objective.parentObjectiveId}
            key={performance.id}
            performance={performance}
            questionsData={questionsData}
            currentValues={currentValues}
            isEditable={isEditable}
            handleCurrentValueChange={handleCurrentValueChange}
            completionEvaluations={completionEvaluations}
            handleCompletionEvaluationChange={handleCompletionEvaluationChange}
            speedEvaluations={speedEvaluations}
            handleSpeedEvaluationChange={handleSpeedEvaluationChange}
            answersContent={answersContent}
            handleAnswerContentChange={handleAnswerContentChange}
            wordCounts={wordCounts}
            errors={errors}
          />
        ))}
      {isEditable && (
        <FooterControls
          type={objectiveData.objective.type}
          parentObjectiveId={objectiveData.objective.parentObjectiveId}
          okrsCompletionChecked={okrsCompletionChecked}
          setOkrsCompletionChecked={setOkrsCompletionChecked}
          nextCheckInDate={nextCheckInDate}
          handleNextCheckInDateChange={handleNextCheckInDateChange}
          handleSave={handleSave}
          handleSaveREPORTDONE={handleSaveREPORTDONE}
          handleSaveLabOrMyObjective={handleSaveLabOrMyObjective}
          objectiveData={objectiveData}
          startDate={objectiveData?.objective?.startDate}
          nextCheckInDateError={nextCheckInDateError}
          loading={loading}
        />
      )}
      <HistoryModal
        isOpen={isHistoryModalOpen}
        onClose={closeHistoryModal}
        historyData={historyData}
        handleNavigateToFeedback={handleNavigateToFeedback}
      />
    </Box>
  );
};

export default ReportList;
