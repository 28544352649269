import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { toast } from "react-toastify";
import { OKRContext } from "../../../context/OKRContext";

const AddNewKeyResultModal = ({
  open,
  onClose,
  // parentObjective = {},
  objective = {},
  // parternObjectiveId,
  // objectiveId,
  // objectiveStartDate,
  onKeyResultAdded,
  initialData = null, // Prop mới cho dữ liệu ban đầu
}) => {
  const {
    fetchMeasurementUnits,
    // fetchKeyResultsByObjectiveId,
    updateChildKeyResult,
    updateParternKeyResult,
    updateKeyResult,
  } = useContext(OKRContext);

  const initialFormValues = {
    title: "",
    target: "",
    unit: "",
    startDate: "",
    endDate: "",
    planLink: "",
    resultLink: "",
    relatedKeyResults: [],
  };

  // const { parentObjectiveId, ObjectiveStartDate, ObjectiveEndDate } =
  //   parentObjective;

  const [formValues, setFormValues] = useState(initialFormValues);
  const relatedKeyResultsOptions = objective.parentObjective?.keyResults || [];

  const [units, setUnits] = useState([]);
  // const [relatedKeyResultsOptions, setRelatedKeyResultsOptions] = useState([]);
  const [error, setError] = useState({});

  // useEffect(() => {
  //   if (initialData) {
  //     console.log("Dữ liệu key result trước khi cập nhật:", initialData);
  //     setFormValues({
  //       title: initialData.title || "",
  //       target: initialData.targetValue || "",
  //       unit: initialData.measurementUnit || "",
  //       startDate: initialData.startDate || "",
  //       endDate: initialData.endDate || "",
  //       planLink: initialData.planLink || "",
  //       resultLink: initialData.resultLink || "",
  //       relatedKeyResults:
  //         initialData.parentKeyResults.map((kr) => ({
  //           id: kr.id,
  //         })) || [],
  //     });
  //   } else {
  //     setFormValues(initialFormValues); // Đặt lại form cho chế độ thêm mới
  //   }
  // }, [initialData]);

  // useEffect(() => {
  //   if (initialData && objective.parentObjective) {
  //     const selectedIds = initialData.parentKeyResults.map((kr) => kr.id);
  //     const parentObjectiveKeyResults =
  //       objective.parentObjective.keyResults.map((kr) => kr.id);

  //     // Lọc ra các ID đã có trong objective.parentObjective.keyResults
  //     const filteredRelatedKeyResults = selectedIds.filter((id) =>
  //       parentObjectiveKeyResults.includes(id)
  //     );

  //     console.log("initialData", initialData);

  //     setFormValues({
  //       title: initialData.title || "",
  //       target: initialData.targetValue || "",
  //       unit: initialData.measurementUnit || "",
  //       startDate: initialData.startDate || "",
  //       endDate: initialData.endDate || "",
  //       planLink: initialData.planLink || "",
  //       resultLink: initialData.resultLink || "",
  //       relatedKeyResults: filteredRelatedKeyResults, // Chỉ bao gồm các ID đã được liên kết
  //     });
  //   } else {
  //     setFormValues(initialFormValues); // Reset form khi thêm mới
  //   }
  // }, [initialData, objective.parentObjective]);

  useEffect(() => {
    console.log("Initial Data:", initialData);
    if (initialData) {
      const selectedIds =
        initialData.parentKeyResults?.map((kr) => kr.id) || [];
      const parentObjectiveKeyResults =
        objective.parentObjective?.keyResults?.map((kr) => kr.id) || [];

      const filteredRelatedKeyResults = selectedIds.filter((id) =>
        parentObjectiveKeyResults.includes(id)
      );

      setFormValues({
        title: initialData.title || "",
        target: initialData.targetValue || "",
        unit: initialData.measurementUnit || "",
        startDate: initialData.startDate || "",
        endDate: initialData.endDate || "",
        planLink: initialData.planLink || "",
        resultLink: initialData.resultLink || "",
        relatedKeyResults: filteredRelatedKeyResults,
      });
    } else {
      setFormValues(initialFormValues); // Đặt lại form khi thêm mới
    }
  }, [initialData, objective.parentObjective]);

  // Fetch measurement units on modal open
  useEffect(() => {
    if (open) {
      fetchMeasurementUnits()
        .then((fetchedUnits) => setUnits(fetchedUnits))
        .catch((error) => console.error("Failed to fetch units", error));
    }
  }, [open, fetchMeasurementUnits]);

  // Fetch related key results if parternObjectiveId is present
  // useEffect(() => {
  //   if (parentObjectiveId) {
  //     fetchKeyResultsByObjectiveId(parentObjectiveId)
  //       .then((keyResults) => {
  //         setRelatedKeyResultsOptions(keyResults); // Populate related key results in dropdown
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching related key results:", error);
  //       });
  //   }
  // }, [parentObjective, fetchKeyResultsByObjectiveId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setError({ ...error, [name]: "" });
  };

  const handleMultiSelectChange = (e) => {
    const { value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      relatedKeyResults: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const validateForm = () => {
    let hasError = false;
    let newError = {};

    // const now = new Date();
    const startDate = new Date(formValues.startDate);
    const endDate = new Date(formValues.endDate);

    // Title validation
    if (!formValues.title) {
      newError.title = "Title is required";
      hasError = true;
    } else if (formValues.title.length > 100) {
      newError.title = "Title cannot exceed 100 characters";
      hasError = true;
    }

    if (
      !formValues.target ||
      isNaN(formValues.target) ||
      Number(formValues.target) <= 0
    ) {
      newError.target = "Target must be a positive number";
      hasError = true;
    }

    if (!formValues.unit) {
      newError.unit = "Unit is required";
      hasError = true;
    }

    // if (!formValues.startDate || startDate < now) {
    //   newError.startDate = "Start date and time must be in the future";
    //   hasError = true;
    // }

    if (startDate < new Date(objective.objectiveStartDate)) {
      newError.startDate = `Start date must be after ${objective.objectiveStartDate}`;
      hasError = true;
    }

    if (startDate > new Date(objective.objectiveEndDate)) {
      newError.startDate = `Start date must be before ${objective.objectiveEndDate}`;
      hasError = true;
    }

    if (!formValues.endDate || endDate <= startDate) {
      newError.endDate = "End date must be after start date";
      hasError = true;
    }

    if (endDate > new Date(objective.objectiveEndDate)) {
      newError.endDate = `End date must be before ${objective.objectiveEndDate}`;
      hasError = true;
    }

    if (endDate < new Date(objective.objectiveStartDate)) {
      newError.endDate = `End date must be after ${objective.objectiveStartDate}`;
      hasError = true;
    }

    // Validate Plan Link and Result Link
    const urlPattern = /^(https?:\/\/)/i; // Regex pattern for http(s) URLs
    if (formValues.planLink && !urlPattern.test(formValues.planLink)) {
      newError.planLink =
        "Plan Link must be a valid URL starting with http:// or https://";
      hasError = true;
    }

    if (formValues.resultLink && !urlPattern.test(formValues.resultLink)) {
      newError.resultLink =
        "Result Link must be a valid URL starting with http:// or https://";
      hasError = true;
    }

    setError(newError);
    return !hasError;
  };

  const handleSaveOrUpdate = async () => {
    const isValid = validateForm();

    if (!isValid) {
      console.error("Validation failed", error);
      return;
    }

    const payload = {
      title: formValues.title,
      planLink: formValues.planLink.trim() === "" ? null : formValues.planLink,
      resultLink:
        formValues.resultLink.trim() === "" ? null : formValues.resultLink,
      targetValue: Number(formValues.target),
      measurementUnit: formValues.unit,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
    };

    try {
      if (initialData) {
        // Chế độ cập nhật
        const updatePayload = {
          ...payload,
          parentKeyResultIds:
            formValues.relatedKeyResults.length > 0
              ? formValues.relatedKeyResults
              : [],
        };
        console.log("Payload for updateKeyResult:", updatePayload);
        await updateKeyResult(initialData.id, updatePayload);
        toast.success("Key Result updated successfully!");
      } else {
        // Chế độ thêm mới
        if (!objective.parentObjective) {
          console.log("Payload for updateParternKeyResult:", payload);
          await updateParternKeyResult(objective.objectiveId, payload);
        } else {
          const childPayload = {
            ...payload,
            parentKeyResultDTOs: formValues.relatedKeyResults,
          };
          console.log("Payload for updateChildKeyResult:", childPayload);
          await updateChildKeyResult(objective.objectiveId, childPayload);
        }
        toast.success("Key Result added successfully!");
      }

      onKeyResultAdded();
      onClose();
      console.log("Save successful");
    } catch (error) {
      console.error(
        "Error saving or updating key result:",
        error.response ? error.response.data : error
      );
      toast.error("Failed to save or update Key Result.");
    }
  };

  const handleClose = () => {
    setFormValues(initialFormValues);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setFormValues(initialFormValues);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
          width: "70vw",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          {initialData ? "Update Key Result" : "Add New Key Result"}
        </Typography>

        <TextField
          label="Title"
          variant="outlined"
          name="title"
          value={formValues.title}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mb: 2 }}
          error={!!error.title}
          helperText={error.title}
        />

        <Box display="flex" gap={2} sx={{ mb: 2 }}>
          <TextField
            label="Target"
            variant="outlined"
            name="target"
            value={formValues.target}
            onChange={handleChange}
            fullWidth
            required
            error={!!error.target}
            helperText={error.target}
            type="number"
            inputProps={{
              min: 0,
              step: "0.01",
            }}
          />
          <FormControl fullWidth required error={!!error.unit}>
            <InputLabel>Select the unit</InputLabel>
            <Select name="unit" value={formValues.unit} onChange={handleChange}>
              {units.map((unit, index) => (
                <MenuItem key={index} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
            {error.unit && <FormHelperText>{error.unit}</FormHelperText>}
          </FormControl>
        </Box>

        <Box display="flex" gap={2} sx={{ mb: 2 }}>
          <TextField
            label="Start date"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            name="startDate"
            value={formValues.startDate}
            onChange={handleChange}
            fullWidth
            required
            error={!!error.startDate}
            helperText={error.startDate}
            inputProps={{
              min: objective.objectiveStartDate || "", // Đặt giá trị rỗng nếu không có giá trị
              max: objective.objectiveEndDate || "",
            }}
          />
          <TextField
            label="End date"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            name="endDate"
            value={formValues.endDate}
            onChange={handleChange}
            fullWidth
            required
            error={!!error.endDate}
            helperText={error.endDate}
            inputProps={{
              min: objective.objectiveStartDate || "", // Đặt giá trị rỗng nếu không có giá trị
              max: objective.objectiveEndDate || "",
            }}
          />
        </Box>

        <Box display="flex" gap={2} sx={{ mb: 2 }}>
          <TextField
            label="Plan Link"
            variant="outlined"
            name="planLink"
            value={formValues.planLink}
            onChange={handleChange}
            fullWidth
            error={!!error.planLink}
            helperText={error.planLink}
          />

          <TextField
            label="Result Link"
            variant="outlined"
            name="resultLink"
            value={formValues.resultLink}
            onChange={handleChange}
            fullWidth
            error={!!error.planLink}
            helperText={error.planLink}
          />
        </Box>

        {/* Dropdown for related key results if parternObjectiveId exists */}
        {objective.parentObjective && (
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Related Key Results (Superior)</InputLabel>
            <Select
              multiple
              name="relatedKeyResults"
              value={formValues.relatedKeyResults}
              onChange={handleMultiSelectChange}
              renderValue={(selected) =>
                relatedKeyResultsOptions
                  .filter((kr) => selected.includes(kr.id))
                  .map((kr) => kr.title)
                  .join(", ")
              }
            >
              {relatedKeyResultsOptions.map((kr) => (
                <MenuItem key={kr.id} value={kr.id}>
                  <Checkbox
                    checked={formValues.relatedKeyResults.includes(kr.id)}
                  />

                  <ListItemText primary={kr.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveOrUpdate}
          >
            {initialData ? "Update" : "Add"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewKeyResultModal;
