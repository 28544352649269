import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableSortLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import apiAuth from "../../customs/axiosCustom";
import "react-toastify/dist/ReactToastify.css";
import cameraAuth from "../../customs/axiosBackendAI";

const useCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState({}); // Loading cho từng nút approve
  const [loadingReject, setLoadingReject] = useState({}); // Loading cho từng nút reject
  const [loadingApproveAll, setLoadingApproveAll] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false); // Vô hiệu hóa tất cả các nút

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  
  const fetchPendingApprovalImages = async () => {
    setLoadingFetch(true);
    try {
      const response = await apiAuth.get("/UserImage/pending-approval");
      setCandidates(response.data);
    } catch (error) {
      console.error("Failed to fetch pending approval images:", error);
    } finally {
      setLoadingFetch(false);
    }
  };

  const approveOrRejectImage = async (userId, imageId, approved) => {
    setDisableAllButtons(true);
    if (approved) {
      setLoadingApprove((prevState) => ({ ...prevState, [imageId]: true }));
    } else {
      setLoadingReject((prevState) => ({ ...prevState, [imageId]: true }));
    }

    try {
      if (approved) {
        const response = await apiAuth.put(`/UserImage/update-status`, {
          userId: userId,
          imageId: imageId,
          isApprove: approved,
        });
        if (response.status === 200) {
          const token = getCookie("jwtToken");
          const trainResponse = await cameraAuth.post(
            "/train_model/",
            null,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (trainResponse.status === 200) {
            const { failed_user_ids } = trainResponse.data;
            return failed_user_ids || [];
          } else {
            toast.error("Image approved, but failed to start model training.");
          }
        }
      } else {
        const response = await apiAuth.delete(`/UserImage/delete`, {
          data: {
            userId: userId,
            imageId: imageId,
          },
        });
        if (response.status === 200) {
          toast.success("Image rejected successfully.");
        }
      }
    } catch (error) {
      console.error("Failed to update image status:", error);
      toast.error("Failed to update image status.");
    } finally {
      if (approved) {
        setLoadingApprove((prevState) => ({ ...prevState, [imageId]: false }));
      } else {
        setLoadingReject((prevState) => ({ ...prevState, [imageId]: false }));
      }
      setDisableAllButtons(false);
    }
    return [];
  };

  const approveAllImages = async (userId, images) => {
    setLoadingApproveAll(true);
    setDisableAllButtons(true);

    try {
      const response = await apiAuth.put(`/UserImage/approve-all`, {
        userId: userId,
        imageIds: images.map((image) => image.id),
        isApprove: true,
      });
      if (response.status === 200) {
        const token = getCookie("jwtToken");
        const trainResponse = await cameraAuth.post(
          "/train_model/",
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (trainResponse.status === 200) {
          const { failed_user_ids } = trainResponse.data;
          if(failed_user_ids == null){
          const emailResponse = await apiAuth.post(
            `/UserImage/send-approval-email`,
            {  userId: userId }
          );
        }
          return failed_user_ids || [];
        } else {
          toast.error("All images approved, but failed to start model training.");
        }
      }
    } catch (error) {
      console.error("Failed to approve all images:", error);
      toast.error("Failed to approve all images.");
    } finally {
      setLoadingApproveAll(false);
      setDisableAllButtons(false);
    }
    return [];
  };

  return {
    candidates,
    setCandidates,
    fetchPendingApprovalImages,
    approveOrRejectImage,
    approveAllImages,
    loadingFetch,
    loadingApprove,
    loadingReject,
    loadingApproveAll,
    disableAllButtons,
  };
};

const CandidateList = () => {
  const {
    candidates,
    setCandidates,
    fetchPendingApprovalImages,
    approveOrRejectImage,
    approveAllImages,
    loadingFetch,
    loadingApprove,
    loadingReject,
    loadingApproveAll,
    disableAllButtons,
  } = useCandidates();

  const [sortDirection, setSortDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("userName");
  const [open, setOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  useEffect(() => {
    fetchPendingApprovalImages();
  }, []);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && sortDirection === "asc";
    setOrderBy(property);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const handleOpenImages = (candidate) => {
    setSelectedCandidate(candidate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCandidate(null);
  };

  const handleApproveOrRejectImage = async (imageId, approved) => {
    if (selectedCandidate) {
      const failedUserIds = await approveOrRejectImage(
        selectedCandidate.userId,
        imageId,
        approved
      );

      if (!approved) {
        // Nếu ảnh bị từ chối (reject), loại bỏ ảnh ngay khỏi giao diện
        setSelectedCandidate((prev) => ({
          ...prev,
          images: prev.images.filter((image) => image.id !== imageId),
        }));
      } else {
        // Nếu ảnh được chấp nhận (approve)
        setSelectedCandidate((prev) => ({
          ...prev,
          images: prev.images.map((image) =>
            failedUserIds.includes(image.id)
              ? { ...image, isInvalid: true } // Đánh dấu ảnh không hợp lệ
              : image.id === imageId
                ? null
                : image
          ).filter(Boolean),
        }));

        if (failedUserIds.includes(imageId)) {
          toast.error("Image is invalid and cannot be trained.");
        } else {
          toast.success("Image approved successfully.");
        }
      }

      if (selectedCandidate.images.length === 1) {
        setOpen(false);
        setCandidates((prev) =>
          prev.filter((c) => c.userId !== selectedCandidate.userId)
        );
      }
    }
  };


  const handleApproveAllImages = async () => {
    if (selectedCandidate && selectedCandidate.images) {
      const failedUserIds = await approveAllImages(
        selectedCandidate.userId,
        selectedCandidate.images
      );

      // Cập nhật danh sách ảnh trong `selectedCandidate`
      setSelectedCandidate((prev) => ({
        ...prev,
        images: prev.images.map((image) =>
          failedUserIds.includes(image.id)
            ? { ...image, isInvalid: true } // Đánh dấu ảnh không hợp lệ
            : null
        ).filter(Boolean),
      }));

      // Kiểm tra nếu không còn ảnh nào sau khi duyệt
      if (failedUserIds.length === 0) {
        toast.success("All images approved successfully.");
        setOpen(false);
        setCandidates((prev) =>
          prev.filter((candidate) => candidate.userId !== selectedCandidate.userId)
        );
      } else {
        toast.error("Some images were invalid.");
      }
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box>
        <h2>Image uploads awaiting approval</h2>
        {loadingFetch ? (
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper} sx={{ maxHeight: 510, overflowY: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell width={200}>
                    <TableSortLabel
                      active={orderBy === "userName"}
                      direction={orderBy === "userName" ? sortDirection : "asc"}
                      onClick={() => handleSortRequest("userName")}
                    >
                      User Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width={200}>User Email</TableCell>
                  <TableCell width={200}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidates.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ textAlign: "center", color: "error.main" }}>
                      No Candidate
                    </TableCell>
                  </TableRow>
                ) : (
                  candidates.map((candidate) => (
                    <TableRow key={candidate.userId}>
                      <TableCell>{candidate.userName}</TableCell>
                      <TableCell>{candidate.userEmail}</TableCell>
                      <TableCell width={300}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleOpenImages(candidate)}
                        >
                          Show Images
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {selectedCandidate && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Candidate Images</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleApproveAllImages}
                  disabled={disableAllButtons || loadingApproveAll}
                >
                  {loadingApproveAll ? <CircularProgress size={24} /> : "Approve"}
                </Button>
              </Box>
              {selectedCandidate.images && selectedCandidate.images.length > 0 ? (
                selectedCandidate.images.map((image, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    sx={{
                      border: image.isInvalid ? "2px solid red" : "none",
                      padding: 1,
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={image.secureUrl}
                      alt={`Candidate Image ${index + 1}`}
                      width="150"
                      style={{
                        border: image.isInvalid ? "2px solid red" : "none",
                        borderRadius: "8px",
                      }}
                    />
                    <Box display="flex" alignItems="center" justifyContent="center" gap={1} mt={1}>
                      {/* <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleApproveOrRejectImage(image.id, true)}
                        disabled={disableAllButtons || loadingApprove[image.id]}
                      >
                        {loadingApprove[image.id] ? <CircularProgress size={24} /> : "Approve"}
                      </Button> */}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleApproveOrRejectImage(image.id, false)}
                        disabled={disableAllButtons || loadingReject[image.id]}
                      >
                        {loadingReject[image.id] ? <CircularProgress size={24} /> : "Reject"}
                      </Button>
                    </Box>
                    {image.isInvalid && (
                      <Typography color="error" variant="body2">
                        Invalid image!! Please Reject.
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography>No images available.</Typography>
              )}

            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default CandidateList;
