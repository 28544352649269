import React, { createContext, useState } from "react";
import apiAuth from "../customs/axiosCustom";
import { hideLoading, showLoading } from "../components/Layout/LoadingScreen";
import { useAuth } from "../customs/authService";
import { toast } from "react-toastify";

export const JuryPanelContext = createContext();

export const JuryPanelProvider = ({ children }) => {
  const { auth, currentSemester } = useAuth();
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [juryPanels, setJuryPanels] = useState([]); // Added state for juryPanels
  const [totalItems, setTotalItems] = useState(0); // Added state for totalItems
  const [page, setPage] = useState(0); // Added state for pagination
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Function to handle errors with notifications
  const handleError = (message, err) => {
    setError(message);
    console.error(message, err);
  };
  const fetchJuryPanelGroup = async () => {
    try {
      const response = await apiAuth.get(`/JuryPanel/group`);
      const JuryPanelGroup = response.data || [];
      return JuryPanelGroup;
    } catch (error) {
      console.error("Error fetching JuryPanel Group:", error);
    }
  };
  const fetchGroupByjuryId = async (juryId) => {
    try {
      const response = await apiAuth.get(`/JuryPanel/GetGroupByJuryID?juryId=${juryId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching group data:", error);
      throw error; 
    }
  };
  const fetchUpdatejuryPaneltoGroup = async (payload) => {
    try {
      const response = await apiAuth.put("/JuryPanel/UpdateJuryToGroup", payload);
      return response.data; 
    } catch (error) {
      console.error("Error updating jury panel to group:", error);
      throw error; 
    }
  };

  const fetchJuryPanels = async () => {
    
    try {
      const response = await apiAuth.get("/juryPanel");
      const data = response.data||[];
      return data;
    } catch (error) {
      console.error("Error fetching jury panels:", error);
    }
  };
  const fetchSearchJuryPanel = async (searchText) => {
    try {
      const url = `/JuryPanel?$filter=contains(tolower(email), tolower('${searchText}')) or contains(tolower(fullName), tolower('${searchText}'))`;
      const response = await apiAuth.get(url);
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error searching jury panels:", error);
    }
  };
  const fetchJuryPanel= async () => {
    try {
      const response = await apiAuth.get(`/JuryPanel`);
      const JuryPanelGroup = response.data || [];

      // Update the state directly with the list of types
      return JuryPanelGroup;
    } catch (error) {
      console.error("Error fetching JuryPanel Group:", error);
    }
  };
  const fetchDeleteJuryOfGroup = async (juryId, groupId) => {
    try {
      const response = await apiAuth.delete('/JuryPanel/DeleteJuryOfGroup', {
        data: {
          juryId: juryId,
          groupId: groupId,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting Jury from Group:", error);
      throw new Error("Failed to delete Jury from Group");
    }
  };

  const fetchJuryPanelGroupHaveMember = async () => {
    try {
      const response = await apiAuth.get("/JuryPanel/group-have-member");
      const data = response.data;
      console.log(data);
      return data;
    } catch (error) {
      console.error("Error fetching jury panels:", error);
    }
  };
  const fetchSearchGroup = async (searchKeyword) => {
    try {
      const response = await apiAuth.get(`/JuryPanel/group-have-member?$filter=contains(tolower(name), tolower('${searchKeyword}'))`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching jury panels:", error);
    }
  };

  const fetchAddGroupJuryPanel = async (groupData) => {
    const data ={
      name:groupData.groupName,
      startDate:groupData.startDate,
      endDate:groupData.endDate,
      limit:groupData.maxPeople
    }
    try {
      showLoading();
      const response = await apiAuth.post("/JuryPanel/group", data);
      return response;
    } catch (error) {
      console.error("Error adding group to Jury Panel", error);
      return {
        status: error.response ? error.response.status : 500,
        message: error.message,
      };
    } finally {
      hideLoading();
    
    }
  };

  const importJuryPanel = async (dataToSubmit) => {
    const data = {
      firstName: dataToSubmit.FirstName,
      lastName: dataToSubmit.LastName,
      email: dataToSubmit.Email,
      juryPanelGroups: dataToSubmit.JuryPanelGroup
        ? [dataToSubmit.JuryPanelGroup]
        : null,
    };
    try {
      showLoading();
      const response = await apiAuth.post("/JuryPanel", data);
      return response.status;
    } catch (error) {
      console.error("Error importing Jury Panel:", error);
      return {
        status: error.response ? error.response.status : 500,
        message: error.message,
      };
    } finally {
      hideLoading();
    }
  };

  return (
    <JuryPanelContext.Provider
      value={{
        auth,
        currentSemester,
        importJuryPanel,
        fetchJuryPanelGroup,
        fetchGroupByjuryId,
        fetchUpdatejuryPaneltoGroup,
        setData,
        fetchJuryPanelGroupHaveMember,
        fetchSearchGroup,
        fetchSearchJuryPanel,
        fetchAddGroupJuryPanel,
        fetchJuryPanel,
        fetchJuryPanels,
        fetchDeleteJuryOfGroup,
        data,
        juryPanels,
        totalItems,
        setJuryPanels,
        setPage,
        setRowsPerPage,
      }}
    >
      {children}
    </JuryPanelContext.Provider>
  );
};

export default JuryPanelContext;
