import React, { useState, useEffect,useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormHelperText,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import { MemberImportContext } from "../../context/MemberImportContext";

const ImportLaboratory = () => {
  const { t } = useAuth();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState([]);
  const [laboratories, setLaboratories] = useState([]);
  const [open, setOpen] = useState(false); // State để mở popup
  const [selectedLab, setSelectedLab] = useState(null);
  const {  fetchTemplate, } = useContext(MemberImportContext);
  const [labInfo, setLabInfo] = useState({
    labName: "",
    checkInTime: "",
    checkOutTime: "",
    leaveRequestNum: "",
    labManagerEmail: "",
  });

  const [dialogErrors, setDialogErrors] = useState({
    labName: "",
    checkInTime: "",
    checkOutTime: "",
  });

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");
  };

  useEffect(() => {
    if (file) {
      handleSubmit();
      fetchData();
    }
  }, [file]);

  const handleSubmit = async () => {
    if (!file) {
      toast.warning(t("importLab.selectFileToImport"));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      showLoading();
      const response = await apiAuth.post(`/Import/importLab`, formData);
      if (response.status === 200) {
        toast.success(t("importLab.uploadSuccess"));
        setErrors([]);
        setFile(null); // Clear the file input
        fetchData();
        setFileName("");
      }
    } catch (error) {
      const { data } = error.response;
      setErrors(data?.errorMessages || []);
      if (data?.message === "systemError") {
        toast.error(t(data?.message));
      } else {
        toast.error(t(`importLab.${data?.message}`));
      }
    } finally {
      hideLoading();
    }
  };

  const fetchData = async () => {
    try {
      const response = await apiAuth.get("/Import/GetLaboratory");
      setLaboratories(response.data);
    } catch (error) {
      toast.error("Failed to load laboratories.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownloadTemplate = () => {
    fetchTemplate({ nameFile: "Import_Laboratories" });
  };

  // Hàm xử lý mở popup và đổ dữ liệu vào state
  const handleOpenDialog = (lab) => {
    setSelectedLab(lab);
    setLabInfo({
      labId: lab.labId,
      labName: lab.labName,
      checkInTime: lab.checkInTime,
      checkOutTime: lab.checkOutTime,
    });
    setDialogErrors({
      labName: "",
      checkInTime: "",
      checkOutTime: "",
    });
    setOpen(true);
  };

  // Hàm xử lý đóng popup
  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedLab(null);
  };

  // Hàm xử lý cập nhật thông tin Lab sau khi chỉnh sửa
  const handleEditLab = async () => {
    // Kiểm tra các trường không được bỏ trống
    let hasError = false;
    const newDialogErrors = { labName: "", checkInTime: "", checkOutTime: "" };

    if (!labInfo.labName) {
      newDialogErrors.labName = "Lab Name cannot be null";
      hasError = true;
    }
    if (!labInfo.checkInTime) {
      newDialogErrors.checkInTime = "Check-In Time cannot be null";
      hasError = true;
    }
    if (!labInfo.checkOutTime) {
      newDialogErrors.checkOutTime = "Check-Out Time cannot be null";
      hasError = true;
    }

    setDialogErrors(newDialogErrors);

    if (hasError) {
      return;
    }

    try {
      showLoading();
      const response = await apiAuth.put(`/Import/UpdateLab`, {
        id: labInfo.labId,
        labName: labInfo.labName,
        checkInTime: labInfo.checkInTime,
        checkOutTime: labInfo.checkOutTime,
      });

      if (response.status === 200) {
        toast.success("Edit successful");
        fetchData(); // Load lại danh sách labs sau khi chỉnh sửa thành công
      } else {
        toast.error("Edit failed");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("CheckOut time must greater than checkIn Time");
      } else {
        toast.error("Edit failed");
      }
    } finally {
      hideLoading();
      handleCloseDialog(); // Đóng popup sau khi chỉnh sửa
    }
  };

  // Hàm xử lý thay đổi thông tin các trường
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLabInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8}>
        <Paper style={{ margin: "16px", padding: "16px" }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h5" gutterBottom>
              Import Laboratories from CSV
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadTemplate}
            >
              {t("importJuryPanel.downloadTemplate")}
            </Button>
          </Box>

          <DropPDF onDrop={handleDrop} />
          {fileName && (
            <Typography
              variant="h6"
              style={{ margin: "8px", display: "inline-block" }}
            >
              {fileName}
            </Typography>
          )}

          {errors.length > 0 && (
            <TableContainer
              component={Paper}
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Lab Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Errors
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map((error, index) => {
                    const parts = error.split(":");
                    const lineNumber = parts[0];
                    const errorMessages = parts
                      .slice(1)
                      .join(":")
                      .trim()
                      .split(".")
                      .filter((msg) => msg);

                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {lineNumber}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {errorMessages.map((msg, msgIndex) => (
                            <li key={msgIndex}>
                              {t(`importLab.${msg.trim()}`)}
                            </li>
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Grid>

      <Grid item xs={10}>
        <Paper style={{ margin: "16px", padding: "16px" }}>
          <Typography variant="h5" gutterBottom>
            Laboratory List
          </Typography>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "300px", overflowY: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Lab Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Check-In Time
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Check-Out Time
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Leave Request Number
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Lab Manager Email
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {laboratories.map((lab) => (
                  <TableRow key={lab.Id}>
                    <TableCell style={{ fontSize: "16px" }}>{lab.labName}</TableCell>
                    <TableCell align="center" style={{ fontSize: "16px" }}>
                      {lab.checkInTime}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "16px" }}>
                      {lab.checkOutTime}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "16px" }}>
                      {lab.leaveRequestNum}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {lab.labManagerEmail}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenDialog(lab)}
                      >
                        Edit Lab
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      {/* Popup xác nhận */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Edit information</DialogTitle>
        <DialogContent>
          <TextField
            name="labId"
            value={labInfo.labId}
            type="hidden"
          />
          <TextField
            label="Lab Name"
            name="labName"
            value={labInfo.labName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!dialogErrors.labName} // Hiển thị lỗi nếu có
            helperText={dialogErrors.labName} // Hiển thị thông báo lỗi
          />
          <TextField
            label="Check-In Time"
            name="checkInTime"
            value={labInfo.checkInTime}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!dialogErrors.checkInTime}
            helperText={dialogErrors.checkInTime}
          />
          <TextField
            label="Check-Out Time"
            name="checkOutTime"
            value={labInfo.checkOutTime}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!dialogErrors.checkOutTime}
            helperText={dialogErrors.checkOutTime}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditLab} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ImportLaboratory;
