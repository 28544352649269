import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Toolbar,
  styled,
  ButtonGroup,
  Typography,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import { useAuth } from "../../customs/authService";
import Logo from "../../assets/images/LIM.svg";
import { useNavigate } from "react-router-dom";
import { accountMenus } from "../../assets/configs/routes";
import Attendance from "../Attendance/Attendance";
import Notification from "./Notification";
import Account from "./Account";
import { UserContext } from "../../context/UserContext";
import AutoBatchAttendanceToggle from "../Attendance/AutoBatchAttendanceToggle";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomAppBar = ({ open, handleDrawerOpen, isIntern }) => {
  const { fetchUserRoles, fetchSwitchRole, getUserInfo } =
    useContext(UserContext);
  const { logout, isLogged, t, currentSemester, auth, isTesting, switchRole } =
    useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  const handleRoleChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedRole(selectedValue);
    const token = await fetchSwitchRole(selectedValue);
    if (token && typeof token === "string") {
      switchRole(token);
    } else {
      console.error("Failed to switch role: No valid token returned.");
    }
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleAccountClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorE2(null);
  };

  const handleAccountMenu = (path) => {
    handleAccountClose();
    navigate(path);
  };

  const handleNotificationMenu = (path) => {
    handleNotificationClose();
    navigate(path);
  };
  useEffect(() => {
    const getRoles = async () => {
      const fetchedRoles = await fetchUserRoles();
      setRoles(fetchedRoles);
    };
    console.log("Roles: ", auth?.user?.roles);
    getRoles();
  }, [fetchUserRoles]);

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{
        backgroundColor: "whitesmoke",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          {isLogged() ? (
            <IconButton
              onClick={handleDrawerOpen}
              edge="start"
              size="small"
              sx={{
                ...(open
                  ? { display: "none" }
                  : {
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "&:active": {
                        backgroundColor: "transparent",
                      },
                    }),
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "23%", cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isLogged() ? (
            <>
              {/* Role Selection */}
              <FormControl sx={{ minWidth: 150, marginX: 1 }} color="info">
                  <InputLabel color="info" sx={{ color: "primary.main" }}>
                    Role
                  </InputLabel>
                  <Select
                    value={auth?.user?.roles}
                    onChange={roles.length >= 2 ? handleRoleChange : undefined} 
                    label="Role"
                    color="info"
                    disabled={roles.length < 2} 
                    sx={{
                      width: "100%", // Fixed width
                      height: "32px",
                      color: "primary.main", // Text color inside Select
                      ".MuiSelect-icon": {
                        color: "primary.main", // Arrow icon color
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.main", // Set border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.dark", // Darker border on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.main", // Border color when focused
                      },
                      ".MuiSelect-select": {
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      },
                    }}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={role}
                        value={role}
                        sx={{
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          color: "primary.main", // Text color for menu items
                        }}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              <Tooltip
                title={
                  currentSemester?.startDate && currentSemester?.endDate
                    ? t("currentSemester.period", {
                        startDate: new Date(
                          currentSemester?.startDate
                        ).toLocaleDateString("en-GB"),
                        endDate: new Date(
                          currentSemester?.endDate
                        ).toLocaleDateString("en-GB"),
                      })
                    : t("semesterWait")
                }
              >
                <Typography
                  sx={{
                    color: "primary.main",
                    verticalAlign: "middle",
                    marginX: 3,
                  }}
                >
                  {t("currentSemester.name", {
                    name: currentSemester?.semesterName ?? t("semesterWait"),
                  })}
                </Typography>
              </Tooltip>
              {auth?.user?.roles.includes("System Admin") && isTesting() && (
                <AutoBatchAttendanceToggle />
              )}
              {isIntern && <Attendance />}

              <Notification
                handleNotificationClick={handleNotificationClick}
                handleNotificationClose={handleNotificationClose}
                handleNotificationMenu={handleNotificationMenu}
                anchorEl={anchorEl}
              />
              <Account
                accountMenus={accountMenus}
                anchorE2={anchorE2}
                handleAccountClick={handleAccountClick}
                handleAccountClose={handleAccountClose}
                handleAccountMenu={handleAccountMenu}
                logout={logout}
              />
            </>
          ) : (
            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button
                onClick={() => navigate("/login")}
                variant="contained"
                color="info"
              >
                Log in
              </Button>
              <Button
                onClick={() => navigate("/admin/login")}
                variant="contained"
                color="info"
              >
                Log in (Admin)
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
