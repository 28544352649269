import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { OKRContext } from "../../../context/OKRContext";
import { toast } from "react-toastify";
import ReportHeader from "./ReportHeader";
import ReportTable from "./ReportTable";
import HistoryModal from "./HistoryModal";
import FooterControls from "./FooterControls";

const ReportOverview = () => {
  const { objectiveId } = useParams();
  const [objectiveData, setReportObjectiveDetails] = useState(null);
  const [questionsData, setQuestionsData] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const {
    fetchReportObjectiveDetail,
    fetchReportObjectiveDraftingDetail,
    fetchQuestionsReport,
    fetchHistoryReportObjective,
    auth,
    fetchUpdateStatusAccomplishObjective,
  } = useContext(OKRContext);

  const [currentValues, setCurrentValues] = useState({});
  const [errors, setErrors] = useState({});
  const [completionEvaluations, setCompletionEvaluations] = useState({});
  const [speedEvaluations, setSpeedEvaluations] = useState({});
  const [answersContent, setAnswersContent] = useState({});
  const [wordCounts, setWordCounts] = useState({});
  const today = new Date();

  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  useEffect(() => {
    if (
      !objectiveData ||
      (objectiveData.objective &&
        objectiveData.objective.id !== parseInt(objectiveId, 10))
    ) {
      loadReportObjectiveDetail();
    }
  }, [
    objectiveId,
    fetchReportObjectiveDetail,
    fetchReportObjectiveDraftingDetail,
    fetchQuestionsReport,
  ]);

  const loadReportObjectiveDetail = async () => {
    try {
      setHistoryData((await fetchHistoryReportObjective(objectiveId)) || []);
      const details = await fetchReportObjectiveDetail(objectiveId);
      const chosenObjectiveData =  details;
      setReportObjectiveDetails(chosenObjectiveData);
      const questions = await fetchQuestionsReport();
      setQuestionsData(questions);

      // Initialize state with data
      const initialCurrentValues = {};
      const initialProgressPercentages = {};
      const initialCompletionEvaluations = {};
      const initialSpeedEvaluations = {};
      const initialAnswersContent = {};
      const initialAnswersId = {};
      const initialWordCounts = {};

      chosenObjectiveData.progressPerformances.map((performance) => {
        initialCurrentValues[performance.id] = performance.currentValue || "";
        initialProgressPercentages[performance.id] =
          performance.progressPercentage || 0;
        initialCompletionEvaluations[performance.id] =
          performance.evaluateCompletion || "Good";
        initialSpeedEvaluations[performance.id] =
          performance.evaluateCompletionSpeed || "Normal";

        performance.answers.forEach((answer) => {
          const content = answer.content || "";
          const id = answer.id || "";
          initialAnswersContent[`${performance.id}-${answer.questionId}`] =
            content;
          initialWordCounts[`${performance.id}-${answer.questionId}`] =
            content.length;
          initialAnswersId[`${performance.id}-${answer.questionId}`] = id;
        });
      });

      setCurrentValues(initialCurrentValues);
      setCompletionEvaluations(initialCompletionEvaluations);
      setSpeedEvaluations(initialSpeedEvaluations);
      setAnswersContent(initialAnswersContent);
      setWordCounts(initialWordCounts);
    } catch (error) {
      console.error("Failed to fetch objective details:", error);
    }
  };
  const isEditable = false;

  const openHistoryModal = async () => {
    try {
      // const history = await fetchHistoryReportObjective(objectiveId);
      // setHistoryData(history || []);
      setHistoryModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch history data:", error);
    }
  };

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
  };

  const handleAnswerContentChange = (e, questionId, performanceId) => {
    const content = e.target.value;

    if (content.length <= 500) {
      setAnswersContent((prev) => ({
        ...prev,
        [`${performanceId}-${questionId}`]: content,
      }));
      setWordCounts((prev) => ({
        ...prev,
        [`${performanceId}-${questionId}`]: content.length,
      }));
    }
  };
  const handleCurrentValueChange = (e, performanceId, targetValue) => {
    const value = e.target.value;

    // Allow empty input or validate decimal format
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, [performanceId]: "" }));

      setCurrentValues((prevValues) => ({
        ...prevValues,
        [performanceId]: value,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [performanceId]: "Please enter a valid decimal number",
      }));
    }
  };

  const handleCompletionEvaluationChange = (performanceId, value) => {
    setCompletionEvaluations((prev) => ({
      ...prev,
      [performanceId]: value,
    }));
  };

  const handleSpeedEvaluationChange = (performanceId, value) => {
    setSpeedEvaluations((prev) => ({
      ...prev,
      [performanceId]: value,
    }));
  };
  const handleAccomplish = async () => {
    try {
      const response = await fetchUpdateStatusAccomplishObjective(objectiveId);
  
      // Kiểm tra mã trạng thái HTTP là 200
      if (response===200) {
        toast.success("Update Status Accomplish Objective successfully.");
        navigate("/okr/okrreport"); // Chuyển trang chỉ khi thành công
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      console.error("Error Update Status Accomplish Objective:", error);
      toast.error("Error Update Status Accomplish Objective. Please try again.");
    }
  };
  

  const navigate = useNavigate();

  const handleNavigateToFeedback = (id) => {
    navigate(`/okr-report/okr-feedback/${id}`);
  };

  if (!objectiveData || !objectiveData.objective) return <CircularProgress />;

  return (
    <Box>
      <ReportHeader
      type={objectiveData.objective.type}
        objectiveData={objectiveData}
        parentObjectiveId={objectiveData.objective.parentObjectiveId}
        historyData={historyData}
        openHistoryModal={openHistoryModal}
        isOverview={true}
        auth={auth?.user?.jti}
        handleAccomplish={handleAccomplish}
      />
      {objectiveData.progressPerformances &&
        objectiveData.progressPerformances.map((performance) => (
          <ReportTable
          type={objectiveData.objective.type}
            key={performance.id}
            performance={performance}
            questionsData={questionsData}
            currentValues={currentValues}
            isEditable={isEditable}
            handleCurrentValueChange={handleCurrentValueChange}
            completionEvaluations={completionEvaluations}
            handleCompletionEvaluationChange={handleCompletionEvaluationChange}
            speedEvaluations={speedEvaluations}
            handleSpeedEvaluationChange={handleSpeedEvaluationChange}
            answersContent={answersContent}
            handleAnswerContentChange={handleAnswerContentChange}
            wordCounts={wordCounts}
            errors={errors}
            isOverview={true}
          />
        ))}
      <HistoryModal
      type={objectiveData.objective.type}
        isOpen={isHistoryModalOpen}
        onClose={closeHistoryModal}
        historyData={historyData}
        handleNavigateToFeedback={handleNavigateToFeedback}
      />
    </Box>
  );
};

export default ReportOverview;
