import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import * as faceapi from "@vladmandic/face-api";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import cameraAuth from "../../customs/axiosBackendAI";
import "../../assets/css/AttendanceModal.css";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  padding: 3,
  bgcolor: "background.paper",
};

const AttendanceModal = ({
  open,
  isCheckIn,
  handleClose,
  handleSetNotify,
  faceVector,
  submitUrl,
  handleCheckIn,
  handleCheckInSubmitTime,
  handleCheckOutSubmitTime,
  handleFaceVector,
}) => {
  const { t } = useAuth();
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [hasFaceDetected, setHasFaceDetected] = useState(false);
  const [otp, setOtp] = useState("");
  const [sessionOtp, setSessionOtp] = useState(null); // Lưu OTP tạm thời trong session
  const [otpTimestamp, setOtpTimestamp] = useState(null); // Lưu thời gian tạo OTP
  const [mode, setMode] = useState("face");
  const [hasCamera, setHasCamera] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCameraReady, setIsCameraReady] = useState(false);

  // const loadModels = async () => {
  //   // Tải các mô hình từ thư mục /model
  //   await faceapi.nets.tinyFaceDetector.loadFromUri("/model");
  //   await faceapi.nets.faceLandmark68Net.loadFromUri("/model");
  //   await faceapi.nets.faceRecognitionNet.loadFromUri("/model");
  // };

  // const detectFace = async () => {
  //   if (videoRef.current) {
  //     const result = await faceapi
  //       .detectSingleFace(videoRef.current, new faceapi.TinyFaceDetectorOptions())
  //       .withFaceLandmarks()
  //       .withFaceDescriptor();

  //     setHasFaceDetected(!!result); // Cập nhật trạng thái nếu phát hiện khuôn mặt
  //   }
  // };

  const checkCamera = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setHasCamera(videoDevices.length > 0);
      })
      .catch((err) => {
        console.error("Error checking devices:", err);
      });
  };

  const stopVideo = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    checkCamera();
    const startVideo = async () => {
      // await loadModels(); // Tải các mô hình
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          streamRef.current = stream;
          videoRef.current.srcObject = stream;
          videoRef.current.onloadeddata = () => {
            setIsCameraReady(true); // Camera đã sẵn sàng
          };
          // Bắt đầu phát hiện khuôn mặt
          // const detectInterval = setInterval(() => {
          //   detectFace();
          // }, 1000); // Phát hiện mỗi giây
          // return () => clearInterval(detectInterval);
        })
        .catch((err) => {
          console.error(err);
          setHasCamera(false);
        });
    };

    if (mode === "face" && open && hasCamera) {
      startVideo();
    } else {
      stopVideo();
    }

    return () => {
      stopVideo();
    };
  }, [mode, open, hasCamera]);


  // Gửi yêu cầu để nhận OTP qua email và lưu trong session
  // Gửi OTP với mã người dùng
  const handleOtpRequest = () => {
    const jti = getTokenJti(); // Lấy jti từ token JWT

    if (!jti) {
      toast.error("User not found");
      return;
    }
    apiAuth
      .post("/attendance/sent-otp", { jti })
      .then((res) => {
        toast.success(t(res?.data?.message));
        sessionStorage.setItem('otp', res.data.otp);  // Lưu OTP
        sessionStorage.setItem('otpTimestamp', Date.now()); // Lưu thời gian tạo OTP
        sessionStorage.setItem('otpJti', jti); // Lưu jti
      })
      .catch((error) => {
        toast.error(t(error?.response?.data?.message));
      });
  };


  // Xác thực OTP với mã OTP trong session
  const handleOtpSubmit = () => {
    const currentTime = Date.now();
    const savedOtp = sessionStorage.getItem('otp');
    const savedOtpTimestamp = sessionStorage.getItem('otpTimestamp');
    const savedOtpJti = sessionStorage.getItem('otpJti');

    // Kiểm tra nếu OTP đã hết hạn (5 phút)
    if (currentTime - savedOtpTimestamp > 300000) {
      toast.error("OTP code has expired. Please request a new OTP code.");
      sessionStorage.removeItem('otp');
      sessionStorage.removeItem('otpTimestamp');
      sessionStorage.removeItem('otpJti');
      return;
    }

    if (otp === savedOtp) {
      const jti = getTokenJti();  // Lấy jti từ cookie của người dùng
      if (savedOtpJti !== jti) {
        toast.error("OTP code has expired. Please request a new OTP code.");
        sessionStorage.removeItem('otp');
        sessionStorage.removeItem('otpTimestamp');
        sessionStorage.removeItem('otpJti');
        return;
      }

      // Gửi OTP và trạng thái check-in/check-out
      apiAuth
        .post(
          submitUrl,
          { otp: otp, isCheckIn },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          handleCheckInOut(); // Cập nhật trạng thái điểm danh
          toast.success("Attendances by OTP successfully");
        })
        .catch((error) => {
          toast.error(t(error?.response?.data?.message || "Lưu thông tin điểm danh thất bại"));
        });
    } else {
      toast.error("OTP invalid");
    }
  };

  const handleCheckInOut = () => {
    if (isCheckIn) {
      handleCheckOutSubmitTime("checkOut");
      handleCheckIn(false);
    } else {
      handleCheckInSubmitTime("checkIn");
      handleCheckIn(true);
    }
    handleSetNotify(false);
    handleClose();
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const getTokenJti = () => {
    // Lấy token từ cookie
    const token = getCookie("jwtToken");
    if (!token) {
      console.error("Không tìm thấy token.");
      return null;
    }

    try {
      // Giải mã token
      const decodedToken = jwtDecode(token);

      // Lấy giá trị jti từ token
      const jti = decodedToken.jti;
      return jti;
    } catch (error) {
      console.error("Lỗi khi giải mã token:", error);
      return null;
    }
  };

  // Hàm điểm danh bằng camera
  const handleSubmitFace = () => {
    setIsLoading(true);
    const base64Image = captureFrameToBase64();
    const token = getCookie("jwtToken");

    if (!base64Image) {
      toast.error("Unable to take photo. Please check camera again..");
      setIsLoading(false);
      return;
    }
    const jti = getTokenJti();
    if (!jti) {
      toast.error("Unable to identify user. Please log in again.");
      setIsLoading(false);
      return;
    }
    cameraAuth
      .post(
        "/recognize_from_image/",
        { image_base64: base64Image, isCheckIn, jti },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // Kiểm tra mã trạng thái của phản hồi
        if (res.data.status === 200) {
          handleCheckInOut(); // Cập nhật trạng thái điểm danh
          toast.success("Attendances successfully");
        } else if (res.data.status === 401) {
          toast.error("Invalid User");
        } else {
          toast.error("Invalid User");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Attendances failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Hàm chuyển đổi frame video thành Base64
  const captureFrameToBase64 = () => {
    if (!videoRef.current) {
      return null; // Trả về null nếu videoRef.current không tồn tại
    }

    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "gray",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" align="center">
          {isCheckIn ? t("attendance.checkOut") : t("attendance.checkIn")}
        </Typography>
        <RadioGroup row value={mode} onChange={(e) => setMode(e.target.value)}>
          <FormControlLabel value="face" control={<Radio />} label={t("Face Scanning")} />
          <FormControlLabel value="otp" control={<Radio />} label={t("OTP")} />
        </RadioGroup>

        {mode === "face" ? (
          <Box display="flex" flexDirection="column" alignItems="center" position="relative">
            <Typography
              variant="body2"
              sx={{
                position: "relative",
                marginBottom: "10px", // Đặt khoảng cách giữa text và khung tròn
                color: "red",
                fontWeight: 500,
                animation: "fadeInOut 2s infinite", // Hiệu ứng mờ dần
              }}
            >
              Align the mold to match the green circle!. Then press the APPROVE button
            </Typography>
            {/* Video Camera */}
            <Box
              sx={{
                width: 200,
                height: 200,
                overflow: "hidden",
                borderRadius: "50%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Video Camera */}
              <video
                ref={videoRef}
                autoPlay
                muted
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                }}
              />

              {/* Vòng tròn động */}
              {isCameraReady && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "10%",
                    left: "10%",
                    width: "80%",
                    height: "80%",
                    border: "2px dashed rgba(0, 255, 0, 0.8)",
                    borderRadius: "50%",
                    animation: "bounce-circle 1.5s infinite",
                  }}
                />
              )}
            </Box>
          </Box>
        ) : (
          <>
            <TextField
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              fullWidth
              margin="normal"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleOtpSubmit(); // Xác thực OTP khi nhấn Enter
                }
              }}
            />
            <Link onClick={handleOtpRequest}>{t("Get OTP")}</Link>
          </>
        )}

        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (mode === "face") {
                handleSubmitFace(); // Gọi hàm xử lý nhận diện khuôn mặt
              } else {
                handleOtpSubmit(); // Xác thực OTP khi chế độ là "otp"
              }
            }}
            disabled={
              mode === "face"
                ? !isCameraReady || isLoading
                : otp === "" || isLoading
            }
          >
            {isLoading ? <CircularProgress size={24} /> : t("Approve")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AttendanceModal;

