import React, { useEffect, useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import apiAuth from "../../customs/axiosCustom";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {statusStyles} from "../Okr/ui/format"

ChartJS.register(...registerables, ChartDataLabels);
const DashBoradLab = () => {
  const [attendancesSumary, setAttendancesSumary] = useState([]);
  const [interns, setInterns] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [chartType, setChartType] = useState("pie");
  const [mentors, setMentors] = useState([]);
  const [totalObjectives, setObjectivesData] = useState([]);
  const formatLabel = (label) => {
    if (!label || typeof label !== "string") {
      return "";
    }
    return label
      .replace(/_/g, " ") // Thay dấu gạch dưới bằng khoảng trắng
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()); // Viết hoa từng từ
  };

  const getCurrentSemesterName = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    let semesterName = "";
    if (month >= 1 && month <= 4) {
      semesterName = `SPRING ${year}`;
    } else if (month >= 5 && month <= 8) {
      semesterName = `SUMMER ${year}`;
    } else if (month >= 9 && month <= 12) {
      semesterName = `FALL ${year}`;
    }
    return semesterName;
  };

  const normalizeObjectivesData = (data) => {
    const defaultStatuses = [
      "Not_Started",
      "In_Progress",
      "Accomplished",
      "Not_Achieved",
      "Unknown",
    ];

    // Trả về các trạng thái mặc định với giá trị 0 nếu không tìm thấy dữ liệu
    return defaultStatuses.map((status) => {
      const found = data?.find((item) => item.status === status);
      return {
        status: formatLabel(status), // Chuyển đổi nhãn để hiển thị đẹp
        count: found ? found.count : 0, // Nếu không tìm thấy thì mặc định là 0
      };
    });
  };

  useEffect(() => {
    const fetchSemesters = async () => {
      try {
        const response = await apiAuth.get("/Semester");
        const data = response.data || [];
        setSemesters(data);
        const currentSemesterName = getCurrentSemesterName();
        const currentSemester = data.find(
          (semester) => semester.semesterName === currentSemesterName
        );
        setSelectedSemester(
          currentSemester ? currentSemester.semesterName : data[0]?.semesterName
        );
      } catch (err) {
        console.log(`Error fetching semesters: ${err.message}`);
      }
    };

    fetchSemesters();
  }, []);

  const fetchTotalInterns = async (semesterName) => {
    showLoading();
    try {
      const response = await apiAuth.get(
        `/DashBoard/summarylabmanage/${encodeURIComponent(semesterName)}`
      );
      const data = response.data || {};

      setInterns(data.interns || []); // Nếu không có interns, gán mảng rỗng
      setMentors(data.mentors || []); // Nếu không có mentors, gán mảng rỗng

      // Gọi normalizeObjectivesData với dữ liệu hoặc mảng rỗng
      setObjectivesData(
        normalizeObjectivesData(data.summary?.objectivesSummary || [])
      );

      // Gán giá trị mặc định cho Attendance Summary nếu không có dữ liệu
      setAttendancesSumary(data.summary?.attendancesSumary || []);
    } catch (err) {
      setInterns([]);
      setMentors([]);
      setObjectivesData(normalizeObjectivesData([])); // Gán dữ liệu mặc định cho Objectives
      setAttendancesSumary([]);
      console.log("Failed to fetch data.");
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    if (selectedSemester) {
      fetchTotalInterns(selectedSemester);
    }
  }, [selectedSemester]);

  const toggleChartType = () => {
    setChartType((prevType) => {
      if (prevType === "pie") return "bar";
      if (prevType === "bar") return "line";
      return "pie";
    });
  };

  const objectivesLabels = (totalObjectives || []).map((item) => item.status);

  const objectivesDatasets = [
    {
      data: (totalObjectives || []).map((item) => item.count),
      backgroundColor: (totalObjectives || []).map(
        (item) => statusStyles[item.status.replace(/ /g, "_")]?.color || "#000"
      ),
      borderColor: (totalObjectives || []).map(
        (item) => statusStyles[item.status.replace(/ /g, "_")]?.color || "#000"
      ),
      borderWidth: 1,
      tension: 0.4,
    },
  ];
  

  const objectivesChartData = {
    labels: objectivesLabels,
    datasets: objectivesDatasets,
  };

  const attendanceChartData = {
    labels: ["Attended", "Absent", "Late In", "Soon Out"],
    datasets: [
      {
        label: "Attendance",
        data: [
          (attendancesSumary || []).reduce(
            (acc, item) => acc + (item.totalAttendanced || 0),
            0
          ),
          (attendancesSumary || []).reduce(
            (acc, item) => acc + (item.totalAbsent || 0),
            0
          ),
          (attendancesSumary || []).reduce(
            (acc, item) => acc + (item.totalLateIn || 0),
            0
          ),
          (attendancesSumary || []).reduce(
            (acc, item) => acc + (item.totalSoonOut || 0),
            0
          ),
        ],
        backgroundColor: ["#33CC00", "#CC3300", "#facc15", "#9ca3af"],
        borderColor: ["#33CC00", "#CC3300", "#facc15", "#9ca3af"],
        borderWidth: 1,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: chartType === "pie", // Chỉ hiển thị legend khi là biểu đồ tròn
        position: "bottom",
        labels: {
          boxWidth: 25,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
      // Cấu hình cho ChartDataLabels
      datalabels: {
        display: chartType === "pie",
        color: "#fff",
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce(
            (acc, val) => acc + val,
            0
          );
          const percentage = ((value / total) * 100).toFixed(1);
          return `${percentage}%`; // Hiển thị phần trăm
        },
        font: {
          weight: "bold",
          size: 10,
        },
      },
    },
    layout: {
      padding: 20,
    },
    maintainAspectRatio: false,
    aspectRatio: 2.5,
  };

  return (
    <Card
      style={{
        width: "100%",
        margin: "auto",
        boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          gutterBottom
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Performance Summary
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <InputLabel>Semester Name</InputLabel>
            <Select
              value={selectedSemester}
              onChange={(e) => setSelectedSemester(e.target.value)}
            >
              {(semesters || []).map((semester) => (
                <MenuItem
                  key={semester.semesterId}
                  value={semester.semesterName}
                >
                  {semester.semesterName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={toggleChartType}
            variant="contained"
            color="primary"
            sx={{ borderRadius: "20px" }}
          >
            Toggle Chart Type
          </Button>
        </Box>

        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: 2,
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                padding: 2,
                paddingBottom: 5,
                backgroundColor: "#fff",
                height: "300px",
                width: "100%",
                margin: "auto",
              }}
            >
              <Typography
                variant="h6"
                align="left"
                sx={{ fontWeight: "bold", ml: 2, mt: 1 }}
              >
                Objectives Overview
              </Typography>
              {chartType === "pie" ? (
                <Pie data={objectivesChartData} options={options} />
              ) : chartType === "bar" ? (
                <Bar data={objectivesChartData} options={options} />
              ) : (
                <Line data={objectivesChartData} options={options} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: 2,
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                padding: 2,
                paddingBottom: 5,
                backgroundColor: "#fff",
                height: "300px",
                width: "100%",
                margin: "auto",
              }}
            >
              <Typography
                variant="h6"
                align="left"
                sx={{ fontWeight: "bold", ml: 2, mt: 1 }}
              >
                Attendance Overview
              </Typography>

              {chartType === "pie" ? (
                <Pie data={attendanceChartData} options={options} />
              ) : chartType === "bar" ? (
                <Bar data={attendanceChartData} options={options} />
              ) : (
                <Line data={attendanceChartData} options={options} />
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
  <Grid item xs={12} md={6}>
    <Typography
      variant="h6"
      align="left"
      sx={{ fontWeight: "bold", mb: 2 }}
    >
      Interns List ({interns.length || 0})
    </Typography>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        maxHeight: "300px",  // Giới hạn chiều cao
        overflowY: "auto",   // Kích hoạt thanh cuộn theo chiều dọc
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name Intern</TableCell>
            <TableCell>Roll Name</TableCell>
            <TableCell>Lab Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(interns || []).length > 0 ? (
            interns.map((intern) => (
              <TableRow key={intern.id}>
                <TableCell>{intern.nameIntern}</TableCell>
                <TableCell>{formatLabel(intern.rollName)}</TableCell>
                <TableCell>{intern.nameLab}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No Interns Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>

  <Grid item xs={12} md={6}>
    <Typography
      variant="h6"
      align="left"
      sx={{ fontWeight: "bold", mb: 2 }}
    >
      List of Mentors ({mentors.length})
    </Typography>
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        maxHeight: "200px",  // Giới hạn chiều cao
        overflowY: "auto",   // Kích hoạt thanh cuộn theo chiều dọc
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Mentor Name</TableCell>
            <TableCell>Email Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mentors.length > 0 ? (
            mentors.map((mentor) => (
              <TableRow
                key={mentor.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                }}
              >
                <TableCell>{mentor.nameMentor}</TableCell>
                <TableCell>{mentor.email}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} align="center">
                No Mentors Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
</Grid>

      </CardContent>
    </Card>
  );
};

export default DashBoradLab;
