import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import KeyResultsModal from "./KeyResultsModal";
import LabChallengeDetailModal from "./LabChallengeDetailModal";
import { statusObjectiveChallenge, formatDate, truncateTarget } from "./format";

const OKRChallengeList = ({
  title,
  objectives,
  loading,
  fetchChildObjectives,
}) => {
  const navigate = useNavigate();
  // const [objectives, setObjectives] = useState([]);
  const [childObjectives, setChildObjectives] = useState({});
  // const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [openModal, setOpenModal] = useState(false); // State for modal
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null); // Selected OKR ID

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     try {
  //       const data = await fetchParentObjectives();
  //       setObjectives(data);
  //     } catch (error) {
  //       console.error("Failed to fetch parent objectives:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [fetchParentObjectives]);

  const toggleExpand = async (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));

    if (!childObjectives[id]) {
      try {
        const children = await fetchChildObjectives(id);
        setChildObjectives((prev) => ({ ...prev, [id]: children }));
      } catch (error) {
        console.error(`Failed to fetch child objectives for ID: ${id}`, error);
      }
    }
  };

  const handleOpenKeyResultsModal = (objectiveId) => {
    setSelectedObjectiveId(objectiveId);
    setOpenModal(true);
  };

  const handleCloseKeyResultsModal = () => {
    setOpenModal(false);
  };

  const handleOpenLabChallengeDetailModal = (objectiveId) => {
    setSelectedObjectiveId(objectiveId);
    setIsDetailModalOpen(true);
  };

  const handleCloseLabChallengeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  // New function for handling navigation for level > 0
  const handleNavigateToDetailPage = (objectiveId) => {
    navigate(`/okr-detail/${objectiveId}`);
  };

  const renderObjectiveRow = (okr, level = 0) => {
    const paddingLeft = `${level * 30}px`;

    return (
      <React.Fragment key={okr.Id}>
        <TableRow>
          {/* Target */}
          <TableCell style={{ paddingLeft }}>
            <Box display="flex" alignItems="center">
              {okr.KeyResults || okr.RegistrationStartDate ? (
                <IconButton onClick={() => toggleExpand(okr.Id)}>
                  {expanded[okr.Id] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </IconButton>
              ) : null}
              <Avatar
                alt={okr.User?.FirstName || "Anonymous"}
                src={
                  okr.User?.Avatar
                    ? `data:image/png;base64,${okr.User.Avatar}`
                    : "/default-avatar.png"
                }
                sx={{ width: 40, height: 40, mr: 2 }}
              />
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  onClick={
                    () =>
                      level === 0
                        ? handleOpenLabChallengeDetailModal(okr.Id) // Open modal for level 0
                        : handleNavigateToDetailPage(okr.Id) // Navigate to detail page for level > 0
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {truncateTarget(okr.Target)}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          {/* Creator Name */}
          <TableCell>
            {okr.User?.FirstName} {okr.User?.LastName || "Anonymous"}
          </TableCell>
          {/* Key Results */}
          {level === 0 ? (
            <TableCell /> // Bỏ trống Key Results ở cấp đầu tiên
          ) : (
            <TableCell>
              <Button
                variant="outlined"
                sx={{ padding: "2px 4px" }}
                onClick={() => handleOpenKeyResultsModal(okr.Id)}
              >
                {okr.KeyResults?.length || 0} Key Results
              </Button>
            </TableCell>
          )}
          {/* Registration Date */}
          {level === 0 ? (
            <TableCell>
              {okr.RegistrationStartDate
                ? `${formatDate(okr.RegistrationStartDate)} - ${formatDate(
                    okr.RegistrationEndDate
                  )}`
                : "N/A"}
            </TableCell>
          ) : (
            <TableCell /> // Bỏ trống Registration Date ở các cấp sau
          )}
          {/* Start Date and End Date (only for level === 0) */}
          {level === 0 ? (
            <TableCell>
              {okr.StartDate
                ? `${formatDate(okr.StartDate)} - ${formatDate(okr.EndDate)}`
                : "N/A"}
            </TableCell>
          ) : (
            <TableCell /> // Bỏ trống StartDate-EndDate ở các cấp con
          )}
          {/* Status */}
          <TableCell>
            <Typography
              variant="body2"
              sx={{
                ...statusObjectiveChallenge[okr.ProcessStatus],
              }}
            >
              {okr.ProcessStatus.replace("_", " ")}
            </Typography>
          </TableCell>
        </TableRow>

        {/* Render child objectives */}
        {expanded[okr.Id] &&
          childObjectives[okr.Id]?.map((childOkr) =>
            renderObjectiveRow(childOkr, level + 1)
          )}
      </React.Fragment>
    );
  };

  return (
    <Box>
      <Typography variant="h5" mb={2} fontWeight="bold">
        {title}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "500px",
          overflowY: "auto",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "40%", fontWeight: "bold" }}>
                Target
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Creator
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Key Results
              </TableCell>
              <TableCell style={{ width: "15%", fontWeight: "bold" }}>
                Registration Date
              </TableCell>
              <TableCell style={{ width: "15%", fontWeight: "bold" }}>
                StartDate - EndDate
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress size={25} />
                </TableCell>
              </TableRow>
            ) : objectives.length > 0 ? (
              objectives.map((okr) => renderObjectiveRow(okr))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No {title} available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Lab Challenge Detail */}
      <LabChallengeDetailModal
        open={isDetailModalOpen}
        onClose={handleCloseLabChallengeDetailModal}
        objectiveId={selectedObjectiveId} // Reuse the same state
      />

      {/* Modal Key Results */}
      <KeyResultsModal
        open={openModal}
        onClose={handleCloseKeyResultsModal}
        objectiveId={selectedObjectiveId}
      />
    </Box>
  );
};

export default OKRChallengeList;
