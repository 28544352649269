// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   TextField,
//   Button,
//   Typography,
//   Paper,
//   Box,
//   CircularProgress,
//   Grid,
//   CardContent,
//   Card,
// } from "@mui/material";
// import { Line, Bar } from "react-chartjs-2";
// import "chart.js/auto"; // Required for using react-chartjs-2
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useAuth } from "../../customs/authService";
// import apiAuth from "../../customs/axiosCustom";

// const AddReportPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { t } = useAuth();

//   const [reportData, setReportData] = useState({
//     staffId: "",
//     internId: "",
//     rollName: "",
//     mentorId: "",
//     nameIntern: "",
//     nameLab: "",
//     nameMentor: "",
//     allowance: "",
//     comOfCompany: "",
//     majorSkill: "",
//     softSkill: "",
//     attitude: "",
//     semesterId: "",
//   });

//   const [loading, setLoading] = useState(false);
//   const [chartData, setChartData] = useState(null);
//   const [chartData1, setChartData1] = useState(null);
//   const [chartType, setChartType] = useState("line"); // State for chart type

//   useEffect(() => {
//     if (location.state && location.state.internData) {
//       const { semesterId, ...internData } = location.state.internData;
//       setReportData((prevState) => ({
//         ...prevState,
//         ...internData,
//         semesterId,
//       }));
//       if (location.state.report) {
//         setReportData((prevState) => ({
//           ...prevState,
//           ...location.state.report, // Merge the report data into reportData
//         }));
//       }
//     }

//     // Fetch the key result summary data for the chart
//     // const fetchData = async () => {
//     //   try {
//     //     const response = await apiAuth.get(
//     //       `/DashBoard/Semester/${location.state.internData.semesterId}/Intern/${location.state.internData.internId}/Summary`
//     //     );
//     //     // Assuming the API returns a single intern's data, process it directly
//     //     setChartData(processChartData(response.data));
//     //     setChartData1(processChartData1(response.data));
//     //   } catch (err) {
//     //     if (err.response) {
//     //       const errorMessage = err.response.data.message;
//     //       if (err.response.status === 400) {
//     //         toast.error(errorMessage || "Invalid request.");
//     //       } else if (err.response.status === 404) {
//     //         toast.error(t("reportV.errors.404"));
//     //       } else {
//     //         toast.error(errorMessage || "An unexpected error occurred.");
//     //       }
//     //     } else {
//     //       toast.error("An unexpected error occurred.");
//     //     }
//     //   }
//     // };
//     const fetchData = async () => {
//       try {
//         const response = await apiAuth.get(
//           `/DashBoard/Semester/${location.state.internData.semesterId}/Intern/${location.state.internData.internId}/Summary`
//         );

//         const data = response.data;

//         // Process the data, handling potential nulls
//         setChartData(processChartData(data.attendancesSumaryIntern || []));
//         setChartData1(processChartData1(data.keyResultsSummary || []));
//       } catch (err) {
//         if (err.response) {
//           const errorMessage = err.response.data.message;
//           if (err.response.status === 400) {
//             toast.error(errorMessage || "Invalid request.");
//           } else if (err.response.status === 404) {
//             toast.error(t("reportV.errors.404"));
//           } else {
//             toast.error(errorMessage || "An unexpected error occurred.");
//           }
//         } else {
//           toast.error("An unexpected error occurred.");
//         }
//       }
//     };


//     fetchData();
//   }, [location.state]);

//   const processChartData = (data) => {
//     const labels = data.keyResultsSummary.map(
//       (item) => `${item.year}-${item.month}`
//     );

//     // Tạo dữ liệu cho từng loại trạng thái
//     const failedData = data.keyResultsSummary.map((item) => item.totalFailed);
//     const inProgressData = data.keyResultsSummary.map(
//       (item) => item.totalInProgress
//     );
//     const completedData = data.keyResultsSummary.map(
//       (item) => item.totalCompleted
//     );
//     const notStartedData = data.keyResultsSummary.map(
//       (item) => item.totalNotStarted
//     );

//     return {
//       labels,
//       datasets: [
//         {
//           label: t("report.failed"),
//           data: failedData,
//           borderColor: "red",
//           backgroundColor: "red",
//         },
//         {
//           label: t("report.inProgress"),
//           data: inProgressData,
//           borderColor: "blue",
//           backgroundColor: "blue",
//         },
//         {
//           label: t("report.completed"),
//           data: completedData,
//           borderColor: "green",
//           backgroundColor: "green",
//         },
//         {
//           label: t("report.notStarted"),
//           data: notStartedData,
//           borderColor: "orange",
//           backgroundColor: "orange",
//         },
//       ],
//     };
//   };

//   const processChartData1 = (data) => {
//     const labels = data.attendancesSumaryIntern.map(
//       (item) => `${item.year}-${item.month}`
//     );
//     const attendedData = data.attendancesSumaryIntern.map(
//       (item) => item.totalAttendanced
//     );
//     const absentData = data.attendancesSumaryIntern.map(
//       (item) => item.totalAbsent
//     );
//     const lateInData = data.attendancesSumaryIntern.map(
//       (item) => item.totalLateIn
//     );
//     const soonOutData = data.attendancesSumaryIntern.map(
//       (item) => item.totalSoonOut
//     );

//     return {
//       labels,
//       datasets: [
//         {
//           label: t("report.attended"),
//           data: attendedData,
//           borderColor: "green",
//           backgroundColor: "green",
//         },
//         {
//           label: t("report.absent"),
//           data: absentData,
//           borderColor: "red",
//           backgroundColor: "red",
//         },
//         {
//           label: t("report.lateIn"),
//           data: lateInData,
//           borderColor: "blue",
//           backgroundColor: "blue",
//         },
//         {
//           label: t("report.soonOut"),
//           data: soonOutData,
//           borderColor: "orange",
//           backgroundColor: "orange",
//         },
//       ],
//     };
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setReportData({ ...reportData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       if (reportData?.reportId) {
//         const response = await apiAuth.put(
//           `/Report/${reportData.reportId}`,
//           reportData
//         );

//         toast.success(response?.data.message || t("reportV.aReportSuc"));
//       } else {
//         const response = await apiAuth.post("/Report", reportData);

//         toast.success(response?.data.message || t("reportV.aReportSuc"));
//       }
//       navigate("/report");
//     } catch (err) {
//       if (err.response) {
//         const errorMessage = err.response.data.message;

//         // Handle different status codes
//         switch (err.response.status) {
//           case 400:
//             toast.error(t("reportV.errors.400"));
//             break;
//           case 409:
//             toast.error(t("reportV.errors.409"));
//             break;
//           case 422:
//             toast.error(t("reportV.errors.422"));
//             break;
//           case 500:
//             toast.error(errorMessage);
//             break;
//           default:
//             toast.error(errorMessage);
//             break;
//         }
//       } else {
//         // Handle network errors or unexpected errors
//         toast.error(
//           "An unexpected error occurred. Please check your connection."
//         );
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Box p={3}>
//       <Typography variant="h4" gutterBottom>
//         {reportData.reportId ? "Update" : t("report.addReport")}
//       </Typography>
//       {loading ? (
//         <CircularProgress />
//       ) : (
//         <Grid container spacing={3}>
//           <Grid item xs={12} sm={12} md={4}>
//             <Paper style={{ padding: "20px" }}>
//               <Card sx={{ maxWidth: 600, margin: "auto", padding: 2 }}>
//                 <CardContent>
//                   <Typography variant="h5" sx={{ marginBottom: 2 }}>
//                     {t("report.internDetail.title")}
//                   </Typography>

//                   <Grid container spacing={2}>
//                     <Grid item xs={6}>
//                       <Typography variant="subtitle1" color="textSecondary">
//                         {t("report.internDetail.name")}
//                       </Typography>
//                       <Typography>{reportData.nameIntern}</Typography>
//                     </Grid>

//                     <Grid item xs={6}>
//                       <Typography variant="subtitle1" color="textSecondary">
//                         {t("report.internDetail.email")}
//                       </Typography>
//                       <Typography>{reportData.email}</Typography>
//                     </Grid>

//                     <Grid item xs={6}>
//                       <Typography variant="subtitle1" color="textSecondary">
//                         {t("report.internDetail.rollName")}
//                       </Typography>
//                       <Typography>{reportData.rollName}</Typography>
//                     </Grid>

//                     <Grid item xs={6}>
//                       <Typography variant="subtitle1" color="textSecondary">
//                         {t("report.internDetail.lab")}
//                       </Typography>
//                       <Typography>{reportData.nameLab}</Typography>
//                     </Grid>

//                     <Grid item xs={6}>
//                       <Typography variant="subtitle1" color="textSecondary">
//                         {t("report.internDetail.mentor")}
//                       </Typography>
//                       <Typography>{reportData.nameMentor}</Typography>
//                     </Grid>
//                   </Grid>
//                 </CardContent>
//               </Card>
//               <form onSubmit={handleSubmit}>
//                 <TextField
//                   fullWidth
//                   multiline
//                   minRows={1}
//                   margin="normal"
//                   name="comOfCompany"
//                   label={t("report.commentOfCompany")}
//                   value={reportData.comOfCompany}
//                   onChange={handleChange}
//                 />
//                 <TextField
//                   fullWidth
//                   margin="normal"
//                   name="majorSkill"
//                   label={t("report.majorSkill")}
//                   value={reportData.majorSkill}
//                   onChange={handleChange}
//                   required
//                 />
//                 <TextField
//                   fullWidth
//                   margin="normal"
//                   name="softSkill"
//                   label={t("report.softSkill")}
//                   value={reportData.softSkill}
//                   onChange={handleChange}
//                   required
//                 />
//                 <TextField
//                   fullWidth
//                   margin="normal"
//                   name="attitude"
//                   label={t("report.attitude")}
//                   value={reportData.attitude}
//                   onChange={handleChange}
//                   required
//                 />
//                 <Box mt={1}>
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     sx={{ fontSize: "0.9rem" }}
//                   >
//                     {t("report.addReport")}
//                   </Button>

//                   <Button
//                     sx={{ fontSize: "1rem", padding: "4px", marginLeft: 1 }}
//                     variant="contained"
//                     color="primary"
//                     onClick={() => navigate("/report")}
//                   >
//                     {t("report.back")}
//                   </Button>
//                 </Box>
//               </form>
//             </Paper>
//           </Grid>
//           <Grid item xs={12} sm={12} md={8}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={12} md={6}>
//                 <Paper style={{ padding: "20px" }}>
//                   <Typography variant="h6" gutterBottom>
//                     {t("report.keyResultsOverview")}
//                   </Typography>
//                   {chartData ? (
//                     chartType === "line" ? (
//                       <Line data={chartData} />
//                     ) : (
//                       <Bar data={chartData} />
//                     )
//                   ) : (
//                     <CircularProgress />
//                   )}
//                 </Paper>
//               </Grid>
//               <Grid item xs={12} sm={12} md={6}>
//                 <Paper style={{ padding: "20px" }}>
//                   <Typography variant="h6" gutterBottom>
//                     {t("report.attendanceOverview")}
//                   </Typography>
//                   {chartData1 ? (
//                     chartType === "line" ? (
//                       <Line data={chartData1} />
//                     ) : (
//                       <Bar data={chartData1} />
//                     )
//                   ) : (
//                     <CircularProgress />
//                   )}
//                 </Paper>
//               </Grid>
//             </Grid>
//             <Box mt={3} textAlign="center">
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() =>
//                   setChartType(chartType === "line" ? "bar" : "line")
//                 }
//               >
//                 {t("report.toggleChartType")}
//               </Button>
//             </Box>
//           </Grid>
//         </Grid>
//       )}
//     </Box>
//   );
// };

// export default AddReportPage;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
import { Line, Bar } from "react-chartjs-2";
import "chart.js/auto";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";

const AddReportPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useAuth();

  const [reportData, setReportData] = useState({
    staffId: "",
    internId: "",
    rollName: "",
    mentorId: "",
    nameIntern: "",
    nameLab: "",
    nameMentor: "",
    allowance: "",
    comOfCompany: "",
    majorSkill: "",
    softSkill: "",
    attitude: "",
    semesterId: "",
  });

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [chartData1, setChartData1] = useState(null);
  const [chartType, setChartType] = useState("line");

  const normalizeObjectivesData = (data) => {
    const defaultStatuses = [
      "Not_Started",
      "In_Progress",
      "Accomplished",
      "Not_Achieved",
      "Unknown",
    ];
    return defaultStatuses.map((status) => {
      const found = data.find((item) => item.status === status);
      return {
        status,
        count: found ? found.count : 0,
      };
    });
  };

  useEffect(() => {
    if (location.state && location.state.internData) {
      const { semesterId, ...internData } = location.state.internData;
      setReportData((prevState) => ({
        ...prevState,
        ...internData,
        semesterId,
      }));
      if (location.state.report) {
        setReportData((prevState) => ({
          ...prevState,
          ...location.state.report,
        }));
      }
    }

    const fetchData = async () => {
      try {
        const response = await apiAuth.get(
          `/DashBoard/Semester/${location.state.internData.semesterId}/Intern/${location.state.internData.internId}/Summary`
        );
        const data = response.data;

        console.log("API Response:", data); // Debug log

        // Process the data, handling potential nulls
        setChartData(processObjectivesData(data.objectivesSummary || []));
        setChartData1(processChartData1(data.attendancesSumaryIntern || []));
      } catch (err) {
        console.error("API Error:", err);
        if (err.response) {
          const errorMessage = err.response.data.message;
          if (err.response.status === 400) {
            toast.error(errorMessage || "Invalid request.");
          } else if (err.response.status === 404) {
            toast.error(t("reportV.errors.404"));
          } else {
            toast.error(errorMessage || "An unexpected error occurred.");
          }
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    };

    fetchData();
  }, [location.state]);

  const processObjectivesData = (data) => {
    const normalizedData = normalizeObjectivesData(data);
  
    const datasets = normalizedData.map((item, index) => ({
      label: item.status,
      data: [item.count],
      backgroundColor: ["#ff6384","#36a2eb","#4bc0c0","#ffce56","#9ca3af",][index],
      borderColor: ["#ff6384","#36a2eb","#4bc0c0","#ffce56","#9ca3af",][index],
      borderWidth: 1,
    }));
  
    return {
      labels: ["Objectives Count"], 
      datasets,
    };
  };
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          boxWidth: 20,
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = tooltipItem.raw;
            return `${tooltipItem.dataset.label}: ${value}`;
          },
        },
      },
    },
  };

  const processChartData1 = (data) => {
    const labels = (data || []).map((item) => `${item.year}-${item.month}`);
    const attendedData = (data || []).map((item) => item.totalAttendanced || 0);
    const absentData = (data || []).map((item) => item.totalAbsent || 0);
    const lateInData = (data || []).map((item) => item.totalLateIn || 0);
    const soonOutData = (data || []).map((item) => item.totalSoonOut || 0);

    return {
      labels,
      datasets: [
        {
          label: t("report.attended"),
          data: attendedData,
          borderColor: "green",
          backgroundColor: "green",
        },
        {
          label: t("report.absent"),
          data: absentData,
          borderColor: "red",
          backgroundColor: "red",
        },
        {
          label: t("report.lateIn"),
          data: lateInData,
          borderColor: "#facc15",
          backgroundColor: "#facc15",
        },
        {
          label: t("report.soonOut"),
          data: soonOutData,
          borderColor: "gray",
          backgroundColor: "gray",
        },
      ],
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReportData({ ...reportData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (reportData?.reportId) {
        const response = await apiAuth.put(
          `/Report/${reportData.reportId}`,
          reportData
        );
        toast.success(response?.data.message || t("reportV.aReportSuc"));
      } else {
        const response = await apiAuth.post("/Report", reportData);
        toast.success(response?.data.message || t("reportV.aReportSuc"));
      }
      navigate("/report");
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.message;
        switch (err.response.status) {
          case 400:
            toast.error(t("reportV.errors.400"));
            break;
          case 409:
            toast.error(t("reportV.errors.409"));
            break;
          case 422:
            toast.error(t("reportV.errors.422"));
            break;
          case 500:
            toast.error(errorMessage);
            break;
          default:
            toast.error(errorMessage);
            break;
        }
      } else {
        toast.error("An unexpected error occurred. Please check your connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {reportData.reportId ? "Update" : t("report.addReport")}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Paper style={{ padding: "20px" }}>
              <Card sx={{ maxWidth: 600, margin: "auto", padding: 2 }}>
                <CardContent>
                  <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    {t("report.internDetail.title")}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("report.internDetail.name")}
                      </Typography>
                      <Typography>{reportData.nameIntern}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("report.internDetail.email")}
                      </Typography>
                      <Typography>{reportData.email}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("report.internDetail.rollName")}
                      </Typography>
                      <Typography>{reportData.rollName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("report.internDetail.lab")}
                      </Typography>
                      <Typography>{reportData.nameLab}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("report.internDetail.mentor")}
                      </Typography>
                      <Typography>{reportData.nameMentor}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  multiline
                  minRows={1}
                  margin="normal"
                  name="comOfCompany"
                  label={t("report.commentOfCompany")}
                  value={reportData.comOfCompany}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="majorSkill"
                  label={
                    t("report.majorSkill")}
                  value={reportData.majorSkill}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="softSkill"
                  label={t("report.softSkill")}
                  value={reportData.softSkill}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="attitude"
                  label={t("report.attitude")}
                  value={reportData.attitude}
                  onChange={handleChange}
                  required
                />
                <Box mt={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: "0.9rem" }}
                  >
                    {t("report.addReport")}
                  </Button>
                  <Button
                    sx={{ fontSize: "1rem", padding: "4px", marginLeft: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/report")}
                  >
                    {t("report.back")}
                  </Button>
                </Box>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Paper style={{ padding: "20px" }}>
                  <Typography variant="h6" gutterBottom>
                    Objectives Overview
                  </Typography>
                  {chartData ? (
                    chartType === "line" ? (
                      <Line data={chartData} options={chartOptions}/>
                    ) : (
                      <Bar data={chartData} options={chartOptions} />
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paper style={{ padding: "20px" }}>
                  <Typography variant="h6" gutterBottom>
                    {t("report.attendanceOverview")}
                  </Typography>
                  {chartData1 ? (
                    chartType === "line" ? (
                      <Line data={chartData1} />
                    ) : (
                      <Bar data={chartData1} />
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </Paper>
              </Grid>
            </Grid>
            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setChartType(chartType === "line" ? "bar" : "line")}
              >
                {t("report.toggleChartType")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AddReportPage;

