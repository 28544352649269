import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  Fab,
  Box,
  Select,
  MenuItem,
  InputBase,
  FormControl,
  InputLabel,
  Paper,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import {
  Search as SearchIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { OKRContext } from "../../../context/OKRContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../customs/authService";

const NavBar = ({
  searchQuery,
  status,
  sortBy,
  onSearch,
  onStatusChange,
  onSortChange,
  onRefreshClick,
}) => {
  const { t } = useAuth();
  const { auth } = useContext(OKRContext);
  const location = useLocation();
  const navigate = useNavigate();
  // const [searchQuery, setSearchQuery] = useState("");
  // const [status, setStatus] = useState("all");
  // const [sortByState, setSortByState] = useState("default");

  const handleSearchClick = () => {
    onSearch(searchQuery);
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    // setStatus(value);
    onStatusChange(value);
  };

  const handleSortChange = (event) => {
    const { value } = event.target;
    if (value !== undefined) {
      // setSortByState(value);
      onSortChange(value);
    }
  };

  const handleRefreshClick = () => {
    // Reset tất cả về giá trị mặc định
    // setSearchQuery("");
    // setStatus("all");
    // setSortByState("default");

    // Gọi hàm refresh từ props để thực hiện fetch với giá trị mặc định
    onRefreshClick();
  };

  const isJuryPanel = auth?.user?.roles?.includes("Jury Panel");

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#f5f5f5", padding: "2px" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Khối Tabs ở bên trái */}
        <Box>
          <Tabs
            value={
              location.pathname.includes("okrdashboard")
                ? 0
                : location.pathname.includes("okrreport")
                ? 1
                : location.pathname.includes("okrranking")
                ? 2
                : location.pathname.includes("okrscoring")
                ? 3
                : false
            }
            textColor="primary"
            indicatorColor="primary"
            sx={{
              ".MuiTab-root": { fontWeight: "bold", fontSize: "1rem" },
            }}
          >
            <Tab
              label="OKR Dashboard"
              component={Link}
              to="/okr/okrdashboard"
            />
            {!isJuryPanel && (
              <Tab label="OKR Report" component={Link} to="/okr/okrreport" />
            )}
            {!isJuryPanel && (
              <Tab label="OKR Ranking" component={Link} to="/okr/okrranking" />
            )}
            {isJuryPanel && (
              <Tab label="OKR Scoring" component={Link} to="/okr/okrscoring" />
            )}
          </Tabs>
        </Box>

        {/* Khối Create, Search, Filter, Refresh ở bên phải */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {/* Fab Thêm mới với Tooltip */}
          {!isJuryPanel && (
            <Tooltip title="Create Objective" arrow>
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                onClick={() => navigate("/create-objective")}
                sx={{
                  backgroundColor: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                  boxShadow: "none",
                }}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          )}

          {/* Tìm kiếm */}
          <Box
            component={Paper}
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "400px",
              borderRadius: "30px",
              padding: "1px 15px",
              backgroundColor: "#ffffff",
              border: "2px solid #cccccc",
              flexGrow: 1,
            }}
          >
            <InputBase
              placeholder={t("Search")}
              inputProps={{ "aria-label": "search" }}
              sx={{
                flex: 1,
              }}
              value={searchQuery}
              onChange={(e) => onSearch(e.target.value)}
            />
            <Fab
              size="small"
              sx={{
                color: "#1976d2",
                backgroundColor: "#ffffff",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              aria-label="search"
              onClick={handleSearchClick}
            >
              <SearchIcon />
            </Fab>
          </Box>

          <FormControl
            variant="outlined"
            sx={{
              minWidth: 100,
              height: "40px",
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& fieldset": {
                  borderColor: "#1976d2",
                },
                "&:hover fieldset": {
                  borderColor: "#1e88e5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0d47a1",
                },
              },
            }}
          >
            <InputLabel sx={{ color: "#1976d2" }}>{t("Status")}</InputLabel>
            <Select
              value={status}
              onChange={handleStatusChange}
              label={t("Status")}
              sx={{
                height: "40px",
                color: "#1976d2",
                backgroundColor: "#ffffff",
                borderRadius: "5px",
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="Not_Started">Not Started</MenuItem>
              <MenuItem value="Register_Time">Register Time</MenuItem>
              <MenuItem value="In_Progress">In Progress</MenuItem>
              <MenuItem value="Milestone_Time">Milestone Time</MenuItem>
              <MenuItem value="Report_Time">Report Time</MenuItem>
              <MenuItem value="Grade_Time">Grade Time</MenuItem>
              <MenuItem value="Accomplished">Accomplished</MenuItem>
              <MenuItem value="Not_Achieved">Not Achieved</MenuItem>
              <MenuItem value="Unknown">Unknown</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            sx={{
              minWidth: 100,
              height: "40px",
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& fieldset": {
                  borderColor: "#1976d2",
                },
                "&:hover fieldset": {
                  borderColor: "#1e88e5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#0d47a1",
                },
              },
            }}
          >
            <InputLabel sx={{ color: "#1976d2" }}>{t("Sort By")}</InputLabel>
            <Select
              value={sortBy}
              onChange={handleSortChange}
              label={t("Sort By")}
              sx={{
                height: "40px",
                color: "#1976d2",
                backgroundColor: "#ffffff",
                borderRadius: "5px",
              }}
            >
              <MenuItem value="default">{t("Default")}</MenuItem>
              <MenuItem value="target">{t("Target")}</MenuItem>
              <MenuItem value="date">{t("Date")}</MenuItem>
            </Select>
          </FormControl>

          {/* Nút Làm mới */}
          <Tooltip title={t("Refresh")} arrow>
            <Fab
              color="primary"
              onClick={handleRefreshClick}
              size="small"
              sx={{ boxShadow: "none" }}
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
