import React, { useState, useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyResultsModal from "./KeyResultsModal";
import {
  statusStyles,
  formatDate,
  truncateTarget,
  getProgressColor,
  getReportStatusStyle,
  formatProgress,
} from "./format";
import { useNavigate } from "react-router-dom";
import { OKRContext } from "../../../context/OKRContext";

const ReportOKRsList = ({ title, objectives, refreshData, loading }) => {
  const { ChildObjectivesList } = useContext(OKRContext);
  const [expanded, setExpanded] = useState({});
  const [childObjectives, setChildObjectives] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);
  const navigate = useNavigate();

  const toggleExpand = async (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
    if (!childObjectives[id]) {
      try {
        const children = await ChildObjectivesList(id); // Fetch child objectives
        setChildObjectives((prev) => ({ ...prev, [id]: children }));
      } catch (error) {
        console.error(`Failed to fetch child objectives for ID: ${id}`, error);
      }
    }
  };

  const handleOpenModal = (objectiveId) => {
    setSelectedObjectiveId(objectiveId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    refreshData();
  };

  const handleReportNavigation = (objectiveId, statusReport) => {
    if (statusReport === "Summary") {
      navigate(`/okr-report/okr-report-overview/${objectiveId}`);
    } else {
      navigate(`/okr-report/${objectiveId}`);
    }
  };

  const renderObjectiveRow = (okr, level = 0) => {
    const paddingLeft = `${level * 30}px`;
    const isChallengeObjective =
      okr.Type === "Challenge_Objective" && level === 0;

    return (
      <React.Fragment key={okr.Id}>
        <TableRow>
          <TableCell style={{ paddingLeft }}>
            <Box display="flex" alignItems="center">
              <IconButton onClick={() => toggleExpand(okr.Id)}>
                {expanded[okr.Id] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </IconButton>
              <Avatar
                alt={okr.User?.FirstName || "Anonymous"}
                src={
                  okr.User?.Avatar
                    ? `data:image/png;base64,${okr.User.Avatar}`
                    : "/default-avatar.png"
                }
                sx={{ width: 40, height: 40, mr: 2 }}
              />
              <strong>{truncateTarget(okr.Target)}</strong>
            </Box>
          </TableCell>
          <TableCell>
            {okr.User?.FirstName} {okr.User?.LastName || "Anonymous"}
          </TableCell>
          <TableCell>
            {/* <Box display="flex" alignItems="center">
              <Box width="100%" mr={1}>
                <LinearProgress
                  variant="determinate"
                  value={Math.min(okr.ProgressPercentage || 0, 100)} // Hiển thị tối đa 100%
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: getProgressColor(
                        Math.min(okr.ProgressPercentage || 0, 100)
                      ),
                    },
                  }}
                />
              </Box>
              <Typography variant="body2" color="textSecondary">
                {`${formatProgress(okr.ProgressPercentage || 0)}%`}
              </Typography>
            </Box> */}
            {isChallengeObjective ? (
              ""
            ) : (
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.min(okr.ProgressPercentage || 0, 100)} // Hiển thị tối đa 100%
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: getProgressColor(
                          Math.min(okr.ProgressPercentage || 0, 100)
                        ),
                      },
                    }}
                  />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {`${formatProgress(okr.ProgressPercentage || 0)}%`}
                </Typography>
              </Box>
            )}
          </TableCell>
          <TableCell>
            {isChallengeObjective ? (
              ""
            ) : (
              <Button
                variant="outlined"
                sx={{ padding: "2px 4px" }}
                onClick={() => handleOpenModal(okr.Id)}
              >
                {okr.KeyResults?.length || 0} Key Results
              </Button>
            )}
            {/* <Button
              variant="outlined"
              sx={{ padding: "2px 4px" }}
              onClick={() => handleOpenModal(okr.Id)}
            >
              {okr.KeyResults?.length || 0} Key Results
            </Button> */}
          </TableCell>
          <TableCell>{formatDate(okr.StartDate)}</TableCell>
          <TableCell>{formatDate(okr.EndDate)}</TableCell>
          <TableCell>
            {isChallengeObjective ? (
              ""
            ) : (
              <Typography variant="body2" sx={statusStyles[okr.ProcessStatus]}>
                {okr.ProcessStatus.replace("_", " ")}
              </Typography>
            )}
            {/* <Typography variant="body2" sx={statusStyles[okr.ProcessStatus]}>
              {okr.ProcessStatus.replace("_", " ")}
            </Typography> */}
          </TableCell>
          <TableCell>
            {/* <Button
              variant="contained"
              sx={{
                ...getReportStatusStyle(okr.StatusReport),
                padding: "2px 8px", // Adjust padding to make the button smaller
                fontSize: "12px", // Decrease font size
                fontWeight: "normal", // Make the text less bold
                minWidth: "unset", // Remove minimum width restriction
              }}
              onClick={() => handleReportNavigation(okr.Id, okr.StatusReport)}
            >
              {okr.StatusReport.replace(/_/g, " ")}
            </Button> */}
            {isChallengeObjective ? (
              ""
            ) : (
              <Button
                variant="contained"
                sx={{
                  ...getReportStatusStyle(okr.StatusReport),
                  padding: "2px 8px", // Adjust padding to make the button smaller
                  fontSize: "12px", // Decrease font size
                  fontWeight: "normal", // Make the text less bold
                  minWidth: "unset", // Remove minimum width restriction
                }}
                onClick={() => handleReportNavigation(okr.Id, okr.StatusReport)}
              >
                {okr.StatusReport.replace(/_/g, " ")}
              </Button>
            )}
          </TableCell>
        </TableRow>

        {expanded[okr.Id] &&
          childObjectives[okr.Id]?.map((childOkr) =>
            renderObjectiveRow(childOkr, level + 1)
          )}
      </React.Fragment>
    );
  };

  return (
    <div>
      <h2>{title}</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", width: "30%" }}>
                Target
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                Creator
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                Progress
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                Key Results
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                Start Date
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                End Date
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                Status
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "10%" }}>
                Report
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress size={25} />
                </TableCell>
              </TableRow>
            ) : objectives.length > 0 ? (
              objectives.map((okr) => renderObjectiveRow(okr))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography>No {title}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <KeyResultsModal
        open={openModal}
        onClose={handleCloseModal}
        objectiveId={selectedObjectiveId}
      />
    </div>
  );
};

export default ReportOKRsList;
